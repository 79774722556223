import { doc, onSnapshot, collection } from "firebase/firestore";
import { db } from "../firebase/config";

const LongSnapshotFirebase = (company, deal_id, collName, docName, callback) => {
  const docRef = doc(
    collection(db, "companies", company, "deals", deal_id, collName),
    docName
  );

  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      if (snapshot.exists()) {
        callback(snapshot.data());
      } else {
        throw new Error("El documento no existe.");
      }
    },
    (error) => {
      throw new Error(`Error al escuchar el snapshot: ${error.message}`);
    }
  );

  return unsubscribe;
};

export default LongSnapshotFirebase;
