import React, { useEffect, useState } from "react";
import { colors } from "../../../styles/colors";
import ModalCarouselComponent from "../../Deal/components/inspection/ModalCarouselComponent";
import { MdAttachFile, MdPhoto, MdRemoveRedEye } from "react-icons/md";
import { HiOutlineDownload } from "react-icons/hi";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import AudioPlayerComponent from './AudioPlayerComponent';

export const FileMessageComponent = ({ id, user, file }) => {
  const [filesNoImage, setFilesNoImage] = useState([]);
  const [fileImage, setFileImage] = useState([]);
  let isPageWide = useMediaQuery("(min-width: 450px)");

  useEffect(() => {
    const filterFileImage = file.filter(
      (data) =>
        data?.type?.startsWith("image/") ||
        data?.type?.startsWith("video/")
    );
    setFileImage(filterFileImage);

    const filterFilesNoImage = file.filter(
      (data) =>
        data.type === "application/pdf" ||
        data.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        data.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        data.type === "audio/mpeg" ||
        data.type === "audio/mp3"
    );
    setFilesNoImage(filterFilesNoImage);
  }, [file]);

  const containerFile = {
    backgroundColor:
      id !== user.uid
        ? colors.primary.secondaryUltraLight
        : colors.primary.primary,
    borderRadius: "4px",
    width: "100%",
  };

  const textNameFile = {
    fontWeight: "400",
    fontSize: "14px",
    color:
      id !== user.uid ? colors.primary.secondaryDark : colors.neutral.light,
    textAlign: "left",
    width: "100%",
  };

  const [selectedMedia, setSelectedMedia] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = (index) => {
    setSelectedMedia(index);
    setIsModalOpen(true);
  };

  return (
    <>
      {filesNoImage?.map((data, index) => (
        <div style={containerFile} className=" mt-2" mt="10px" key={index}>
          <div className="d-flex align-items-center justify-content-between">
            {data.type === "audio/mpeg" || data.type === "audio/mp3" ? (
              <AudioPlayerComponent url={data.url} />
            ) : (
              <>
                <MdAttachFile
                  fontSize={24}
                  type="file"
                  color={
                    id !== user.uid ? colors.neutral.dark : colors.neutral.light
                  }
                />
                <p style={textNameFile} className="m-0 ms-1 me-2">
                  {data.name}
                </p>
                <a href={data.urlFirestore} target="_blank" rel="noreferrer">
                  <MdRemoveRedEye
                    fontSize={24}
                    color={
                      id !== user.uid ? colors.neutral.dark : colors.neutral.light
                    }
                  />
                </a>
                {data.url && (
                  <a href={`${data.url}`} download={data.name} rel="noreferrer">
                    <HiOutlineDownload
                      className="ms-2 me-2"
                      fontSize="22px"
                      color="white"
                    />
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      ))}
      <div style={containerFile} className=" mt-2" mt="10px">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          {fileImage?.slice(0, 3)?.map((data, index) => (
            <div className="d-flex align-items-start justify-content-center flex-column" key={index}>
              {data.url && (
                <a
                  href={`${data.url}`}
                  download={data.name}
                  rel="noreferrer"
                >
                  <HiOutlineDownload fontSize="22px" color="white" />
                </a>
              )}
              <img
                src={data.urlFirestore}
                alt="file-url"
                style={{
                  width: fileImage.length <= 1 ? "100%" : isPageWide ? "161px" : "115px",
                  height: fileImage.length <= 1 ? "330px" : isPageWide ? "161px" : "115px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() => handleImageClick(index)}
              />
            </div>
          ))}
          {fileImage.length > 3 && (
            <div
              className="d-flex align-items-center justify-content-center container-see-more-img mt-4"
              onClick={() => handleImageClick(0)}
            >
              <p className="text-see-more-img">+{fileImage.length - 3}</p>
            </div>
          )}
        </div>
      </div>
      <ModalCarouselComponent
        filesInspect={fileImage}
        modal={isModalOpen}
        setmodal={setIsModalOpen}
        selectedMedia={selectedMedia}
        setSelectedMedia={setSelectedMedia}
        download={true}
      />
    </>
  );
};
