import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import ModalChangeInspection from '../ModalChangeInspection';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';

export default function ChangeInspectionTypeButton({ user, inspType }) {
  const [modal, setModal] = useState(false);
  const [selectedType, setSelectedType] = useState(inspType); // Estado para el tipo seleccionado inicializado con inspType
  const [newType, setNewType] = useState(null); // Estado para el nuevo tipo seleccionado
  let isPageWide = useMediaQuery("(min-width: 450px)");

  useEffect(() => {
    setSelectedType(inspType);
  }, [inspType]);

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setNewType(value);
    setModal(true);
  };

  return (
    <>
      {isPageWide ? (
        <Form>
          <Form.Group className='d-flex align-items-center'>
            <Form.Label className='me-2 mb-0 change-insp-text'>Cambiar tipo de inspección:</Form.Label>
            <div>
              <Form.Check
                type="radio"
                label="Virtual"
                value="virtual"
                checked={selectedType === 'virtual'}
                onChange={handleRadioChange}
                inline
              />
              <Form.Check
                type="radio"
                label="Física"
                value="physical"
                checked={selectedType === 'physical'}
                onChange={handleRadioChange}
                inline
              />
            </div>
          </Form.Group>
        </Form>
      ) : (
        <Form className='mt-2'>
          <Form.Group>
            <Form.Label className='mb-0 change-insp-text'>Cambiar tipo de inspección:</Form.Label>
            <div className='d-flex'>
              <Form.Check
                type="radio"
                label="Virtual"
                value="virtual"
                checked={selectedType === 'virtual'}
                onChange={handleRadioChange}
                inline
                className='me-2'
              />
              <Form.Check
                type="radio"
                label="Física"
                value="physical"
                checked={selectedType === 'physical'}
                onChange={handleRadioChange}
                inline
              />
            </div>
          </Form.Group>
        </Form>
      )}
      <ModalChangeInspection
        modal={modal}
        setModal={setModal}
        user={user}
        newType={newType}
        setSelectedType={setSelectedType}
      />
    </>
  );
}
