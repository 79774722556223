import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "../../../../styles/style/dragAndDropFileStyle.css";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { colors } from "./../../../../styles/colors";
import { useUser } from "../../../../context/UserContext";
import { sendFile } from "../../services/TakeServices/AttachmentServices";
import DragAndDropComponent from "../../../../components/DragAndDropComponent";

export default function AttachmentTabComponent({
  disabled,
  takeData,
  toggleEditing,
  textStyle,
}) {
  const [filesTake, setFilesTake] = useState([]);
  const params = useParams();
  const { user } = useUser();

  React.useEffect(() => {
    setFilesTake(takeData?.attachments);
  }, [takeData]);

  const sendFilesTake = () => {
    sendFile(user, params.deal_id, filesTake);
    toast.success("Archivo guardado con exito.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    toggleEditing();
  };

  return (
    <>
      <DragAndDropComponent
        dataFiles={filesTake}
        setDataFiles={setFilesTake}
        disabled={disabled}
      />
      <div className="d-flex align-items-center justify-content-end">
        {!disabled ? (
          <Button onClick={sendFilesTake} disabled={disabled}>
            Guardar cambiosss
          </Button>
        ) : null}
      </div>
    </>
  );
}
