import { db } from "../../../firebase/config";
import { generateId } from "../../../utils/IdGenerator";
import { FaUser, FaUsers } from "react-icons/fa";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";

export const sendMessage = async (
  company,
  deal_id,
  uid,
  message,
  displayName,
  team,
  fecha,
  hora,
  files,
  mentions,
  allUsers,
  concatenatedArray,
  manual
) => {
  const mentionsOnlyIdAndName = mentions?.map((obj) => {
    const foundObj = concatenatedArray.find(
      (item) => item.display === obj.name
    );
    const foundMembers = allUsers.filter(
      (item) => item.team === foundObj.display
    );

    return foundMembers.length > 0
      ? { name: foundObj.display, id: foundObj.id, members: foundMembers }
      : { name: foundObj.display, id: foundObj.id };
  });

  // Crear referencia al documento específico en Firestore
  const commentsRef = doc(db, "companies", company, "deals", deal_id, "data", "comments");

  // Actualizar el campo "chat" usando arrayUnion
  await updateDoc(commentsRef, {
    chat: arrayUnion({
      message: `${message}`,
      uid: `${uid}`,
      id: generateId(),
      name: displayName,
      team: team,
      fecha: fecha,
      hora: hora,
      file: files,
      mentions: mentionsOnlyIdAndName,
      manual: manual,
    }),
  });
};

export const changePropertyName = (array) => {
  return array.map((obj) => {
    const { name, ...rest } = obj;
    return { display: name, ...rest };
  });
};

export const removeParentheses = (text) => {
  const regex = /\(([^)]+)\)/g;
  const result = text.replace(regex, "$1");
  return result;
};

export const renderSuggestion = (suggestion, search, highlightedDisplay) => {
  const isTeam = suggestion.type === "team";
  const color = isTeam ? "#864BFA" : "#31156D";

  return (
    <>
      <div className="d-flex align-items-center justify-content-start">
        {isTeam ? <FaUsers color={color} /> : <FaUser color={color} />}
        <div style={{ color }} className="ms-2">
          {highlightedDisplay}
        </div>
      </div>
    </>
  );
};
