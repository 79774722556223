import React, { useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { MdAdd } from 'react-icons/md';

import { FaTrashAlt } from 'react-icons/fa';
import '../../styles/usersView.css'
import InviteUserModalComponent from './InviteUserModalComponent';
import DeleteUserModalComponent from './DeleteUserModalComponent';
import TeamModalComponent from './TeamModalComponent';

export default function TeamsTableComponent({ selectedTeam, usersData, teams, fetchDataUsers }) {
  const [showEditTeamsModal, setShowEditTeamsModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowEditTeamsModal = (user) => {
    setSelectedUser(user);
    setShowEditTeamsModal(true);
  };

  const handleHideEditTeamsModal = () => {
    setSelectedUser(null);
    setShowEditTeamsModal(false);
  };
  const handleShowDeleteModal = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };
  const headTable = [
    "Usuario",
    "Mail",
    "Team",
    "",
  ];

  const teamsColors = {
    'CS': '--cs',
    'AS': '--as',
    'BackOffice': '--backoffice',
    'Inspeccion': '--inspector',
    'Pricing': '--pricing',
    'Admin': '--admin',
    'null': '--system',
  };

  const renderRoleButton = (rowData, RowTeam) => {
    const teamObject = teams.find(team => team.value === RowTeam);
    const teamLabel = teamObject ? teamObject.label : RowTeam;

    return (
      <Button
        key={RowTeam}
        style={{
          backgroundColor: `var(${teamsColors[RowTeam]})`,
          borderColor: `var(${teamsColors[RowTeam]})`
        }}
        onClick={() => handleShowEditTeamsModal({ ...rowData, team: RowTeam })}
      >
        {RowTeam === null || RowTeam === '' ? 'Asignar team' : teamLabel}
      </Button>
    );
  };
  return (
    <>
      <Table hover responsive className="mt-3 deals-table-style">
        <thead>
          <tr>
            {headTable.map((data, i) => (
              <th key={i} className='textHead'>
                {i === 3 ? (
                  <div className="right-align">
                    <Button variant='primary'
                      className='me-2'
                      onClick={() => setShowInviteUserModal(true)}>
                      Invitar usuario
                      <MdAdd />
                    </Button></div>
                ) : (
                  data
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {usersData?.map((rowData, i) => (
            (selectedTeam === null || rowData.team === selectedTeam) &&
            <tr key={i}>
              <td>
                <p className="text-content-table-deals">{rowData?.name}</p>
              </td>
              <td>
                <p className="text-content-table-deals not-capitalize">{rowData?.email.toLowerCase()}</p>
              </td>
              <td>
                {renderRoleButton(rowData, rowData.team)}
              </td>
              <td className="right-align">
                <FaTrashAlt className='trash-icon me-2' onClick={() => handleShowDeleteModal(rowData)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <DeleteUserModalComponent
        user={selectedUser}
        show={showDeleteModal}
        fetchDataUsers={fetchDataUsers}
        onHide={() => setShowDeleteModal(false)}
      />
      <InviteUserModalComponent
        show={showInviteUserModal}
        onHide={() => setShowInviteUserModal(false)} />
      <TeamModalComponent
        fetchDataUsers={fetchDataUsers}
        selectedUser={selectedUser}
        teams={teams}
        teamsColors={teamsColors}
        show={showEditTeamsModal}
        onHide={handleHideEditTeamsModal} />
    </>
  )
}
