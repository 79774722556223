import React, { useEffect, useState } from "react";
import { useUser } from "../../../context/UserContext";
import { useParams } from "react-router-dom";
import { getStatusColor } from "../../../utils/StatusColor";
import "../../../styles/style/PricingStyle.css";
import { StatusIconComponent } from "./../../../components";
import FinalOfferAdminModal from "./Modals/Admin/FinalOfferAdminModal";
import InitialOfferAdminModal from "./Modals/Admin/InitialOfferAdminModal";
import InicialRangelAdminModal from "./Modals/Admin/InitialRangeAdminModal";
import VirtualOfferAdminModal from "./Modals/Admin/VirtualOfferAdminModal";
import SalePricingAdminModal from "./Modals/Admin/SalePricingAdminModal";
import LongSnapshotFirebase from "../../../utils/LongSnapshotFirebase";
import { colors } from "../../../styles/colors";
import { formatValue } from "../services/formatPrice";

export default function AdminPricingComponent({
  finalOffer,
  rangeInitial,
  initialOffer,
  virtualOffer,
  salePrice,
  setActiveModalPricing,
}) {
  const { deal_id } = useParams();
  const { user } = useUser();

  const [showRangeInitial, setshowRangeInitial] = useState(false);
  const [showOfferInitial, setshowOfferInitial] = useState(false);
  const [showOfferFinal, setshowOfferFinal] = useState(false);
  const [showOfferVirtual, setshowOfferVirtual] = useState(false);
  const [showSalePrice, setShowSalePrice] = useState(false);

  const [actionId, setactionId] = useState();
  const [revisionActionId, setrevisionActionId] = useState();
  const [sendActionId, setsendActionId] = useState();
  const [valueCotizacion, setvalueCotizacion] = useState("");

  const [dataHistoryOfferI, setdataHistoryOfferI] = useState();
  const [dataHistoryFinalOffer, setdataHistoryFinalOffer] = useState();
  const [dataHistoryRange, setdataHistoryRange] = useState();
  const [dataHistoryVirtualOffer, setdataHistoryVirtualOffer] = useState();
  const [dataHistory, setdataHistory] = useState();

  useEffect(() => {
    const callbackPricing = (dataPricing) => {
      setdataHistoryOfferI(dataPricing?.offer.history);
      setdataHistoryFinalOffer(dataPricing?.final_offer.history);
      setdataHistoryRange(dataPricing?.range.history);
      setdataHistoryVirtualOffer(dataPricing?.virtual_offer.history);
    };
    const unsubscribePricing = LongSnapshotFirebase(
      user?.company,
      deal_id,
      "data",
      "pricing",
      callbackPricing
    );
    return () => {
      unsubscribePricing();
    };
  }, [deal_id, showRangeInitial, user?.company]);

  const handleShowRangeInitial = (value) => {
    setshowRangeInitial(true);
    setactionId("range");
    setsendActionId("range-accepted");
    setrevisionActionId("check-range");
    setvalueCotizacion(value);
    setdataHistory(dataHistoryRange);
  };

  const handleShowOfferInitial = (value) => {
    setshowOfferInitial(true);
    setactionId("offer-i");
    setsendActionId("offer-i-accepted");
    setrevisionActionId("check-offer-i");
    setvalueCotizacion(value);
    setdataHistory(dataHistoryOfferI);
  };

  const handleShowOfferVirtual = (value) => {
    setshowOfferVirtual(true);
    setactionId("offer-v");
    setsendActionId("offer-v-accepted");
    setrevisionActionId("check-offer-v");
    setvalueCotizacion(value);
    setdataHistory(dataHistoryVirtualOffer);
  };

  const handleShowOfferFinal = (value) => {
    setshowOfferFinal(true);
    setactionId("offer-f");
    setsendActionId("offer-f-accepted");
    setrevisionActionId("check-offer-f");
    setvalueCotizacion(value);
    setdataHistory(dataHistoryFinalOffer);
  };

  const handleShowSalePrice = (value) => {
    setShowSalePrice(true);
    setvalueCotizacion(value);
  };

  return (
    <>
      {rangeInitial?.enable === true ? (
        <div
          className="containerPricing"
          onClick={() => {
            handleShowRangeInitial(rangeInitial?.value);
            setActiveModalPricing(true);
          }}
        >
          <p className="textTitle">Rango inicial</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(rangeInitial?.status),
              }}
            >
              {rangeInitial?.value === ""
                ? "$00.000.000"
                : rangeInitial?.value.startsWith("$")
                ? rangeInitial?.value
                : "$" + rangeInitial?.value}
            </p>
            {rangeInitial?.status === "" ? (
              <span className="status-icon"></span>
            ) : (
              <StatusIconComponent status={rangeInitial?.status} />
            )}
          </div>
        </div>
      ) : null}
      {initialOffer?.enable === true ? (
        <div
          className="containerPricing"
          onClick={() => {
            handleShowOfferInitial(initialOffer?.value);
            setActiveModalPricing(true);
          }}
        >
          <p className="textTitle">Oferta inicial</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(initialOffer?.status),
              }}
            >
              {initialOffer?.value === ""
                ? "$00.000.000"
                : initialOffer?.value.startsWith("$")
                ? initialOffer?.value
                : "$" + initialOffer?.value}
            </p>
            {initialOffer?.status === "" ? (
              <span className="status-icon"></span>
            ) : (
              <StatusIconComponent status={initialOffer?.status} />
            )}
          </div>
        </div>
      ) : null}
      {virtualOffer?.enable === true ? (
        <div
          className="containerPricing"
          onClick={() => {
            handleShowOfferVirtual(virtualOffer?.value);
            setActiveModalPricing(true);
          }}
        >
          <p className="textTitle">Oferta virtual</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(virtualOffer?.status),
              }}
            >
              {virtualOffer?.value === ""
                ? "$00.000.000"
                : "$" + virtualOffer?.value}
            </p>
            {virtualOffer?.status === "" ? (
              <span className="status-icon"></span>
            ) : (
              <StatusIconComponent status={virtualOffer?.status} />
            )}
          </div>
        </div>
      ) : null}
      {finalOffer?.enable === true ? (
        <div
          className="containerPricing"
          onClick={() => {
            handleShowOfferFinal(finalOffer?.value);
            setActiveModalPricing(true);
          }}
        >
          <p className="textTitle">Oferta final</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(finalOffer?.status),
              }}
            >
              {finalOffer?.value === ""
                ? "$00.000.000"
                : "$" + finalOffer?.value}
            </p>

            {finalOffer?.status === "" ? (
              <span className="status-icon"></span>
            ) : (
              <StatusIconComponent status={finalOffer?.status} />
            )}
          </div>
        </div>
      ) : null}

      {salePrice?.enable === true ? (
        <div
          className="containerPricing"
          onClick={() => {
            handleShowSalePrice(salePrice?.value_price);
            setActiveModalPricing(true);
          }}
        >
          <p className="textTitle">Precio de venta</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                // color: getStatusColor(salePrice?.status),
                color:
                  salePrice?.value_price !== ""
                    ? colors.primary.primary
                    : colors.primary.secondaryLight,
              }}
            >
              {salePrice?.value_price === ""
                ? "$00.000.000"
                : "$" + salePrice?.value_price}
              {salePrice?.value_days === ""
                ? ""
                : " - " + salePrice?.value_days + " días"}
              {salePrice?.status}
            </p>
            {salePrice?.status === "" ? (
              <span className="status-icon"></span>
            ) : (
              <StatusIconComponent status={salePrice?.status} />
            )}
          </div>
        </div>
      ) : null}
      <InicialRangelAdminModal
        deal_id={deal_id}
        setshowRangeInitial={setshowRangeInitial}
        showRangeInitial={showRangeInitial}
        user={user}
        dataHistory={dataHistory}
        actionId={actionId}
        sendActionId={sendActionId}
        revisionActionId={revisionActionId}
        valueCotizacion={valueCotizacion}
        status={rangeInitial?.status}
        setActiveModalPricing={setActiveModalPricing}
      />
      <InitialOfferAdminModal
        deal_id={deal_id}
        setshowOfferInitial={setshowOfferInitial}
        showOfferInitial={showOfferInitial}
        user={user}
        dataHistory={dataHistory}
        actionId={actionId}
        sendActionId={sendActionId}
        revisionActionId={revisionActionId}
        valueCotizacion={valueCotizacion}
        status={initialOffer?.status}
        setActiveModalPricing={setActiveModalPricing}
      />
      <VirtualOfferAdminModal
        deal_id={deal_id}
        setshowOfferVirtual={setshowOfferVirtual}
        showOfferVirtual={showOfferVirtual}
        user={user}
        dataHistory={dataHistory}
        actionId={actionId}
        sendActionId={sendActionId}
        revisionActionId={revisionActionId}
        valueCotizacion={valueCotizacion}
        status={virtualOffer?.status}
        setActiveModalPricing={setActiveModalPricing}
      />
      {/* 
  rangeInitial,
  virtualOffer,
, */}
      <FinalOfferAdminModal
        deal_id={deal_id}
        setshowOfferFinal={setshowOfferFinal}
        showOfferFinal={showOfferFinal}
        user={user}
        dataHistory={dataHistory}
        actionId={actionId}
        sendActionId={sendActionId}
        revisionActionId={revisionActionId}
        valueCotizacion={valueCotizacion}
        status={finalOffer?.status}
        setActiveModalPricing={setActiveModalPricing}
      />
      <SalePricingAdminModal
        deal_id={deal_id}
        setShowSalePrice={setShowSalePrice}
        showSalePrice={showSalePrice}
        user={user}
        dataHistory={dataHistory}
        actionId={actionId}
        sendActionId={sendActionId}
        revisionActionId={revisionActionId}
        valueCotizacion={valueCotizacion}
        status={salePrice?.status}
        setActiveModalPricing={setActiveModalPricing}
      />
    </>
  );
}
