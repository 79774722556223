import React, { useEffect, useState } from "react";
import { Modal, Row, Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { colors } from "../../../../styles/colors";
import { useUser } from "../../../../context/UserContext";
import InputComponent from "../../../../components/InputComponent";
import {
  createPayments,
  editPayments,
} from "../../services/TakeServices/PaymentServices";
import { formatNumber } from "../../../Pricing/services/servicesPricing";

export default function ModalPaymentScheduleComponent({
  setModal,
  modal,
  isEditing,
  setIsEditing,
  dataEditPayment,
  payments,
}) {
  const params = useParams();
  const { user } = useUser();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [valueTipoOperacion, setvalueTipoOperacion] = useState();
  const [valueMonto, setvalueMonto] = useState("");
  const [valuePorcentaje, setvaluePorcentaje] = useState("");

  useEffect(() => {
    if (isEditing) {
      setValue("titular", dataEditPayment?.titular);
      setValue("tipoOperacion", dataEditPayment?.tipoOperacion);
      setValue("monto", dataEditPayment?.monto);
      setValue("porc", dataEditPayment?.porc);
      setValue("moneda", dataEditPayment?.moneda);
      setValue("fechaAPagar", dataEditPayment?.fechaAPagar);
      setValue("fechaPago", dataEditPayment?.fechaPago);
    }
  }, [dataEditPayment, isEditing, setValue]);

  const editPaymentData = (data) => {
    const newObj = Object.assign({}, dataEditPayment, data);
    const indexPayment = payments.findIndex(
      (item) => item.payment_id === dataEditPayment.payment_id
    );
    payments[indexPayment] = newObj;
    editPayments(params.deal_id, payments, user);
    reset();
    setModal(false);
  };

  const addPayment = (data) => {
    if (isEditing) {
      //editar
      editPaymentData(data);
      toast.success("Informacion editada con exito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
      setvalueTipoOperacion();
      setvalueMonto("");
      setvaluePorcentaje("");
      reset();
      setModal(false);
    } else if (valueMonto !== "$" && valuePorcentaje !== "%") {
      // crear
      const estado = "pendiente";
      const file = "";
      createPayments(
        params.deal_id,
        data.titular,
        data.tipoOperacion,
        data.monto,
        data.porc,
        data.moneda,
        data.fechaAPagar,
        data.fechaPago,
        estado,
        file,
        user
      );
      toast.success("Informacion Guardada con exito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
      setvalueTipoOperacion();
      setvalueMonto("");
      setvaluePorcentaje("");
      reset();
      setModal(false);
    }
  };

  const handleClose = () => {
    reset();
    setIsEditing(false);
    setModal(false);
  };

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
  };

  const textError = {
    margin: "0px",
    color: colors.status.red,
    fontSize: "12px",
    width: "100%",
  };

  const inputError = {
    borderColor: colors.status.red,
  };

  const handleInputChange = (e) => {
    setvalueTipoOperacion(e.target.value);
  };

  const handleInputChangeMonto = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setvalueMonto(`$${formattedValue}`);
  };

  const handleInputChangePorcentaje = (event) => {
    const inputValueWithoutPercentage = event.target.value;

    if (
      inputValueWithoutPercentage === "" ||
      /^[1-9][0-9]?$|^100$/.test(inputValueWithoutPercentage)
    ) {
      setvaluePorcentaje(inputValueWithoutPercentage);
    }
  };

  return (
    <>
      <Modal size="lg" show={modal} onHide={handleClose}>
        <form onSubmit={handleSubmit(addPayment)}>
          <Modal.Header closeButton>
            <Modal.Title className="deal-title">
              {isEditing ? "Editar" : "Crear"} Pago
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6} className="mb-2">
                <p style={textStyle} className="mb-2">
                  Titular
                </p>
                <input
                  {...register("titular", {
                    required: true,
                  })}
                  className="input-deal"
                  type="text"
                />
                {errors.titular && (
                  <p style={textError} className="mt-1">
                    El campo titular es requerido
                  </p>
                )}
              </Col>
              <Col lg={6}>
                <p style={textStyle} className="mb-2">
                  Tipo de operación
                </p>
                <Form.Select
                  className="select-order"
                  style={{ width: "100%", color: colors.primary.secondaryTwo }}
                  {...register("tipoOperacion")}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option>Transferencia</option>
                  <option>Efectivo</option>
                  <option>Permuta</option>
                  <option>Cheque a 30 días</option>
                  <option>Cheque a 60 días</option>
                  <option>Cheque a 90 días</option>
                  <option>Cheque a 120 días</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <p style={textStyle} className="mb-2">
                  Monto
                </p>
                <input
                  className="input-deal"
                  {...register("monto", {
                    required: true,
                  })}
                  value={valueMonto}
                  onChange={handleInputChangeMonto}
                />
                {errors.monto || valueMonto === "$" ? (
                  <p style={textError} className="mt-1">
                    El campo monto es requerido
                  </p>
                ) : null}
              </Col>
              <Col lg={6}>
                <p style={textStyle} className="mb-2">
                  Porcentaje
                </p>
                <input
                  type="number"
                  disabled={valueTipoOperacion === "Permuta" ? true : false}
                  className="input-deal"
                  style={{ width: "100%" }}
                  {...register("porc", {
                    required: valueTipoOperacion !== "Permuta" ? true : false,
                  })}
                  value={valuePorcentaje}
                  onChange={handleInputChangePorcentaje}
                  max={100}
                />
                {errors.porc || valuePorcentaje === "%" ? (
                  <p style={textError} className="mt-1">
                    El campo porcentaje es requerido
                  </p>
                ) : null}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col lg={6} className="mt-1">
                <p style={textStyle} className="mb-2">
                  Moneda
                </p>
                <Form.Select
                  className="select-order"
                  style={{ width: "100%", color: colors.primary.secondaryTwo }}
                  {...register("moneda")}
                >
                  <option value="ARS">Pesos (ARS)</option>
                  <option value="USD">Dólares (USD)</option>
                </Form.Select>
              </Col>
              <Col lg={6} className="mt-1">
                <div>
                  <p style={textStyle} className="mb-2">
                    Fecha a pagar
                  </p>
                  <input
                    disabled={valueTipoOperacion === "Permuta" ? true : false}
                    style={{ width: "100%" }}
                    className="input-deal"
                    type="date"
                    {...register("fechaAPagar", {
                      required: valueTipoOperacion !== "Permuta" ? true : false,
                    })}
                  />
                </div>
                {errors.fechaAPagar ? (
                  <p style={textError} className="mt-1">
                    El campo fecha a pagar es requerido
                  </p>
                ) : null}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col lg={6} className="mt-1">
                <p style={textStyle} className="mb-2">
                  Fecha de pago
                </p>
                <InputComponent
                  disabled={valueTipoOperacion === "Permuta" ? true : false}
                  placeholder=""
                  type="date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  register={register}
                  valueRegister={"fechaPago"}
                />
              </Col>
              <Col lg={6}></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              {isEditing ? "Editar" : "Crear"} Pago
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
