import React, { useEffect, useState } from "react";
import { Badge, OverlayTrigger, Popover, Modal, Button, Spinner } from "react-bootstrap";
import { colors } from "../../../../styles/colors";
import { ImWhatsapp } from "react-icons/im";
import { FiPlusCircle } from "react-icons/fi";
import {
  ModalActions,
  ModalAddActions,
  ReminderModalComponent,
} from "../../../States/components";
import { useUser } from "../../../../context/UserContext";
import { useCompany } from "../../../../context/CompanyContext";
import { useParams } from "react-router-dom";
import useFirebaseOnSnapshot from "../../../../hooks/useFirebaseOnSnapshot";
import { teams } from "../../../../constants/teams";
import { MdOutlineNotificationAdd } from "react-icons/md";
import "../../../../styles/style/DealActionsSection.css";
import { SmallSpinnerComponent } from "../../../../components";
import { TbPhoneCall } from "react-icons/tb";
import { postCallAnura } from "../../../Comments/services/callAnuraService";

export default function DealActionsCardSectionComponent({ basics, deal }) {
  const params = useParams();
  const { user } = useUser();
  const { companyData, actions } = useCompany();
  const { deal_id } = useParams();
  const [modal, setmodal] = useState(false);
  const [modalAddActions, setmodalAddActions] = useState(false);
  const [callModal, setCallModal] = useState(false);
  const [ticketId, setticketId] = useState();
  const [status, setstatus] = useState();
  const [actionsOpen, setactionsOpen] = useState();
  const [showModalRevision, setshowModalRevision] = useState();
  const [nameAction, setnameAction] = useState();
  const [actionId, setactionId] = useState();
  const [idAction, setidAction] = useState();
  const [dateAction, setdateAction] = useState();
  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");
  const [callLoading, setCallLoading] = useState(false);
  const [callResponse, setCallResponse] = useState(null);
  const [callModalTitle, setCallModalTitle] = useState("Llamada en curso"); // Nuevo estado para el título del modal
  const { data, loading } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "sections_status"
  );

  function setReasonAndComment(text) {
    if (text) {
      const parts = text.split(",");
      if (parts.length === 2) {
        const reason = parts[0].trim();
        const comment = parts[1].trim();
        setReason(reason);
        setComment(comment);
      } else {
        setReason(text);
      }
    }
  }

  useEffect(() => {
    setReasonAndComment(basics?.car?.closed_desc);
    setticketId(
      deal?.source === "landing_san_jorge" ||
        deal?.source === "san_jorge" ||
        deal?.source === ""
        ? deal?.trengo_ticket_id
        : deal?.trengo_auta_ticket_id
    );
    setstatus(deal?.status);
    setactionsOpen(deal?.actions?.data_actions);
  }, [basics, data, deal, params.deal_id, user.company]);

  const handleCallClick = async () => {
    setCallModal(true);
    setCallLoading(true);
    try {
      const response = await postCallAnura({ deal_id: deal_id, user_id: user?.uid, company: user?.company });
      if (response === 'ok') {
        setCallModalTitle("Llamada en curso");
        setCallResponse("Debes ejecutar la llamada desde Anura para realizar la llamada.");
        // window.open('https://userpanel.anura.com.ar/', '_blank'); // Abrir la URL si la respuesta es 'ok'
      } else {
        setCallModalTitle("Error al realizar la llamada");
        setCallResponse("Asegúrate de que la aplicación Anura esté abierta en tu computadora.");
      }
    } catch (error) {
      setCallModalTitle("Error al realizar la llamada");
      setCallResponse("Asegúrate de que la aplicación Anura esté abierta en tu computadora.");
    } finally {
      setCallLoading(false);
    }
  };

  return (
    <div className="container-card-deal-layout">
      {loading ? (
        <SmallSpinnerComponent />
      ) : (
        <>
          <div className="status-and-icons-container w-100">
            <span>
              <p
                className="mt-2"
                style={{
                  color:
                    status?.status === "CERRADO"
                      ? colors.status.red
                      : status?.status === "ABIERTO"
                        ? colors.status.blue
                        : colors.neutral.green,
                  fontWeight: "600",
                  fontSize: "16px",
                  marginBottom: "0px",
                }}
              >
                {status?.status
                  ?.toLowerCase()
                  .replace(/\b\w/g, (c) => c.toUpperCase())}
              </p>
            </span>
            <span>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 200 }}
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Body>Llamar al cliente</Popover.Body>
                  </Popover>
                }
              >
                <span>
                  <TbPhoneCall
                    fontSize="24px"
                    color={colors.primary.primary}
                    className="status-and-icons"
                    onClick={handleCallClick} />
                </span>
              </OverlayTrigger>
              {companyData?.settings?.add_actions.activated ? (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 200 }}
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Body>Agregar acción</Popover.Body>
                    </Popover>
                  }
                >
                  <span>
                    <FiPlusCircle
                      fontSize="22px"
                      color={colors.primary.primary}
                      className="status-and-icons"
                      onClick={() => setmodalAddActions(true)}
                    />
                  </span>
                </OverlayTrigger>
              ) : null}
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 200 }}
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Body>Agregar recordatorio</Popover.Body>
                  </Popover>
                }
              >
                <span>
                  <MdOutlineNotificationAdd
                    fontSize="24px"
                    color={colors.primary.primary}
                    className="status-and-icons"
                    onClick={() => setmodal(true)}
                  />
                </span>
              </OverlayTrigger>
              {ticketId ? (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 200 }}
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Body>Ir a Trengo</Popover.Body>
                    </Popover>
                  }
                >
                  <span>
                    <a
                      href={`https://app.trengo.com/tickets/${ticketId}`}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <ImWhatsapp
                        fontSize="20px"
                        color="#00BB2D"
                        className="status-and-icons"
                      />
                    </a>
                  </span>
                </OverlayTrigger>
              ) : null}
            </span>
          </div>
          {status?.status === "CERRADO" ? (
            <>
              <p
                style={{
                  marginBottom: "8px",
                  fontSize: "14px",
                  color: "#AA0000",
                }}
              >
                {reason || "No hay motivo"}
              </p>
              <p className="offer-date">{comment || "No hay comentario"}</p>
            </>
          ) : (
            <p
              style={{
                marginBottom: "10px",
                fontSize: "14px",
                color:
                  status?.status === "ABIERTO"
                    ? "#1162A6"
                    : colors.status.green,
              }}
            >
              {status?.phase
                ?.toLowerCase()
                .replace(/\b\w/g, (c) => c.toUpperCase())}
            </p>
          )}
          <div className="actions-container">
            {actionsOpen?.map((info, i) => (
              <span key={info.id}>
                {info.status !== "open" ? null : (
                  <>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 200 }}
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Body>
                            {actions[info.action_id]?.title}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <span
                        onClick={() => {
                          setactionId(info.action_id);
                          setidAction(info.id);
                          setnameAction(info.action);
                          setdateAction(info.date);
                          if (
                            user?.team === info.team ||
                            user?.team === teams.ADMIN
                          ) {
                            setshowModalRevision(true);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="position-relative me-1 ms-1"
                          style={{
                            marginRight: "6px",
                            height: "30px",
                            display: "flex",
                          }}
                        >
                          <img
                            src={actions[info.action_id]?.svg}
                            alt="acciones"
                            style={{
                              width: "22px",
                              height: "22px",
                              marginTop: "5px",
                            }}
                          />
                          {info?.action_counter ? (
                            <Badge
                              pill
                              className="position-absolute translate-middle d-flex align-items-center justify-content-center"
                              style={{
                                zIndex: 3,
                                left: "20px",
                                top: "3px",
                                width: "20px",
                                height: "20px",
                                fontSize: "10px",
                                paddingLeft: "5px",
                              }}
                            >
                              <p style={{ margin: "0px" }}>
                                {info?.action_counter}
                              </p>
                            </Badge>
                          ) : null}
                        </span>
                      </span>
                    </OverlayTrigger>
                  </>
                )}
              </span>
            ))}
          </div>
          <ModalActions
            showModalRevision={showModalRevision}
            setshowModalRevision={setshowModalRevision}
            user={user}
            deal_id={deal_id}
            actionId={actionId}
            idAction={idAction}
            nameAction={nameAction}
            date={dateAction}
          />
          <ReminderModalComponent
            modal={modal}
            setmodal={setmodal}
            user={user}
            deal_id={deal_id}
          />
          <ModalAddActions
            modal={modalAddActions}
            setModal={setmodalAddActions}
            actions={actions}
            user={user}
            deal_id={deal_id}
          />
          <Modal show={callModal} onHide={() => setCallModal(false)} >
            <Modal.Header >
              <Modal.Title>{callModalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
              {callLoading ? (
                <Spinner animation="border" role="status" aria-hidden="true" style={{ color: colors.primary.primary }} />
              ) : (
                <p className="">{callResponse}</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => setCallModal(false)}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
}
