import { useEffect, useState } from "react";
import { Table, Tab, Tabs } from "react-bootstrap";

const CamaraValComponent = ({ data }) => {
  const [year, setyear] = useState();
  const [dataCamaraVal, setdataCamaraVal] = useState();

  useEffect(() => {
    setyear(data?.base_year);
    setdataCamaraVal(data?.camara_val);
  }, [data]);

  return (
    <Tabs id="uncontrolled-tab-example" className="mb-3">
      {dataCamaraVal?.map((info, i) =>
        info.models.map((info2) => (
          <Tab
            key={i}
            eventKey={`${info2.model}`}
            title={`${info2.model} (${Math.trunc(info2.match_score)}%)`}
          >
            {/* <p>{info2.match_score}</p> */}
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th className="text-head-table">Marca</th>
                  <th className="text-head-table">Modelo</th>
                  <th className="text-head-table">Version</th>
                  <th className="text-head-table">Año</th>
                  <th className="text-head-table">Precio {Number(year) - 1}</th>
                  <th className="text-head-table">Precio {year}</th>
                  <th className="text-head-table">Precio {Number(year) + 1}</th>
                  <th className="text-head-table">Score</th>
                </tr>
              </thead>
              <tbody>
                {info2.versions?.map((info3) => (
                  <tr key={info3.price}>
                    <td className="text-content-table">{info.brand}</td>
                    <td className="text-content-table">{info2.model}</td>
                    <td className="text-content-table">{info3.version}</td>
                    <td className="text-content-table">{year}</td>
                    <td className="text-content-table">
                      {/* ${info3.price_prev_year} */}
                      {info3.price_prev_year !== ""
                        ? `$ ${Number(info3.price_prev_year).toLocaleString(
                          "de"
                        )}`
                        : "-"}
                    </td>
                    <td className="text-content-table">
                      {info3.price !== ""
                        ? `$ ${Number(info3.price).toLocaleString("de")}`
                        : "-"}
                    </td>
                    <td className="text-content-table">
                      {info3.price_next_year !== ""
                        ? `$ ${Number(info3.price_next_year).toLocaleString(
                          "de"
                        )}`
                        : "-"}
                    </td>
                    <td className="text-content-table">
                      {info3.match_score} %
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Tab>
        ))
      )}
    </Tabs>
  );
};

export default CamaraValComponent;
