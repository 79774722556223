import { apiUrl, axiosInstance, handleError } from "../../../services/apiConfig";

export const postCallAnura = async ({ deal_id, user_id, company }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/anura/call`, {
      deal_id, user_id, company
    });
    return response.data;
  } catch (error) {
    handleError(error);
    throw error; // Lanzar el error después de manejarlo
  }
};