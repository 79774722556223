import React from "react";
import { Modal, Table } from "react-bootstrap";
import NavigateDealIcon from "../modules/Deal/images/navigate-arrow-icon.svg";
import { colors } from "../styles/colors";
import { useNavigate, useParams } from "react-router-dom";
import ButtonCloseDeal from "../modules/Menu/ButtonCloseDeal";
import { useUser } from "../context/UserContext";

const ModalHistoryDealsComponent = ({ modal, handleEvent, dataHistory }) => {
  const navigate = useNavigate();
  const { deal_id } = useParams();
  const { user } = useUser();

  const titleModal = {
    color: colors.primary.secondaryTwo,
    fontWeight: "500",
    fontSize: "18px",
    margin: "0px",
  };

  function formatNumberWithCommas(numberOrString) {
    let number = parseFloat(numberOrString);

    if (isNaN(number)) {
      return "Invalid input";
    }
    const isFloat = number % 1 !== 0;

    const formattedNumber = isFloat
      ? number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return formattedNumber;
  }

  return (
    <Modal show={modal} onHide={handleEvent} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <p style={titleModal}>Historial de deals</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {dataHistory?.length > 0 ? (
          <Table className="deals-table-style" >
            <thead>
              <tr>
                <th className="textHead">Fecha</th>
                <th className="textHead">Fecha actualización</th>
                <th className="textHead" colSpan="7">
                  Vehículo
                </th>
              </tr>
            </thead>
            <tbody>
              {dataHistory?.map((info, index) =>
                info.car ? (
                  <tr key={index}>
                    <td>
                      <p
                        className="text-content-table-deals"
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {info?.date
                          ? typeof info?.date === "string"
                            ? new Date(info?.date).toLocaleDateString()
                            : new Date(
                              info.date.seconds * 1000
                            ).toLocaleDateString()
                          : null}
                      </p>
                    </td>
                    <td>
                      <p
                        className="text-content-table-deals"
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {info?.updated
                          ? typeof info?.updated === "string"
                            ? new Date(info?.updated).toLocaleDateString()
                            : new Date(
                              info.updated.seconds * 1000
                            ).toLocaleDateString()
                          : null}
                      </p>
                    </td>
                    <td>
                      <p
                        className="text-content-table-deals"
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {info.car.brand}
                      </p>
                    </td>
                    <td>
                      <p
                        className="text-content-table-deals"
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {info.car.model}
                      </p>
                    </td>
                    <td>
                      <p
                        className="text-content-table-deals"
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {info.car.version}
                      </p>
                    </td>
                    <td>
                      <p
                        className="text-content-table-deals"
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {info.car.year}
                      </p>
                    </td>
                    <td>
                      <p
                        className="text-content-table-deals"
                        style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {formatNumberWithCommas(info.car.kms)} kms
                      </p>
                    </td>
                    <td>
                      <div style={{ marginTop: "6px" }}>
                        <ButtonCloseDeal deal_id={info.deal} user={user} />
                      </div>
                    </td>
                    <td>
                      <img
                        src={NavigateDealIcon}
                        alt="navigate-arrow"
                        style={{ cursor: "pointer" }}
                        className="mt-1"
                        onClick={() => {
                          navigate(`deal/${info.deal}`);
                          handleEvent();
                        }}
                      />
                    </td>
                  </tr>
                ) : null
              )}
            </tbody>
          </Table>
        ) : (
          <p>No se encontraron resultados con los datos ingresados.</p>
        )}
      </Modal.Body>
    </Modal >
  );
};

export default ModalHistoryDealsComponent;
