import React from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { uploadFile } from "../../../../firebase/config";
import { toast } from "react-hot-toast";
import { colors } from "../../../../styles/colors";
import { RiDeleteBin6Fill } from "react-icons/ri";
import "../../../../styles/style/photoAttachmentStyle.css";
import SmallSpinnerComponent from "../../../../components/SmallSpinnerComponent";
import { editPayments } from "../../services/TakeServices/PaymentServices";
import { useUser } from "../../../../context/UserContext";
import { useParams } from "react-router-dom";
import { generateId } from "../../../../utils/IdGenerator";
import Swal from "sweetalert2";

export default function ModalPaymentFileComponent({
  setModal,
  modal,
  payments,
  dataEditPayment,
}) {
  const handleClose = () => setModal(false);
  const [fileC, setFileC] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { user } = useUser();
  const params = useParams();

  const sendFiletest = async (e) => {
    setIsLoading(true);
    const result = await uploadFile(e.target.files[0]);
    const fileData = {
      name: e.target.files[0].name,
      size: e.target.files[0].size,
      urlFirestore: result,
      url: URL.createObjectURL(e.target.files[0]),
      id: generateId(),
    };
    setFileC([fileData]);
    setIsLoading(false);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFiles = [...e.dataTransfer.files];
    Array.from(droppedFiles).forEach(async (file) => {
      const result = await uploadFile(file);
      setFileC((state) => [
        ...state,
        {
          name: file.name,
          size: file.size,
          urlFirestore: result,
          url: URL.createObjectURL(file),
          id: generateId(),
        },
      ]);
    });
  };

  const handleSubmit = () => {
    if (fileC.length > 1) {
      Swal.fire({
        title: "Solo podés adjuntar un solo comprobante.",
        text: `Elimina comprobantes para solo adjuntar uno`,
        icon: "info",
        confirmButtonText: "Okey",
      });
    } else {
      dataEditPayment.file = fileC;
      const indexPayment = payments.findIndex(
        (item) => item.id === dataEditPayment.id
      );
      payments[indexPayment] = dataEditPayment;

      editPayments(params.deal_id, payments, user);
      toast.success("Informacion editada con exito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
      setFileC([]);
      handleClose();
    }
  };

  const handledRemoveFiles = (data) => {
    setFileC((state) => state.filter((item) => item.url !== data.url));
  };

  return (
    <Modal size="md" show={modal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="deal-title">Cronograma de pago</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="mt-2 drag-and-drop-zone d-flex align-items-center justify-content-center"
          style={{ width: "100%", margin: "0px" }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <label
            htmlFor="upload-files"
            className="attch-input"
            style={{ marginTop: "0px", textAlign: "center" }}
          >
            Arrastra archivos aquí o <br />
            <span className="attach-icon-text">
              selecciona el archivo desde tu computadora
            </span>{" "}
          </label>
          <input
            type="file"
            id="upload-files"
            multiple={true}
            onChange={sendFiletest}
            style={{ display: "none" }}
          />
        </div>
        <Row className="mt-2">
          {!fileC ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              {isLoading ? <SmallSpinnerComponent /> : ""}
            </div>
          ) : (
            fileC?.map((data, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="attach-text-kb">{data?.name}</p>
                <RiDeleteBin6Fill
                  className="delete-attach-icon"
                  onClick={() => {
                    handledRemoveFiles(data);
                  }}
                />
              </div>
            ))
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            setFileC(null);
            handleClose();
          }}
        >
          Cancelar
        </Button>
        <Button variant="primary" disabled={!fileC} onClick={handleSubmit}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
