import { db } from "../../firebase/config";
import { collection, query, where, and, onSnapshot, getDocs, getDoc, doc } from "firebase/firestore";


const isSameDate = function (date, last_appointment_date) {
  const compareDate1 = new Date(last_appointment_date); // Get the current date
  compareDate1.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

  const compareDate2 = new Date(date); // Convert the input date to a Date object
  compareDate2.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

  return compareDate1.getTime() === compareDate2.getTime();
}


const formatDate = function (date) {
  return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
}


export class CheckView {
  constructor(filters, companyData, user) {
    this.user = user || null;
    this.companyData = companyData || null;
    this.filters = filters || null;
    this.loadMore = false;
    this.countingDealsPending = false;
  }


  getDatesForQuery() {
    let fromDate = this.filters.selectedDates.from
    let toDate = this.filters.selectedDates.to
    if (fromDate === '') {
      fromDate = new Date()
      fromDate.setDate(fromDate.getDate());
      fromDate.setHours(0, 0, 0, 0);

    }
    if (toDate === '') {
      toDate = new Date()
      toDate.setDate(toDate.getDate() + 1);
      toDate.setHours(0, 0, 0, 0);
    }
    return [fromDate, toDate]
  }


  buildQuery() {
    let [fromDate, toDate] = this.getDatesForQuery()
    return query(collection(db, "companies", this.user?.company, "deals"), and(
      where('appointment_data.check.date', ">=", fromDate),
      where('appointment_data.check.date', "<", toDate)
    ));
  }


  getLoadMore(snapshotDeals) {
    return
  }


  async processDeals(firebaseDeals, setDeals, setLoadingDeals) {
    let processedDeals = [];
    let processedDealsIds = [];
    let [fromDate, toDate] = this.getDatesForQuery()
    for (const deal of firebaseDeals) {
      if (deal.status === "created_from_landing") {
        continue;
      }
      if (!processedDealsIds.includes(deal.id)) {
        let processedDeal = {}
        let rescheduled = false
        let cancelled = false
        let date = deal.appointment_data.check.date
        let last_appointment_date = deal.appointment_data.check.last_appointment_date

        // No date, check is cancelled
        if (!date) {
          cancelled = true
          date = deal.appointment_data.check.last_appointment_date.toDate()
        } else {
          date = date.toDate()
          // Si se esta seleccionando una fecha nueva, hoy en adelante
          const tomorrow = new Date();
          tomorrow.setHours(24, 0, 0, 0);
          tomorrow.setMinutes(tomorrow.getMinutes() + 1);
          // Hay agendamiento y es a futuro
          // El usuario este viendo el pasado u hoy
          // Mostrar que fue reagendado y cuando
          if (last_appointment_date && (date > tomorrow) && (toDate < tomorrow)) {
            last_appointment_date = last_appointment_date.toDate()
            if (!isSameDate(date, last_appointment_date)) {
              rescheduled = formatDate(date)
              date = last_appointment_date
            }
          }
        }


        processedDeal.deal_data = deal
        processedDeal.date = formatDate(date)
        processedDeal.hour = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);
        processedDeal.cs = deal.user_team?.CS || ''
        processedDeal.actions = deal.actions || []
        processedDeal.car = deal.car || ''
        processedDeal.name = deal.name || ''
        processedDeal.car_id = deal.car_id || ''
        processedDeal.trengo_ticket_id = deal.trengo_ticket_id || ''
        processedDeal.user_team = deal.user_team || {}
        processedDeal.current_user_team = deal.current_user_team || {}
        processedDeal.id = deal.id

        // Campos calculados
        // Status
        // Agendada, Cancelada, En curso, Reagendada, Realizada
        // Faltaria limpiar para dejar ultimas acciones para que no se mezclen reagendamientos?
        const open_actions = processedDeal?.actions.data_actions?.filter(action => action.status === 'open').map(action => action.action_id)
        const done_actions = processedDeal?.actions.data_actions?.filter(action => action.status === 'done').map(action => action.action_id)
        const cancelled_actions = processedDeal?.actions.data_actions?.filter(action => action.status === 'cancelled').map(action => action.action_id)
        if (done_actions?.includes('offer-f-accepted')) {
          processedDeal.status = "Oferta Aceptada"
        } else if (done_actions?.includes('offer-f-rejected')) {
          processedDeal.status = "Oferta Rechazada"
        } else if (done_actions?.includes('offer-f')) {
          processedDeal.status = "Oferta Enviada"
        } else if (done_actions?.includes('check')) {
          processedDeal.status = "Oferta Pendiente"
        } else if (cancelled_actions?.includes('offer-f') && done_actions?.includes('confirm-check-as')) {
          processedDeal.status = "Rechazado"
        } else if (cancelled_actions?.includes('check') && done_actions?.includes('confirm-check-as')) {
          processedDeal.status = "Rechazado"
        } else if (done_actions?.includes('confirm-check-as')) {
          processedDeal.status = "En curso"
        } else if (rescheduled) {
          processedDeal.status = "Reagendada (" + rescheduled + ")"
        } else if (open_actions?.includes('schedule-check')) {
          processedDeal.status = "Cancelada"
        } else if (done_actions?.includes('confirm-check')) {
          processedDeal.status = "Confirmada"
        } else if (open_actions?.includes('confirm-check-as')) {
          processedDeal.status = "Agendada"
        } else {
          processedDeal.status = "Error"
        }
        processedDeals.push(processedDeal);
        processedDealsIds.push(deal.id);
      }
    };
    try {
      processedDeals.sort((dealA, dealB) => {
        const combinedDateTimeA = dealA.date + dealA.hour;
        const combinedDateTimeB = dealB.date + dealB.hour;
        return combinedDateTimeA.localeCompare(combinedDateTimeB);
      });

      // Update the state with the processedDeals array
      setDeals(processedDeals);
      setLoadingDeals(false);
    } catch (error) {
      console.error(error);
      setLoadingDeals(false);
      // Handle the error
    }
  }


  doSnapshot(firebaseQuery, setFirebaseDeals, setLoadMore) {
    return onSnapshot(firebaseQuery, async (querySnapshot) => {
      // Inspecciones
      let snapshotDeals = querySnapshot.docs.map((docSnapshot) => {
        let deal_data = docSnapshot.data()
        deal_data.id = docSnapshot.id
        return deal_data
      });

      // Reagendamientos con inspeccion previa en rango
      let [fromDate, toDate] = this.getDatesForQuery()
      const q = query(collection(db, "companies", this.user?.company, "deals"), and(
        where('appointment_data.check.last_appointment_date', ">=", fromDate),
        where('appointment_data.check.last_appointment_date', "<", toDate)
      ));
      const nextSnapshotDealsQuery = await getDocs(q);
      const nextSnapshotDeals = nextSnapshotDealsQuery.docs.map((docSnapshot) => docSnapshot.data());
      snapshotDeals = snapshotDeals.concat(nextSnapshotDeals);
      const filteredDeals = snapshotDeals.filter(
        (deal) => typeof deal?.actions?.requested_datetime !== 'string'
      );
      setFirebaseDeals(filteredDeals);
    });
  }
}