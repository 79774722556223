import React from 'react'

export default function GraphicsMarketingPage() {
  return (
    <div className="p-3 h-100">
      <iframe
        width="100%"
        height="100%"
        src="https://lookerstudio.google.com/embed/reporting/34d53556-b426-4532-9615-5b007d07c012/page/rKhAE"
        frameborder="0"
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
      </iframe>
    </div>
  )
}
