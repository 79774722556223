import React from 'react'

export default function GraphicsInspectionPage() {
  return (
    <div className="p-3 h-100">
      <iframe
        width="100%"
        height="100%"
        src="https://lookerstudio.google.com/embed/reporting/079b741b-3036-46d4-a8d0-a213ed56f9ae/page/y5w5D"
        frameborder="0"
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
      </iframe>
    </div>
  )
}
