import React from "react";
import { OverlayTrigger, Popover, Badge } from "react-bootstrap";

export default function TableActionsComponent({ actions, action, i }) {
  return (
    <OverlayTrigger
      key={i}
      placement="right"
      delay={{ show: 250, hide: 200 }}
      overlay={
        <Popover id="popover-basic">
          <Popover.Body>{actions[action.action_id]?.title}</Popover.Body>
        </Popover>
      }
    >
      <span className="position-relative me-1 ms-1">
        <img
          src={actions[action.action_id]?.svg}
          key={i}
          alt=""
          style={{ width: "22px", height: "22px", marginTop: "5px" }}
        />
        <Badge
          pill
          className="position-absolute translate-middle"
          style={{ zIndex: 3, left: "20px", top: "3px" }}
        >
          {action?.action_counter}
        </Badge>
      </span>
    </OverlayTrigger>
  );
}
