import React, { useState, useRef, useEffect } from "react";
import ChatCommentsComponent from "./components/ChatCommentsComponent";
import { useParams, useOutletContext } from 'react-router-dom';
import { useCompany } from "../../context/CompanyContext";
import { useUser } from "../../context/UserContext";
import { useNavigate } from 'react-router-dom';
import { Swal } from 'sweetalert2';
import { updateDealInProcess } from "../Main/services/servicesTableDeals";
import { teams } from "../../constants/teams";


export default function CommentsPage() {
  const { deal_id } = useParams();
  const { user } = useUser();
  const { allUsers } = useCompany();

  const [deal, setDeal] = useState('')
  const takeRef = useRef(true);

  const context = useOutletContext()
  const navigate = useNavigate();

  useEffect(() => {
    onTakeDeal(deal);
    setDeal(context.deal)

  }, []);

  const onTakeDeal = (deal) => {
    if (takeRef.current) {
      if (!deal.current_user_team || user?.team === teams.ADMIN) {
        updateDealInProcess(user?.company, user?.team, user?.uid, deal_id);
      } else if (deal.current_user_team) {
        if (
          deal.current_user_team[user?.team] === null ||
          Object.keys(deal.current_user_team).length === 0 ||
          !deal.current_user_team[user?.team]
        ) {
          updateDealInProcess(user?.company, user?.team, user?.uid, deal_id);
        } else if (deal.current_user_team[user?.team] !== user?.uid) {
          const userIdInProcess = deal.current_user_team[user?.team];
          let userNameInProcess = allUsers.find((user) =>
            userIdInProcess.includes(user.id)
          );
          Swal.fire({
            title: "No puede trabajar en este deal",
            text: `Se encuentra en proceso con ${userNameInProcess?.name}  `,
            icon: "warning",
            confirmButtonText: "Aceptar",
            showCancelButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then(() => {
            navigate("/");
          });
        }
      }
      takeRef.current = false;
    }
  };
  return (
    <>
      <ChatCommentsComponent />
    </>
  );
}
