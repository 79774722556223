import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function SpinnerFullScreenComponent() {
  return (
    <div
      style={{
        height: "80vh",
        width: "100%",
        display: "flex",
      }}
    >
      <Spinner
        animation="border"
        variant="primary"
        role="status"
        style={{
          margin: "auto",
        }}
      >
      </Spinner>
    </div>
  );
}
