import React from "react";
import { Col, Row, } from "react-bootstrap";
import "../../../../../styles/style/RadioButtonsTableStyle.css";
import AccesoriesDiscountsTableComponent from './AccessoriesDiscountsTableComponent';
import GeneralesComponent from './Tables/GeneralesComponent';
import GuanteraComponent from './Tables/GuanteraComponent';
import SeguridadComponent from './Tables/SeguridadComponent';
import VariosComponent from "./Tables/VariosComponent";



export default function AccessoriesReviewTabComponent({
  disabled,
  register,
  deal_id,
  dataAccesories,
  setdataAccesoriesDiscounts
}) {
  return (
    <div>
      <Row>
        <Col lg={6}>
          <GuanteraComponent
            disabled={disabled}
            register={register}
            dataGuantera={dataAccesories?.guantera}
          />
          <GeneralesComponent
            disabled={disabled}
            register={register}
            dataGenerales={dataAccesories?.generales}
          />
        </Col>
        <Col lg={6}>
          <SeguridadComponent
            disabled={disabled}
            register={register}
            dataSeguridad={dataAccesories?.seguridad}
          />
          <VariosComponent
            disabled={disabled}
            register={register}
            dataVarios={dataAccesories?.varios}
          />
        </Col>
      </Row>
      <div className="divider" style={{ marginTop: "16px" }} />
      <AccesoriesDiscountsTableComponent
        isEditing={disabled}
        deal_id={deal_id}
        setdataAccesoriesDiscounts={setdataAccesoriesDiscounts}
      />
    </div>
  );
}
