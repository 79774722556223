import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore"; // Ajuste para Firestore
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { generateId } from "../utils/IdGenerator";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROYECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Inicializar la app de Firebase
const app = initializeApp(firebaseConfig);

// Inicializar Firestore con configuración de long-polling y sin límite de caché
const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,  // Forzar long-polling
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,  // Deshabilitar el límite de caché
});

// Inicializar los servicios que necesitas
const auth = getAuth(app); // Para la autenticación
const storage = getStorage(app); // Para Storage

// Función para subir archivos
export const uploadFile = async (file) => {
  try {
    const storageRef = ref(storage, generateId()); // Usar ref del módulo storage
    await uploadBytes(storageRef, file); // Subir el archivo
    const url = await getDownloadURL(storageRef); // Obtener la URL de descarga
    return url;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

// Exportar los servicios
export { db, auth, storage, app };