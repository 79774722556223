import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../../../../styles/style/photoAttachmentStyle.css";
import PhotoCarouselComponent from "../PhotoCarouselComponent";
import { useUser } from "../../../../../context/UserContext";
import useFirebaseOnSnapshot from "../../../../../hooks/useFirebaseOnSnapshot";
import { SmallSpinnerComponent } from "../../../../../components";
import DragAndDropComponent from "../../../../../components/DragAndDropComponent";

export default function PhotoAttachmentComponent({
  disabled,
  deal_id,
  setfilesInspect,
  filesInspect,
  dataPhotos,
  register,
  onCheckboxChange, // Recibe una función para notificar al padre del cambio
}) {
  const { user } = useUser();
  const { data, loading } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "check"
  );

  // Actualiza los archivos inspectados
  useEffect(() => {
    setfilesInspect(data?.check?.photos?.photos);
  }, [data, deal_id, setfilesInspect, user.company]);

  // Maneja el cambio de los checkboxes y notifica al componente padre
  const handleCheckboxChange = () => {
    const allChecked = Object.entries(dataPhotos).every(([key, value]) => {
      const checkbox = document.querySelector(`input[name="${key}"]`);
      return checkbox && checkbox.checked;
    });

    onCheckboxChange(allChecked); // Llama a la función para notificar al padre
  };

  return (
    <>
      {loading ? (
        <SmallSpinnerComponent />
      ) : (
        <>
          {!disabled ? (
            <Container>
              <p style={{ fontWeight: "bold" }}>
                Carga las Siguientes fotografias:
              </p>
              <Row>
                <>
                  {Object.entries(dataPhotos)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Ordena alfabéticamente por el key
                    .map(([key, value]) => (
                      <div className="form-check" key={key}>
                        <input
                          className="form-check-input"
                          disabled={disabled}
                          type="checkbox"
                          defaultChecked={value}
                          {...register(`${key}`)}
                          onChange={handleCheckboxChange} // Llama a la función cuando el checkbox cambie
                        />
                        <label className="label-input-check">
                          {key.replaceAll("_", " ")}
                        </label>
                      </div>
                    ))}
                </>
              </Row>
              <DragAndDropComponent
                dataFiles={filesInspect}
                setDataFiles={setfilesInspect}
                disabled={disabled}
              />
            </Container>
          ) : (
            <PhotoCarouselComponent filesInspect={filesInspect} />
          )}
        </>
      )}
    </>
  );
}
