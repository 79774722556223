import React, { useEffect } from "react";
import "../../../styles/style/DealsTableStyle.css";
import "../../../styles/style/filterDashboardStyle.css";
import { DefaultViewTableComponent } from "../components";
import { useDeals } from "../../../context/DealsContext";

export default function PendingReportsViewPage() {

  const { setViewName } = useDeals();
  useEffect(() => {
    setViewName('pendingReportsView')
  }, [])

  return (
    <>
      <div className="p-3 h-100">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className='view-title-page'>Informes pendientes</h1>
          <span style={{ width: '20px' }}></span>
        </div >
        <DefaultViewTableComponent />
      </div >
    </>
  );
}
