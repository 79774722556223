import { db } from "../../../firebase/config";
import {
  collection,
  doc,
  updateDoc,
  where,
  query,
  getDocs,
  writeBatch, // Cambiado batch por writeBatch
  addDoc
} from "firebase/firestore";

export const updateDealInProcess = async (company, userTeam, userId, id) => {
  const dealRef = doc(db, "companies", company, "deals", id);
  try {
    await updateDoc(dealRef, { [`current_user_team.${userTeam}`]: userId });
  } catch (error) {
    console.error("Error updating deal in process: ", error);
    return error;
  }
};

export const cleanDealInProcess = async (company, userTeam, id) => {
  const dealRef = doc(db, "companies", company, "deals", id);
  try {
    await updateDoc(dealRef, { [`current_user_team.${userTeam}`]: null });
  } catch (error) {
    console.error("Error cleaning deal in process: ", error);
    return error;
  }
};

export const updateCompany = async (company, uid) => {
  const userRef = doc(db, "users", uid);
  try {
    await updateDoc(userRef, { company });
  } catch (error) {
    console.error("Error updating company: ", error);
    return error;
  }
};

export const createCar = async (user, setnewDocId, company) => {
  const dealsRef = collection(db, "companies", company, "deals");
  try {
    const docRef = await addDoc(dealsRef, {
      created_by: user,
      status: "manual",
    });
    console.log("Document written with ID: ", docRef.id);
    setTimeout(() => {
      setnewDocId(docRef.id);
    }, 4000);
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

export const searchPatent = async (
  patenteValida,
  valueInput,
  setresultQueryDb,
  setloadingDataDb,
  user,
  setDataHistory,
  setModalSearchPhone,
  setModal
) => {
  let queryRef = collection(db, "companies", user?.company, "deals");
  let querySearchPhoneRef = collection(db, "clients_admin");

  const isNumber = /^54911\d{8}$/.test(valueInput);

  if (patenteValida) {
    queryRef = query(queryRef, where("car_id", "==", valueInput));
  } else if (isNumber) {
    querySearchPhoneRef = query(querySearchPhoneRef, where("phone", "==", valueInput));
  } else {
    queryRef = query(queryRef, where("trengo_ticket_id", "==", valueInput));
  }

  if (isNumber) {
    try {
      const snapshot = await getDocs(querySearchPhoneRef);
      if (snapshot.empty) {
        setTimeout(() => {
          setDataHistory([]);
          setloadingDataDb(false);
          setModal(false);
          setModalSearchPhone(true);
        }, 2000);
      } else {
        snapshot.forEach((doc) => {
          setDataHistory(doc.data().deals);
          setModal(false);
          setModalSearchPhone(true);
        });
        setloadingDataDb(false);
      }
    } catch (error) {
      console.error("Error fetching data by phone:", error);
      setloadingDataDb(false);
    }
  } else {
    try {
      const snapshot = await getDocs(queryRef);
      if (snapshot.empty) {
        setTimeout(() => {
          console.log("No results found.");
          setresultQueryDb("No results found.");
          setloadingDataDb(false);
        }, 2000);
      } else {
        const results = snapshot.docs.map((doc) => doc.data());
        setTimeout(() => {
          setresultQueryDb(results);
          setloadingDataDb(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error executing query:", error);
      setloadingDataDb(false);
    }
  }
};

export const validarPatente = (patente) => {
  const patenteRegex = /^([A-Z]{3}\s?\d{3}|[A-Z]{2}\s?\d{3}\s?[A-Z]{2})$/;
  return patenteRegex.test(patente);
};

export const addMinutes = (dt, min) => {
  return new Date(dt.getTime() + min * 60000);
};

export const updateReadMessage = async (company, notif_id) => {
  const notificationRef = doc(db, "companies", company, "notifications", notif_id);
  try {
    await updateDoc(notificationRef, { read: true });
  } catch (error) {
    console.error("Error updating notification: ", error);
    return error;
  }
};

export const updateReadAllMessages = async (company, notif_ids) => {
  const batchUpdate = writeBatch(db); // Cambiado a writeBatch
  notif_ids.forEach((notif_id) => {
    const notificationRef = doc(db, "companies", company, "notifications", notif_id);
    batchUpdate.update(notificationRef, { read: true });
  });
  try {
    await batchUpdate.commit();
    console.log("Selected notifications updated successfully.");
  } catch (error) {
    console.error("Error updating selected notifications:", error);
  }
};
