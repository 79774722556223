import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../styles/style/PricingStyle.css";
import { useUser } from "../../../context/UserContext";
import AdminPricingComponent from "./AdminPricingComponent";
import AdvisorPricingComponent from "./AdvisorPricingComponent";
import PricerPricingComponent from "./PricerPricingComponent";
import { updateStatusExpired } from "./../services/servicesPricing";
import ExpectedOffer from "./Other/ExpectedOffer";
import { teams } from "../../../constants/teams";
import { SmallSpinnerComponent } from "../../../components";

export default function PricingComponent({
  handleClose,
  data,
  loading,
  latestKey,
  setActiveModalPricing,
}) {
  const { deal_id } = useParams();
  const { user } = useUser();

  const [finalOffer, setfinalOffer] = useState();
  const [rangeInitial, setrangeInitial] = useState();
  const [initialOffer, setinitialOffer] = useState();
  const [virtualOffer, setvirtualOffer] = useState();
  const [salePrice, setsalePrice] = useState();
  const [expectedOffer, setexpectedOffer] = useState();
  const [dataDateUntil, setdataDateUntil] = useState();
  const [dataDateUntilOfferInitial, setdataDateUntilOfferInitial] = useState();
  const [dataDateUntilOfferVirtual, setdataDateUntilOfferVirtual] = useState();
  const [dataDateUntilOfferFinal, setdataDateUntilOfferFinal] = useState();
  const [dataDateUntilRange, setdataDateUntilRange] = useState();

  useEffect(() => {
    setfinalOffer(data?.final_offer);
    setrangeInitial(data?.range);
    setinitialOffer(data?.offer);
    setvirtualOffer(data?.virtual_offer);
    setsalePrice(data?.sale_price);
    setexpectedOffer(data?.expected_offer);
    setdataDateUntil(data?.sale_price?.until_date);
    setdataDateUntilOfferInitial(data?.offer?.until_date);
    setdataDateUntilOfferVirtual(data?.virtual_offer?.until_date);
    setdataDateUntilOfferFinal(data?.final_offer?.until_date);
    setdataDateUntilRange(data?.range?.until_date);
  }, [data]);

  const getExpireDates = (time) => {
    let date = new Date(time?.seconds * 1000).getTime() - new Date().getTime();
    return date;
  };

  useEffect(() => {
    if (getExpireDates(dataDateUntilRange) < 0) {
      const data = {
        "range.status": "expired",
      };
      updateStatusExpired(user?.company, deal_id, data);
    }
    if (getExpireDates(dataDateUntilOfferInitial) < 0) {
      const data = {
        "offer.status": "expired",
      };
      updateStatusExpired(user?.company, deal_id, data);
    }
    if (getExpireDates(dataDateUntilOfferVirtual) < 0) {
      const data = {
        "virtual_offer.status": "expired",
      };
      updateStatusExpired(user?.company, deal_id, data);
    }
    if (getExpireDates(dataDateUntilOfferFinal) < 0) {
      const data = {
        "final_offer.status": "expired",
      };
      updateStatusExpired(user?.company, deal_id, data);
    }
    if (getExpireDates(dataDateUntil) < 0) {
      const data = {
        "sale_price.status": "expired",
      };
      updateStatusExpired(user?.company, deal_id, data);
    }
  }, [
    dataDateUntil,
    dataDateUntilOfferFinal,
    dataDateUntilOfferInitial,
    dataDateUntilOfferVirtual,
    dataDateUntilRange,
    deal_id,
    user?.company,
  ]);
  return (
    <>
      {loading ? (
        <SmallSpinnerComponent />
      ) : (
        <>
          {user?.team === teams.ADMIN ? (
            <AdminPricingComponent
              finalOffer={finalOffer}
              rangeInitial={rangeInitial}
              initialOffer={initialOffer}
              virtualOffer={virtualOffer}
              salePrice={salePrice}
              handleClose={handleClose}
              setActiveModalPricing={setActiveModalPricing}
            />
          ) : user?.team === teams.CS || user?.team === teams.AS ? (
            <AdvisorPricingComponent
              finalOffer={finalOffer}
              rangeInitial={rangeInitial}
              initialOffer={initialOffer}
              virtualOffer={virtualOffer}
              salePrice={salePrice}
              latestKey={latestKey}
              setActiveModalPricing={setActiveModalPricing}
            />
          ) : user?.team === teams.PRICING ? (
            <PricerPricingComponent
              finalOffer={finalOffer}
              rangeInitial={rangeInitial}
              initialOffer={initialOffer}
              virtualOffer={virtualOffer}
              salePrice={salePrice}
              latestKey={latestKey}
              setActiveModalPricing={setActiveModalPricing}
            />
          ) : null}
          <ExpectedOffer
            user={user}
            deal_id={deal_id}
            expectedOffer={expectedOffer}
            setActiveModalPricing={setActiveModalPricing}
          />
        </>
      )}
    </>
  );
}
