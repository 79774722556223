import React, { useState } from 'react';
import { Button, Modal, Spinner, Alert } from 'react-bootstrap';
import { postDollarExchange } from '../../services/sevicesPricingView';

export default function PricingEditExchangeRateModalComponent({ modal, setModal, fetchDataExchange, fetchData }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [exchangeRate, setExchangeRate] = useState('');
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  const formatCurrency = (value) => {
    const numericValue = value.replace(/[^\d]/g, '');
    if (numericValue.length === 4) {
      const formattedValue = Number(numericValue).toLocaleString('es-AR');
      return `$${formattedValue}`;
    }
    return `$${numericValue}`;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setExchangeRate(formatCurrency(inputValue));
  };

  const handleSaveChanges = () => {

    setShowConfirmAlert(true);
  };

  const handleConfirmSaveChanges = () => {
    setIsSubmitting(true);

    setShowConfirmAlert(false);

    const numericValue = exchangeRate.replace(/[^\d]/g, '');
    postDollarExchange({ endpoint: 'pricing/dolar_exchange', data: { new_dolar_exchange: numericValue } })
      .then((response) => {
        console.log('Respuesta exitosa:', response);
        fetchDataExchange();
        fetchData();
      })
      .catch((error) => {
        console.error('Error al enviar datos:', error);
      })
      .finally(() => {
        setIsSubmitting(false);
        setModal(false);
      });
  };

  const handleCancelSaveChanges = () => {
    setShowConfirmAlert(false);
  };

  return (
    <Modal show={modal} centered onHide={() => setModal(false)}>
      <p className="add-card-title-modal mt-2" style={{ textAlign: 'center' }}> Editar Tipo de Cambio</p>
      <p className="card-subtitle mt-4 mb-1" style={{ textAlign: 'center' }}> Tipo de Cambio USD</p>
      <input
        type='text'
        className="input-pricing align-self-center text-center mb-4"
        placeholder='$00.000'
        value={exchangeRate}
        onChange={handleInputChange}
      />
      <Modal.Footer style={{ borderTop: 'none', justifyContent: 'space-evenly' }}>
        <Button
          variant="outline-primary"
          style={{ width: "40%" }}
          onClick={() => {
            setModal(false)
            setShowConfirmAlert(false);
            setExchangeRate('')
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          style={{ width: "40%" }}
          onClick={handleSaveChanges} // Llama a la función handleSaveChanges al hacer clic en el botón
          disabled={exchangeRate.length === 0 || exchangeRate.length === 5 || showConfirmAlert}
        >
          {isSubmitting ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Guardar cambios'
          )}
        </Button>
      </Modal.Footer>

      {/* Doble confirmación */}
      <Alert show={showConfirmAlert} variant="warning" onClose={handleCancelSaveChanges} style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Alert.Heading className="add-card-title-modal mt-2">¿Estás seguro?</Alert.Heading>
        <p className="card-subtitle mt-2 mb-4">Esta acción actualizará el tipo de cambio. ¿Deseas continuar?</p>
        <Button variant="primary" onClick={handleConfirmSaveChanges}>
          Sí, estoy seguro
        </Button>
      </Alert>
    </Modal >
  );
}
