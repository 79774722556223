import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import ToolTipComment from "./ToolTipComment";
import { colors } from "../../../../styles/colors";
import { teams } from "../../../../constants/teams";
import { useState } from "react";

export default function VehicleComponent({
  disabled,
  register,
  datavehicle,
  setValue,
  user,
  textStyle,
  setValueMunicipalizado,
  setValueRadicacion,
}) {
  const [valueRadicacionInterior, setValueRadicacionInterior] = useState("");

  const disableStyle = {
    color: colors.neutral.darkTwo,
    // backgroundColor: colors.gray[50],
    backgroundColor: "#f8f8f8",
  };
  const disableStyleAs = {
    color: colors.neutral.darkTwo,
    backgroundColor: colors.gray[50],
  };

  const enableStyle = {
    color: colors.primary.secondaryDark,
    fontWeight: "500",
  };

  React.useEffect(() => {
    setValue("minucipalizado", datavehicle?.minucipalizado);
    setValueMunicipalizado(datavehicle?.minucipalizado);
    setValue("radicacion", datavehicle?.radicacion);
    setValueRadicacion(datavehicle?.radicacion);
    setValue("plan_pago", datavehicle?.plan_pago);
    setValue("infracciones_codigo_4", datavehicle?.infracciones_codigo_4);
    setValue("patente_duplicada", datavehicle?.patente_duplicada);
    setValue("radicacion_gba_azules", datavehicle?.radicacion_gba_azules);
    setValue("radicacion_interior", datavehicle?.radicacion_interior);
    setValue("stopper", datavehicle?.stopper);
    setValue(
      "radicacion_previa_prov_interior",
      datavehicle?.radicacion_previa_prov_interior
    );
    setValue(
      "deuda_judicializada_infracciones",
      datavehicle?.deuda_judicializada_infracciones
    );
    setValue(
      "deuda_judicializada_patentes",
      datavehicle?.deuda_judicializada_patentes
    );
    setValue("provincia_radicacion", datavehicle?.provincia_radicacion);
    setValueRadicacionInterior(datavehicle?.radicacion_interior);
    setValue("plan_facilidades", datavehicle?.plan_facilidades);
  }, [datavehicle, setValue]);

  const handleInputChange = (e) => {
    setValueRadicacionInterior(e.target.value);
  };

  return (
    <div>
      <p style={textStyle}>Vehiculo</p>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">Check Municipalizado</label>
              {datavehicle?.minucipalizado === "Si" ? (
                <ToolTipComment data={datavehicle?.municipalizado_comentario} />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("minucipalizado")}
              onChange={(e) => setValueMunicipalizado(e.target.value)}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">Radicación del vehículo</label>
              {datavehicle?.radicacion !== "CABA" &&
              datavehicle?.radicacion !== "GBA" &&
              datavehicle?.radicacion !== "" ? (
                <ToolTipComment data={"SOLICITAR LIBRE DEUDA"} />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              {...register("radicacion")}
              disabled={disabled}
            >
              <option>CABA</option>
              <option>GBA</option>
              <option>Catamarca</option>
              <option>Chaco</option>
              <option>Chubut</option>
              <option>Córdoba</option>
              <option>Corrientes</option>
              <option>Entre Ríos</option>
              <option>Formosa</option>
              <option>Jujuy</option>
              <option>La Pampa</option>
              <option>La Rioja</option>
              <option>Mendoza</option>
              <option>Misiones</option>
              <option>Neuquén</option>
              <option>Río Negro</option>
              <option>Salta</option>
              <option>San Juan</option>
              <option>San Luis</option>
              <option>Santa Cruz</option>
              <option>Santa Fe</option>
              <option>Santiago del Estero</option>
              <option>Tierra del Fuego</option>
              <option>Tucumán</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">Patente duplicada</label>
              {datavehicle?.patente_duplicada === "Si" ? (
                <ToolTipComment
                  data={datavehicle?.patente_duplicada_comentario}
                />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={
                !disabled &&
                (user?.team === teams.ADMIN || user?.team === teams.BACKOFFICE)
                  ? false
                  : true
              }
              {...register("patente_duplicada")}
              style={user?.team === teams.AS ? disableStyleAs : enableStyle}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">
                Con cédulas azules y radicado en GBA
              </label>
              {datavehicle?.radicacion_gba_azules === "Si" ? (
                <ToolTipComment
                  data={datavehicle?.radicacion_gba_azules_comentario + "."}
                />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("radicacion_gba_azules")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={valueRadicacionInterior === "Si" ? 3 : 6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">
                Vehiculo radicado en el interior
              </label>
              {datavehicle?.radicacion_interior === "Si" ? (
                <ToolTipComment
                  data={datavehicle?.radicacion_interior_comentario + "."}
                />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("radicacion_interior")}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        {valueRadicacionInterior === "Si" ? (
          <Col lg={3}>
            <div style={{ marginBottom: "12px", marginTop: "8px" }}>
              <label className="label-input">Provincia de radicación</label>
              <input
                disabled={disabled}
                className="input-deal"
                style={{ width: "100%" }}
                {...register("provincia_radicacion")}
              />
            </div>
          </Col>
        ) : null}
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">
                Radicacion anterior en provincia del interior
              </label>
              {datavehicle?.radicacion_previa_prov_interior === "Si" ? (
                <ToolTipComment
                  data={datavehicle?.radicacion_previa_prov_interior_comentario}
                />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("radicacion_previa_prov_interior")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">Stopper</label>
              {datavehicle?.stopper &&
              datavehicle?.stopper !== "Ninguna de las anteriores" ? (
                <ToolTipComment
                  data={
                    datavehicle?.stopper === "Prendado"
                      ? datavehicle?.prendado_comentario
                      : datavehicle?.stopper === "Embargado"
                      ? datavehicle?.embargado_comentario
                      : datavehicle?.stopper === "Inhibido"
                      ? datavehicle?.inhibido_comentario + "."
                      : null
                  }
                />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("stopper")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Prendado</option>
              <option>Embargado</option>
              <option>Inhibido</option>
              <option>Ninguna de las anteriores</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">Planes de pago</label>
              {datavehicle?.plan_pago === "Si" ? (
                <ToolTipComment
                  data={datavehicle?.plan_pago_comentario + "."}
                />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("plan_pago")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">Plan de Facilidades</label>
              {datavehicle?.plan_facilidades === "Si" ? (
                <ToolTipComment
                  data={
                    "VEHÍCULO CON PLAN DE FACILIDADES, DEBERÁ ABONARLO Y SE PUEDE TOMAR EL VEHÍCULO CUANDO EL PAGO ESTÉ IMPACTADO EN EL PORTAL WEB CORRESPONDIENTE SIN EXCEPCIÓN."
                  }
                />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("plan_facilidades")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">Infracciones en código 4</label>
              {datavehicle?.infracciones_codigo_4 === "Si" ? (
                <ToolTipComment
                  data={datavehicle?.infracciones_codigo_4_comentario + "."}
                />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("infracciones_codigo_4")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">
                Deuda judicializada de infracciones
              </label>
              {datavehicle?.deuda_judicializada_infracciones === "Si" ? (
                <ToolTipComment
                  data={
                    datavehicle?.deuda_judicializada_infracciones_comentario
                  }
                />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("deuda_judicializada_infracciones")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">
                Deuda judicializada de patentes
              </label>
              {datavehicle?.deuda_judicializada_patentes === "Si" ? (
                <ToolTipComment
                  data={datavehicle?.deuda_judicializada_patentes_comentario}
                />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("deuda_judicializada_patentes")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
    </div>
  );
}
