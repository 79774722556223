import React, { useState } from "react";
import { Col, Dropdown, Row, Button } from "react-bootstrap";
import { MdFilterList } from "react-icons/md";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useDeals } from "./../../../context/DealsContext";
import { BiCalendarEvent } from "react-icons/bi";
import ModalFilterDateComponent from "./ModalFilterDateComponent";

const formatDate = function (date) {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

const QuickViewsFilterComponent = () => {
  let isMobile = useMediaQuery("(max-width: 500px)");
  const { filters, clearFilter } = useDeals();
  const today = formatDate(new Date());
  let selectedDateValue;
  if (filters.selectedDates.from) {
    selectedDateValue = formatDate(filters.selectedDates.from);
  } else {
    selectedDateValue = today;
  }
  const [selectedDate, setSelectedDate] = useState(selectedDateValue); // State to store the selected date
  const [openFilters, setOpenFilters] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const openCalendarModal = () => {
    setOpenModal(true);
  };

  const cleanFilter = () => {
    clearFilter();
    setSelectedDate(today); // Reset the selected date state
    setOpenFilters(false);
  };

  const widthMobileFilter = {
    width: "90vw",
  };

  const widthFilter = {
    width: "30vw",
  };

  return (
    <Dropdown show={openFilters} onToggle={(isOpen) => setOpenFilters(isOpen)}>
      <Dropdown.Toggle className="filter-button" id="dropdown-basic">
        <MdFilterList className="filter-icon-button" fontSize={18} />
        Filtro
      </Dropdown.Toggle>
      <Dropdown.Menu
        show={filters.selectedDates.from}
        className="filter-menu"
        style={isMobile ? widthMobileFilter : widthFilter}
      >
        <Row style={{ width: " 100%", margin: "0px" }}>
          <p
            className="filter-title"
            style={{ marginBottom: "8px", marginTop: "8px" }}
          >
            Filtros de fecha
          </p>
          <Col style={{ marginBottom: "8px", marginTop: "8px" }}>
            <div
              className="reminder-input d-flex align-items-center justify-content-between mb-2"
              onClick={openCalendarModal}
              style={{
                cursor: "pointer",
                width: "100%",
                height: "38px",
              }}
            >
              <p
                className="reminder-text-placeholder"
                style={{
                  color: "#1B2130",
                }}
              >
                {filters.selectedDates.from
                  ? filters.selectedDates?.from.toLocaleDateString() +
                    " - " +
                    filters.selectedDates?.to.toLocaleDateString()
                  : "Seleccionar tiempo"}
              </p>
              <div className="me-2">
                <BiCalendarEvent fontSize="22px" color="#1B2130" />
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ width: " 100%", margin: "0px" }}>
          <Col></Col>
          <Col
            xs={12}
            md={6}
            style={{
              // marginBottom: "8px",
              // marginTop: "8px",
              display: "flex",
              justifyContent: " flex-end ",
            }}
          >
            <Button className="mb-2" onClick={cleanFilter}>
              Limpiar filtros
            </Button>
          </Col>
        </Row>
      </Dropdown.Menu>
      <ModalFilterDateComponent
        openModal={openModal}
        setOpenModal={setOpenModal}
        setState={setState}
        state={state}
      />
    </Dropdown>
  );
};

export default QuickViewsFilterComponent;
