import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputComponent from "../../../../components/InputComponent";

export default function FinalConsumerModalComponent({
  modal,
  setModal,
  setarrayConsumidorFinal,
}) {
  const { register, handleSubmit, reset } = useForm();

  const addTitular = (data) => {
    setarrayConsumidorFinal((arrayTitular) => [...arrayTitular, data]);
    reset();
    setModal(false);
  };

  const handleClose = () => setModal(false);
  return (
    <>
      <Modal size="lg" show={modal} onHide={handleClose}>
        <form>
          <Modal.Header closeButton>
            <Modal.Title className="deal-title">Agregar titular</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Nombre completo"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"nombre_completo"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"DNI/CUIL"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"cuil_dni"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="select-container">
                  <label className="label-input">Estado civil</label>
                  <Form.Select
                    className="deal-select"
                    {...register("estado_civil")}
                  >
                    <option>Casado</option>
                    <option>Soltero</option>
                    <option>Divorciado</option>
                    <option>Viudo</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Domicilio fiscal del vehículo (cédula)"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"domicilio_fiscal"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Domicilio real del titular"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"domicilio_real"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Mail"}
                  placeholder=""
                  type="email"
                  register={register}
                  valueRegister={"mail"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Titularidad (%)"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"titularidad"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Nombre del conyugue (opcional)"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"conyuge"}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit(addTitular)}
            >
              Agregar titular
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
