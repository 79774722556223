import React, { useState } from 'react';
import { Button, Container, Spinner } from 'react-bootstrap';
import LogoAuta from "./images/logo-auta.png";

function OfflineScreen() {
  const [isReloading, setIsReloading] = useState(false);

  const handleReload = () => {
    setIsReloading(true);
    window.location.reload();
  };


  return (
    <div className="fullscreen h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#80808052' }}>
      <Container className=" d-flex flex-column justify-content-center align-items-center ">
        <img
          src={LogoAuta}
          alt="logo-auta"
          style={{ marginBottom: '30px' }}
        />
        <h1>¡Sin conexión a Internet!</h1>
        <p>Comprueba tu conexión y vuelve a intentarlo.</p>
        <Button variant="primary" onClick={handleReload} disabled={isReloading}>
          {isReloading ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Recargar la página'
          )}
        </Button>
      </Container>
    </div>
  );
}

export default OfflineScreen;