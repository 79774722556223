import React from 'react'

export default function GraphicsAcquisitionsPage() {
  return (
    <div className="p-3 h-100">
      <iframe
        width="100%"
        height="100%"
        src="https://lookerstudio.google.com/embed/reporting/8f9e1258-7165-4ac0-acf3-b0e4119642a9/page/6qv8D"
        frameborder="0"
        allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
      </iframe>
    </div>
  )
}
