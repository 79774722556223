import { doc, updateDoc } from "firebase/firestore"; // Asegúrate de importar correctamente las funciones
import { db } from "../../../firebase/config";

export const updateDataAdmin = async (deal_id, data, user, filesAdmin) => {
  const version_array = {
    version: data.version === true ? "final" : "preliminar",
    observaciones: data.observaciones,
    observaciones_adicionales: data.observaciones_adicionales,
  };

  const vehicle_info_array = {
    "vehicle_info.minucipalizado": data.minucipalizado,
    "vehicle_info.radicacion": data.radicacion,
    "vehicle_info.plan_pago": data.plan_pago,
    "vehicle_info.stopper": data.stopper,
    "vehicle_info.plan_facilidades": data.plan_facilidades,
    "vehicle_info.infracciones_codigo_4": data.infracciones_codigo_4,
    "vehicle_info.deuda_judicializada": data.deuda_judicializada,
    "vehicle_info.deuda_judicializada_patentes": data.deuda_judicializada_patentes,
    "vehicle_info.deuda_judicializada_infracciones": data.deuda_judicializada_infracciones,
    "vehicle_info.radicacion_previa_prov_interior": data.radicacion_previa_prov_interior,
    "vehicle_info.radicacion_gba_azules": data.radicacion_gba_azules,
    "vehicle_info.patente_duplicada": data.patente_duplicada,
    "vehicle_info.radicacion_interior": data.radicacion_interior,
    "vehicle_info.provincia_radicacion": data.provincia_radicacion,
  };

  const informes_array = {
    "informes.infracciones_caba": data.infracciones_caba,
    "informes.infracciones_gba": data.infracciones_gba,
    "informes.otras_infracciones": data.otras_infracciones,
    "informes.total_otras_infracciones": data.total_otras_infracciones,
    "informes.patentes": data.patentes,
    "informes.proporcional_patentes": data.proporcional_patentes,
    "informes.check_vtv": data.check_vtv,
    "informes.check_vpa": data.check_vpa,
    "informes.grabado_autopartes": data.grabado_autopartes,
    "informes.grabado_cristales": data.grabado_cristales,
  };

  const attachments = {
    attachments: filesAdmin,
  };

  const titulares_array = {
    "titulares.cantidad_titulares": data.cantidad_titulares,
    "titulares.conyuges": data.conyuges,
    "titulares.tipo": data.tipo,
    "titulares.tipo_empresa": data.tipo_empresa,
  };

  const comnet_array = {
    "informacion_comnet.cliente": data.cliente,
    "informacion_comnet.interno": data.interno,
  };

  // Filtrar datos no definidos
  const vehicle_info = Object.filter(vehicle_info_array, (info) => info !== undefined && info !== "");
  const informes = Object.filter(informes_array, (info) => info !== undefined && info !== "");
  const titulares = Object.filter(titulares_array, (info) => info !== undefined && info !== "");
  const version = Object.filter(version_array, (info) => info !== undefined && info !== "");
  const informacion_comnet = Object.filter(comnet_array, (info) => info !== undefined && info !== "");

  let date = new Date();

  const update_date = {
    day: date.toLocaleDateString(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };

  Object.assign(user, {
    update_date,
  });

  // Crear el objeto completo para la actualización
  const administrativeData = {
    ...version,
    ...vehicle_info,
    ...informes,
    ...titulares,
    ...attachments,
    ...informacion_comnet,
    user_update_data: user
  };

  try {
    // Usar Firebase modular para la actualización
    const docRef = doc(db, "companies", `${user?.company}`, "deals", `${deal_id}`, "data", "administrative");
    await updateDoc(docRef, administrativeData);
    return "Actualización exitosa";
  } catch (error) {
    console.error("Error al actualizar los datos: ", error);
    throw error;
  }
};
