import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default function AccesoriesDiscountsModalInput({
  modal,
  setModal,
  setdataObservaciones,
  setdataAccesoriesDiscounts,
}) {
  const handleClose = () => setModal(false);
  const [insertedData, setInsertedData] = React.useState("");

  function eliminarSignoDolar(objetos) {
    return objetos.map((objeto) => {
      return { ...objeto, descuento: objeto.descuento.replace(/\$/g, "") };
    });
  }

  function ajustarDescuento(objetos) {
    return objetos.map((objeto) => {
      let descuento = objeto.descuento;

      // Si el descuento es una cadena vacía, ajustarlo a "0"
      if (descuento === "") {
        descuento = 0;
      } else {
        // Convertir el descuento a número y quitar los puntos como separadores de miles
        descuento = parseInt(descuento.replace(/\./g, ""), 10);
        descuento = descuento.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }

      return { ...objeto, descuento };
    });
  }

  function splitData() {
    const splitData = insertedData.split("\n").map((x) => x.split("\t"));
    let output = []; //your main output
    let header = true; //this is a flag
    let i, v;
    for (i = 0; i < splitData.length; i++) {
      let row_array = splitData[i];
      if (header) {
        var keys = row_array;
        header = false;
      } else {
        output.push({});
        for (v = 0; v < row_array.length; v++) {
          output[i - 1][keys[v]] = row_array[v];
        }
      }
    }

    const objetosSinSignoDolar = eliminarSignoDolar(output);
    const objetosAjustados = ajustarDescuento(objetosSinSignoDolar);
    console.log("objetosSinSignoDolar", objetosSinSignoDolar);
    console.log("objetosAjustados", objetosAjustados);

    setdataObservaciones(objetosAjustados);
    setdataAccesoriesDiscounts(objetosAjustados);
    handleClose();
  }

  return (
    <>
      <Modal show={modal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Descuentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="observaciones">
              <Form.Label>Ingresa los datos</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={"Ingresar los valores tabulados sin las cabeceras"}
                onChange={(e) =>
                  setInsertedData(
                    // eslint-disable-next-line no-useless-concat
                    `accesorio	descuento\n` + `${e.target.value}`
                  )
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={splitData}>
            Cargar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
