import React, { useState } from "react";
import CardLoginComponent from "./components/CardLoginComponent";
import '../../styles/style/LoginPageStyle.css'
import { useAuth } from "../../context/AuthContext";
import { Form } from "react-bootstrap";

export default function LoginPage() {
  const { loginCompany, setLoginCompany } = useAuth()
  const [companies] = useState(['san_jorge'])


  const handleCompanyChange = (company) => {
    setLoginCompany(company)
    localStorage.setItem('loginCompany', company);
  }
  return (
    <div className="full-page">
      <div className="auta-logo" alt="logo de auta" />
      <p className='deal-title' style={{ fontSize: '18px', marginBottom: '1rem' }}>
        Selecciona tu empresa para iniciar sesion</p>
      <div style={{ width: '300px', marginBottom: '20px' }}>
        <Form.Select
          className="filter-select"
          value={loginCompany}
          onChange={(e) => handleCompanyChange(e.target.value)}
        >
          <option value="">{'Selecciona'}</option>
          {companies?.map((company, i) => (
            <option key={i} value={company}>
              {company}
            </option>
          ))}
        </Form.Select>
      </div>
      <CardLoginComponent isDisabled={loginCompany ? false : true} />
    </div>
  );
}
