import React, { useState } from "react";
import { Container } from "react-bootstrap";
import EmptyStateComponent from "../../../../components/EmptyStateComponent";
import ModalCarouselComponent from "./ModalCarouselComponent";

export default function PhotoCarouselComponent({ filesInspect }) {
  const [selectedMedia, setSelectedMedia] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = (index) => {
    setSelectedMedia(index);
    setIsModalOpen(true);
  };

  return (
    <>
      {filesInspect?.length === 0 ? (
        <EmptyStateComponent />
      ) : (
        <Container>
          <div className="row" style={{ marginBottom: "20px" }}>
            {filesInspect?.map((media, index) => (
              <div
                key={media.id}
                className={`col-sm-4 img-container ${
                  selectedMedia === index ? "selected" : ""
                }`}
                onClick={() => handleImageClick(index)}
              >
                {media.type === "video/mp4" ? (
                  <div className="grid-video">
                    <video className="video">
                      <source src={media.urlFirestore} type="video/mp4" />
                    </video>
                  </div>
                ) : (
                  <img
                    className="grid-image"
                    src={media.urlFirestore}
                    alt="Imagen"
                  />
                )}
              </div>
            ))}
          </div>
          <ModalCarouselComponent
            filesInspect={filesInspect}
            modal={isModalOpen}
            setmodal={setIsModalOpen}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
          />
        </Container>
      )}
    </>
  );
}
