import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ComfortComponent from "./components/ComfortComponent";
import ConsumptionBenefitsComponent from "./components/ConsumptionBenefitsComponent";
import TechnicalDataComponent from "./components/TechnicalDataComponent";
import SecurityComponent from "./components/SecurityComponent";
import "../../styles/style/TechnicalSheet.css";
import { getFichaTecnica } from "./services/servicesTechnicalSheet";
import { useParams } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import useFirebaseGet from './../../hooks/useFirebaseGet';
import { ServerErrorComponent, SpinnerFullScreenComponent } from "../../components";

export default function TechnicalSheetPage() {
  const { deal_id } = useParams();
  const { user } = useUser();
  const [dataDatosTecnicos, setdataDatosTecnicos] = useState();
  const [dataConsumosPrestaciones, setdataConsumosPrestaciones] = useState();
  const [dataConfort, setdataConfort] = useState();
  const [dataSeguridad, setdataSeguridad] = useState();
  const { data, error, loading } = useFirebaseGet(user?.company, deal_id, 'data', 'basics')

  useEffect(() => {
    getFichaTecnica(data?.car?.version_id).then((res) => {
      const dataFichaTecnica = res.data();
      const filterDatosTecnicos = dataFichaTecnica?.features.filter(
        (data) => data.category_name === "Datos técnicos"
      );
      const filterConsumosPrestaciones = dataFichaTecnica?.features.filter(
        (data) => data.category_name === "Motor y transmisión"
      );
      const filterConfort = dataFichaTecnica?.features.filter(
        (data) => data.category_name === "Confort"
      );
      const filterSeguridad = dataFichaTecnica?.features.filter(
        (data) => data.category_name === "Seguridad"
      );
      setdataDatosTecnicos(filterDatosTecnicos);
      setdataConsumosPrestaciones(filterConsumosPrestaciones);
      setdataConfort(filterConfort);
      setdataSeguridad(filterSeguridad);
    });
  }, [deal_id, user, data]);

  return (
    <>{loading
      ? (
        <SpinnerFullScreenComponent />
      ) : (
        <>{error
          ? (
            <ServerErrorComponent error={error} />
          ) : (
            <div>
              <Row className="justify-content-md-center">
                <Col lg={6}>
                  <TechnicalDataComponent data={dataDatosTecnicos} />
                </Col>
                <Col lg={6}>
                  <ConsumptionBenefitsComponent data={dataConsumosPrestaciones} />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <ComfortComponent data={dataConfort} />
                </Col>
                <Col lg={6}>
                  <SecurityComponent data={dataSeguridad} />
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
}
