import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export default function InteriorCheckComponent({
  disabled,
  dataCheckInterior,
  register,
  setValue,
}) {
  React.useEffect(() => {
    setValue("tapizados_estado", dataCheckInterior?.tapizados_estado);
    setValue(
      "cierre_centralizado_estado",
      dataCheckInterior?.cierre_centralizado_estado
    );
    setValue("vidrios_estado", dataCheckInterior?.vidrios_estado);
  }, [dataCheckInterior, setValue]);

  return (
    <div>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">Estado de tapizados</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("tapizados_estado")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Excelente</option>
              <option>Manchas leves</option>
              <option>Muy manchado/Roto</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              Funcionamiento de cierre centralizado
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("cierre_centralizado_estado")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Funciona</option>
              <option>No funciona</option>
              <option>No tiene</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              Funcionamiento de vidrios eléctricos (incluye techo corredizo en
              caso de aplicar)
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("vidrios_estado")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Funcionan todos</option>
              <option>Alguno roto</option>
              <option>No tiene</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
    </div>
  );
}
