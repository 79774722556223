import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function SmallSpinnerComponent() {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
      }}
    >
      <Spinner
        animation="border"
        variant="primary"
        style={{
          margin: "auto",
        }}
      />
    </div>
  )
}
