import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import { colors } from '../styles/colors';

export default function ServerErrorComponent() {
  const [isReloading, setIsReloading] = useState(false);
  const handleReload = () => {
    setIsReloading(true);
    window.location.reload();
  };
  return (
    <div className="empty-container"
      style={{
        height: "100%",
        backgroundColor: colors.neutral.light,
        borderRadius: "8px",
        border: '1px solid rgb(232, 225, 239)'
      }}>
      <div style={{
        borderRadius: "8px",
        padding: "16px",
        marginBottom: "20px",
        width: "100%",
      }}>
        <FaExclamationCircle className="empty-icon" size={200} color={colors.error.dark} />
        <h2 className=" mt-3">Error en el servidor...</h2>
        <Button variant="primary" onClick={handleReload} disabled={isReloading}>
          {isReloading ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Recargar la página'
          )}
        </Button>
      </div>
    </div>
  );
}
