import { useState, useEffect } from "react";
import { doc, onSnapshot, collection } from "firebase/firestore";
import { db } from "../firebase/config";

const useFirebaseOnSnapshot = (company, deal_id, docName) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const docRef = doc(
      collection(db, "companies", company, "deals", deal_id, "data"),
      docName
    );

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setData(snapshot.data());
          setLoading(false);
        } else {
          setError("El documento no existe");
          setLoading(false);
        }
      },
      (error) => {
        setError("Error al obtener los datos: " + error.message);
        setLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [company, deal_id, docName]);

  return { data, loading, error };
};

export default useFirebaseOnSnapshot;
