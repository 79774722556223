export default {
  control: {
    backgroundColor: "#fff",
    fontSize: 16,
    fontWeight: "normal",
    fontFamily: "SF Pro Display",
  },

  "&multiLine": {
    control: {
      fontFamily: "SF Pro Display",
      //   minHeight: 63,
      height: "40px",
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
      width: "260px",
    },
    input: {
      padding: 9,
      //   border: "1px solid silver",
      border: "none",
      outline: "none",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
      height: "150px",
      overflowY: "scroll",
      borderRadius: "8px",
    },
    item: {
      padding: "5px 15px",
      // borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "rgb(248,248,248)",
        color: "rgb(134,75,250)",
        // backgroundColor: "#cee4e5",
      },
    },
  },
};
