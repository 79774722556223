import React, { useEffect } from "react";
import { Modal, Row, Button, Col } from "react-bootstrap";
import InputComponent from "../../../../components/InputComponent";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { colors } from "./../../../../styles/colors";
import { useUser } from "../../../../context/UserContext";
import {
  createAccount,
  editAccount,
} from "../../services/TakeServices/PaymentServices";

export default function ModalAddOrEditAccountComponent({
  setModal,
  modal,
  isEditing,
  dataEditAccount,
  bank_accounts,
}) {
  const params = useParams();
  const { register, handleSubmit, reset, setValue } = useForm();
  const { user } = useUser();

  useEffect(() => {
    if (isEditing) {
      setValue("cbu", dataEditAccount?.cbu);
      setValue("bank", dataEditAccount?.bank);
      setValue("account_holder", dataEditAccount?.account_holder);
      setValue("CUIT", dataEditAccount?.CUIT);
    }
  }, [dataEditAccount, isEditing, setValue]);

  const editAccountData = (data) => {
    const newObj = Object.assign({}, dataEditAccount, data);
    const indexAccount = bank_accounts.findIndex(
      (item) => item.id === dataEditAccount.id
    );
    bank_accounts[indexAccount] = newObj;
    editAccount(params.deal_id, bank_accounts, user);
    reset();
    setModal(false);
  };

  const addAccount = (data) => {
    if (isEditing) {
      //editar
      editAccountData(data);
      toast.success("Informacion editada con exito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
    } else {
      //crear
      createAccount(
        params.deal_id,
        data.cbu,
        data.bank,
        data.account_holder,
        data.CUIT,
        user
      );
      toast.success("Informacion Guardada con exito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
    }
    reset();
    setModal(false);
  };

  const handleClose = () => {
    reset();
    setModal(false);
  };

  return (
    <>
      <Modal size="lg" show={modal} onHide={handleClose}>
        <form onSubmit={handleSubmit(addAccount)}>
          <Modal.Header closeButton>
            <Modal.Title className="deal-title">
              {isEditing ? "Editar" : "Crear"} cuenta
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"CBU"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"cbu"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Banco"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"bank"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Titular de la cuenta"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"account_holder"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"CUIT"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"CUIT"}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              {isEditing ? "Editar" : "Crear"} cuenta
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
