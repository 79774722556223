import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  formatNumber,
  updateOfferInitial,
} from "../../../services/servicesPricing";
import toast from "react-hot-toast";
import { colors } from "../../../../../styles/colors";
import { useState } from "react";

const InicialOfferPricerModal = ({
  deal_id,
  setshowOfferInitial,
  showOfferInitial,
  user,
  dataHistory,
  setActiveModalPricing,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [valueOfferInitial, setvalueOfferInitial] = useState("");

  const handleCloseOfferInitial = () => {
    setshowOfferInitial(false);
    setActiveModalPricing(false);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);

    setvalueOfferInitial(formattedValue);
  };

  const sendPricingOfferInitial = (dataInput) => {
    const data = {
      "offer.value": dataInput.value,
      "offer.user": user,
    };
    updateOfferInitial(user?.company, deal_id, data);
    toast.success("Pricing enviado.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferInitial(false);
    setActiveModalPricing(false);
  };

  return (
    <div>
      <Modal
        show={showOfferInitial}
        onHide={handleCloseOfferInitial}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="titleModal">Oferta inicial</p>
            <form
              onSubmit={handleSubmit(sendPricingOfferInitial)}
              style={{ width: "100%" }}
            >
              <div className="d-flex align-items-center mt-2">
                <input
                  className="inputModal"
                  style={{ width: "100%" }}
                  placeholder="$000.000.000"
                  {...register("value", {
                    required: "La oferta inicial es requerida",
                  })}
                  value={valueOfferInitial}
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center flex-column justify-content-between mt-3"
              >
                {errors.value && (
                  <p
                    style={{
                      margin: "0px",
                      color: colors.status.red,
                      fontSize: "12px",
                      width: "100%",
                    }}
                    className="mt-2"
                  >
                    {errors.value?.message}
                  </p>
                )}
                <button
                  type="submit"
                  variant="primary"
                  className="btnSendPricing mb-2 mt-2"
                >
                  Enviar pricing
                </button>
              </div>
            </form>
            <div className="mt-2" style={{ width: "100%" }}>
              {dataHistory?.length > 0
                ? dataHistory.map((info, i) => (
                    <div
                      className="d-flex align-items-center justify-content-between dividerHistory mb-3"
                      style={{ width: "100%" }}
                      key={i}
                    >
                      <p className="textHistory mb-2">${info.value}</p>
                      <p className="textHistory mb-2">
                        {new Date(
                          info.date.seconds * 1000
                        ).toLocaleDateString()}{" "}
                        -{" "}
                        {new Date(info.date.seconds * 1000).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </p>
                    </div>
                  ))
                : null}
            </div>
            <button
              variant="secondary"
              onClick={handleCloseOfferInitial}
              className="btnCancelarModal"
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InicialOfferPricerModal;
