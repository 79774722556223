import React, { useEffect } from 'react'
import CardLoginComponent from "./components/CardLoginComponent";
import '../../styles/style/LoginPageStyle.css'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export default function LoginWithCompany() {

  const { company: companyParam } = useParams();
  const { setLoginCompany } = useAuth()

  useEffect(() => {
    setLoginCompany(companyParam)
    localStorage.setItem('loginCompany', companyParam);
  }, [companyParam, setLoginCompany]);

  return (
    <div className="full-page">
      <div className="auta-logo" alt="logo de auta" />
      <p className='deal-title' style={{ fontSize: '18px', marginBottom: '1rem' }}>
        Login para usuarios de {companyParam.replace(/_/g, ' ')}</p>
      <CardLoginComponent isDisabled={false} />
    </div>
  )
}
