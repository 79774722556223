import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { teams } from '../constants/teams';

function InitialRouteBasedOnRole() {
  const { user } = useUser();

  if (!user) {
    return <Navigate to="/login" />;
  }

  const roleRoutes = {
    [teams.ADMIN]: "/inspections",
    [teams.INSPECCION]: "/inspections",
    [teams.AS]: "/inspections",
    [teams.BACKOFFICE]: "/takes",
    [teams.CS]: "/tasks",
    [teams.PRICING]: "/tasks",
  };

  return <Navigate to={roleRoutes[user.team] || "/login"} />;
}

export default InitialRouteBasedOnRole;
