import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/style/backButtonStyle.css";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { cleanDealInProcess } from "../modules/Main/services/servicesTableDeals";
import { useUser } from "../context/UserContext";
import { getNamesViews, getRoutesNames } from "../constants/views";
import { useDeals } from "../context/DealsContext";


export default function BackButtonComponent() {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useUser();
  const { viewName } = useDeals()

  const goDashboard = () => {
    navigate(`/${getRoutesNames(viewName)}`);
    cleanDealInProcess(user?.company, user?.team, params.deal_id);
  };
  return (
    <button className="button-back" onClick={goDashboard}>
      <MdKeyboardArrowLeft size={20} />
      <span style={{ fontSize: "14px", fontWeight: 400 }}>
        {`Volver a ${getNamesViews(viewName)}`}
      </span>
    </button>
  );
}
