import { Table } from "react-bootstrap";
import {
  seguridadBulonesSeguridad,
  seguridadPortaEquipaje,
} from "./../../../../../../constants/index";
import { colors } from "../../../../../../styles/colors";

export default function VariosComponent({ disabled, register, dataVarios }) {
  const styledisabled = {
    backgroundColor: colors.gris[100],
  };

  return (
    <div className="table-container">
      <p style={{ fontSize: "14px", marginBottom: "4px" }}>
        Accesorios - Varios
      </p>
      <Table
        bordered
        className="check-box-table"
        style={disabled ? styledisabled : {}}
      >
        <thead>
          <tr className="table-header">
            <th></th>
            <th>Si</th>
            <th>No</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Bulones seguridad</td>
            {dataVarios?.bulones_seguridad !== undefined
              ? seguridadBulonesSeguridad.map((item, i) => (
                  <td key={i}>
                    <input
                      className="form-check-input"
                      type="radio"
                      disabled={disabled}
                      defaultChecked={
                        disabled === true &&
                        item.value === dataVarios.bulones_seguridad
                          ? true
                          : false
                      }
                      {...register(`${item.register}`)}
                      value={`${item.value}`}
                    />
                  </td>
                ))
              : null}
          </tr>
          <tr>
            <td>Porta equipaje</td>
            {dataVarios?.portaequipaje !== undefined
              ? seguridadPortaEquipaje.map((item, i) => (
                  <td key={i}>
                    <input
                      className="form-check-input"
                      type="radio"
                      disabled={disabled}
                      defaultChecked={
                        disabled === true &&
                        item.value === dataVarios.portaequipaje
                          ? true
                          : false
                      }
                      {...register(`${item.register}`)}
                      value={`${item.value}`}
                    />
                  </td>
                ))
              : null}
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
