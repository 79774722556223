import React, { useState, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import "../../../styles/style/NavBarStyle.css";
import LogoAuta from "../../../images/logo-auta.png";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useUser } from "../../../context/UserContext";
import { NotificationDrawerComponent } from "../../../components";
import { collection, query, where, orderBy, onSnapshot, limit as firebaseLimit } from "firebase/firestore";
import { db } from "../../../firebase/config";
import { MdMenu, MdNotifications } from "react-icons/md";
import { useDeals } from "../../../context/DealsContext";
import { teams } from "../../../constants/teams";
import MainSearchComponent from "./MainSearchComponent";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { colors } from "../../../styles/colors";
import { useCompany } from "../../../context/CompanyContext";
import { updateReadMessage } from "../services/servicesTableDeals";
import MainActionDataComponent from "./MainActionDataComponent";

export default function MainHeaderComponent({ toggleSidebar }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { user } = useUser();
  const [readNotifications, setReadNotifications] = useState([]);
  const [unReadNotifications, setUnReadNotifications] = useState([]);
  const { pendingDealsCount, dealsCount } = useDeals();
  const [limit, setLimit] = useState(10);
  const [unreadCount, setUnreadCount] = useState();
  let isPageWide = useMediaQuery("(min-width: 460px)");
  const { allUsers } = useCompany();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const findSenderName = (data) => {
    const senderName = allUsers?.find((user) => data.includes(user?.id));
    return senderName?.name;
  };

  // Función para generar el contenido de la notificación
  function generateNotificationContent(sender, deal_id, ticket_id, colors, onClickHandler) {
    const message = (
      <span
        style={{
          cursor: "pointer",
          textDecoration: "none",
          color: colors.neutral.light,
        }}
        onClick={onClickHandler}
      >
        Tienes un nuevo <strong>Mensaje</strong> de{" "}
        <strong>{findSenderName(sender)}</strong>
      </span>
    );

    if (ticket_id) {
      return (
        <a
          href={ticket_id}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            cursor: "pointer",
            textDecoration: "none",
            color: colors.neutral.light,
          }}
          onClick={onClickHandler}
        >
          {message}
        </a>
      );
    } else {
      return message;
    }
  }

  // Fetching read notifications
  useEffect(() => {
    if (!user?.company || !user?.uid) return;

    const collectionRef = collection(db, "companies", user.company, "notifications");
    const notificationsQuery = query(
      collectionRef,
      where("receiver_id", "==", user.uid),
      where("read", "==", true),
      orderBy("date", "desc"),
      firebaseLimit(limit)
    );

    const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
      const newData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReadNotifications(newData);
    });

    return () => unsubscribe();
  }, [user?.company, user?.uid, limit]);

  // Fetching unread notifications
  useEffect(() => {
    if (!user?.company || !user?.uid) return;

    const collectionRef = collection(db, "companies", user.company, "notifications");
    const notificationsQuery = query(
      collectionRef,
      where("receiver_id", "==", user.uid),
      where("read", "==", false),
      orderBy("date", "desc")
    );

    let initialLoad = true;
    let initialUnreadCount = 0;

    const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
      const newData = snapshot.docs.map((doc) => {
        const data = doc.data();
        return { id: doc.id, ...data };
      });

      if (initialLoad) {
        initialLoad = false;
        initialUnreadCount = newData.length;
        setUnReadNotifications(newData);
        setUnreadCount(initialUnreadCount);
        return;
      }

      const newUnreadCount = newData.length;

      if (newUnreadCount > initialUnreadCount) {
        const latestNewNotification = newData[0];
        const sender = latestNewNotification.sender;
        const deal_id = latestNewNotification?.deal_id;
        const ticket_id = latestNewNotification?.ticket_id;
        const notification_id = latestNewNotification?.id;

        const onClickHandler = () => {
          updateReadMessage(user.company, notification_id);
          if (ticket_id) {
            window.open(ticket_id, "_blank");
          } else {
            navigate(`/comments/${deal_id}`);
          }
        };

        const notificationContent = generateNotificationContent(
          sender,
          deal_id,
          ticket_id,
          colors,
          onClickHandler
        );

        toast.success(notificationContent, {
          duration: 5000,
          position: "bottom-right",
          style: {
            color: colors.neutral.light,
            background: colors.primary.primary,
          },
          icon: (
            <MdNotifications
              className="notif-icon"
              alt="notificaciones"
              style={{ marginRight: "0px" }}
            />
          ),
        });

        initialUnreadCount = newUnreadCount;
      }

      setUnReadNotifications(newData);
      setUnreadCount(newUnreadCount);
    });

    return () => {
      unsubscribe();
    };
  }, [user?.company, user?.uid, navigate]);

  return (
    <>
      <Navbar
        className="main-header"
        style={{ paddingRight: "3%", paddingLeft: "3%" }}
      >
        <div style={{ display: "flex" }}>
          {!isPageWide ? (
            <div className="expand-button" onClick={toggleSidebar}>
              <MdMenu className="menu-icon" />
            </div>
          ) : null}
          <div
            className="navbar-container-logo"
            style={{ display: !isPageWide ? "none" : null, cursor: "pointer" }}
            onClick={() => navigate(`/tasks`)}
          >
            <img className="logo-auta" src={LogoAuta} alt="logo-auta" />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <MainSearchComponent user={user} />

          {user?.team === teams.ADMIN ? null : (
            <>
              <MainActionDataComponent
                pendingDealsCount={pendingDealsCount}
                nextDealsCount={dealsCount}
              />
            </>
          )}
          <span onClick={handleDrawerToggle} style={{ cursor: "pointer" }}>
            {unreadCount === 0 ? null : (
              <span className="position-absolute badge-notif">
                {unreadCount}
              </span>
            )}
            <MdNotifications
              className="notif-icon"
              alt="notificaciones"
              style={{ marginRight: "0px" }}
            />
          </span>
        </div>
      </Navbar>
      <NotificationDrawerComponent
        isOpen={isDrawerOpen}
        onClose={handleDrawerToggle}
        readNotifications={readNotifications}
        unReadNotifications={unReadNotifications}
        limit={limit}
        setLimit={setLimit}
        company={user?.company}
      />
    </>
  );
}
