import { db } from "../../../firebase/config";
import { doc, updateDoc } from "firebase/firestore";

// Función personalizada para filtrar objetos
Object.filter = function (mainObject, filterFunction) {
  return Object.keys(mainObject)
    .filter(function (ObjectKey) {
      return filterFunction(mainObject[ObjectKey]);
    })
    .reduce(function (result, ObjectKey) {
      result[ObjectKey] = mainObject[ObjectKey];
      return result;
    }, {});
};

// Función para actualizar datos de pre-toma
export const updatePreTake = async (company, deal_id, data, user) => {
  const kms_object = {
    "check_kms.last_kms": data.last_kms,
  };

  const accesorios_guantera_object = {
    "accesories.guantera.segunda_llave": data.segunda_llave,
    "accesories.guantera.manual": data.manual,
    "accesories.guantera.service": data.service,
  };

  const accesorios_generales_object = {
    "accesories.generales.crique": data.crique,
    "accesories.generales.llave": data.llave,
    "accesories.generales.rueda_auxilio": data.rueda_auxilio,
  };

  const accesorios_seguridad_object = {
    "accesories.seguridad.matafuegos": data.matafuegos,
    "accesories.seguridad.valiza": data.valiza,
    "accesories.seguridad.chaleco": data.chaleco,
    "accesories.seguridad.botiquin": data.botiquin,
    "accesories.seguridad.linga_remolque": data.linga_remolque,
    "accesories.seguridad.otros": data.otros,
  };

  const accesorios_varios_object = {
    "accesories.varios.bulones_seguridad": data.bulones_seguridad,
    "accesories.varios.portaequipaje": data.portaequipaje,
  };

  const results_object = {
    "results.status": data.status,
    "results.ready_to_sell": data.ready_to_sell,
    "results.discounts_value": data.discounts_value,
    "results.days_to_sale": data.days_to_sale,
    "results.mechanical_repair": data.mechanical_repair,
    "results.visual_repair": data.visual_repair,
    "results.check_car": data.check_car,
    "results.check_vtv": data.check_vtv,
    "results.chasis": data.chasis,
    "results.car_type": data.car_type,
    "results.engine": data.engine,
    "results.color": data.color,
    "results.transmission": data.transmission,
    "results.gnc": data.gnc,
    "results.fuel": data.fuel,
    "results.comments": data.comments,
    "results.kms": data.kms,
  };

  const check_kms = Object.filter(kms_object, (info) => info !== undefined && info !== "");
  const guantera = Object.filter(accesorios_guantera_object, (info) => info !== undefined && info !== "");
  const generales = Object.filter(accesorios_generales_object, (info) => info !== undefined && info !== "");
  const seguridad = Object.filter(accesorios_seguridad_object, (info) => info !== undefined && info !== "");
  const varios = Object.filter(accesorios_varios_object, (info) => info !== undefined && info !== "");
  const results = Object.filter(results_object, (info) => info !== undefined && info !== "");

  const preTakeData = Object.assign({}, check_kms, guantera, generales, seguridad, varios, results, { user_update_data: user });

  try {
    const preTakeDocRef = doc(db, "companies", company, "deals", deal_id, "data", "pre_take");
    await updateDoc(preTakeDocRef, preTakeData);
  } catch (error) {
    console.error("Error actualizando pre-toma: ", error);
    return error;
  }
};

// Función para actualizar las observaciones de pre-toma
export const updateObservacionesPreTake = async (user, company, deal_id, data) => {
  try {
    const preTakeDocRef = doc(db, "companies", company, "deals", deal_id, "data", "pre_take");
    await updateDoc(preTakeDocRef, {
      "results.discounts": data,
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error actualizando las observaciones de pre-toma: ", error);
    return error;
  }
};

// Función para actualizar los accesorios de pre-toma
export const updateAccesoriesPreTake = async (user, company, deal_id, data) => {
  try {
    const preTakeDocRef = doc(db, "companies", company, "deals", deal_id, "data", "pre_take");
    await updateDoc(preTakeDocRef, {
      "accesories.additional_discounts": data,
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error actualizando los accesorios de pre-toma: ", error);
    return error;
  }
};

// Función para actualizar archivos adjuntos de pre-toma
export const updateAttachedFiles = async (user, company, deal_id, data) => {
  try {
    const preTakeDocRef = doc(db, "companies", company, "deals", deal_id, "data", "pre_take");
    await updateDoc(preTakeDocRef, {
      "results.files": data,
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error actualizando archivos adjuntos: ", error);
    return error;
  }
};
