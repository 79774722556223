import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputComponent from "../../../../../components/InputComponent";


export default function ExteriorCheckComponent({
  disabled,
  register,
  dataCheckExterior,
  setValue,
}) {
  useEffect(() => {
    setValue("llantas_estado", dataCheckExterior?.llantas_estado);
    setValue("chapa_pintura_estado", dataCheckExterior?.chapa_pintura_estado);
  }, [dataCheckExterior, setValue]);

  return (
    <div>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              ¿Cómo calificarías el estado general de llantas/tasas?
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("llantas_estado")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Excelente</option>
              <option>Levemente rayado</option>
              <option>Muy rayado/golpeado</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <InputComponent
            disabled={disabled}
            type="number"
            max={5}
            min={1}
            label={
              "Del 1 al 5 ¿Cómo calificarías el estado general de chapa y pintura? ( siendo 5 el mas alto y 1 el mas bajo)*"
            }
            register={register}
            valueRegister={"chapa_pintura_estado"}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="select-container">
            <label className="label-input">
              Seleccioná todos los paneles en los que el auto presenta rayas
              profundas, bollos o choques.
            </label>
            {dataCheckExterior?.paneles_rayas_profundas !== undefined ? (
              <>
                {Object.entries(dataCheckExterior.paneles_rayas_profundas).map(
                  ([key, value]) =>
                    key === "ninguno" ? (
                      <div className="form-check" key={key}>
                        <input
                          className="form-check-input"
                          disabled={disabled}
                          type="checkbox"
                          defaultChecked={value}
                          {...register(`ningunos`)}
                        />
                        <label className="label-input-check">Ninguno</label>
                      </div>
                    ) : (
                      <div className="form-check" key={key}>
                        <input
                          className="form-check-input"
                          disabled={disabled}
                          type="checkbox"
                          defaultChecked={value}
                          {...register(`${key}`)}
                        />
                        <label className="label-input-check">
                          {key.replaceAll("_", " ")}
                        </label>
                      </div>
                    )
                )}
              </>
            ) : null}
          </div>
        </Col>
        <Col md={6}>
          <div className="select-container">
            <label className="label-input">
              Seleccioná todos los paneles en los que el auto presenta rayas
              leves.
            </label>
            {dataCheckExterior?.paneles_rayas_leves !== undefined ? (
              <>
                {Object.entries(dataCheckExterior.paneles_rayas_leves).map(
                  ([key, value]) => (
                    <div className="form-check" key={key}>
                      <input
                        className="form-check-input"
                        disabled={disabled}
                        type="checkbox"
                        defaultChecked={value}
                        {...register(`leves_${key}`)}
                      />
                      <label className="label-input-check">
                        {key.replaceAll("_", " ")}
                      </label>
                    </div>
                  )
                )}
              </>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
}
