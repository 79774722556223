import React from "react";
import { Table } from "react-bootstrap";
import EmptyStateComponent from "../../../components/EmptyStateComponent";

export default function HistorialComponent({ dataHistory }) {

  return (
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      {dataHistory?.length > 0 ? (
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="table-header">Fecha</th>
              <th className="table-header">Usuario</th>
              <th className="table-header">Sección</th>
              <th className="table-header">Detalle</th>
            </tr>
          </thead>
          <tbody>
            {dataHistory?.map((info, i) => (
              <tr className="table-body" key={i}>
                <td className="table-body">
                  {new Date(info.date.seconds * 1000).toLocaleDateString()} -{" "}
                  {new Date(info.date.seconds * 1000).toLocaleTimeString(
                    "en-US",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}
                </td>
                <td className="table-body">{info.user_name}</td>
                <td className="table-body">{info.section}</td>
                <td className="table-body">{info.detail}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyStateComponent />
      )}
    </div>
  );
}
