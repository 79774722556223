import { collection, doc, where, query, orderBy, limit, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/config";

export class DefaultView {
  constructor(filters, companyData, user) {
    this.user = user || null;
    this.companyData = companyData || null;
    this.filters = filters || null;
    this.loadMore = true;
    this.countingDealsPending = true;
  }

  addMinutes = function (dt, min) {
    return new Date(dt.getTime() + min * 60000);
  };

  buildQuery() {
    const user = this.user;
    const filters = this.filters;
    const companyData = this.companyData;

    let dealsCollection = collection(db, "companies", user?.company, "deals");

    let q = query(dealsCollection);

    // Filtro de usuario
    if (filters.selectedUser?.team) {
      const userFilterRole = `user_team.${filters.selectedUser.team}`;
      q = query(q, where(userFilterRole, "==", filters.selectedUser.id));
    }

    // Filtro de acciones
    if (filters.selectedAction) {
      q = query(q, where("actions.actions", "array-contains-any", [filters.selectedAction]));
    }

    // Filtro de fase
    if (filters.selectedPhase) {
      q = query(q, where("status.phase", "==", filters.selectedPhase));
    }

    // Filtro de fecha
    const dateFilterField =
      user?.team === "Admin"
        ? "actions.requested_datetime"
        : `actions.requested_datetime_by_team.${user.team}`;

    if (filters.dealDateStatus === "next") {
      q = query(q, where(dateFilterField, ">=", new Date()));
    } else if (filters.dealDateStatus === "pending") {
      q = query(q, where(dateFilterField, "<", this.addMinutes(new Date(), 5)));
    }

    // Filtros de fechas específicas
    if (filters.selectedDates.from || filters.selectedDates.to) {
      if (filters.selectedDates.from) {
        q = query(q, where(dateFilterField, ">=", filters.selectedDates.from));
      }
      if (filters.selectedDates.to) {
        q = query(q, where(dateFilterField, "<=", filters.selectedDates.to));
      }
    }

    // Orden de los resultados
    const orderDirection = filters.orderBy === "asc" ? "asc" : "desc";
    q = query(q, orderBy(dateFilterField, orderDirection));

    const limitDeals = companyData?.settings?.deals_init_limit;
    q = query(q, limit(limitDeals * filters.limitFactor));

    // Filtro de categoría del coche
    if (filters.category) {
      q = query(q, where("car_data.category", "==", filters.category));
    }

    return q;
  }

  getLoadMore(snapshotDeals) {
    return snapshotDeals.length >= this.companyData?.settings?.deals_init_limit * this.filters.limitFactor;
  }

  processDeals(firebaseDeals, setDeals, setLoadingDeals) {
    if (this.user.team === "Admin") {
      setDeals(firebaseDeals);
      setLoadingDeals(false);
      return;
    }

    const ownershipWindow = this.companyData?.settings?.ownership_window;
    const defaultOwnershipWindow = ownershipWindow?.default;
    const ownershipWindowValue = ownershipWindow?.[this.user.team] || defaultOwnershipWindow;

    const dealsWithOwner = firebaseDeals.filter((deal) => {
      const userTeam = deal?.user_team[this.user?.team];
      const requestedDatetime = deal?.actions?.requested_datetime_by_team[this.user?.team]?.toDate();
      const dateDiff = this.addMinutes(new Date(), 5) - requestedDatetime;

      if (dateDiff < 0) {
        return userTeam === this.user.uid || !userTeam;
      } else {
        const minutes = Math.floor(Math.abs(Math.round(dateDiff) / 1000 / 60));
        return minutes > ownershipWindowValue || userTeam === this.user.uid || !userTeam;
      }
    });

    setDeals(dealsWithOwner);
    setLoadingDeals(false);
  }

  doSnapshot(q, setFirebaseDeals, setLoadMore) {
    return onSnapshot(q, (querySnapshot) => {
      const snapshotDeals = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
      setLoadMore(this.getLoadMore(snapshotDeals));
      const filteredDeals = snapshotDeals.filter(deal => typeof deal?.actions?.requested_datetime !== "string");
      setFirebaseDeals(filteredDeals);
    });
  }
}
