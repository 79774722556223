import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import DragAndDropComponent from './DragAndDropComponent';
import { useUser } from '../context/UserContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function ModalReportComponent({ modal, setModal }) {
  const { user } = useUser();
  const [file, setFile] = useState([]);
  const [problemDetails, setProblemDetails] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [errorText, setErrorText] = useState('');
  const { deal_id: dealId } = useParams(); // Assuming deal_id is the param name
  const instance = axios.create({
    baseURL: 'https://backend-admin-dot-admin-dev-355313.rj.r.appspot.com/',
    timeout: 7000,
  });

  const handleClose = () => {
    setModal(false);
    setFile([]);
    setProblemDetails('');
    setIsSending(false);
    setErrorText('');
  };

  const handleSubmit = async () => {
    if (!problemDetails.trim()) {
      setErrorText('El campo de detalles del problema es requerido');
      return;
    }
    try {
      setIsSending(true);
      const formData = new FormData();
      formData.append('file', file[0]?.urlFirestore);
      formData.append('user_name', user?.name);
      formData.append('message', problemDetails);
      if (dealId) {
        formData.append('deal_id', dealId);
      }
      await instance.post('send_report', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      handleClose();
    } catch (error) {
      console.error('Error al enviar el reporte:', error);
      setErrorText('Error al enviar el reporte, inténtalo de nuevo');
      setIsSending(false);
    }
  };

  return (
    <Modal show={modal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="deal-title">Reportar problema</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          placeholder="Ingresar problema"
          className="reminder-input"
          name='details'
          style={{ height: "100px", width: "100%", paddingTop: '10px' }}
          value={problemDetails}
          onChange={(e) => setProblemDetails(e.target.value)}
          required
        />
        <DragAndDropComponent
          dataFiles={file}
          setDataFiles={setFile}
          disabled={file?.length > 0}
        />
        {errorText && (
          <p style={{ color: 'red', fontSize: '12px' }}>{errorText}</p>
        )}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button
          variant="outline-primary"
          style={{ width: "48%" }}
          type="button"
          onClick={handleClose}
        >
          Cerrar
        </Button>
        <Button
          variant="primary"
          style={{ width: "48%" }}
          type="button"
          onClick={handleSubmit}
          disabled={isSending}
        >
          {isSending ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Enviar Reporte'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
