import React, { useEffect, useState } from "react";
import HistoryDealsIcon from "../images/history-deals-icon.svg";
import { getHistoryDeals } from "../services/historyDeals";
import ModalHistoryDealsComponent from "../../../components/ModalHistoryDealsComponent";

export const ButtonHistoryDealComponent = ({ client_id }) => {
  const [modal, setModal] = useState(false);
  const handleClose = () => setModal(false);
  const [dataHistory, setDataHistory] = useState([]);

  useEffect(() => {
    if (!client_id) {
      return; // Evitar la ejecución si no hay client_id
    }
    getHistoryDeals(client_id)
      .then((res) => {
        if (res) {
          const dataHistoryDeals = res?.deals;
          setDataHistory(dataHistoryDeals || []); // Asegúrate de que dataHistoryDeals sea un array
        } else {
          console.warn("No history deals found for client:", client_id);
        }
      })
      .catch((error) => {
        console.error("Error fetching history deals:", error);
      });
  }, [client_id]);
  return (
    <>
      {client_id && (
        <div
          className="d-flex align-items-center justify-content-center me-2"
          style={{ cursor: "pointer" }}
          onClick={() => setModal(true)}
        >
          <p className="text-history-deals me-1">Historial de deals</p>
          <img src={HistoryDealsIcon} alt="history-deals-icon" />
        </div>
      )}
      <ModalHistoryDealsComponent
        modal={modal}
        handleEvent={handleClose}
        dataHistory={dataHistory}
      />
    </>
  );
};
