import React from 'react';
import { colors } from '../styles/colors';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { updateReadAllMessages, updateReadMessage } from '../modules/Main/services/servicesTableDeals';
import { useCompany } from '../context/CompanyContext';
import { Accordion } from 'react-bootstrap';
import { getInitials } from '../utils/getInitials';

export default function NotificationDrawerComponent({ isOpen, onClose, readNotifications, limit, setLimit, unReadNotifications, company }) {
  const { allUsers } = useCompany();
  const navigate = useNavigate();

  const formatDate = (timestamp) => {
    const today = new Date();
    const date = new Date(timestamp);
    if (date.toDateString() === today.toDateString()) {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    } else {
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      return `${day} ${month}`;
    }
  }

  const openTicketOrComments = (item) => {
    if (item?.ticket_id) {
      const ticketUrl = `${item.ticket_id}`;
      window.open(ticketUrl, '_blank');
    } else if (item?.deal_id) {
      navigate(`/comments/${item.deal_id}`);
    }
    updateReadMessage(company, item?.id)
  };
  const changeAllNotificationsAsRead = () => {
    const unReadNotificationsId = unReadNotifications.map((obj) => obj.id).slice(0, 500);
    updateReadAllMessages(company, unReadNotificationsId)
  }

  const findSenderName = (data) => {
    const senderName = allUsers.find((user) =>
      data.includes(user.id)
    );
    return senderName?.name
  }
  const findSenderTeamColor = (data) => {
    const senderUser = allUsers.find((user) => data.includes(user.id));
    if (senderUser && senderUser.team) {
      const teamColorVariable = `--${senderUser.team.toLowerCase()}`;
      return `var(${teamColorVariable}, white)`;
    }
    return 'white';
  };

  return (
    <div className={`notification-drawer ${isOpen ? 'open' : ''}`}>
      <div className="drawer-header">
        <p className='notification-title' onClick={() => { changeAllNotificationsAsRead() }}>Marcar todo como leidos</p>
        <button className="close-button" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>
      <div className="drawer-content scrollChat">

        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0" >
            <Accordion.Header>No leidos</Accordion.Header>
            <Accordion.Body>
              {unReadNotifications.length === 0 ?
                (<p className="empty-noft" >No hay notificaciones sin leer</p>) :
                <> {unReadNotifications.map((item) => (
                  <div key={item.id} className='notification-item' onClick={() => openTicketOrComments(item)}>
                    <div className="avatar-container">
                      <div className="circle-avatar" style={{ backgroundColor: findSenderTeamColor(item?.sender) }}>
                        {getInitials(findSenderName(item?.sender))}
                      </div>
                    </div>
                    <div style={{ width: '100%', marginLeft: '10px' }}>
                      <div className="notification-item-header">
                        <h1 className="message-title" >{findSenderName(item?.sender)}</h1>
                        <span className="message-hour">{formatDate(item?.date.toDate())}</span>
                      </div>
                      <p className="message-subtitle">{item?.mentions} </p>
                      <p className="message-body">{item?.message}</p>
                    </div>
                  </div>
                ))}
                </>}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Leidos</Accordion.Header>
            <Accordion.Body>
              {readNotifications.map((item) => (
                <div key={item.id} className='notification-item read-container' onClick={() => openTicketOrComments(item)}>
                  <div className="avatar-container">
                    <div className="circle-avatar" style={{ backgroundColor: findSenderTeamColor(item?.sender) }}>
                      {getInitials(findSenderName(item?.sender))}
                    </div>
                  </div>
                  <div style={{ width: '100%', marginLeft: '10px' }}>
                    <div className="notification-item-header">
                      <h1 className="message-title read" >{findSenderName(item?.sender)}</h1>
                      <span className="message-hour">{formatDate(item?.date.toDate())}</span>
                    </div>
                    <p className="message-subtitle read">{item?.mentions} </p>
                    <p className="message-body">{item?.message}</p>
                  </div>
                </div>
              ))}
              {readNotifications?.length < limit ? null : (
                <div className='d-flex align-items-center justify-content-center button-more' onClick={() => setLimit(limit + 10)}>
                  <p style={{ margin: "0px", color: colors.primary.primary, fontSize: "14px" }}>
                    Cargar más
                  </p>
                  <MdKeyboardArrowDown
                    color={colors.primary.primary}
                    fontSize="14"
                  />
                </div>)}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

    </div>
  )
}