/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../context/UserContext";
import Table from "react-bootstrap/Table";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDeals } from "../../../context/DealsContext";
import { ImWhatsapp } from "react-icons/im";
import EmptyStateComponent from "../../../components/EmptyStateComponent";
import { colors } from "../../../styles/colors";
import Swal from "sweetalert2";
import { teams } from "../../../constants/teams";
import { action_status } from "../../../constants/actionStatus";
import { useCompany } from "../../../context/CompanyContext";
import { SpinnerFullScreenComponent } from "../../../components";
import { UserAttendingTooltipComponent, TableActionsComponent } from ".";
import { updateDealInProcess } from "../services/servicesTableDeals";
import { getStatusColor } from "../../../utils/StatusColor";
import { Form, Spinner } from "react-bootstrap";
import { updateGoogleSheets } from "../services/taskTableServices";

const headTable = [
  "Aging",
  "Actualizado",
  "Último agente",
  "Acciones",
  "Cliente",
  "Patente",
  "Vehiculo",
  "Cat",
  "Oferta",
  "",
];

const TasksTableComponent = () => {
  let isPageWide = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();
  const { user } = useUser();
  const { actions, allUsers } = useCompany();
  const { loadMore, increaseDeals, deals, filters, setFilters, loadingDeals } =
    useDeals();
  const [refresh, setRefresh] = useState("");
  const [dealsActionsFilter, setDealsActionsFilter] = useState([]);
  const [activeDealsOpen, setActiveDealsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (loadingDeals) {
      return;
    }
    const interval = setInterval(() => {
      setRefresh((seconds) => seconds + 1);
      setFilters({ ...filters, refresh: filters.refresh + 1 });
    }, 180000);
    return () => {
      clearInterval(interval);
    };
  }, [refresh, deals, loadingDeals]);

  useEffect(() => {

    if (!activeDealsOpen) {
      setDealsActionsFilter(deals);
    } else {
      const filterActions = deals.filter(
        (item) => item.actions.actions?.length > 0
      );
      setDealsActionsFilter(filterActions);
    }

  }, [deals, activeDealsOpen]);

  const getTime = (time) => {
    const timeDiff = time - new Date();
    const absTimeDiff = Math.abs(timeDiff);
    const min = Math.floor((absTimeDiff / 1000 / 60) % 60);
    const hs = Math.floor(absTimeDiff / 1000 / 60 / 60);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setHours(0, 0, 0, 0);

    const options = { weekday: "long", day: "numeric", month: "short" };

    if (time > tomorrow || time < yesterday) {
      return time.toLocaleDateString("es-AR", options);
    }

    let tm = "";
    if (hs >= 1) {
      tm = `${hs}hr ${min}min `;
    } else if (hs === 0) {
      tm = `${min}min `;
    }
    if (timeDiff < 0) {
      return "Hace " + tm;
    } else {
      return "En " + tm;
    }
  };

  const verDeal = (id) => {
    navigate(`/comments/${id}`);
  };
  let userTeamInProcess = [];

  const canUserViewDeal = (data) => {
    if (data?.current_user_team) {
      userTeamInProcess = Object.keys(data?.current_user_team);
      const userTeamExist = userTeamInProcess.includes(user?.team);
      if (!userTeamExist) {
        return true;
      } else {
        if (!data.current_user_team[user?.team]) {
          return true;
        } else if (data.current_user_team[user?.team] === user.uid) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };
  let showName = [];

  const showTooltip = (data) => {
    let usersNames = [];
    for (let key in data?.current_user_team) {
      if (data?.current_user_team[key] !== null) {
        Object.values(data?.current_user_team).forEach((value) => {
          if (value) {
            const foundUser = allUsers?.find((user) =>
              value?.includes(user.id)
            );
            if (foundUser) {
              usersNames.push(foundUser);
            } else {
              // Agregar el id del usuario undefined
              usersNames.push({
                team: Object.keys(data?.current_user_team),
                name: value,
              });
            }
          }
        });
        showName = usersNames;
        return true;
      }
    }
  };

  let userNameInProcess;

  function handleClick(event, data) {
    if (user?.team === teams.ADMIN || !data?.current_user_team) {
      updateDealInProcess(user?.company, user?.team, user?.uid, data.id);
      // verDeal(data.id);
    } else if (canUserViewDeal(data)) {
      if (data.current_user_team[user?.team] !== user.uid) {
        updateDealInProcess(user?.company, user?.team, user?.uid, data.id);
      }
      // verDeal(data.id);
    } else {
      event.preventDefault();
      const userIdInProcess = Object.values(data?.current_user_team);
      userNameInProcess = allUsers.find((user) =>
        userIdInProcess.includes(user.id)
      );
      Swal.fire({
        title: "No puede trabajar en este deal",
        text: `Se encuentra en proceso con ${userNameInProcess?.name}  `,
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
    }
  }

  const rowStyleDealInProsess = {
    backgroundColor: colors.primary.secundaryTransparent,
    cursor: "pointer",
  };
  const rowStyleDeal = {
    backgroundColor: colors.neutral.light,
    cursor: "pointer",
  };
  function getSpecificWord(text) {
    const keywordMapping = {
      expected_offer: "Oferta esperada",
      final_offer: "Oferta final",
      offer: "Oferta inicial",
      range: "Rango inicial",
      sale_price: "Precio de venta",
      virtual_offer: "Oferta Virtual",
    };
    if (keywordMapping.hasOwnProperty(text)) {
      return keywordMapping[text];
    } else {
      return false;
    }
  }

  const styleFontSize = {
    fontSize: "12px",
  };

  function formatNumberWithCommas(numberOrString) {
    let number = parseFloat(numberOrString);

    if (isNaN(number)) {
      return "Invalid input";
    }
    // Verifica si el número tiene decimales
    const isFloat = number % 1 !== 0;
    // Formatea el número con puntos como separadores de miles y, si es necesario, muestra los decimales
    const formattedNumber = isFloat
      ? number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return formattedNumber;
  }

  const createSheetData = async (data) => {
    setIsSubmitting(true)
    const formattedData = data.map(deal => [
      deal.created ? formatCreatedDate(deal.created) : '',
      getTime(deal.actions.requested_datetime?.toDate() || ''),
      deal.last_agent ? `${deal.last_agent.name} (${deal.last_agent.team})` : '',
      deal.actions?.data_actions.map(action => action.action).join(', '),
      deal.name,
      deal.car_id ? analyzeString(deal.car_id) : '',
      deal.car_data ? `${deal.car_data.brand.toLowerCase()} ${deal.car_data.model.toLowerCase()} ${formatNumberWithCommas(deal.car_data.kms) + ' kms'}` : '',
      deal.car_data?.category || '',
      deal.last_offer ? `${getSpecificWord(deal.last_offer.offer_id)}: ${deal.last_offer.value.includes("$") ? deal.last_offer.value : "$" + deal.last_offer.value}` : '',
      deal.trengo_ticket_id ? 'WhatsApp' : '',
      deal.id ? `${process.env.REACT_APP_URL}/${deal.id} ` : '',
    ]);
    try {
      const response = await updateGoogleSheets({ data: formattedData });
      if (response && response.link) {
        // Abrir la URL en una nueva pestaña
        window.open(response.link, '_blank');
        setIsSubmitting(false)
      } else {
        console.error('No se encontró una URL en la respuesta:', response);
        setIsSubmitting(false)
      }
    } catch (error) {
      console.error('Error al modificar el equipo del usuario:', error);
      setIsSubmitting(false)

    }
  };
  function analyzeString(input) {
    const hasNumbers = /\d/; // Regex to check for any digits
    const hasLetters = /[a-zA-Z]/; // Regex to check for any letters

    if (hasNumbers.test(input) && hasLetters.test(input)) {
      return input; // Contains both numbers and letters
    } else if (hasNumbers.test(input) && !hasLetters.test(input)) {
      return ''; // Contains only numbers
    }

    return input; // For any other cases (e.g., only letters or special characters)
  }
  function formatCreatedDate(created) {
    if (!created) return '';

    const createdDate = new Date(created);
    const now = new Date();

    const diffInMilliseconds = now - createdDate;
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    if (diffInDays > 0) {
      return `${diffInDays} d`;
    } else {
      return '0 d';
    }
  }

  return (
    <>
      {loadingDeals ? (
        <SpinnerFullScreenComponent />
      ) : (
        <>
          {dealsActionsFilter?.length === 0 ? (
            <div className="mt-4 h-100 deals-table-style">
              <EmptyStateComponent />
            </div>
          ) : (
            <>
              <div className="d-flex mt-2 mb-2 w-100 align-items-center justify-content-between">
                <Form.Check
                  type="checkbox"
                  id="custom-switch-open"
                  label="Deals open"
                  checked={activeDealsOpen}
                  onChange={() => setActiveDealsOpen(!activeDealsOpen)}
                  style={{ fontSize: "14px", marginBottom: '0px' }}
                />
                <button
                  className="btn btn-primary"
                  onClick={() => createSheetData(dealsActionsFilter)}
                >
                  {isSubmitting ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    'Exportar a Google Sheets'
                  )}

                </button>
              </div>
              <Table hover responsive className="mt-1 deals-table-style">
                <thead>
                  <tr>
                    {headTable.map((data, i) => (
                      <th
                        key={i}
                        className=" textHead pt-3 pb-3"
                        style={{
                          width: i === 1 ? "10%" : "",
                          fontSize: "14px",
                        }}
                      >
                        {data}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {dealsActionsFilter?.map((data, i) => (
                    <tr
                      key={i}
                      onClick={(e) => handleClick(e, data)}
                      style={
                        !data?.current_user_team
                          ? rowStyleDeal
                          : !data?.current_user_team[user?.team]
                            ? rowStyleDeal
                            : data?.current_user_team[user?.team] !== user.uid
                              ? rowStyleDealInProsess
                              : rowStyleDeal
                      }
                    >
                      <td onClick={() => verDeal(data.id)}>
                        <p
                          className="text-content-table-deals text-lowercase"
                          style={styleFontSize}
                        >
                          {formatCreatedDate(data?.created)}
                        </p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        {user?.team === teams.ADMIN ? (
                          <p
                            className="text-content-table-deals"
                            style={styleFontSize}
                          >
                            {getTime(
                              data?.actions?.requested_datetime?.toDate()
                            )}
                          </p>
                        ) : (
                          <p
                            className="text-content-table-deals"
                            style={styleFontSize}
                          >
                            {getTime(
                              data?.actions?.requested_datetime_by_team[
                                user?.team
                              ]?.toDate()
                            )}
                          </p>
                        )}
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p
                          className="text-content-table-deals"
                          style={styleFontSize}
                        >
                          {data?.last_agent
                            ? data?.last_agent?.name +
                            " " +
                            "(" +
                            data?.last_agent?.team +
                            ")"
                            : null}
                        </p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        {data?.actions?.data_actions?.map((action, i) =>
                          user?.team !== teams.ADMIN &&
                            action.team === user?.team &&
                            action.status === action_status.OPEN ? (
                            <TableActionsComponent
                              actions={actions}
                              action={action}
                              i={i}
                              key={i}
                            />
                          ) : user?.team === teams.ADMIN &&
                            action.status === action_status.OPEN ? (
                            <TableActionsComponent
                              actions={actions}
                              action={action}
                              i={i}
                              key={i}
                            />
                          ) : actions === undefined ? (
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/admin-dev-355313.appspot.com/o/Contenedor.svg?alt=media&token=d2b03e0d-2402-407c-940c-36f5e2a60e3b"
                              className="me-1"
                              key={i}
                            />
                          ) : null
                        )}
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p
                          className="text-content-table-deals"
                          style={styleFontSize}
                        >
                          {data.name}
                        </p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p
                          className="text-content-table-deals"
                          style={styleFontSize}
                        >
                          {analyzeString(data.car_id)}
                        </p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        {data?.car_data ? (
                          <p
                            className="text-content-table-deals"
                            style={styleFontSize}
                          >
                            {data.car_data?.brand?.toLowerCase()}{" "}
                            {data.car_data?.brand === "FORD"
                              ? data.car_data?.model
                                .split(" ")[0]
                                ?.toLowerCase()
                              : data.car_data?.model?.toLowerCase()}{" "}
                            {data.car_data.kms &&
                              formatNumberWithCommas(data.car_data.kms) +
                              " kms"}
                          </p>
                        ) : null}
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p
                          className="text-content-table-deals"
                          style={styleFontSize}
                        >
                          {data?.car_data?.category}
                        </p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        {data?.last_offer?.offer_id !== "" ? (
                          <div>
                            <p
                              className="text-content-table-deals"
                              style={{
                                fontSize: "10px",
                              }}
                            >
                              {getSpecificWord(data?.last_offer?.offer_id)}{" "}
                            </p>
                            <p
                              style={{
                                color: getStatusColor(data?.last_offer?.status),
                                margin: "0px",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              {data?.last_offer?.value.includes("-")
                                ? data?.last_offer?.value
                                  .split("-")
                                  .map((part, idx) => (
                                    <span key={idx}>
                                      {idx > 0 ? "-" : ""}
                                      {part.includes("$") ? part : "$" + part}
                                    </span>
                                  ))
                                : data?.last_offer?.value.includes("$")
                                  ? data?.last_offer?.value
                                  : "$" + data?.last_offer?.value}
                            </p>
                          </div>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const url = data?.source === "landing_san_jorge" || data?.source === "san_jorge" || data?.source === ""
                            ? `https://app.trengo.com/tickets/${data.trengo_ticket_id}`
                            : `https://app.trengo.com/tickets/${data.trengo_auta_ticket_id}`;
                          window.open(url, "_blank");
                        }}
                      >
                        <span style={{ display: "flex", alignItems: "flex-end" }}>
                          {data?.source === "landing_san_jorge" || data?.source === "san_jorge" || data?.source === "" ? (
                            data.trengo_ticket_id && (
                              <p className="text-content-table-deals">
                                <ImWhatsapp fontSize="20" color="#00BB2D" />
                              </p>
                            )
                          ) : (
                            data.trengo_auta_ticket_id && (
                              <p className="text-content-table-deals">
                                <ImWhatsapp fontSize="20" color="#00BB2D" />
                              </p>
                            )
                          )}
                          {showTooltip(data) && (
                            <UserAttendingTooltipComponent showName={showName} />
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
                  {!loadMore ? null : (
                    <tr>
                      <td
                        colSpan="9"
                        style={{ textAlign: "center", cursor: "pointer" }}
                        onClick={increaseDeals}
                      >
                        <div
                          className={`d-flex align-items-center justify-content-${isPageWide ? "center" : "start"
                            }`}
                        >
                          <p
                            style={{
                              margin: "0px",
                              color: colors.primary.primary,
                              fontSize: "14px",
                              marginLeft: isPageWide ? "" : "100px",
                            }}
                          >
                            Cargar más
                          </p>
                          <MdKeyboardArrowDown
                            color={colors.primary.primary}
                            fontSize="14"
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </>
          )}
        </>
      )}
    </>
  );
};

export default TasksTableComponent;
