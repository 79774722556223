import React from "react";

export default function GraphicsPage() {
  return (
    <div className="p-3 h-100">
      <iframe
        width="100%"
        height="100%"
        src="https://lookerstudio.google.com/embed/reporting/25cf046d-5a0b-41b3-805a-78d4e7ffc427/page/p_quhcl17kfd"
        frameborder="0"
        allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </div>
  );
}
