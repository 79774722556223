import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputComponent from "../../../../components/InputComponent";
import { updateApoderado } from "../../services/TakeServices/HolderServices";
import { useUser } from "../../../../context/UserContext";

export default function ModalEditLegalEntityComponent({
  modal,
  setModal,
  deal_id,
  apoderadoEdit,
  apoderadoId,
  arrayPersonaJuridica,
}) {
  const { register, handleSubmit, reset, setValue } = useForm();
  const { user } = useUser();

  React.useEffect(() => {
    setValue("name", apoderadoEdit?.name);
    setValue("dni", apoderadoEdit?.dni);
  }, [apoderadoEdit, setValue]);

  const handleClose = () => setModal(false);

  const addPersonaJuridica = (data) => {
    const newObj = Object.assign({}, apoderadoEdit, data);
    const indexPersonaJuridica = arrayPersonaJuridica.findIndex(
      (item) => item.dni === apoderadoEdit.dni
    );
    arrayPersonaJuridica[indexPersonaJuridica] = newObj;
    updateApoderado(deal_id, arrayPersonaJuridica, user);
    reset();
    setModal(false);
  };

  return (
    <>
      <Modal size="lg" show={modal} onHide={handleClose}>
        <form onSubmit={handleSubmit(addPersonaJuridica)}>
          <Modal.Header closeButton>
            <Modal.Title className="deal-title">Editar apoderado</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Apoderado"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"name"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"DNI Apoderado"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"dni"}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              Editar apoderado
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
