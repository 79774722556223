import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { editCarCategory } from '../../services/sevicesPricingView';
// Asegúrate de importar el servicio adecuado

export default function PricingEditCategoryModalComponent({ modal, setModal, selectedIds, fetchData, userId, fetchLastEditor }) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleButtonClick = (category) => {
    setSelectedCategory(category);
  };

  const handleSaveChanges = async () => {
    setIsSubmitting(true);
    try {
      const result = await editCarCategory({ user_id: userId, idsAuta: selectedIds, category: selectedCategory });
      console.log('Resultado del POST de categorías:', result);
      fetchData();
      fetchLastEditor();
    } catch (error) {
      console.error('Error al realizar el POST de categorías:', error);
    } finally {
      setIsSubmitting(false);
      setModal(false); // Cierra el modal después de guardar cambios
    }
  };

  return (
    <Modal show={modal} centered onHide={() => setModal(false)}>
      <Modal.Body className='pricing-body-modal d-flex align-items-center'>
        <p className="add-card-title-modal mt-2" style={{ textAlign: 'center' }}> Editar Categoría</p>
        <div className="square-buttons-container mt-4 mb-2">
          {['A', 'B', 'C', 'D'].map((category) => (
            <button
              key={category}
              className={`square-button ${selectedCategory === category ? 'selected' : ''}`}
              onClick={() => handleButtonClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: 'none', justifyContent: 'space-evenly' }}>
        <Button
          variant="outline-primary"
          style={{ width: "40%" }}
          onClick={() => setModal(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          style={{ width: "40%" }}
          onClick={handleSaveChanges}
          disabled={!selectedCategory || isSubmitting}
        >
          {isSubmitting ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Guardar cambios'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
