import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export default function InicialInfoComponent({
  disabled,
  register,
  dataInfoInicial,
  setValue,
}) {
  React.useEffect(() => {
    setValue("ex_vehiculo_pasajeros", dataInfoInicial?.ex_vehiculo_pasajeros);
    setValue("situacion_gnc", dataInfoInicial?.situacion_gnc);
    setValue("dano_estructural", dataInfoInicial?.dano_estructural);
    setValue("modificacion_deportiva", dataInfoInicial?.modificacion_deportiva);
    setValue("ploteo", dataInfoInicial?.ploteo);
  }, [dataInfoInicial, setValue]);

  return (
    <div>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              ¿El auto es o fue utilizado como Taxi, Remis o similar?
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("ex_vehiculo_pasajeros")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
          <div className="select-container">
            <label className="label-input">
              ¿El auto tiene daños estructurales?
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("dano_estructural")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
          <div className="select-container">
            <label className="label-input">¿El auto tiene o tuvo GNC?</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("situacion_gnc")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Tiene actualmente GNC</option>
              <option>Tuvo GNC en el pasado</option>
              <option>No tiene, ni tuvo GNC</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">¿El auto tiene ploteo?</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("ploteo")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
          <div className="select-container">
            <label className="label-input">¿El auto tiene modificación deportiva?</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("modificacion_deportiva")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>

    </div>
  );
}
