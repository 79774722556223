import { useState, useEffect } from 'react';
import { doc, getDoc, collection } from 'firebase/firestore';
import { db } from '../firebase/config';

const useFirebaseGet = (company, deal_id, collectionName, docName) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(
          collection(db, 'companies', company, 'deals', deal_id, collectionName),
          docName
        );
        const snapshot = await getDoc(docRef);
        setData(snapshot.data());
        setLoading(false);
      } catch (err) {
        console.log(err);
        setError('Error al obtener los datos: ' + err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [collectionName, company, deal_id, docName]);

  return { data, loading, error };
};

export default useFirebaseGet;
