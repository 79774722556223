import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { colors } from "../../../../../styles/colors";
import {
  aceptarPropuesta,
  rechazarPropuesta,
  solicitarRevision,
} from "../../../services/servicesPricing";
import { teams } from "../../../../../constants/teams";

const RevisionModal = ({
  deal_id,
  setshowRevisionModal,
  showRevisionModal,
  titleModal,
  user,
  revisionActionId,
  valueCotizacion,
  sendActionId,
  dataHistory,
  status,
  actionIdRejected,
  setActiveModalPricing,
}) => {
  const { register, handleSubmit, reset } = useForm();

  const [showModalRevision, setshowModalRevision] = useState();

  const handleRevisionModal = () => {
    setshowRevisionModal(false);
    setActiveModalPricing(false);
  };

  const sendApply = () => {
    const data = {
      "action_requested.action_id": sendActionId,
      "action_requested.comments": "",
      "action_requested.user": user,
      "action_requested.data_reminder": {},
    };
    aceptarPropuesta(user?.company, deal_id, data);
    setshowRevisionModal(false);
    setActiveModalPricing(false);
  };

  const activeModalRevision = () => {
    setshowRevisionModal(false);
    setshowModalRevision(true);
    setActiveModalPricing(false);
  };

  const rejectedPrice = () => {
    const data = {
      "action_requested.action_id": actionIdRejected,
      "action_requested.comments": "",
      "action_requested.user": user,
    };
    rechazarPropuesta(user?.company, deal_id, data);
    toast.success("Oferta rechazada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowRevisionModal(false);
    setActiveModalPricing(false);
  };

  const sendSolicitudRevision = (dataComment) => {
    const data = {
      "action_requested.action_id": revisionActionId,
      "action_requested.user": user,
      "action_requested.comments": dataComment.comments,
      "action_requested.data_reminder": {},
    };
    solicitarRevision(user?.company, deal_id, data);
    setshowModalRevision(false);
    reset();
  };

  return (
    <div>
      <Modal
        show={showRevisionModal}
        onHide={handleRevisionModal}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="titleModal">{titleModal}</p>
            <input
              className="inputModalDisabled mt-3"
              style={{ width: "100%" }}
              placeholder={valueCotizacion}
              disabled
            />
            <div
              style={{ width: "100%" }}
              className="d-flex flex-column align-items-center justify-content-between"
            >
              {status !== "expired" &&
              (user.team === teams.CS ||
                user.team === teams.AS ||
                user.team === teams.ADMIN) ? (
                <>
                  <button
                    variant="primary"
                    className="btnSendPricing mt-3 mb-2"
                    onClick={sendApply}
                  >
                    Aceptó la oferta
                  </button>
                  <button
                    variant="primary"
                    className="btnSendPricing mb-2"
                    onClick={rejectedPrice}
                  >
                    Rechazó la oferta
                  </button>
                </>
              ) : null}
              <button
                variant="primary"
                className="btnPedirRevision mb-3 mt-2"
                onClick={activeModalRevision}
              >
                Pedir revisión
              </button>
              {dataHistory?.length > 0
                ? dataHistory.map((info, i) => (
                    <div
                      className="d-flex align-items-center justify-content-between dividerHistory mb-3"
                      style={{ width: "100%" }}
                      key={i}
                    >
                      <p className="textHistory mb-2">${info.value}</p>
                      <p className="textHistory mb-2">
                        {" "}
                        {new Date(
                          info.date.seconds * 1000
                        ).toLocaleDateString()}{" "}
                        -{" "}
                        {new Date(info.date.seconds * 1000).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </p>
                    </div>
                  ))
                : null}
              <button
                variant="secondary"
                onClick={handleRevisionModal}
                className="btnCancelarModal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalRevision}
        onHide={handleRevisionModal}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="titleModal">{titleModal}</p>
            <form
              onSubmit={handleSubmit(sendSolicitudRevision)}
              style={{ width: "100%" }}
            >
              <textarea
                className="inputModal mt-3"
                style={{ width: "100%", height: "110px" }}
                placeholder="Dejar comentario opcional"
                {...register("comments")}
              />
              <div
                style={{ width: "100%" }}
                className="d-flex flex-column align-items-center justify-content-between"
              >
                <button
                  variant="primary"
                  className="btnSendPricing mb-2 mt-3"
                  type="submit"
                  // onClick={sendApply}
                >
                  Solicitar
                </button>
              </div>
            </form>
            <button
              variant="secondary"
              className="btnCancelarModal"
              onClick={() => {
                setshowModalRevision(false);
                setActiveModalPricing(false);
              }}
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RevisionModal;
