
import { apiUrl, axiosInstance, handleError } from '../../../services/apiConfigPricing';

export const getApiData = async ({ endpoint, search, page, carsPerPage, filterValues }) => {

  try {
    const response = await axiosInstance.get(`${apiUrl}/${endpoint}`, {
      params: {
        search,
        page,
        cars_per_page: carsPerPage,
        stopper_filter: filterValues?.stopper_filter,
        category_filter: filterValues?.category_filter,
        year_filter: filterValues?.year_filter,
        brand_filter: filterValues?.brand_filter,
        model_filter: filterValues?.model_filter,
        version_filter: filterValues?.version_filter,
        price_filter_min: filterValues?.price_filter_min,
        price_filter_max: filterValues?.price_filter_max,
        order_by_prices: filterValues?.order_by_prices,
        order_by_name: filterValues?.order_by_name,
        order_by_year: filterValues?.order_by_year,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener datos de la API' };
  }
};

export const getDollarExchange = async ({ endpoint, params }) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/${endpoint}`, {
      params,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener datos de la API' };
  }
};

export const postDollarExchange = async ({ endpoint, data }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/${endpoint}`, data);
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al enviar datos a la API' };
  }
};
export const editCarCategory = async ({ user_id, idsAuta, category }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/pricing/change_category`, {
      user_id,
      idsAuta,
      category,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al enviar datos para editar la categoría de autos' };
  }
};
export const editCarStopper = async ({ user_id, idsAuta, stopper }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/pricing/change_stopper`, {
      user_id,
      idsAuta,
      stopper,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al enviar datos para editar el stopper de autos' };
  }
};
export const editCarPriceByCoefficient = async ({ user_id, idsAuta, percentage, price }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/pricing/change_price`, {
      user_id,
      idsAuta,
      percentage,
      price,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al enviar datos para editar el precio por coeficiente de autos' };
  }
};
export const exportTable = async ({ endpoint }) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/${endpoint}`);
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al exportar la tabla' };
  }
};
export const getQuoterPrice = async ({ idAuta, kms, gnc }) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/quoter/get_price`, {
      params: {
        idAuta,
        kms,
        gnc,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener datos de la API para el cotizador' };
  }
};


export const getBrands = async () => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/quoter/get_brands`, {
      params: {
        get_stoppers: true,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener las marcas de la API' };
  }
};

export const getModels = async (brandName) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/quoter/get_models`, {
      params: {
        get_stoppers: true,
        brand: brandName,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener los modelos de la API' };
  }
};

export const getVersions = async (brandName, modelName) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/quoter/get_versions`, {
      params: {
        get_stoppers: true,
        brand: brandName,
        model: modelName,
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener las versiones de la API' };
  }
};
export const getMinAndMaxPrices = async () => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/pricing/table_extremes`);
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener el precio máximo y mínimo' };
  }
};

export const getEditor = async (user_id,) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/pricing/last_pricing_update`, {
      user_id,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener el ultimo usuario que editó' };
  }
};
export const getPricingSettings = async () => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/pricing/pricing_settings`);
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener los ajustes de precios' };
  }
};

// Función para guardar los settings de precios
export const savePricingSettings = async (preparedDiscounts) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/pricing/pricing_settings`, preparedDiscounts);
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al guardar los ajustes de precios' };
  }
};

export const changeAllPrices = async ({ user_id, percentage, brand_filter, model_filter, year_filter, category_filter }) => {
  try {
    const params = {
      user_id,
      percentage,
      brand_filter,
      model_filter,
      year_filter,
      category_filter,
    };

    // Usamos axiosInstance.post pero con los parámetros en la URL
    const response = await axiosInstance.post(`${apiUrl}/pricing/change_all_prices`, null, { params });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al enviar datos para cambiar todos los precios' };
  }
};

export const getChangeHistory = async () => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/pricing/get_change_history`);
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener el historial de cambios' };
  }
};

export const verifyChangePrices = async ({ user_id, percentage, brand_filter, model_filter, year_filter, category_filter }) => {
  try {
    const params = {
      user_id,
      percentage,
      brand_filter,
      model_filter,
      year_filter,
      category_filter,
    };
    const response = await axiosInstance.get(`${apiUrl}/pricing/change_all_prices`, { params });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al enviar datos para cambiar todos los precios' };
  }
};
