import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { editCarCategory, editCarPriceByCoefficient, editCarStopper } from '../../services/sevicesPricingView';

export default function PricingEditStopperModalComponent({
  modal,
  setModal,
  selectedIds,
  fetchData,
  stopperValue,
  categoryValue,
  dollarValue,
  isSingleRowSelected,
  userId,
  fetchLastEditor
}) {
  let isPageWide = useMediaQuery("(min-width: 460px)");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedStopper, setSelectedStopper] = useState();
  const [alertOption, setAlertOption] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [dollars, setDollars] = useState();

  const handleButtonClick = (stopper) => {
    if (stopper === 'SI') {
      //opcion 1: cambiar stopper a si una o mas versiones
      setAlertOption('option1')
    } else {
      if (isSingleRowSelected) {
        //opcion 2: cambiar stopper a No, 80 o 100 UNA version
        setAlertOption('option2')
      } else {
        //opcion 3: cambiar stopper a No, 80 o 100 en mas de una version
        setAlertOption('option3')
      }
    }
    setSelectedStopper(stopper);
  };

  const handleButtonClickCategory = (category) => {
    setSelectedCategory(category);
  };

  const formatCurrency = (value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    const formattedValue = new Intl.NumberFormat('es-ES').format(numericValue);
    return formattedValue;
  };

  const handleDollarsChange = (e) => {
    const inputValue = e.target.value;
    setDollars('$' + formatCurrency(inputValue));
  };

  const handleSaveChanges = async () => {
    setIsSubmitting(true);
    try {
      const result = await editCarStopper({ user_id: userId, idsAuta: selectedIds, stopper: selectedStopper });
      console.log('Resultado del POST de stopper:', result);
      fetchData()
      fetchLastEditor()
    } catch (error) {
      console.error('Error al realizar el POST de stopper:', error);

    } finally {
      setIsSubmitting(false);
      setModal(false);

    }
  };

  const saveChangesStopperDollarAndCategory = async () => {
    const requestData = {
      user_id: userId,
      idsAuta: selectedIds,
      price: parseFloat(dollars.replace(/\D/g, ''))
    };
    try {
      setIsSubmitting(true);
      const resultCategory = await editCarCategory({ user_id: userId, idsAuta: selectedIds, category: alertOption === 'option1' ? 'S' : selectedCategory });
      console.log('Resultado del POST de categoy', resultCategory);
      if (alertOption === 'option2') {
        const resultDolar = await editCarPriceByCoefficient(requestData);
        console.log('Resultado del POST de usd', resultDolar);
      }
    } catch (error) {
      console.error('Error al realizar el POST de stopper:', error);
    }
    handleSaveChanges()
  }

  const handleCloseModal = () => {
    setModal(false)
    setAlertOption('')
    setSelectedCategory()
    setSelectedStopper()
  }

  useEffect(() => {
    if (isSingleRowSelected && stopperValue?.length === 1) {
      setSelectedStopper(stopperValue[0]);
    } else {
      setSelectedStopper('');
    }
    if (isSingleRowSelected && categoryValue?.length === 1) {
      setSelectedCategory(categoryValue[0]);
    } else {
      setSelectedCategory('');
    }
    if (isSingleRowSelected && dollarValue?.length === 1) {
      setDollars('$' + formatCurrency(dollarValue[0]));
    } else {
      setDollars('');
    }
  }, [isSingleRowSelected, stopperValue, categoryValue, dollarValue])

  useEffect(() => {
    if (!modal) {
      setAlertOption('');
      setSelectedCategory(null);
      setSelectedStopper(null);
      setDollars('');
    }
  }, [modal]);

  return (
    <Modal show={modal} centered onHide={() => setModal(false)}>
      <p className="add-card-title-modal mt-2" style={{ textAlign: 'center' }}>Editar Stopper</p>
      <Modal.Body>
        <div className="d-flex justify-content-center mt-2" >
          <div className="d-flex flex-column" style={{ width: "40%" }}>
            <Form.Check
              inline
              label="Si"
              name="stopperGroup"
              type='radio'
              className="mb-2 radio-button-text-pricing"
              id={`inline-radio-1`}
              onChange={() => handleButtonClick('SI')}
              checked={selectedStopper === 'SI'}
            />
            <Form.Check
              inline
              label="Hasta 80.000 KMS"
              name="stopperGroup"
              type='radio'
              className="mb-2 radio-button-text-pricing"
              id={`inline-radio-2`}
              onChange={() => handleButtonClick('HASTA 80.000KMS')}
              checked={selectedStopper === 'HASTA 80.000KMS'}
            />
          </div>
          <div className="d-flex flex-column" style={{ width: "40%" }}>
            <Form.Check
              inline
              label="No"
              name="stopperGroup"
              type='radio'
              className="mb-2 radio-button-text-pricing"
              id={`inline-radio-3`}
              onChange={() => handleButtonClick('NO')}
              checked={selectedStopper === 'NO'}
            />
            <Form.Check
              inline
              label="Hasta 100.000 KMS"
              name="stopperGroup"
              type='radio'
              className="mb-2 radio-button-text-pricing"
              id={`inline-radio-4`}
              onChange={() => handleButtonClick('HASTA 100.000KMS')}
              checked={selectedStopper === 'HASTA 100.000KMS'}
            />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center" >
          {alertOption === 'option1' ? (
            <>
              <p className="add-card-title-modal">ADVERTENCIA</p>
              <p className="card-subtitle text-center mt-2 mb-4">
                ¿Estas seguro que deseas realizar el siguiente cambio?
                La/s version/es seleccionadas no estarán disponibles para cotizar.
                La/s categoría/s preestablecidas de las mismas se
                <strong> PERDERAN</strong> y pasaran a ser "Stopper (S)". </p></>
          ) : alertOption === 'option2' ? (
            <>
              <p className="card-subtitle text-center mt-2 mb-4">
                ¿Estas seguro que deseas realizar el siguiente cambio?
                La version que seleccionaste estara disponible para cotizar,
                por favor revisa y edita de ser necesario la categoria y
                precio en USD para continuar con el cambio.</p>
              <p className="add-card-title-modal">Selecciona Categoría</p>
              <div className="square-buttons-container mt-2 mb-2">
                {['A', 'B', 'C', 'D'].map((category) => (
                  <button
                    key={category}
                    className={`square-button ${selectedCategory === category ? 'selected' : ''}`}
                    onClick={() => handleButtonClickCategory(category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
              <p className="add-card-title-modal mt-2 ">Edita precio en USD</p>
              <input
                type='text'
                className="input-pricing mb-4"
                placeholder='$000.000'
                value={dollars}
                onChange={handleDollarsChange}
              />
            </>
          ) : alertOption === 'option3' ? (
            <>
              <p className="add-card-title-modal">ADVERTENCIA</p>
              <p className="card-subtitle text-center mt-2 mb-4">
                ¿Estas seguro que deseas realizar el siguiente cambio?
                Por favor asignale una categoria a todas las versiones
                seleccionadas que a partir de ahora estarán disponibles
                para cotizar, por favor revisa y edita de ser necesario
                el precio en USD de los mismos. </p>
              <p className="add-card-title-modal">Selecciona Categoría</p>
              <div className="square-buttons-container mt-2 mb-2">
                {['A', 'B', 'C', 'D'].map((category) => (
                  <button
                    key={category}
                    className={`square-button ${selectedCategory === category ? 'selected' : ''}`}
                    onClick={() => handleButtonClickCategory(category)}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-evenly', borderTop: 'none' }}>
        <Button
          variant="outline-primary"
          style={{ fontSize: !isPageWide ? '14px' : null, width: "40%" }}
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          style={{ fontSize: !isPageWide ? '14px' : null, width: "40%" }}
          onClick={saveChangesStopperDollarAndCategory}
          disabled={!selectedStopper || isSubmitting}
        >
          {isSubmitting ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : alertOption === 'option2' ? 'Guardar los cambios' : 'Sí, estoy seguro'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
