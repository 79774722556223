import React, { useCallback, useEffect, useState } from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import InputComponent from "./../../../../components/InputComponent";
import { useUser } from "../../../../context/UserContext";
import { useForm } from "react-hook-form";
import { colors } from "./../../../../styles/colors";
import { toast } from "react-hot-toast";
import { saveUserData } from "./../../services/servicesBaseInfo";
import VehicleView from "./Vehicle/VehicleView";
import ModalVehicle from "./Vehicle/ModalVehicle";
import shortSnapshotFirebase from "../../../../utils/ShortSnapshotFirebase";

export default function BasicInfoComponent({
  disabled,
  deal_id,
  basicInfo,
  userData,
  toggleEditing,
  companyData,
}) {
  const { user } = useUser();
  const { register, handleSubmit, setValue } = useForm();
  const [modal, setModal] = useState(false);
  const [dataSource, setDataSource] = useState("");

  useEffect(() => {
    const callbackDeals = (data) => {
      setDataSource(data?.source);
    };
    const unsubscribeDeals = shortSnapshotFirebase(
      user?.company,
      "deals",
      deal_id,
      callbackDeals
    );
    return () => {
      unsubscribeDeals();
    };
  }, [deal_id, user?.company]);

  const updateDealBasic = useCallback(
    (data) => {
      const values = {
        email: data.email,
        name: data.nombre,
        phone: data.telefono,
        patente: data.patente,
        kms: data.kilometraje,
        source: data.source,
        medium: data.medium,
        paracaidas: data.paracaidas,
      };
      try {
        saveUserData(values, user?.company, deal_id, user);
        toast.success("Informacion Guardada con exito.", {
          style: {
            color: colors.text.primary,
            background: colors.neutral.green,
          },
        });
        toggleEditing();
      } catch (error) {
        console.log(error);
        toast.error("Error al guardar los datos.", {
          style: {
            color: colors.text.primary,
            background: colors.error.dark,
          },
        });
      }
    },
    [deal_id, toggleEditing, user]
  );
  const landingMap = {
    landing_san_jorge: "Landing San Jorge",
    san_jorge: "Landing San Jorge",
    landing_auta: "Landing Auta",
    web_auta: "Web Auta",
    auta: "Landing Auta",
  };
  const formattedValue = landingMap[dataSource] || dataSource;
  useEffect(() => {
    setValue("patente", basicInfo?.patente);
    setValue("email", userData?.email);
    setValue("telefono", userData?.phone);
    setValue("nombre", userData?.name);
    setValue("source", formattedValue);
    setValue("medium", basicInfo?.source?.medium);
    setValue("paracaidas", basicInfo?.paracaidas);
  }, [basicInfo, userData, formattedValue, updateDealBasic, setValue]);

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "12px",
  };

  return (
    <>
      <VehicleView
        dataVehiculo={basicInfo?.car}
        register={register}
        textStyle={textStyle}
      />
      <ModalVehicle
        modal={modal}
        setModal={setModal}
        dataVehiculo={basicInfo?.car}
        user={user}
        deal_id={deal_id}
      />
      <form onSubmit={handleSubmit(updateDealBasic)}>
        <div>
          <Row>
            {basicInfo?.car?.stopper === true ? (
              <Col lg={6}>
                <InputComponent
                  disabled={true}
                  label={"Motivo del stopper"}
                  placeholder={basicInfo?.car?.closed_desc}
                  register={register}
                />
              </Col>
            ) : null}
            <Col lg={6}>
              <InputComponent
                disabled={true}
                label={"Kilometraje"}
                placeholder={basicInfo?.car?.kms}
                register={register}
              />
            </Col>
            <Col
              lg={6}
              style={{
                marginTop: basicInfo?.car?.stopper === true ? "-15px" : "0px",
                marginBottom:
                  basicInfo?.car?.stopper === true ? "20px" : "10px",
              }}
            >
              {companyData?.settings?.urls?.update_car_url ? (
                <>
                  {dataSource === "landing_san_jorge" ||
                  dataSource === "san_jorge" ? (
                    <a
                      href={`https://deal.chevroletsanjorge.com.ar/${deal_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="btnEditCar" disabled={disabled}>
                        Editar auto
                      </Button>
                    </a>
                  ) : (
                    <a
                      href={`https://deal.auta.com.ar/${deal_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="btnEditCar" disabled={disabled}>
                        Editar auto
                      </Button>
                    </a>
                  )}
                </>
              ) : (
                <Button
                  className="btnEditCar"
                  disabled={disabled}
                  onClick={() => setModal(true)}
                >
                  Editar auto
                </Button>
              )}
            </Col>
          </Row>
        </div>
        <div className="divider mt-1 mb-1" />
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">Paracaidas</label>
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("paracaidas")}
            >
              <option value={false}>No</option>
              <option value={true}>Si</option>
            </Form.Select>
          </div>
        </Col>
        <div className="divider mt-1 mb-1" />
        <div>
          <Row>
            <Col lg={6}>
              <InputComponent
                disabled={disabled}
                label={"Patente"}
                placeholder={"Ingresar patente"}
                register={register}
                valueRegister={"patente"}
              />
            </Col>
          </Row>
        </div>
        <div className="divider mt-1 mb-1" />
        <div>
          <p style={textStyle} className="mb-2">
            Usuario
          </p>
          <Row>
            <Col lg={6}>
              <InputComponent
                disabled={disabled}
                label={"Nombre del cliente"}
                placeholder={"Nombre"}
                register={register}
                valueRegister={"nombre"}
              />
            </Col>
            <Col lg={6}>
              <InputComponent
                disabled={disabled}
                label={"Email del cliente"}
                placeholder={"email@email.com"}
                register={register}
                valueRegister={"email"}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <InputComponent
                disabled={disabled}
                label={"Teléfono"}
                placeholder={"000 0 000 000"}
                register={register}
                valueRegister={"telefono"}
              />
            </Col>
          </Row>
        </div>
        <div className="divider" />
        <div>
          <p style={textStyle} className="mb-2">
            Fuente
          </p>
          <Row>
            <Col lg={6}>
              <InputComponent
                disabled={true}
                label={"Fuente"}
                placeholder={"De dónde proviene el lead"}
                register={register}
                valueRegister={"source"}
              />
            </Col>
            <Col lg={6}>
              <InputComponent
                disabled={disabled}
                label={"Medio"}
                placeholder={""}
                register={register}
                valueRegister={"medium"}
              />
            </Col>
          </Row>
        </div>
        <Container style={{ height: "40px" }}>
          <Button hidden={disabled} style={{ float: "right" }} type="submit">
            Guardar cambios
          </Button>
        </Container>
      </form>
    </>
  );
}
