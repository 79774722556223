import { db } from "../../firebase/config";
import { collection, query, where, and, onSnapshot, getDocs, getDoc, doc, orderBy } from "firebase/firestore";


const isSameDate = function (date, last_appointment_date) {
  const compareDate1 = new Date(last_appointment_date); // Get the current date
  compareDate1.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

  const compareDate2 = new Date(date); // Convert the input date to a Date object
  compareDate2.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

  return compareDate1.getTime() === compareDate2.getTime();
}


const formatDate = function(date) {
  return date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0"+date.getDate()).slice(-2)
}


export class TakeView {
  constructor(filters, companyData, user) {
    this.user = user || null;
    this.companyData = companyData || null;
    this.filters = filters || null;
    this.loadMore = false;
    this.countingDealsPending = false;
  }


  getDatesForQuery() {
    let fromDate = this.filters.selectedDates.from
    let toDate = this.filters.selectedDates.to
    if (fromDate === '') {
      fromDate = new Date()
      fromDate.setDate(fromDate.getDate());
      fromDate.setHours(0, 0, 0, 0);
    }
    if (toDate === '') {
      // toDate = (new Date()).setHours(23,59,59,0);
      toDate = new Date()
      toDate.setDate(toDate.getDate() + 1);
      toDate.setHours(0, 0, 0, 0);
    }
    return [fromDate, toDate]
  }


  buildQuery() {
    const filters = this.filters
    let [fromDate, toDate] = this.getDatesForQuery();

    // Create the base query with the date filters
    let baseQuery = query(
      collection(db, "companies", this.user?.company, "deals"),
      and(
        where("appointment_data.take.date", ">=", fromDate),
        where("appointment_data.take.date", "<", toDate)
      )
    );

    // Check if filters.selectedPhase has a value
    if (filters.selectedPhase) {
      // Add the status.phase filter to the base query
      baseQuery = query(baseQuery, where("status.phase", "==", filters.selectedPhase));
    }

    // Order filter
    const orderDirection = filters.orderBy === "asc" ? "asc" : "desc";
    const dateFilterField = "appointment_data.take.date";
    baseQuery = query(baseQuery, orderBy(dateFilterField, orderDirection));

    return baseQuery;
  }


  getLoadMore(snapshotDeals) {
    return
  }


  async processDeals(firebaseDeals, setDeals, setLoadingDeals) {
    let processedDeals = [];
    let processedDealsIds = [];
    let [fromDate, toDate] = this.getDatesForQuery()
    for (const deal of firebaseDeals) {
      if (deal.status === "created_from_landing") {
        continue;
      }
      if (!processedDealsIds.includes(deal.id)) {
        let processedDeal = {}
        let rescheduled = false
        let cancelled = false
        let date
        if (deal.appointment_data.take.date) {
          date = deal.appointment_data.take.date.toDate()
          processedDeal.date = formatDate(date)
          processedDeal.hour = ("0"+date.getHours()).slice(-2) + ':' + ("0"+date.getMinutes()).slice(-2);
        } else {
          processedDeal.date = "A Agendar"
          processedDeal.hour = "A Agendar"
        }

        processedDeal.deal_data = deal
        processedDeal.cs = deal.user_team?.CS || ''
        processedDeal.actions = deal.actions || []
        processedDeal.car = deal.car || ''
        processedDeal.name = deal.name || ''
        processedDeal.car_id = deal.car_id || ''
        processedDeal.trengo_ticket_id = deal.trengo_ticket_id || ''
        processedDeal.user_team = deal.user_team || {}
        processedDeal.current_user_team = deal.current_user_team || {}
        processedDeal.id = deal.id

        // Campos calculados
        // Status
        // Agendada, Cancelada, En curso, Reagendada, Realizada
        // Faltaria limpiar para dejar ultimas acciones para que no se mezclen reagendamientos?
        const open_actions = processedDeal.actions.data_actions.filter(action => action.status === 'open').map(action => action.action_id)
        const done_actions = processedDeal.actions.data_actions.filter(action => action.status === 'done').map(action => action.action_id)
        const cancelled_actions = processedDeal.actions.data_actions.filter(action => action.status === 'cancelled').map(action => action.action_id)
        if (done_actions.includes('take')) {
          processedDeal.status = "Realizada"
        } else if (cancelled_actions.includes('take')) {
          processedDeal.status = "Rechazado"
        } else if (done_actions.includes('pre-take')) {
            processedDeal.status = "Pretoma completa"
        } else if (done_actions.includes('confirm-take-as')) {
          processedDeal.status = "En curso"
        } else if (rescheduled) {
          processedDeal.status = "Reagendada ("+(date.getMonth() + 1) + "-" + date.getDate()+")"
        } else if (open_actions.includes('schedule-take')) {
          processedDeal.status = "Cancelada"
        } else if (done_actions.includes('confirm-take')) {
          processedDeal.status = "Confirmada"
        } else if (open_actions.includes('confirm-take-as')) {
          processedDeal.status = "Agendada"
        } else if (open_actions.includes('take')) {
          processedDeal.status = "Agendada"
        } else {
          processedDeal.status = "Error"
        }

        if (done_actions.includes('complete-reports')) {
            processedDeal.status_bo = "OK"
        } else if (done_actions.includes('reports')) {
            processedDeal.status_bo = "Solicitados"
        } else if (done_actions.includes('pre-reports')) {
            processedDeal.status_bo = "Preliminares"
        } else {
            processedDeal.status_bo = "Incompleto"
        }

        const collectionRef = collection(db, "companies", this.user?.company, "deals");
        const adminDocRef = doc(collectionRef, deal.id, "data", "administrative")
        processedDeal.docAdministrativePromise = await getDoc(adminDocRef);
        processedDeals.push(processedDeal);
        processedDealsIds.push(deal.id);
      }
    };
    try {
      const docSnapshots = await Promise.all(processedDeals.map((processedDeal) => processedDeal.docAdministrativePromise));

      for (let deal of processedDeals) {
        const administrative_data = deal.docAdministrativePromise.exists() ? deal.docAdministrativePromise.data() : null;
        if (administrative_data) {
            deal.comments_bo = administrative_data.observaciones_adicionales
        }
      };

      processedDeals.sort((dealA, dealB) => {
        const combinedDateTimeA = dealA.date + dealA.hour;
        const combinedDateTimeB = dealB.date + dealB.hour;
        return combinedDateTimeA.localeCompare(combinedDateTimeB);
      });

      // Update the state with the processedDeals array
      setDeals(processedDeals);
      setLoadingDeals(false);
    } catch (error) {
      console.error(error);
      setLoadingDeals(false);
      // Handle the error
    }
  }


  doSnapshot(firebaseQuery, setFirebaseDeals, setLoadMore) {
    return onSnapshot(firebaseQuery, async (querySnapshot) => {
        // Inspecciones
        let snapshotDeals = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());

        // Reagendamientos con inspeccion previa en rango
        let [fromDate, toDate] = this.getDatesForQuery()
        const q = query(
            collection(db, "companies", this.user?.company, "deals"),
            and(
                where('appointment_data.take.last_appointment_date', ">=", fromDate),
                where('appointment_data.take.last_appointment_date', "<", toDate)
            )
        );
        const nextSnapshotDealsQuery  = await getDocs(q);

        const nextSnapshotDeals = nextSnapshotDealsQuery.docs.map((docSnapshot) => docSnapshot.data());
        snapshotDeals = snapshotDeals.concat(nextSnapshotDeals);
        const filteredDeals = snapshotDeals.filter(
            (deal) => typeof deal?.actions?.requested_datetime !== 'string'
        );
        setFirebaseDeals(filteredDeals);
    });
  }
}