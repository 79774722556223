import React from 'react';
import { Container, Col, Button } from 'react-bootstrap';
import { MdWarning } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../styles/colors';

export default function UnauthorizedPage() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Container className="empty-container" style={{ height: "100vh" }}>
      <Col>
        <div style={{
          backgroundColor: colors.neutral.light,
          borderRadius: "8px",
          padding: "16px",
          marginBottom: "20px",
        }}>
          <MdWarning className="empty-icon" color="red" />
          <p className="empty-text">No tienes permiso para acceder a esta página.</p>
          <p className="empty-text">Por favor, contacta al administrador si crees que esto es un error.</p>
          <Button variant="primary" onClick={handleGoBack}>
            Regresar
          </Button>
        </div>
      </Col>
    </Container>
  );
}