import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import MainSidebarComponent from './MainSidebarComponent'
import MainHeaderComponent from './MainHeaderComponent';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import ReportButton from '../../../images/Report_Button.svg'
import ModalReportComponent from '../../../components/ModalReportComponent';
export default function MainLayoutComponent() {
  let isPageWide = useMediaQuery("(min-width: 450px)");
  const [expanded, setExpanded] = useState(false);
  const [modal, setModal] = useState(false);

  const toggleSidebar = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className='main-layout'>
        <MainHeaderComponent toggleSidebar={toggleSidebar} />
        <div className='main-content-wrapper '>
          <MainSidebarComponent expanded={expanded} setExpanded={setExpanded} />
          <div className={`${isPageWide ? 'main-content' : 'main-content-mobile'} ${expanded ? 'expanded' : ''}`}>
            <Outlet />
          </div>
          <div className='floating-button-container' onClick={() => setModal(true)}>
            <img className="report-img " src={ReportButton} alt="report" />
          </div>
        </div>
      </div>
      <ModalReportComponent modal={modal} setModal={setModal} />
    </>
  )
}
