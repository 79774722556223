import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputComponent from "../../../../components/InputComponent";
import { useUser } from "../../../../context/UserContext";
import { updateTitular } from "../../services/TakeServices/HolderServices";

export default function ModalEditFinalConsumerComponent({
  modal,
  setModal,
  deal_id,
  titularEdit,
  titularId,
  arrayConsumidorFinal,
}) {
  const { register, handleSubmit, reset, setValue } = useForm();
  const { user } = useUser();

  React.useEffect(() => {
    setValue("nombre_completo", titularEdit?.nombre_completo);
    setValue("cuil_dni", titularEdit?.cuil_dni);
    setValue("estado_civil", titularEdit?.estado_civil);
    setValue("conyuge", titularEdit?.conyuge);
    setValue("domicilio_fiscal", titularEdit?.domicilio_fiscal);
    setValue("domicilio_real", titularEdit?.domicilio_real);
    setValue("titularidad", titularEdit?.titularidad);
    setValue("mail", titularEdit?.mail);
  }, [setValue, titularEdit]);

  const addTitular = (data) => {
    const newObj = Object.assign({}, titularEdit, data);
    const indexTitular = arrayConsumidorFinal.findIndex(
      (item) => item.cuil_dni === titularEdit.cuil_dni
    );
    arrayConsumidorFinal[indexTitular] = newObj;
    updateTitular(deal_id, arrayConsumidorFinal, user);
    reset();
    setModal(false);
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      <Modal size="lg" show={modal} onHide={handleClose}>
        <form>
          <Modal.Header closeButton>
            <Modal.Title className="deal-title">Editar titular</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Nombre completo"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"nombre_completo"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"DNI/CUIL"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"cuil_dni"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="select-container">
                  <label className="label-input">Estado civil</label>
                  <Form.Select
                    className="deal-select"
                    {...register("estado_civil")}
                  >
                    <option>Casado</option>
                    <option>Soltero</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Domicilio fiscal del vehículo (cédula)"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"domicilio_fiscal"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Domicilio real del titular"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"domicilio_real"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Mail"}
                  placeholder=""
                  type="email"
                  register={register}
                  valueRegister={"mail"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Titularidad (%)"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"titularidad"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Nombre del conyugue (opcional)"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"conyuge"}
                />
              </Col>
              <Col lg={6}></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleSubmit(addTitular)}>
              Editar titular
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
