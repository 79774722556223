import React from "react";
import { ImWhatsapp } from "react-icons/im";
import TableActionsComponent from "./TableActionsComponent";
import UserAttendingTooltipComponent from "./UserAttendingTooltipComponent";

const DealTableRow = ({
  item,
  handleClick,
  verDeal,
  convertDateFormat,
  rowStyleDeal,
  rowStyleDealInProsess,
  user,
  allUsers,
  actions,
  action_status,
  showName,
}) => {
  const MAX_ACTIONS_TO_DISPLAY = 4;
  const isInProcess = item?.current_user_team && item?.current_user_team[user?.team] && item?.current_user_team[user?.team] !== user.uid;

  return (
    <tr
      key={item.id}
      onClick={(e) => handleClick(e, item)}
      style={isInProcess ? rowStyleDealInProsess : rowStyleDeal}
    >
      <td onClick={() => verDeal(item.id)}>
        <p className="text-content-table-deals" style={{ whiteSpace: "nowrap", overflow: "hidden", fontSize: "12px", width: "60px" }}>
          {item?.deal_data?.status?.phases_dates["INSPECCION AGENDADA"]?.seconds
            ? new Date(item.deal_data.status.phases_dates["INSPECCION AGENDADA"]?.seconds * 1000).toLocaleDateString()
            : "-"}
        </p>
      </td>
      <td onClick={() => verDeal(item.id)}>
        <p className="text-content-table-deals" style={{ whiteSpace: "nowrap", overflow: "hidden", fontSize: "12px" }}>
          {convertDateFormat(item.date) + " - " + item.hour}
        </p>
      </td>
      <td onClick={() => verDeal(item.id)}>
        <p className="text-content-table-deals" style={{ fontSize: "12px" }}>
          {allUsers.find((dataAllUser) => dataAllUser.id === item.user_team?.CS)?.name}
        </p>
      </td>
      <td onClick={() => verDeal(item.id)}>
        <p className="text-content-table-deals" style={{ whiteSpace: "nowrap", overflow: "hidden", fontSize: "12px" }}>
          {item.deal_data?.appointment_data?.check.punto_de_inspeccion
            ? item.deal_data.appointment_data.check.punto_de_inspeccion === "Av. Warnes 2707, CABA"
              ? "Warnes"
              : item.deal_data.appointment_data.check.punto_de_inspeccion === "Av. Hipólito Yrigoyen 12745 - Lavallol" ||
                item.deal_data.appointment_data.check.punto_de_inspeccion === "Antonio Sastre 40 - Llavallol"
                ? "Llavallol"
                : "Independencia"
            : "-"}
        </p>
      </td>
      <td onClick={() => verDeal(item.id)}>
        <p className="text-content-table-deals" style={{ whiteSpace: "nowrap", overflow: "hidden", fontSize: "12px" }}>
          Si/No
        </p>
      </td>
      <td onClick={() => verDeal(item.id)} style={{ borderRight: "2px solid #E8E1EF" }}>
        <p className="text-content-table-deals" style={{ fontSize: "12px" }}>
          {item?.status}
        </p>
      </td>
      <td onClick={() => verDeal(item.id)}>
        <div className="d-flex align-items-center justify-content-start">
          {item?.actions?.data_actions?.filter((action) => action.status === action_status.OPEN).slice(0, MAX_ACTIONS_TO_DISPLAY).map((action, i) => (
            <TableActionsComponent actions={actions} action={action} i={i} key={i} />
          ))}
          {item?.actions?.data_actions?.filter((action) => action.status === action_status.OPEN).length > MAX_ACTIONS_TO_DISPLAY && (
            <p className="text-length-action">
              +{item.actions.data_actions.filter((action) => action.status === action_status.OPEN).length - MAX_ACTIONS_TO_DISPLAY}
            </p>
          )}
        </div>
      </td>
      <td onClick={() => verDeal(item.id)}>
        <p className="text-content-table-deals" style={{ fontSize: "12px" }}>
          {item?.last_agent ? `${item?.last_agent?.name} (${item?.last_agent?.team})` : null}
        </p>
      </td>
      <td onClick={() => verDeal(item.id)}>
        <p className="text-content-table-deals" style={{ fontSize: "12px" }}>
          {item.name}
        </p>
      </td>
      <td onClick={() => verDeal(item.id)}>
        <p className="text-content-table-deals" style={{ fontSize: "12px" }}>
          {item?.car}
        </p>
      </td>
      <td onClick={() => verDeal(item.id)} style={{ borderRight: "2px solid #E8E1EF" }}>
        <p className="text-content-table-deals" style={{ fontSize: "12px" }}>
          {item?.car_id}
        </p>
      </td>
      <td onClick={() => verDeal(item.id)}>
        <p className="text-content-table-deals" style={{ fontSize: "12px" }}>
          {item?.deal_data?.last_offer?.value}
        </p>
      </td>
      <td onClick={() => verDeal(item.id)}>
        <p className="text-content-table-deals" style={{ fontSize: "12px" }}>
          Oferta esperada
        </p>
      </td>
      <td onClick={() => item.trengo_ticket_id && window.open(`https://app.trengo.com/tickets/${item.trengo_ticket_id}`, "_blank")}>
        <span style={{ display: "flex", alignItems: "flex-end" }}>
          {item.trengo_ticket_id && <p className="text-content-table-deals"><ImWhatsapp fontSize="20" color="#00BB2D" /></p>}
          {showName.length > 0 && <UserAttendingTooltipComponent showName={showName} />}
        </span>
      </td>
    </tr>
  );
};

export default DealTableRow;
