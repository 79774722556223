import React, { createContext, useContext, useEffect, useState } from "react";
import { doc, getDoc, collection } from "firebase/firestore";
import { db } from "../firebase/config";
import { useUser } from "./UserContext";
import { teams } from "../constants/teams";

const companyContext = createContext();
export const useCompany = () => {
  const context = useContext(companyContext);
  if (!context) throw new Error("There is no company provider");
  return context;
};

export function CompanyProvider({ children }) {
  const { user, userCompany } = useUser();
  const [companyData, setCompanyData] = useState(
    JSON.parse(localStorage.getItem("companyData")) || null
  );
  const [actions, setActions] = useState([]);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [phases, setPhases] = useState([]);
  const [loading, setLoading] = useState(!companyData); // Cargar si no hay data en localStorage
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userCompany) {
      setLoading(false);
      return;
    }

    const companyDocRef = doc(db, "companies", user?.company);
    const globalsDocRef = collection(companyDocRef, "globals");

    Promise.all([
      getDoc(companyDocRef),
      getDoc(doc(globalsDocRef, "all_users")),
      getDoc(doc(globalsDocRef, "actions")),
      getDoc(doc(globalsDocRef, "phases")),
    ])
      .then(([companyDoc, allUsersDoc, actionsDoc, phasesDoc]) => {
        if (companyDoc.exists()) {
          const companyData = companyDoc.data();
          setCompanyData(companyData);
          localStorage.setItem("companyData", JSON.stringify(companyData));
        }

        if (allUsersDoc.exists()) {
          let allUsersResult = allUsersDoc.data()?.users || [];
          allUsersResult.sort((a, b) => a.name?.localeCompare(b.name));
          setAllUsers(allUsersResult);

          if (user?.team === teams.ADMIN) {
            setUsers(allUsersResult);
          } else {
            setUsers(
              allUsersResult?.filter(
                (users_user) => users_user.team === user?.team
              )
            );
          }
        } else {
          setAllUsers([]);
        }

        if (actionsDoc.exists()) {
          const actionsResult = actionsDoc.data()?.actions || [];
          setActions(actionsResult);
        } else {
          setActions([]);
        }

        if (phasesDoc.exists()) {
          let phasesResult = phasesDoc.data()?.phases || [];
          phasesResult.sort();
          setPhases(phasesResult);
        } else {
          setPhases([]);
        }

        setLoading(false); // Finalizar la carga
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [userCompany, user?.team]);

  return (
    <companyContext.Provider
      value={{ companyData, allUsers, actions, users, phases, loading, error }}
    >
      {children}
    </companyContext.Provider>
  );
}
