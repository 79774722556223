import { useEffect, useState } from "react";
import { requestSheet } from "../services/analiticsServices";
import toast from "react-hot-toast";
import { colors } from "../../../styles/colors";
import { Spinner } from "react-bootstrap";

const BtnsSheetsComponent = ({ deal_id, user, data }) => {
  const [dataSheet, setdataSheet] = useState();
  const [loadingUrl, setloadingUrl] = useState(null)

  useEffect(() => {
    if (data?.sheet_url !== undefined) {
      setdataSheet(data?.sheet_url);
      setloadingUrl(false);
    }
  }, [data?.sheet_url]);

  const sendSheet = () => {
    const data = {
      "action_requested.action_id": "supporting-sheet",
      "action_requested.comments": "",
      "action_requested.user": user,
      "action_requested.data_reminder": {},
    };
    requestSheet(user?.company, deal_id, data);
    setloadingUrl(true);
    toast.success("Sheet solicitado, espere un momento.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-start">
      <div className="btnInformes mb-2" onClick={sendSheet}>
        Sheet de soporte
      </div>
      {dataSheet !== undefined ? (
        <a
          href={`${dataSheet}`}
          target="_blank"
          style={{ textDecoration: "none", color: "white" }}
          rel="noreferrer"
        >
          <div className="btnInformes mb-2 ms-2">Abrir sheet</div>
        </a>
      ) : (
        <div
          className="btnInformes mb-2 ms-2"
          style={{ backgroundColor: "#DDDDDD", cursor: "default" }}
        >
          {loadingUrl === true ? (
            <>
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="me-1"
              />
              Abrir sheet
            </>
          ) : (
            "Abrir sheet"
          )}
        </div>
      )}
    </div>
  );
};

export default BtnsSheetsComponent;
