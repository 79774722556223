import { Col, Row } from "react-bootstrap";
import { InputComponent } from "../../../../components";
import { useEffect } from "react";
import { teams } from "../../../../constants/teams";
import { colors } from "../../../../styles/colors";

const ComnetInformationComponent = ({
  disabled,
  register,
  dataComnet,
  user,
  deal_id,
  setValue,
  textStyle,
}) => {
  useEffect(() => {
    setValue("interno", dataComnet?.interno ? dataComnet?.interno : "000000");
    setValue("cliente", dataComnet?.cliente ? dataComnet?.cliente : "000000");
  }, [dataComnet, setValue]);

  const disableStyleAs = {
    color: colors.neutral.darkTwo,
    backgroundColor: colors.gray[50],
  };

  const enableStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "500",
  };

  return (
    <div>
      <p style={textStyle}>Información Comnet</p>
      <Row>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Interno</label>
            <input
              disabled={
                !disabled &&
                (user?.team === teams.ADMIN || user?.team === teams.BACKOFFICE)
                  ? false
                  : true
              }
              className="input-deal"
              type="number"
              {...register("interno")}
              style={user?.team === teams.AS ? disableStyleAs : enableStyle}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Cliente</label>
            <input
              disabled={
                !disabled &&
                (user?.team === teams.ADMIN || user?.team === teams.BACKOFFICE)
                  ? false
                  : true
              }
              className="input-deal"
              type="number"
              {...register("cliente")}
              style={user?.team === teams.AS ? disableStyleAs : enableStyle}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ComnetInformationComponent;
