import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputComponent from "../../../../../components/InputComponent";
import ObservationTableComponent from "./ObservationTableComponent";
import ToolTipComment from "../../adminInfo/ToolTipComment";
import AttachmentPreTakeComponent from "./AttachmentPreTakeComponent";
import { getDiscountsData } from "../../../services/PreTakeServices/discountPreTakeService";

export default function DemeritInspectionTabComponent({
  disabled,
  register,
  deal_id,
  dataResults,
  setValue,
  setdataDiscounts,
  dataResultFiles,
  setDataResultFiles,
  formDataTableView,
  setFormDataTableView,
  formData,
  setFormData
}) {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setValue("kms", dataResults?.kms);
    setValue("car_type", dataResults?.car_type);
    setValue("chasis", dataResults?.chasis);
    setValue("check_car", dataResults?.check_car);
    setValue("check_vtv", dataResults?.check_vtv);
    setValue("color", dataResults?.color);
    setValue("gnc", dataResults?.gnc);
    setValue("comments", dataResults?.comments);
    setValue("color", dataResults?.color);
    setValue("fuel", dataResults?.fuel);
    setValue("transmission", dataResults?.transmission);
    setValue("engine", dataResults?.engine);
  }, [dataResults, setValue]);


  const saveFromDataString = (section, key, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [key]: value
      }
    }));
  };
  const saveFormDataObjet = (section, key, data) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [key]: {
          ...prevFormData[section][key],
          ...data
        }
      }
    }));
  };
  const handleChange = (key) => (event) => {
    const { value } = event.target;
    saveFromDataString('results', key, value);
  };
  const handleOverallStatusChange = (value) => {
    saveFormDataObjet('section7', 'overallStatus',
      { title: 'Estado general', values: [value] });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getDiscountsData({ deal_id, company: 'san_jorge' });
        if (!result.error) {
          if (Object.keys(result).length !== 0) {
            const { formData: fetchedFormData, formDataTableView: fetchedFormDataTableView } = result;
            setFormData(fetchedFormData);
            setFormDataTableView(fetchedFormDataTableView);
          }

        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [deal_id]);

  useEffect(() => {
    const requiresMechanicalRepair = formData.results.requiresMechanicalRepair === "Si";
    const requiresSheetMetalAndPaint = formData.results.requiresSheetMetalAndPaint === "Si";
    const readyForSale = !(requiresMechanicalRepair || requiresSheetMetalAndPaint) ? "Si" : "No";
    saveFromDataString('results', 'readyForSale', readyForSale);
  }, [formData.results.requiresMechanicalRepair, formData.results.requiresSheetMetalAndPaint]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p className="deal-subtitle">Resumen</p>
      </div>
      <Row>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Estado general</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              value={formData?.section7?.overallStatus?.values[0]}
              onChange={(e) => handleOverallStatusChange(e.target.value)}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option value="Excelente">Excelente</option>
              <option value="Muy Bueno">Muy Bueno</option>
              <option value="Bueno">Bueno</option>
              <option value="Regular">Regular</option>
              <option value="Malo">Malo</option>
              <option value="Rechazado">Rechazado</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">¿Listo para la venta?</label>
            <Form.Select
              className="deal-select"
              disabled={true}
              value={formData.results.readyForSale}
              onChange={handleChange('readyForSale')}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Deméritos totales</label>
            <input
              disabled={true}
              className="input-deal"
              value={`$${formData?.results?.totalDemerits?.toLocaleString('es-ES')}`}
              name="demeritosTotales"
            />
          </div>
        </Col>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Días de puesta a punto</label>
            <input
              disabled={disabled}
              className="input-deal"
              value={formData.results.daysOfSetUp}
              type="number"
              name="diasDePuestaAPunto"
              onChange={handleChange('daysOfSetUp')}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input"> ¿Requiere reparación mecánica?</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              name="requiereReparacionMecanica"
              value={formData.results.requiresMechanicalRepair}
              onChange={handleChange('requiresMechanicalRepair')}
            >
              <option value="">Seleccionar...</option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">Requiere chapa y pintura</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              value={formData.results.requiresSheetMetalAndPaint}
              onChange={handleChange('requiresSheetMetalAndPaint')}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <div className="divider" style={{ marginTop: "16px" }} />
      <Row>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Kilometraje Original</label>
            <input
              disabled={true}
              className="input-deal"
              value={formData?.section1?.mileage}
              type="number"
              name="diasDePuestaAPunto"
            />
          </div>
        </Col>
        <Col lg={6}>
          <InputComponent
            disabled={disabled}
            type="number"
            min={1}
            label={"Kilometraje actual"}
            placeholder={"Ingresa el kilometraje actual de vehiculo"}
            register={register}
            valueRegister={"kms"}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <div className="d-flex align-items-center justify-content-between">
              <label className="label-input">
                Coincide con version declarada
              </label>
              {dataResults?.check_car === "No" ? (
                <ToolTipComment
                  data={"Especificar diferencias en comentarios"}
                />
              ) : null}
            </div>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("check_car")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">Aprobaría VTV</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              name="check_vtv"
              {...register("check_vtv")}
            >
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <InputComponent
            disabled={disabled}
            type="text"
            label={"Chasis"}
            placeholder={""}
            register={register}
            valueRegister={"chasis"}
          />
        </Col>
        <Col lg={6}>
          <InputComponent
            disabled={disabled}
            type="text"
            label={"Tipo vehiculo"}
            placeholder={""}
            register={register}
            valueRegister={"car_type"}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <InputComponent
            disabled={disabled}
            type="text"
            label={"Motor"}
            placeholder={""}
            register={register}
            valueRegister={"engine"}
          />
        </Col>
        <Col lg={6}>
          <InputComponent
            disabled={disabled}
            type="text"
            label={"Color"}
            placeholder={""}
            register={register}
            valueRegister={"color"}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <InputComponent
            disabled={disabled}
            type="text"
            label={"Transmisión"}
            placeholder={""}
            register={register}
            valueRegister={"transmission"}
          />
        </Col>
        <Col lg={6}>
          <InputComponent
            disabled={disabled}
            type="text"
            label={"Combustible"}
            placeholder={""}
            register={register}
            valueRegister={"fuel"}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <InputComponent
            disabled={disabled}
            type="text"
            label={"Oblea GNC"}
            placeholder={""}
            register={register}
            valueRegister={"gnc"}
          />
        </Col>
      </Row>
      <div className="divider" style={{ marginTop: "16px" }} />
      <ObservationTableComponent
        isEditing={!disabled}
        deal_id={deal_id}
        setdataDiscounts={setdataDiscounts}
        formDataTableView={formDataTableView}
        setFormDataTableView={setFormDataTableView}
        formData={formData}
        setFormData={setFormData}
        saveFromDataString={saveFromDataString}
        isLoading={isLoading}
      />
      <div className="divider" style={{ marginTop: "16px" }} />
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">Comentarios</label>
            <textarea
              disabled={disabled}
              className="inputModal mt-1"
              style={{ width: "100%", height: "110px" }}
              placeholder="Dejar comentarios adicionales"
              {...register("comments")}
            />
          </div>
        </Col>
      </Row>

      <div className="divider" style={{ marginTop: "16px" }} />
      <AttachmentPreTakeComponent
        isEditing={disabled}
        dataResultFiles={dataResultFiles}
        setDataResultFiles={setDataResultFiles}
      />
    </div>
  );
}
