import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputComponent from "../../../../../components/InputComponent";

export default function AdditionalChecksComponent({
  disabled,
  register,
  dataCheckAdicionales,
  setValue,
}) {
  React.useEffect(() => {
    setValue("services_oficiales", dataCheckAdicionales?.services_oficiales);
    setValue(
      "comentarios_adicionales",
      dataCheckAdicionales?.comentarios_adicionales
    );
  }, [dataCheckAdicionales, setValue]);

  return (
    <div>
      <Row>
        <Col md={6}>
          <div className="select-container">
            <label className="label-input">
              Seleccioná todos los documentos FALTANTES (que no poseas) del
              auto.
            </label>
            {dataCheckAdicionales?.documentos_faltantes !== undefined ? (
              <>
                {Object.entries(dataCheckAdicionales.documentos_faltantes).map(
                  ([key, value]) => (
                    <div className="form-check" key={key}>
                      <input
                        className="form-check-input"
                        disabled={disabled}
                        type="checkbox"
                        defaultChecked={value}
                        {...register(`${key}`)}
                      />
                      <label className="label-input-check">
                        {key.replaceAll("_", " ")}
                      </label>
                    </div>
                  )
                )}
              </>
            ) : null}
          </div>
        </Col>
        <Col md={6}>
          <div className="select-container">
            <label className="label-input">
              Seleccioná todos los accesorios que tengas del auto.
            </label>
            {dataCheckAdicionales?.accesorios !== undefined ? (
              <>
                {Object.entries(dataCheckAdicionales.accesorios).map(
                  ([key, value]) => (
                    <div className="form-check" key={key}>
                      <input
                        className="form-check-input"
                        disabled={disabled}
                        type="checkbox"
                        defaultChecked={value}
                        {...register(`${key}`)}
                      />
                      <label className="label-input-check">
                        {key.replaceAll("_", " ")}
                      </label>
                    </div>
                  )
                )}
              </>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              ¿Cuenta con services oficiales al día de la fecha?
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("services_oficiales")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <InputComponent
            disabled={disabled}
            type="text"
            label={
              "Agregá comentarios adicionales sobre tu auto (en caso de considerarlo necesario)"
            }
            register={register}
            valueRegister={"comentarios_adicionales"}
          />
        </Col>
      </Row>
    </div>
  );
}
