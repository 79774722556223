import React, { createContext, useContext, useState, useEffect } from "react";
import { useCompany } from "./CompanyContext";
import { useUser } from "./UserContext";
import { DefaultView } from "./views/DefaultView.js";
import { CheckView } from "./views/CheckView.js";
import { TakeView } from "./views/TakeView.js";
import { IncomingLeadsView } from "./views/IncomingLeadsView";
import { PendingReportsView } from "./views/PendingReportsView";
import {
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { teams } from "../constants/teams";

const DealsContext = createContext();

export const useDeals = () => {
  const context = useContext(DealsContext);
  if (!context) throw new Error("There is no filter provider");
  return context;
};

export function DealsProvider({ children }) {
  const { companyData } = useCompany();
  const { user, loadingCompany } = useUser();
  const [viewName, setViewName] = useState(null);
  const [view, setView] = useState(null);
  const [firebaseQuery, setFirebaseQuery] = useState(null);
  const [firebaseDeals, setFirebaseDeals] = useState([]);
  const [deals, setDeals] = useState([]);
  const [loadingDeals, setLoadingDeals] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [pendingDealsCount, setPendingDealsCount] = useState(0);
  const [dealsCount, setDealsCount] = useState(0);
  const defaultFilter = {
    quickView: "",
    selectedUser: "",
    selectedAction: "",
    selectedPhase: "",
    dealDateStatus: "pending",
    orderBy: "desc",
    category: "",
    selectedDates: {
      from: "",
      to: "",
    },
    refresh: 0,
    limitFactor: 1,
  };
  const [filters, setFilters] = useState(defaultFilter);

  // Se activa cuando cambia o inicia una company. Al limpiar filtros activa el useEffect de la query.
  useEffect(() => {
    // Se activa cuando loading es false, osea que se termino de cargar.
    if (loadingCompany) {
      // Vaciar deals
      setLoadingDeals(true);
      setDeals([]);
    }
  }, [loadingCompany]);

  // Se activa cuando se cambia la vista
  useEffect(() => {
    if (loadingCompany) {
      return;
    }
    if (!user || !companyData) {
      return;
    }
    // Solo inicializa la vista cuando todo esté listo
    switch (viewName) {
      case "tasks":
        setView(new DefaultView(filters, companyData, user));
        break;
      case "checkView":
        setView(new CheckView(filters, companyData, user));
        break;
      case "takeView":
        setView(new TakeView(filters, companyData, user));
        break;
      case "incomingLeadsView":
        setView(new IncomingLeadsView(filters, companyData, user));
        break;
      case "pendingReportsView":
        setView(new PendingReportsView(filters, companyData, user));
        break;
      default:
        console.warn("No view initialized.");
        break;
    }
  }, [viewName, loadingCompany, user, companyData]);

  // Se reinician los filtros
  useEffect(() => {
    if (loadingCompany) {
      return;
    }
    // Load more
    setLoadMore(view.loadMore);
    setFilters(defaultFilter);
  }, [view]);

  // Se activa cuando cambian los filtros
  // Queries para contador
  // Inclusive refresh

  useEffect(() => {
    if (loadingCompany) {
      return;
    }
    if (view) {
      // Contador
      view.filters = filters;
      // Query
      let query = view.buildQuery();
      setFirebaseQuery(query);
    }
  }, [filters]);

  useEffect(() => {
    if (loadingCompany) {
      return;
    }
    const addMinutes = function (dt, min) {
      return new Date(dt.getTime() + min * 60000);
    };
    const dealsRef = collection(db, "companies", user?.company, "deals");

    const dateFilterField =
      user?.team === teams.ADMIN
        ? "actions.requested_datetime"
        : `actions.requested_datetime_by_team.${user?.team}`;

    const dateFilterFieldMin =
      user?.team === teams.ADMIN
        ? "actions.requested_datetime"
        : `actions.requested_datetime_by_team_min.${user.team}`;

    const queryWhereUser = where(`user_team.${user?.team}`, "==", user?.uid);

    const unsubscribeNextDeals = onSnapshot(
      query(dealsRef, where(dateFilterField, ">=", new Date()), queryWhereUser),
      (snapshot) => {
        setDealsCount(snapshot.size);
      }
    );

    const unsubscribePendingDeals = onSnapshot(
      query(
        dealsRef,
        where(dateFilterFieldMin, "<", addMinutes(new Date(), 5)),
        queryWhereUser
      ),
      (snapshot) => {
        setPendingDealsCount(snapshot.size);
      }
    );
    return () => {
      unsubscribeNextDeals();
      unsubscribePendingDeals();
    };
  }, [loadingCompany, user?.company, user?.team, user?.uid]);

  // Se activa cuando cambia la query o en snapshot.
  // Setea el state firebaseQuery, que son los deals limpios que vienen de firebase.
  useEffect(() => {
    if (loadingCompany) {
      return;
    }
    const unsubscribe = view.doSnapshot(
      firebaseQuery,
      setFirebaseDeals,
      setLoadMore
    );
    return () => {
      unsubscribe();
    };
  }, [firebaseQuery]);

  // Setea los deals para renderizar segun vista.
  useEffect(() => {
    if (loadingCompany) {
      return;
    }
    const dealsAfterFilter = view.processDeals(
      firebaseDeals,
      setDeals,
      setLoadingDeals
    );
  }, [firebaseDeals]);

  const increaseDeals = () => {
    setFilters({ ...filters, limitFactor: filters.limitFactor + 1 });
  };

  const clearFilter = () => {
    setFilters(defaultFilter);
  };

  return (
    <DealsContext.Provider
      value={{
        filters,
        setFilters,
        viewName,
        setViewName,
        clearFilter,
        pendingDealsCount,
        dealsCount,
        increaseDeals,
        deals,
        loadingDeals,
        setLoadingDeals,
        loadMore,
      }}
    >
      {children}
    </DealsContext.Provider>
  );
}
