import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { StatusIconComponent } from "../../../components";
import { useUser } from "../../../context/UserContext";
import { getStatusColor } from "../../../utils/StatusColor";
import ApplyModal from "./Modals/Advisor/ApplyModal";
import RevisionModal from "./Modals/Advisor/RevisionModal";
import LongSnapshotFirebase from "../../../utils/LongSnapshotFirebase";

export default function AdvisorPricingComponent({
  finalOffer,
  rangeInitial,
  initialOffer,
  virtualOffer,
  salePrice,
  latestKey,
  setActiveModalPricing,
}) {
  const { deal_id } = useParams();
  const { user } = useUser();
  const [titleModal, settitleModal] = useState();

  const [actionId, setactionId] = useState();
  const [actionIdRejected, setactionIdRejected] = useState();
  const [revisionActionId, setrevisionActionId] = useState();
  const [sendActionId, setsendActionId] = useState();
  const [valueCotizacion, setvalueCotizacion] = useState("");

  const [dataHistoryOfferI, setdataHistoryOfferI] = useState();
  const [dataHistoryFinalOffer, setdataHistoryFinalOffer] = useState();
  const [dataHistoryRange, setdataHistoryRange] = useState();
  const [dataHistoryVirtualOffer, setdataHistoryVirtualOffer] = useState();
  const [dataHistory, setdataHistory] = useState();

  const [showApplyModal, setshowApplyModal] = useState();
  const [showRevisionModal, setshowRevisionModal] = useState();
  const [statusRevision, setstatusRevision] = useState();
  const quotationList = [
    "final_offer",
    "offer",
    "range",
    "sale_price",
    "virtual_offer",
  ];
  useEffect(() => {
    const callbackPricing = (dataPricing) => {
      setdataHistoryOfferI(dataPricing?.offer.history);
      setdataHistoryFinalOffer(dataPricing?.final_offer.history);
      setdataHistoryRange(dataPricing?.range.history);
      setdataHistoryVirtualOffer(dataPricing?.virtual_offer.history);
    };
    const unsubscribePricing = LongSnapshotFirebase(
      user?.company,
      deal_id,
      "data",
      "pricing",
      callbackPricing
    );
    return () => {
      unsubscribePricing();
    };
  }, [deal_id, user?.company]);

  const handleShowRangeInitial = (value, status) => {
    setstatusRevision(status);
    setshowApplyModal(true);
    setshowRevisionModal(true);
    settitleModal("Rango inicial");
    setactionId("range");
    setsendActionId("range-accepted");
    setactionIdRejected("range-rejected");
    setrevisionActionId("check-range");
    setvalueCotizacion(value);
    setdataHistory(dataHistoryRange);
  };

  const handleShowOfferInitial = (value, status) => {
    setstatusRevision(status);
    setshowApplyModal(true);
    setshowRevisionModal(true);
    settitleModal("Oferta inicial");
    setactionId("offer-i");
    setsendActionId("offer-i-accepted");
    setactionIdRejected("offer-i-rejected");
    setrevisionActionId("check-offer-i");
    setvalueCotizacion(value);
    setdataHistory(dataHistoryOfferI);
  };

  const handleShowOfferVirtual = (value, status) => {
    setstatusRevision(status);
    setshowApplyModal(true);
    setshowRevisionModal(true);
    settitleModal("Oferta virtual");
    setactionId("offer-v");
    setsendActionId("offer-v-accepted");
    setactionIdRejected("offer-v-rejected");
    setrevisionActionId("check-offer-v");
    setvalueCotizacion(value);
    setdataHistory(dataHistoryVirtualOffer);
  };

  const handleShowOfferFinal = (value, status) => {
    setstatusRevision(status);
    setshowApplyModal(true);
    setshowRevisionModal(true);
    settitleModal("Oferta final");
    setactionId("offer-f");
    setsendActionId("offer-f-accepted");
    setactionIdRejected("offer-f-rejected");
    setrevisionActionId("check-offer-f");
    setvalueCotizacion(value);
    setdataHistory(dataHistoryFinalOffer);
  };

  return (
    <>
      {rangeInitial?.enable === true ? (
        <div
          className="containerPricing"
          onClick={() => {
            handleShowRangeInitial(rangeInitial?.value, rangeInitial?.status);
            setActiveModalPricing(true);
          }}
          style={{
            pointerEvents:
              quotationList[2] === latestKey && (!rangeInitial?.value || true)
                ? "auto"
                : "none",
          }}
        >
          <p className="textTitle">Rango inicial </p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(rangeInitial?.status),
              }}
            >
              {rangeInitial?.value === ""
                ? "$00.000.000"
                : rangeInitial?.value.startsWith("$")
                ? rangeInitial?.value
                : "$" + rangeInitial?.value}
            </p>

            {rangeInitial?.status === "" ? (
              <span className="status-icon"></span>
            ) : (
              <StatusIconComponent status={rangeInitial?.status} />
            )}
          </div>
        </div>
      ) : null}

      {initialOffer?.enable === true ? (
        <div
          className="containerPricing"
          onClick={() => {
            handleShowOfferInitial(initialOffer?.value, initialOffer?.status);
            setActiveModalPricing(true);
          }}
          style={{
            pointerEvents:
              quotationList[1] === latestKey && (!initialOffer?.value || true)
                ? "auto"
                : "none",
          }}
        >
          <p className="textTitle">Oferta inicial</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(initialOffer?.status),
              }}
            >
              {initialOffer?.value === ""
                ? "$00.000.000"
                : initialOffer?.value.startsWith("$")
                ? initialOffer?.value
                : "$" + initialOffer?.value}
            </p>
            {initialOffer?.status === "" ? (
              <span className="status-icon"></span>
            ) : (
              <StatusIconComponent status={initialOffer?.status} />
            )}
          </div>
        </div>
      ) : null}

      {virtualOffer?.enable === true ? (
        <div
          className="containerPricing"
          onClick={() => {
            handleShowOfferVirtual(virtualOffer?.value, virtualOffer?.status);
            setActiveModalPricing(true);
          }}
          style={{
            pointerEvents:
              quotationList[4] === latestKey && (!virtualOffer?.value || true)
                ? "auto"
                : "none",
          }}
        >
          <p className="textTitle">Oferta virtual</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(virtualOffer?.status),
              }}
            >
              {virtualOffer?.value === ""
                ? "$00.000.000"
                : "$" + virtualOffer?.value}
            </p>

            {virtualOffer?.status === "" ? (
              <span className="status-icon"></span>
            ) : (
              <StatusIconComponent status={virtualOffer?.status} />
            )}
          </div>
        </div>
      ) : null}
      {finalOffer?.enable === true ? (
        <div
          className="containerPricing"
          onClick={() => {
            handleShowOfferFinal(finalOffer?.value, finalOffer?.status);
            setActiveModalPricing(true);
          }}
          style={{
            pointerEvents:
              quotationList[0] === latestKey && (!finalOffer?.value || true)
                ? "auto"
                : "none",
          }}
        >
          <p className="textTitle">Oferta final</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(finalOffer?.status),
              }}
            >
              {finalOffer?.value === ""
                ? "$00.000.000"
                : "$" + finalOffer?.value}
            </p>
            {finalOffer?.status === "" ? (
              <span className="status-icon"></span>
            ) : (
              <StatusIconComponent status={finalOffer?.status} />
            )}
          </div>
        </div>
      ) : null}
      {!valueCotizacion ? (
        <ApplyModal
          deal_id={deal_id}
          showApplyModal={showApplyModal}
          setshowApplyModal={setshowApplyModal}
          titleModal={titleModal}
          user={user}
          actionId={actionId}
          setActiveModalPricing={setActiveModalPricing}
        />
      ) : (
        <RevisionModal
          deal_id={deal_id}
          showRevisionModal={showRevisionModal}
          setshowRevisionModal={setshowRevisionModal}
          titleModal={titleModal}
          user={user}
          revisionActionId={revisionActionId}
          valueCotizacion={valueCotizacion}
          sendActionId={sendActionId}
          dataHistory={dataHistory}
          status={statusRevision}
          actionIdRejected={actionIdRejected}
          setActiveModalPricing={setActiveModalPricing}
        />
      )}
    </>
  );
}
