/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { MdEdit, MdCancel } from "react-icons/md";
import BasicInfoComponent from "../components/basicInfo/BasicInfoComponent";
import "../../../styles/style/BasicsInfoStyle.css";
import { useUser } from "../../../context/UserContext";
import { colors } from "../../../styles/colors";
import Swal from "sweetalert2";
import { updateDealInProcess } from "../../Main/services/servicesTableDeals";
import useFirebaseOnSnapshot from "../../../hooks/useFirebaseOnSnapshot";
import {
  ServerErrorComponent,
  SpinnerFullScreenComponent,
} from "../../../components";
import { teams } from "../../../constants/teams";
import { useCompany } from "../../../context/CompanyContext";

export default function BasicInfoPage() {
  const { deal_id } = useParams();
  const { user } = useUser();
  const { companyData, allUsers } = useCompany();
  const [isEditing, setisEditing] = useState(true);
  const [deal, setDeal] = useState('')
  const takeRef = useRef(true);
  const { data, loading, error } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "basics"
  );
  const context = useOutletContext()
  const navigate = useNavigate();

  useEffect(() => {
    onTakeDeal(deal);
    setDeal(context.deal)

  }, [data]);

  const onTakeDeal = (deal) => {
    if (takeRef.current) {
      if (!deal.current_user_team || user?.team === teams.ADMIN) {
        updateDealInProcess(user?.company, user?.team, user?.uid, deal_id);
      } else if (deal.current_user_team) {
        if (
          deal.current_user_team[user?.team] === null ||
          Object.keys(deal.current_user_team).length === 0 ||
          !deal.current_user_team[user?.team]
        ) {
          updateDealInProcess(user?.company, user?.team, user?.uid, deal_id);
        } else if (deal.current_user_team[user?.team] !== user?.uid) {
          const userIdInProcess = deal.current_user_team[user?.team];
          let userNameInProcess = allUsers.find((user) =>
            userIdInProcess.includes(user.id)
          );
          Swal.fire({
            title: "No puede trabajar en este deal",
            text: `Se encuentra en proceso con ${userNameInProcess?.name}  `,
            icon: "warning",
            confirmButtonText: "Aceptar",
            showCancelButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
          }).then(() => {
            navigate("/");
          });
        }
      }
      takeRef.current = false;
    }
  };

  const toggleEditing = React.useCallback(() => {
    setisEditing((isEditing) => !isEditing);
  }, []);

  return (
    <>
      {loading ? (
        <SpinnerFullScreenComponent />
      ) : (
        <>
          {error ? (
            <ServerErrorComponent error={error} />
          ) : (
            <div className="containerAll">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="deal-title">
                  {isEditing ? "Resultados" : "Formulario"}
                </p>
                <div style={{ width: "30px", height: "30px" }}>
                  {isEditing ? (
                    <MdEdit
                      onClick={toggleEditing}
                      style={{
                        fontSize: "26px",
                        color: colors.primary.primary,
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <MdCancel
                      onClick={toggleEditing}
                      style={{
                        fontSize: "26px",
                        color: colors.status.red,
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
              <BasicInfoComponent
                toggleEditing={toggleEditing}
                deal_id={deal_id}
                disabled={isEditing}
                basicInfo={data}
                userData={context?.userData}
                companyData={companyData}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
