import React, { useEffect, useRef, useState } from "react";
import MessageComponent from "./MessageComponent";
import { MdAttachFile, MdSend } from "react-icons/md";
import { useUser } from "../../../context/UserContext";
import {
  changePropertyName,
  removeParentheses,
  renderSuggestion,
  sendMessage,
} from "../services/servicesComment";
import { uploadFile } from "../../../firebase/config";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "../../../styles/style/ChatStyle.css";
import { colors } from "../../../styles/colors";
import useFirebaseOnSnapshot from "../../../hooks/useFirebaseOnSnapshot";
import {
  ServerErrorComponent,
  SmallSpinnerComponent,
} from "../../../components";
import { useCompany } from "../../../context/CompanyContext";
import { Mention, MentionsInput } from "react-mentions";
import styleMentions from "./styleMentions";
import styleDefault from "./styleDefault";

import { teams_comments } from "../../../constants/teams";
import { generateId } from "../../../utils/IdGenerator";

const ChatCommentsComponent = () => {
  const { user } = useUser();
  const { deal_id } = useParams();
  const { allUsers } = useCompany();
  const [dataChat, setdataChat] = useState();
  const [mentions, setMentions] = useState([]);
  const [text, setText] = useState("");

  const { data, loading, error } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "comments"
  );

  const chatBoxRef = useRef(null); // Referencia al contenedor de mensajes

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTo({
        top: chatBoxRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    setdataChat(data?.chat);
  }, [data, deal_id, user.company]);

  useEffect(() => {
    scrollToBottom();
  }, [dataChat]);

  const modifiedUsers = changePropertyName(allUsers);

  const concatenatedArray = modifiedUsers.concat(teams_comments);

  const sendComment = () => {
    const modifiedText = removeParentheses(text);

    let date = new Date();
    console.log(
      parseInt(date.getMinutes()) < 10 && parseInt(date.getMinutes()) >= 0
        ? `${date.getHours()}:0${date.getMinutes()}`
        : `${date.getHours()}:${date.getMinutes()}`
    );

    sendMessage(
      user?.company,
      deal_id,
      user.uid,
      modifiedText,
      user.name,
      user.team,
      date.toLocaleDateString(),
      parseInt(date.getMinutes()) < 10 && parseInt(date.getMinutes()) > 0
        ? `${date.getHours()}:0${date.getMinutes()}`
        : `${date.getHours()}:${date.getMinutes()}`,
      "",
      mentions,
      allUsers,
      concatenatedArray,
      true
    );
    setText("");
    setMentions([]);
    scrollToBottom();
  };

  const sendFile = async (e) => {
    const selectedFiles = [...e.target.files];
    let selectedFilesList = [];

    // Crea un array de promesas para la carga de archivos
    const uploadPromises = Array.from(selectedFiles).map(async (file) => {
      const result = await uploadFile(file);

      selectedFilesList.push({
        name: file.name,
        size: file.size,
        urlFirestore: result,
        url: URL.createObjectURL(file),
        id: generateId(),
        type: file.type,
      });
    });

    // Espera a que todas las promesas se resuelvan
    Promise.all(uploadPromises)
      .then(() => {
        // Una vez que todas las promesas se han resuelto, el array de archivos está listo
        console.log("Subida de archivos ok");
        let date = new Date();
        sendMessage(
          user?.company,
          deal_id,
          user.uid,
          "",
          user.name,
          user.team,
          date.toLocaleDateString(),
          parseInt(date.getMinutes()) < 10 && parseInt(date.getMinutes()) > 0
            ? `${date.getHours()}:0${date.getMinutes()}`
            : `${date.getHours()}:${date.getMinutes()}`,
          selectedFilesList,
          [],
          allUsers,
          concatenatedArray,
          true
        );
        scrollToBottom();
      })
      .catch((error) => {
        // Maneja cualquier error que ocurra durante la carga de archivos
        console.error("Error al cargar archivos:", error);
      });
  };

  const handleInputChange = (event, newValue, newPlainTextValue, mentions) => {
    const processedMentions = mentions.map((mention) => ({
      name: mention.display,
    }));
    setMentions(processedMentions);
    setText(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      sendComment();
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e) => {
    let date = new Date();
    e.preventDefault();

    const droppedFiles = [...e.dataTransfer.files];
    Array.from(droppedFiles).forEach(async (file) => {
      const result = await uploadFile(file);
      sendMessage(
        user?.company,
        deal_id,
        user.uid,
        "",
        user.name,
        user.team,
        date.toLocaleDateString(),
        parseInt(date.getMinutes()) < 10 && parseInt(date.getMinutes()) > 0
          ? `${date.getHours()}:0${date.getMinutes()}`
          : `${date.getHours()}:${date.getMinutes()}`,
        {
          name: file.name,
          type: file.type,
          urlFirestore: result,
          url: URL.createObjectURL(file),
        },
        [],
        allUsers,
        concatenatedArray
      );
    });
  };

  return (
    <>
      {loading ? (
        <SmallSpinnerComponent />
      ) : (
        <>
          {error ? (
            <ServerErrorComponent error={error} />
          ) : (
            <div className="d-flex flex-column justify-content-end chat-container">
              <div
                ref={chatBoxRef}
                className="pe-4 ps-4 pt-4 scrollChat chat-box"
              >
                {dataChat?.map((info) => (
                  <div
                    className={`d-flex justify-content-${
                      info.uid === user.uid ? "end" : "start"
                    }`}
                    key={info.id}
                  >
                    <MessageComponent
                      id={info.uid}
                      message={info.message}
                      name={info.name}
                      team={info.team}
                      fecha={info.fecha}
                      hora={info.hora}
                      file={info.file}
                      manual={info.manual}
                    />
                  </div>
                ))}
              </div>
              <form>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ borderTop: "1px solid #E1E7EF" }}
                  py="16px"
                  px="20px"
                >
                  <div
                    className="d-flex align-items-center justify-content-center"
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    style={{ width: "100%" }}
                  >
                    <label htmlFor="upload-file">
                      <div className="ps-2">
                        <MdAttachFile
                          fontSize={24}
                          color={colors.neutral.lightTwo}
                          type="file"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </label>
                    <input
                      type="file"
                      id="upload-file"
                      multiple={true}
                      onChange={sendFile}
                      style={{ display: "none" }}
                    />
                    <MentionsInput
                      style={styleDefault}
                      value={text}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      className="mt-3 mb-3 chat-input-send scrollChat"
                      placeholder="Escribir un mensaje..."
                    >
                      <Mention
                        style={styleMentions}
                        data={concatenatedArray}
                        displayTransform={(id, display) => `${display}`}
                        renderSuggestion={renderSuggestion}
                        markup="@(__display__)"
                        appendSpaceOnAdd={true}
                      />
                    </MentionsInput>
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                      onClick={sendComment}
                    >
                      <MdSend color={colors.primary.primary} fontSize={24} />
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ChatCommentsComponent;
