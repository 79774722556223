import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import "../../../../../styles/style/tabsStyle.css";
import { useUser } from "../../../../../context/UserContext";
import AccesoriesDiscountsModalInput from "./AccessoriesDiscountsModalInput";
import LongSnapshotFirebase from "../../../../../utils/LongSnapshotFirebase";

export default function AccesoriesDiscountsTableComponent({
  isEditing,
  deal_id,
  setdataAccesoriesDiscounts,
}) {
  const [modal, setModal] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [dataObservaciones, setdataObservaciones] = useState();
  const { user } = useUser();

  useEffect(() => {
    const callbackObservation = (dataObservation) => {
      setdataObservaciones(dataObservation?.accesories?.additional_discounts);
    };
    const unsubscribeObservation = LongSnapshotFirebase(
      user?.company,
      deal_id,
      "data",
      "pre_take",
      callbackObservation
    );
    return () => {
      unsubscribeObservation();
    };
  }, [deal_id, user?.company]);

  const descuentos = () => {
    if (dataObservaciones?.length > 0) {
      const totalDescuentos = dataObservaciones?.reduce((total, objeto) => {
        return (
          total +
          parseFloat(
            objeto.descuento.toString().replace(/\./g, "").replace(/\$/g, "")
          )
        );
      }, 0);

      return (
        <>{totalDescuentos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</>
      );
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <p className="title-discount">Descuentos Accesorios: </p>
            <p className="total-discount">Descuento total ${descuentos()}</p>
          </div>
          <Button
            hidden={isEditing}
            onClick={() => {
              setModal(true);
            }}
            style={{ marginLeft: "20px" }}
          >
            Cargar descuentos
          </Button>
        </div>
        <Table bordered className="discount-table">
          <thead>
            <tr>
              <th className="table-header">Accesorio</th>
              <th className="table-header">Descuento</th>
            </tr>
          </thead>
          <tbody>
            {dataObservaciones?.map((item, i) => {
              return (
                <tr key={i}>
                  <td className="table-body">{item.accesorio}</td>
                  <td className="table-body">
                    $
                    {item.descuento
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <AccesoriesDiscountsModalInput
        modal={modal}
        setModal={setModal}
        data={data}
        setData={setData}
        deal_id={deal_id}
        setdataObservaciones={setdataObservaciones}
        setdataAccesoriesDiscounts={setdataAccesoriesDiscounts}
      />
    </>
  );
}
