import React, { useEffect } from "react";
import { TasksTableComponent, FiltersComponent } from "../components";
import { useDeals } from "../../../context/DealsContext";

export default function TasksViewPage() {
  const { setViewName } = useDeals();
  useEffect(() => {
    setViewName("tasks");
  }, []);

  return (
    <div className="p-3 h-100">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="view-title-page">Tareas</h1>
        <FiltersComponent />
      </div>
      <TasksTableComponent />
    </div>
  );
}
