const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '8px',
    fontWeight: '400',
    fontSize: '14px',
    color: '#1b2130',
    border: state.isFocused ? '1px solid grey' : '1px solid var(--border-cards)',
    height: '38px'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#1b2130' : 'white',
    color: state.isSelected ? 'white' : '#1b2130',
    '&:hover': {
      backgroundColor: '#f0f0f0', // Cambiar color de fondo en hover
      color: '#1b2130' // Cambiar color del texto en hover
    }
  })
};


export default customSelectStyles;