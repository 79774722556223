import React, { useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { MdFilterList } from 'react-icons/md'

export default function FilterUsersComponent({
  activeView, selectedTeam, setSelectedTeam, teams }) {
  const [openFilters, setOpenFilters] = useState(false);

  const handleRoleToggle = (team) => {
    setSelectedTeam(prevSelectedTeam => (prevSelectedTeam === team.value ? null : team.value));
  };

  return (
    <Dropdown show={openFilters} onToggle={(isOpen) => setOpenFilters(isOpen)}>
      <Dropdown.Toggle className="filter-button" id="dropdown-basic">
        <MdFilterList className="filter-icon-button" fontSize={18} />
        Filtro
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {activeView === 'teams' &&
          teams.map((team) => (
            <Dropdown.Item key={team.value} onClick={() => handleRoleToggle(team)}>
              <Form.Check
                type="checkbox"
                id={`team-checkbox-${team.value}`}
                label={team.label}
                checked={selectedTeam === team.value}
                onChange={() => { }}
              />
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}