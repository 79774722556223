import React from 'react'
import { Container, Col, Button } from 'react-bootstrap';
import { MdWarning } from 'react-icons/md';
import { colors } from '../styles/colors';
import { useAuth } from '../context/AuthContext';

export default function ErrorScreenComponent({ errorMessage }) {

  const { logout } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.log(error.message);
    }
  };
  console.error(errorMessage)
  return (
    <Container className="empty-container" style={{ height: "100vh" }}>
      <Col>
        <div style={{
          backgroundColor: colors.neutral.light,
          borderRadius: "8px",
          padding: "16px",
          marginBottom: "20px",
        }}>
          <MdWarning className="empty-icon" color="red" />
          <p className="empty-text">Su usuario no posee rol o compañía.</p>
          <p className="empty-text">Comuníquese con el administrador de la aplicación.</p>
          <Button variant="primary" onClick={handleLogout} >
            Volver al login
          </Button>
        </div>
      </Col>
    </Container>
  )
}
