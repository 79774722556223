import React, { createContext, useContext, useEffect, useState } from "react";
import {
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../firebase/config";
import { createUser, getUser } from "../services/AuthServices";

const authContext = createContext();
export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

export function AuthProvider({ children }) {
  const [userLoggedIn, setUserLoggedIn] = useState(
    JSON.parse(localStorage.getItem("userLoggedIn")) || null
  );
  const [isAuthenticated, setIsAuthenticated] = useState(!!userLoggedIn);
  const [loginCompany, setLoginCompany] = useState(
    localStorage.getItem("loginCompany") || null
  );
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null);

  const loginWithGoogle = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);
      const token = await result.user.getIdToken(); // Obtén el token de autenticación
      localStorage.setItem("authToken", token);
      return { user: result.user, token };
    } catch (err) {
      setError(err);
      console.error("Error en login con Google:", err);
    }
  };

  const logout = () => {
    signOut(auth);
    localStorage.removeItem("authToken");
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("loginCompany");
    setUserLoggedIn(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);

      if (currentUser && loginCompany) {
        try {
          let token = storedToken;
          if (!token || currentUser.uid !== userLoggedIn?.uid) {
            token = await currentUser.getIdToken(true);
            localStorage.setItem("authToken", token);
          }

          if (
            userLoggedIn?.uid === currentUser.uid &&
            userLoggedIn?.company === loginCompany
          ) {
            setLoading(false);
            return;
          }

          const getUserData = await getUser({
            params: { user_id: currentUser?.uid, company: loginCompany },
          });

          let userData = {};
          if (!getUserData.error) {
            userData = {
              email: currentUser.email,
              name: getUserData.name,
              uid: currentUser.uid,
              company: loginCompany,
              companies: getUserData.companies,
              team: getUserData.team,
              token,
            };
            setUserLoggedIn(userData);
            setIsAuthenticated(true);
            localStorage.setItem("userLoggedIn", JSON.stringify(userData));
            localStorage.setItem("loginCompany", loginCompany);
          } else {
            const createNewUser = await createUser({
              user_id: currentUser.uid,
              company: loginCompany,
              name: currentUser.displayName,
              email: currentUser.email,
              team: null,
            });
            userData = {
              email: createNewUser.email,
              name: createNewUser.name,
              uid: createNewUser.user_id,
              company: createNewUser.company,
              team: "",
              token,
            };
            setUserLoggedIn(userData);
            setIsAuthenticated(true);

            localStorage.setItem("userLoggedIn", JSON.stringify(userData));
            localStorage.setItem("loginCompany", loginCompany);
          }
        } catch (error) {
          console.log("Error getting document: ", error);
          setError(error);
        } finally {
          setLoading(false);
        }
      } else {
        setUserLoggedIn(null);
        setIsAuthenticated(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [loginCompany, userLoggedIn]);

  return (
    <authContext.Provider
      value={{
        userLoggedIn,
        logout,
        loginWithGoogle,
        isAuthenticated,
        setLoginCompany,
        loginCompany,
        loading,
        error,
      }}
    >
      {children}
    </authContext.Provider>
  );
}
