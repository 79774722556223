import React, { useEffect, useState } from 'react';
import { Col, Container, Form, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { MdInfo } from 'react-icons/md';
import {
  rimsOptions,
  clutchPedalOptions,
  condenserOptions,
  directionOptions,
  distributionOptions,
  fourXfourOptions,
  oilLossOptions,
  tiresOptions
} from '../../../../../constants/sectionsConst';
import {
  handleSingleCheckAndInputBoxChange,
  updateDataForCheckboxChange,
  updateFormDataTableView,
  updateFormDataTableViewMultiple
} from '../../../../../utils/sectionFuncions';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';

export default function Section2TabComponent({
  setIs4x4,
  formData,
  formDataTableView,
  saveFromDataString,
  saveFormDataTable,
  setFormDataTableView,
  editAllowed,
  saveFormDataObjet
}) {

  let isMobile = useMediaQuery("(max-width: 500px)");
  const [selectedOption1, setSelectedOption1] = useState('Ok');
  const [selectedOption2, setSelectedOption2] = useState('Ok');
  const [selectedOption3, setSelectedOption3] = useState('Ok');
  const [selectedOption4, setSelectedOption4] = useState('Ok');
  const [selectedOption5, setSelectedOption5] = useState('Ok');
  const [tireMeasurements, setTireMeasurements] = useState('');

  const { section2 } = formDataTableView;

  useEffect(() => {
    if (formData) {
      setTireMeasurements(formData?.section2?.tireMeasurements)
    }
  }, [formData]);
  const handleOilLossChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section2?.oilLoss?.values, value)
    saveFormDataTable('section2', 'oilLoss',
      { title: 'Perdida de Aceite', values: updatedValues });
  };

  const handleOptionChangeDirection = (value) => {
    saveFormDataTable('section2', 'direction', { title: 'Cremallera dirección', values: value });
  };

  const handleOptionChangeCondenser = (value) => {
    saveFormDataTable('section2', 'condenser', { title: 'Condensador A/AC', values: value });
  };
  const handleOptionChangeDistribution = (value) => {
    saveFormDataTable('section2', 'distribution', { title: 'Distribución', values: value });
  };
  const handleOptionChangeClutchPedal = (value) => {
    const updatedValues = updateDataForCheckboxChange(section2?.clutchPedal?.values, value)
    saveFormDataTable('section2', 'clutchPedal', { title: 'Pedal de Embrague', values: updatedValues });
  };
  const handleOptionChangeFourXfour = (value) => {
    setIs4x4(value === "Si");
    saveFormDataObjet('section2', 'fourXfour', { title: '4x4', values: value });
  }

  const handleCheckboxTiresChange = (sectionName, nestedObjectName, rowTitle, optionIndex) => {
    const selectedOption = tiresOptions[optionIndex];
    updateFormDataTableView(setFormDataTableView, sectionName, nestedObjectName, rowTitle, selectedOption, formDataTableView[sectionName]);
  };

  const handleCheckboxRimsChange = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleCheckboxAndInputChange1 = (value) => {
    saveFormDataTable('section2', 'frontAxleAndFuel',
      { title: 'Tren delantero y fuelles', values: [value] }
    );
  }
  const handleCheckboxAndInputChange2 = (value) => {
    saveFormDataTable('section2', 'rearAxle',
      { title: 'Tren trasero', values: [value] });
  }
  const handleCheckboxAndInputChange3 = (value) => {
    saveFormDataTable('section2', 'floorsChassis',
      { title: 'Pisos / Chasi', values: [value] });
  }

  const handleCheckboxAndInputChange4 = (value) => {
    saveFormDataTable('section2', 'exhaustCircuit',
      { title: 'Circuito de Escape', values: [value] });
  }
  const handleCheckboxAndInputChange5 = (value) => {
    saveFormDataTable('section2', 'changeOfSpeeds',
      { title: 'Pasaje de Velocidades', values: [value] });
  }

  const handleInputOtherFluidLoss = (value) => {
    saveFormDataTable('section2', 'otherFluidLoss',
      { title: 'Pérdida de otro Fluido', values: value });
  }
  const handleInputVHComments = (value) => {
    saveFormDataTable('section2', 'VHComments',
      { title: 'Comentarios VH a máxima/media altura', values: value });
  }
  const handleTextChange = (setValue, key) => (event) => {
    const { value } = event.target;
    setValue(value);
    saveFromDataString('section2', key, value);
  };


  return (
    <>{!isMobile ? (
      <Container fluid>
        <h1 className='section-title'>Paso 1</h1>
        <Row>
          <Col md={6}>
            <div className='mb-2'>
              <div className='d-flex align-items-center'>
                <label className='section-input-label me-1' id='tren-delantero-y-fuelles'>Tren delantero y fuelles</label>
                <span className="text-danger">*</span>
              </div>
              <div className='section-container' id='section2-frontAxleAndFuel'>
                <Form.Check
                  disabled={!editAllowed}
                  type="checkbox"
                  label='Ok'
                  value='Ok'
                  checked={section2?.frontAxleAndFuel?.values[0] === 'Ok'}
                  className='mb-2 section-checks'
                  onChange={(e) => {
                    handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption1);
                    handleCheckboxAndInputChange1(e.target.value)
                  }}
                />
                <div className="d-flex align-items-center">
                  <Form.Check
                    disabled={!editAllowed}
                    type="checkbox"
                    label="Otro:"
                    value='Other'
                    className='section-checks'
                    checked={section2?.frontAxleAndFuel?.values[0] !== 'Ok' || selectedOption1 === 'Other'}
                    onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption1)}
                  />
                  <input
                    type="text"
                    className='section-input ms-2'
                    id='otherText1'
                    value={section2?.frontAxleAndFuel?.values[0] !== 'Ok' ? section2?.frontAxleAndFuel?.values[0] : ''}
                    onChange={(e) => handleCheckboxAndInputChange1(e.target.value)}
                    disabled={selectedOption1 !== 'Other' || !editAllowed}
                  />
                </div>
              </div>
            </div>
            <div className='mb-2'>
              <div className='d-flex align-items-center '>
                <label className='section-input-label me-1'>Tren trasero</label>
                <span className="text-danger">*</span>
              </div>
              <div className='section-container' id='section2-rearAxle'>
                <Form.Check
                  disabled={!editAllowed}
                  type="checkbox"
                  label='Ok'
                  value='Ok'
                  checked={section2?.rearAxle?.values[0] === 'Ok'}
                  className='mb-2 section-checks'
                  onChange={(e) => {
                    handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption2);
                    handleCheckboxAndInputChange2(e.target.value)
                  }}
                />
                <div className="d-flex align-items-center">
                  <Form.Check
                    disabled={!editAllowed}
                    type="checkbox"
                    label="Otro:"
                    value='Other'
                    className='section-checks'
                    checked={section2?.rearAxle?.values[0] !== 'Ok' || selectedOption2 === 'Other'}
                    onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption2)}
                  />
                  <input
                    type="text"
                    className='section-input ms-2'
                    value={section2?.rearAxle?.values[0] !== 'Ok' ? section2?.rearAxle?.values[0] : ''}
                    onChange={(e) => handleCheckboxAndInputChange2(e.target.value)}
                    disabled={selectedOption2 !== 'Other' || !editAllowed}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className='mb-2'>
              <div className='d-flex align-items-center '>
                <label className='section-input-label me-1'>Pisos / Chasi</label>
                <span className="text-danger">*</span>
              </div>
              <div className='section-container' id='section2-floorsChassis'>
                <Form.Check
                  disabled={!editAllowed}
                  type="checkbox"
                  label='Ok'
                  value='Ok'
                  checked={section2?.floorsChassis?.values[0] === 'Ok'}
                  className='section-checks mb-2'
                  onChange={(e) => {
                    handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption3);
                    handleCheckboxAndInputChange3(e.target.value)
                  }}
                />
                <div className="d-flex align-items-center">
                  <Form.Check
                    disabled={!editAllowed}
                    type="checkbox"
                    label="Otro:"
                    value='Other'
                    className='section-checks'
                    checked={section2?.floorsChassis?.values[0] !== 'Ok' || selectedOption3 === 'Other'}
                    onChange={(event) => handleSingleCheckAndInputBoxChange(event.target.value, setSelectedOption3)}
                  />
                  <input
                    type="text"
                    className='section-input ms-2'
                    value={section2?.floorsChassis?.values[0] !== 'Ok' ? section2?.floorsChassis?.values[0] : ''}
                    onChange={(e) => handleCheckboxAndInputChange3(e.target.value)}
                    disabled={selectedOption3 !== 'Other' || !editAllowed}
                  />
                </div>
              </div>
            </div>
            <div className='mb-2'>
              <div className='d-flex align-items-center '>
                <label className='section-input-label me-1'>Circuito de Escape</label>
                <span className="text-danger">*</span>
              </div>
              <div className='section-container' id='section2-exhaustCircuit'>
                <Form.Check
                  disabled={!editAllowed}
                  type="checkbox"
                  label='Ok'
                  value='Ok'
                  checked={section2?.exhaustCircuit?.values[0] === 'Ok'}
                  className='section-checks mb-2'
                  onChange={(e) => {
                    handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption4);
                    handleCheckboxAndInputChange4(e.target.value);
                  }}
                />
                <div className="d-flex align-items-center">
                  <Form.Check
                    disabled={!editAllowed}
                    type="checkbox"
                    label="Otro:"
                    value='Other'
                    className='section-checks'
                    checked={section2?.exhaustCircuit?.values[0] !== 'Ok' || selectedOption4 === 'Other'}
                    onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption4)}
                  />
                  <input
                    type="text"
                    className='section-input ms-2'
                    value={section2?.exhaustCircuit?.values[0] !== 'Ok' ? section2?.exhaustCircuit?.values[0] || '' : ''}
                    onChange={(e) => handleCheckboxAndInputChange4(e.target.value)}
                    disabled={selectedOption4 !== 'Other' || !editAllowed}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col md={6}>
            <div className='mb-2'>
              <div className='d-flex align-items-center'>
                <label className='section-input-label me-1' id='section2-oilLoss'>Perdida de Aceite</label>
                <span className="text-danger">*</span>
              </div>
              <div className='section-container' >
                {oilLossOptions.map((option, index) => (
                  <Form.Check
                    disabled={!editAllowed}
                    key={index}
                    type="checkbox"
                    label={option}
                    name="oilLoss"
                    checked={section2?.oilLoss?.values?.includes(option)}
                    onChange={() => handleOilLossChange(option)}
                    className={`section-checks ${index === oilLossOptions.length - 1 ? '' : 'mb-2'}`}
                  />
                ))}
              </div>
            </div>
          </Col>
          <Col md={6} className="d-flex flex-column justify-content-between">
            <div className='mb-2' id='section2-otherFluidLoss'>
              <label className='section-input-label'>Pérdida de otro Fluido</label>
              <textarea
                className='section-input '
                style={{ height: '84%' }}
                value={section2?.otherFluidLoss?.values}
                disabled={!editAllowed}
                onChange={(e) => handleInputOtherFluidLoss(e.target.value)}
              />
            </div>
            <div className='mb-2'>
              <div className='d-flex align-items-center'>
                <label className='section-input-label me-1'>Cremallera dirección</label>
                <span className="text-danger">*</span>
              </div>
              <div className='section-container' id='section2-direction'>
                {directionOptions.map((option, index) => (
                  <Form.Check
                    key={option}
                    type="checkbox"
                    disabled={!editAllowed}
                    label={option}
                    checked={section2?.direction?.values === option}
                    onChange={() => handleOptionChangeDirection(option)}
                    className={`section-checks ${index === directionOptions.length - 1 ? '' : 'mb-2'}`}
                  />
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col md={6}>
            <div className='mb-2'>
              <div className='d-flex align-items-center'>
                <label className='section-input-label me-1'>Neumáticos</label>
                <span className="text-danger">*</span>
              </div>
              <div className='overflow-x-auto'>
                <table className="section-table" id='section2-tires'>
                  <thead>
                    <tr>
                      <th className="section-table-cell"></th>
                      {tiresOptions.map((option, index) => (
                        <th key={index} className="section-table-cell">{option}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {section2?.tires.filter(item => typeof item === 'object').map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="section-table-cell">{row.title}</td>
                        {tiresOptions.map((option, optionIndex) => (
                          <td key={optionIndex} className="section-table-cell">
                            <Form.Check
                              disabled={!editAllowed}
                              type="checkbox"
                              className='section-checks'
                              checked={row?.values?.includes(option)}
                              onChange={() => handleCheckboxTiresChange('section2', 'tires', row.title, optionIndex)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
          <Col md={6} className="d-flex flex-column justify-content-between">
            <div className='mb-2'>
              <div className='d-flex align-items-center mb-1'>
                <label className='section-input-label me-1'>Medidas Neumático</label>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Body>
                        Información
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <span>
                    <MdInfo className='section-info-icon' />
                  </span>
                </OverlayTrigger>
              </div>
              <input
                type="text"
                disabled={!editAllowed}
                className='section-input'
                value={tireMeasurements}
                onChange={handleTextChange(setTireMeasurements, 'tireMeasurements')}
              />
            </div>
            <div className='mb-2'>
              <div className='d-flex align-items-center'>
                <label className='section-input-label me-1'>Condensador A/AC</label>
                <span className="text-danger">*</span>
              </div>
              <div className='section-container' id='section2-condenser'>
                {condenserOptions.map((option, index) => (
                  <Form.Check
                    key={option}
                    disabled={!editAllowed}
                    type="checkbox"
                    label={option}
                    checked={section2?.condenser?.values === option}
                    onChange={() => handleOptionChangeCondenser(option)}
                    className={`section-checks ${index === condenserOptions.length - 1 ? '' : 'mb-2'}`}
                  />
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col md={6} className="d-flex flex-column justify-content-between">
            <div className='mb-3'>
              <div className='d-flex align-items-center'>
                <label className='section-input-label me-1' id='section1-llantas'>Llantas</label>
                <span className="text-danger">*</span>
              </div>
              <table className="section-table" id='section2-rims'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {rimsOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section2?.rims?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {rimsOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxRimsChange('section2', 'rims', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='mb-2' >
              <div className='d-flex align-items-center mb-1' id='section2-VHComments'>
                <label className='section-input-label me-1'>Comentarios VH a máxima/media altura</label>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Body>
                        Información
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <span>
                    <MdInfo className='section-info-icon' />
                  </span>
                </OverlayTrigger>
              </div>
              <textarea
                disabled={!editAllowed}
                className='section-input'
                value={section2?.VHComments?.values}
                onChange={(e) => handleInputVHComments(e.target.value)}
              />
            </div>
            <div className='mb-2'>
              <div className='d-flex align-items-center'>
                <label className='section-input-label me-1'>Distribución</label>
                <span className="text-danger">*</span>
              </div>
              <div className='section-container' id='section2-distribution'>
                {distributionOptions.map((option, index) => (
                  <Form.Check
                    disabled={!editAllowed}
                    key={option}
                    type="checkbox"
                    label={option}
                    checked={section2?.distribution?.values === option}
                    onChange={() => handleOptionChangeDistribution(option)}
                    className={`section-checks ${index === distributionOptions.length - 1 ? '' : 'mb-2'}`}
                  />
                ))}
              </div>
            </div>
          </Col>
          <Col md={6} className="d-flex flex-column justify-content-between">
            <div className='mb-2'>
              <div className='d-flex align-items-center'>
                <label className='section-input-label me-1'>Pasaje de Velocidades</label>
                <span className="text-danger">*</span>
              </div>
              <div className='section-container' id='section2-changeOfSpeeds'>
                {['MT - OK', 'AT - OK'].map((option, index) => (
                  <Form.Check
                    key={option}
                    disabled={!editAllowed}
                    type="checkbox"
                    label={option}
                    name={option}
                    value={option}
                    checked={section2?.changeOfSpeeds?.values[0] === option}
                    className={`section-checks ${index === 1 ? 'mb-1' : 'mb-2'}`}
                    onChange={(e) => {
                      handleSingleCheckAndInputBoxChange(option, setSelectedOption5);
                      handleCheckboxAndInputChange5(e.target.value)
                    }}
                  />
                ))}
                <div className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    label="Otro:"
                    name="Other"
                    disabled={!editAllowed}
                    value='Other'
                    className='section-checks'
                    checked={((section2?.changeOfSpeeds?.values[0] !== 'MT - OK') && (section2?.changeOfSpeeds?.values[0] !== 'AT - OK')) || (selectedOption5 === 'Other')} onChange={(e) => {
                      handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption5);
                    }}
                  />
                  <input
                    type="text"
                    className='section-input ms-2'
                    value={section2?.changeOfSpeeds?.values[0] !== 'MT - OK' && section2?.changeOfSpeeds?.values[0] !== 'AT - OK' ? section2?.changeOfSpeeds?.values : ''}
                    onChange={(e) => handleCheckboxAndInputChange5(e.target.value)}
                    disabled={selectedOption5 !== 'Other' || !editAllowed}
                  />
                </div>
              </div>
            </div>
            <div className='mb-2'>
              <div className='d-flex align-items-center'>
                <label className='section-input-label me-1'>Pedal de Embrague</label>
                <span className="text-danger">*</span>
              </div>
              <div className='section-container' id='section2-clutchPedal'>
                {clutchPedalOptions.map((option, index) => (
                  <Form.Check
                    key={option}
                    type="checkbox"
                    label={option}
                    disabled={!editAllowed}
                    checked={section2?.clutchPedal?.values.includes(option)}
                    onChange={() => handleOptionChangeClutchPedal(option)}
                    className={`section-checks ${index === clutchPedalOptions.length - 1 ? '' : 'mb-2'}`}
                  />
                ))}
              </div>
            </div>
            <div className='mb-2'>
              <div className='d-flex align-items-center'>
                <label className='section-input-label me-1'>4x4</label>
                <span className="text-danger">*</span>
              </div>
              <div className='section-container' id='section2-fourXfour'>
                {fourXfourOptions.map((option, index) => (
                  <Form.Check
                    key={option}
                    type="checkbox"
                    label={option}
                    disabled={!editAllowed}
                    checked={formData?.section2?.fourXfour?.values === option}
                    onChange={() => handleOptionChangeFourXfour(option)}
                    className={`section-checks ${index === fourXfourOptions.length - 1 ? '' : 'mb-2'}`}
                  />
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ) : (
      <Container fluid>
        <h1 className='section-title'>Paso 1</h1>
        <div className='mb-2'>
          <div className='d-flex align-items-center'>
            <label className='section-input-label me-1' id='tren-delantero-y-fuelles'>Tren delantero y fuelles</label>
            <span className="text-danger">*</span>
          </div>
          <div className='section-container' id='section2-frontAxleAndFuel'>
            <Form.Check
              disabled={!editAllowed}
              type="checkbox"
              label='Ok'
              value='Ok'
              checked={section2?.frontAxleAndFuel?.values[0] === 'Ok'}
              className='mb-2 section-checks'
              onChange={(e) => {
                handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption1);
                handleCheckboxAndInputChange1(e.target.value)
              }}
            />
            <div className="d-flex align-items-center">
              <Form.Check
                disabled={!editAllowed}
                type="checkbox"
                label="Otro:"
                value='Other'
                className='section-checks'
                checked={section2?.frontAxleAndFuel?.values[0] !== 'Ok' || selectedOption1 === 'Other'}
                onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption1)}
              />
              <input
                type="text"
                className='section-input ms-2'
                id='otherText1'
                value={section2?.frontAxleAndFuel?.values[0] !== 'Ok' ? section2?.frontAxleAndFuel?.values[0] : ''}
                onChange={(e) => handleCheckboxAndInputChange1(e.target.value)}
                disabled={selectedOption1 !== 'Other' || !editAllowed}
              />
            </div>
          </div>
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center '>
            <label className='section-input-label me-1'>Pisos / Chasi</label>
            <span className="text-danger">*</span>
          </div>
          <div className='section-container' id='section2-floorsChassis'>
            <Form.Check
              disabled={!editAllowed}
              type="checkbox"
              label='Ok'
              value='Ok'
              checked={section2?.floorsChassis?.values[0] === 'Ok'}
              className='section-checks mb-2'
              onChange={(e) => {
                handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption3);
                handleCheckboxAndInputChange3(e.target.value)
              }}
            />
            <div className="d-flex align-items-center">
              <Form.Check
                disabled={!editAllowed}
                type="checkbox"
                label="Otro:"
                value='Other'
                className='section-checks'
                checked={section2?.floorsChassis?.values[0] !== 'Ok' || selectedOption3 === 'Other'}
                onChange={(event) => handleSingleCheckAndInputBoxChange(event.target.value, setSelectedOption3)}
              />
              <input
                type="text"
                className='section-input ms-2'
                value={section2?.floorsChassis?.values[0] !== 'Ok' ? section2?.floorsChassis?.values[0] : ''}
                onChange={(e) => handleCheckboxAndInputChange3(e.target.value)}
                disabled={selectedOption3 !== 'Other' || !editAllowed}
              />
            </div>
          </div>
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center '>
            <label className='section-input-label me-1'>Tren trasero</label>
            <span className="text-danger">*</span>
          </div>
          <div className='section-container' id='section2-rearAxle'>
            <Form.Check
              disabled={!editAllowed}
              type="checkbox"
              label='Ok'
              value='Ok'
              checked={section2?.rearAxle?.values[0] === 'Ok'}
              className='mb-2 section-checks'
              onChange={(e) => {
                handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption2);
                handleCheckboxAndInputChange2(e.target.value)
              }}
            />
            <div className="d-flex align-items-center">
              <Form.Check
                disabled={!editAllowed}
                type="checkbox"
                label="Otro:"
                value='Other'
                className='section-checks'
                checked={section2?.rearAxle?.values[0] !== 'Ok' || selectedOption2 === 'Other'}
                onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption2)}
              />
              <input
                type="text"
                className='section-input ms-2'
                value={section2?.rearAxle?.values[0] !== 'Ok' ? section2?.rearAxle?.values[0] : ''}
                onChange={(e) => handleCheckboxAndInputChange2(e.target.value)}
                disabled={selectedOption2 !== 'Other' || !editAllowed}
              />
            </div>
          </div>
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center '>
            <label className='section-input-label me-1'>Circuito de Escape</label>
            <span className="text-danger">*</span>
          </div>
          <div className='section-container' id='section2-exhaustCircuit'>
            <Form.Check
              disabled={!editAllowed}
              type="checkbox"
              label='Ok'
              value='Ok'
              checked={section2?.exhaustCircuit?.values[0] === 'Ok'}
              className='section-checks mb-2'
              onChange={(e) => {
                handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption4);
                handleCheckboxAndInputChange4(e.target.value);
              }}
            />
            <div className="d-flex align-items-center">
              <Form.Check
                disabled={!editAllowed}
                type="checkbox"
                label="Otro:"
                value='Other'
                className='section-checks'
                checked={section2?.exhaustCircuit?.values[0] !== 'Ok' || selectedOption4 === 'Other'}
                onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption4)}
              />
              <input
                type="text"
                className='section-input ms-2'
                value={section2?.exhaustCircuit?.values[0] !== 'Ok' ? section2?.exhaustCircuit?.values[0] || '' : ''}
                onChange={(e) => handleCheckboxAndInputChange4(e.target.value)}
                disabled={selectedOption4 !== 'Other' || !editAllowed}
              />
            </div>
          </div>
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center'>
            <label className='section-input-label me-1' id='section2-oilLoss'>Perdida de Aceite</label>
            <span className="text-danger">*</span>
          </div>
          <div className='section-container' >
            {oilLossOptions.map((option, index) => (
              <Form.Check
                disabled={!editAllowed}
                key={index}
                type="checkbox"
                label={option}
                name="oilLoss"
                checked={section2?.oilLoss?.values?.includes(option)}
                onChange={() => handleOilLossChange(option)}
                className={`section-checks ${index === oilLossOptions.length - 1 ? '' : 'mb-2'}`}
              />
            ))}
          </div>
        </div>
        <div className='mb-2' id='section2-otherFluidLoss'>
          <label className='section-input-label'>Pérdida de otro Fluido</label>
          <textarea
            className='section-input '
            style={{ height: '84%' }}
            value={section2?.otherFluidLoss?.values}
            disabled={!editAllowed}
            onChange={(e) => handleInputOtherFluidLoss(e.target.value)}
          />
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center'>
            <label className='section-input-label me-1'>Cremallera dirección</label>
            <span className="text-danger">*</span>
          </div>
          <div className='section-container' id='section2-direction'>
            {directionOptions.map((option, index) => (
              <Form.Check
                key={option}
                type="checkbox"
                disabled={!editAllowed}
                label={option}
                checked={section2?.direction?.values === option}
                onChange={() => handleOptionChangeDirection(option)}
                className={`section-checks ${index === directionOptions.length - 1 ? '' : 'mb-2'}`}
              />
            ))}
          </div>
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center'>
            <label className='section-input-label me-1'>Neumáticos</label>
            <span className="text-danger">*</span>
          </div>
          <div className='overflow-x-auto'>
            <table className="section-table" id='section2-tires'>
              <thead>
                <tr>
                  <th className="section-table-cell"></th>
                  {tiresOptions.map((option, index) => (
                    <th key={index} className="section-table-cell">{option}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {section2?.tires.filter(item => typeof item === 'object').map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="section-table-cell">{row.title}</td>
                    {tiresOptions.map((option, optionIndex) => (
                      <td key={optionIndex} className="section-table-cell">
                        <Form.Check
                          disabled={!editAllowed}
                          type="checkbox"
                          className='section-checks'
                          checked={row?.values?.includes(option)}
                          onChange={() => handleCheckboxTiresChange('section2', 'tires', row.title, optionIndex)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center mb-1'>
            <label className='section-input-label me-1'>Medidas Neumático</label>
            <OverlayTrigger
              placement="top"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Body>
                    Información
                  </Popover.Body>
                </Popover>
              }
            >
              <span>
                <MdInfo className='section-info-icon' />
              </span>
            </OverlayTrigger>
          </div>
          <input
            type="text"
            disabled={!editAllowed}
            className='section-input'
            value={tireMeasurements}
            onChange={handleTextChange(setTireMeasurements, 'tireMeasurements')}
          />
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center'>
            <label className='section-input-label me-1'>Condensador A/AC</label>
            <span className="text-danger">*</span>
          </div>
          <div className='section-container' id='section2-condenser'>
            {condenserOptions.map((option, index) => (
              <Form.Check
                key={option}
                disabled={!editAllowed}
                type="checkbox"
                label={option}
                checked={section2?.condenser?.values === option}
                onChange={() => handleOptionChangeCondenser(option)}
                className={`section-checks ${index === condenserOptions.length - 1 ? '' : 'mb-2'}`}
              />
            ))}
          </div>
        </div>
        <div className='mb-3'>
          <div className='d-flex align-items-center'>
            <label className='section-input-label me-1' id='section1-llantas'>Llantas</label>
            <span className="text-danger">*</span>
          </div>
          <table className="section-table" id='section2-rims'>
            <thead>
              <tr>
                <th className="section-table-cell"></th>
                {rimsOptions.map((option, index) => (
                  <th key={index} className="section-table-cell">{option}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {section2?.rims?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className="section-table-cell">{row.title}</td>
                  {rimsOptions.map((option, optionIndex) => (
                    <td key={optionIndex} className="section-table-cell">
                      <Form.Check
                        disabled={!editAllowed}
                        type="checkbox"
                        className='section-checks'
                        checked={row?.values?.includes(option)}
                        onChange={() => handleCheckboxRimsChange('section2', 'rims', row.title, option)}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center'>
            <label className='section-input-label me-1'>Pasaje de Velocidades</label>
            <span className="text-danger">*</span>
          </div>
          <div className='section-container' id='section2-changeOfSpeeds'>
            {['MT - OK', 'AT - OK'].map((option, index) => (
              <Form.Check
                key={option}
                disabled={!editAllowed}
                type="checkbox"
                label={option}
                name={option}
                value={option}
                checked={section2?.changeOfSpeeds?.values[0] === option}
                className={`section-checks ${index === 1 ? 'mb-1' : 'mb-2'}`}
                onChange={(e) => {
                  handleSingleCheckAndInputBoxChange(option, setSelectedOption5);
                  handleCheckboxAndInputChange5(e.target.value)
                }}
              />
            ))}
            <div className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                label="Otro:"
                name="Other"
                disabled={!editAllowed}
                value='Other'
                className='section-checks'
                checked={((section2?.changeOfSpeeds?.values[0] !== 'MT - OK') && (section2?.changeOfSpeeds?.values[0] !== 'AT - OK')) || (selectedOption5 === 'Other')} onChange={(e) => {
                  handleSingleCheckAndInputBoxChange(e.target.value, setSelectedOption5);
                }}
              />
              <input
                type="text"
                className='section-input ms-2'
                value={section2?.changeOfSpeeds?.values[0] !== 'MT - OK' && section2?.changeOfSpeeds?.values[0] !== 'AT - OK' ? section2?.changeOfSpeeds?.values : ''}
                onChange={(e) => handleCheckboxAndInputChange5(e.target.value)}
                disabled={selectedOption5 !== 'Other' || !editAllowed}
              />
            </div>
          </div>
        </div>
        <div className='mb-2' >
          <div className='d-flex align-items-center mb-1' id='section2-VHComments'>
            <label className='section-input-label me-1'>Comentarios VH a máxima/media altura</label>
            <OverlayTrigger
              placement="top"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Body>
                    Información
                  </Popover.Body>
                </Popover>
              }
            >
              <span>
                <MdInfo className='section-info-icon' />
              </span>
            </OverlayTrigger>
          </div>
          <textarea
            disabled={!editAllowed}
            className='section-input'
            value={section2?.VHComments?.values}
            onChange={(e) => handleInputVHComments(e.target.value)}
          />
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center'>
            <label className='section-input-label me-1'>Pedal de Embrague</label>
            <span className="text-danger">*</span>
          </div>
          <div className='section-container' id='section2-clutchPedal'>
            {clutchPedalOptions.map((option, index) => (
              <Form.Check
                key={option}
                type="checkbox"
                label={option}
                disabled={!editAllowed}
                checked={section2?.clutchPedal?.values.includes(option)}
                onChange={() => handleOptionChangeClutchPedal(option)}
                className={`section-checks ${index === clutchPedalOptions.length - 1 ? '' : 'mb-2'}`}
              />
            ))}
          </div>
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center'>
            <label className='section-input-label me-1'>Distribución</label>
            <span className="text-danger">*</span>
          </div>
          <div className='section-container' id='section2-distribution'>
            {distributionOptions.map((option, index) => (
              <Form.Check
                disabled={!editAllowed}
                key={option}
                type="checkbox"
                label={option}
                checked={section2?.distribution?.values === option}
                onChange={() => handleOptionChangeDistribution(option)}
                className={`section-checks ${index === distributionOptions.length - 1 ? '' : 'mb-2'}`}
              />
            ))}
          </div>
        </div>
        <div className='mb-2'>
          <div className='d-flex align-items-center'>
            <label className='section-input-label me-1'>4x4</label>
            <span className="text-danger">*</span>
          </div>
          <div className='section-container' id='section2-fourXfour'>
            {fourXfourOptions.map((option, index) => (
              <Form.Check
                key={option}
                type="checkbox"
                label={option}
                disabled={!editAllowed}
                checked={formData?.section2?.fourXfour?.values === option}
                onChange={() => handleOptionChangeFourXfour(option)}
                className={`section-checks ${index === fourXfourOptions.length - 1 ? '' : 'mb-2'}`}
              />
            ))}
          </div>
        </div>
      </Container>
    )}
    </>
  );
}
