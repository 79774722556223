import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

export default function ObservationDiscountsModalInput({
  modal,
  setModal,
  handleSave,
  editData,
  editSection,
  isNewObservation,
}) {
  const initialFormState = {
    title: "",
    values: "",
    discount: "",
    mandatory: "",
    comments: "",
  };

  const [formData, setFormData] = useState(initialFormState);
  const [formValid, setFormValid] = useState(false);

  const resetForm = useCallback(() => {
    setFormData(initialFormState);
    setFormValid(false);
  }, [initialFormState]);

  const handleClose = useCallback(() => {
    resetForm();
    setModal(false);
  }, [resetForm, setModal]);

  useEffect(() => {
    if (modal && editData) {
      setFormData({
        title: editData.title || "",
        values: editData.values || "",
        discount: editData.discount || "",
        mandatory: editData.mandatory || "",
        comments: editData.comments || "",
      });
    }
  }, [modal, editData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };
    if (name === "discount") {
      const numericValue = value.replace(/\./g, '');
      if (!isNaN(numericValue)) {
        updatedFormData = {
          ...formData,
          discount: numericValue,
        };
      }
    } else {
      updatedFormData = {
        ...formData,
        [name]: value,
      };
    }

    setFormData(updatedFormData);
    validateForm(updatedFormData);
  };

  const validateForm = (data) => {
    const isValid = data.title.trim() !== "" &&
      data.values !== "" &&
      data.discount !== "" &&
      data.mandatory.trim() !== "" &&
      (editSection !== 'section' || data.comments.trim() !== "");
    setFormValid(isValid);
  };

  const handleSaveClick = useCallback(() => {
    const formattedDiscount = `$${formatNumberWithDotSeparator(formData.discount)}`;
    const saveData = {
      title: formData.title,
      values: formData.values,
      discount: formattedDiscount,
      mandatory: formData.mandatory,
      ...(editSection?.includes('section') && { comments: formData.comments }),
    };

    handleSave(saveData);
    handleClose();
  }, [formData, handleSave, handleClose, editSection]);

  const formatNumberWithDotSeparator = (numberString) => {
    if (numberString === "") return "";
    let numberWithoutCurrency = numberString.replace(/[^0-9.-]/g, '');
    let number = parseInt(numberWithoutCurrency.replace(/\./g, ''), 10);
    if (isNaN(number)) return numberString;
    return number.toLocaleString("es-ES");
  };

  return (
    <Modal show={modal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {isNewObservation
            ? "Agregar Observación"
            : editSection === "observations"
              ? "Editar Observación"
              : "Editar Sección"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={6}>
            <div className="mb-2">
              <label className="section-input-label mb-2">Observación</label>
              <input
                type="text"
                name="title"
                className="section-input"
                value={formData.title}
                onChange={handleChange}
                disabled={editSection?.includes('section')}
              />
            </div>
            <div className="mb-2">
              <label className="section-input-label mb-2">Respuesta</label>
              <input
                type="text"
                name="values"
                className="section-input"
                value={formData.values}
                onChange={handleChange}
                disabled={editSection?.includes('section')}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-2">
              <label className="section-input-label mb-2">Descuento</label>
              <input
                type="text"
                name="discount"
                className="section-input"
                value={formatNumberWithDotSeparator(formData.discount)}
                onChange={handleChange}
              />
            </div>
            <div className="mb-2">
              <label className="section-input-label mb-2">Obligatorio</label>
              <Form.Select
                className="section-input"
                name="mandatory"
                value={formData.mandatory}
                onChange={handleChange}
              >
                <option value="">Seleccionar...</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Form.Select>
            </div>
            {editSection?.includes('section') && (
              <div className="mb-2">
                <label className="section-input-label mb-2">Comentarios</label>
                <input
                  type="text"
                  name="comments"
                  className="section-input"
                  value={formData.comments}
                  onChange={handleChange}
                />
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSaveClick} disabled={!formValid}>
          Guardar cambios
        </Button>
      </Modal.Footer>
    </Modal>
  );
}