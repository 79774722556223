import { useEffect, useState } from "react";
import { Table, Tab, Tabs, Form } from "react-bootstrap";

const MercadoLibreComponent = ({ data }) => {
  const [year, setyear] = useState();
  const [dataMeli, setdataMeli] = useState();
  const [campoSelected, setcampoSelected] = useState();
  const [condicionSelected, setcondicionSelected] = useState();
  const [estado, setestado] = useState();

  useEffect(() => {
    setyear(data?.base_year);
    setdataMeli(data?.mercado_libre);
  }, [data]);

  const ordenar = (array) => {
    if (condicionSelected === "Ascendente") {
      if (campoSelected === "last_price_pesos") {
        array.sort((a, b) => {
          return a.last_price_pesos - b.last_price_pesos;
        });
        setestado(!estado);
      } else if (campoSelected === "car_kms") {
        array.sort((a, b) => {
          return a.car_kms - b.car_kms;
        });
        setestado(!estado);
      } else if (campoSelected === "car_location_0") {
        array.sort((a, b) => {
          return a.car_location_0 - b.car_location_0;
        });
        setestado(!estado);
      }
    } else if (condicionSelected === "Descendente") {
      if (campoSelected === "last_price_pesos") {
        array.sort((a, b) => {
          return b.last_price_pesos - a.last_price_pesos;
        });
        setestado(!estado);
      } else if (campoSelected === "car_kms") {
        array.sort((a, b) => {
          return b.car_kms - a.car_kms;
        });
        setestado(!estado);
      } else if (campoSelected === "car_location_0") {
        array.sort((a, b) => {
          return b.car_location_0 - a.car_location_0;
        });
        setestado(!estado);
      }
    }
  };

  return (
    <>
      <Tabs id="uncontrolled-tab-example" className="mb-3">
        {dataMeli?.map((info, i) =>
          info.models.map((info2, i2) => (
            <Tab
              key={i}
              eventKey={`${info2.model}`}
              title={`${info2.model} (${Math.trunc(info2.match_score)}%)`}
            >
              <div
                style={{
                  height: "40vh",
                  overflow: "scroll",
                  marginTop: "20px",
                }}
                className="scrollTable"
                key={i}
              >
                <Table bordered hover>
                  <thead>
                    <tr className="fixed-head">
                      <th className="text-head-table">Marca</th>
                      <th className="text-head-table">Modelo</th>
                      <th className="text-head-table">Version</th>
                      <th className="text-head-table">Año</th>
                      <th className="text-head-table">
                        Precio {Number(year) - 1}
                      </th>
                      <th className="text-head-table">
                        Rotacion {Number(year) - 1}
                      </th>
                      <th className="text-head-table">Precio {year}</th>
                      <th className="text-head-table">Rotacion {year}</th>
                      <th className="text-head-table">
                        Precio {Number(year) + 1}
                      </th>
                      <th className="text-head-table">
                        Rotacion{Number(year) + 1}
                      </th>
                      <th className="text-head-table">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {info2.versions?.map((info3, i3) => (
                      <tr key={i3}>
                        <td className="text-content-table">{info.brand}</td>
                        <td className="text-content-table">{info2.model}</td>
                        <td className="text-content-table">{info3.version}</td>
                        <td className="text-content-table">{year}</td>
                        <td className="text-content-table">
                          {info3.prev_year !== ""
                            ? `$ ${Math.trunc(
                              Number(info3.prev_year)
                            ).toLocaleString("de")}`
                            : "-"}
                          ({info3.prev_year_count})
                        </td>
                        <td className="text-content-table">
                          {Math.trunc(Number(info3.prev_year_rotacion))} (
                          {info3.prev_year_rotacion_count})
                        </td>
                        <td className="text-content-table">
                          {info3.year !== ""
                            ? `$ ${Math.trunc(
                              Number(info3.year)
                            ).toLocaleString("de")}`
                            : "-"}
                          ({info3.year_count})
                        </td>
                        <td className="text-content-table">
                          {Math.trunc(Number(info3.year_rotacion))} (
                          {info3.year_rotacion_count})
                        </td>
                        <td className="text-content-table">
                          {info3.next_year !== ""
                            ? `$ ${Math.trunc(
                              Number(info3.next_year)
                            ).toLocaleString("de")}`
                            : "-"}
                          ({info3.next_year_count})
                        </td>
                        <td className="text-content-table">
                          {Math.trunc(Number(info3.next_year_rotacion))} (
                          {info3.next_year_rotacion_count})
                        </td>
                        <td className="text-content-table">
                          {info3.match_score} %
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="d-flex align-items-center justify-content-start mt-3">
                  <Form.Select
                    className="select-order"
                    onChange={(e) => setcampoSelected(e.target.value)}
                  >
                    <option>Seleccione un campo</option>
                    <option value="last_price_pesos">Precio</option>
                    <option value="car_kms">Kilometros</option>
                    {/* <option value="car_location_0">Ubicacion</option> */}
                  </Form.Select>
                  <Form.Select
                    className="select-order ms-2"
                    onChange={(e) => setcondicionSelected(e.target.value)}
                  >
                    <option>Seleccione una condicion</option>
                    <option>Ascendente</option>
                    <option>Descendente</option>
                  </Form.Select>
                  <button
                    className="btn-ordenar ms-2 me-2"
                    type="submit"
                    onClick={() => ordenar(info2.published_versions)}
                  >
                    Ordenar
                  </button>
                </div>
              </form>
              <div
                style={{
                  height: "40vh",
                  overflow: "scroll",
                  marginTop: "20px",
                }}
                className="scrollTable"
              >
                <Table bordered hover>
                  <thead>
                    <tr className="fixed-head">
                      <th className="text-head-table">Min Date</th>
                      <th className="text-head-table">Card ID</th>
                      <th className="text-head-table">Año</th>
                      <th className="text-head-table">Marca</th>
                      <th className="text-head-table">Modelo</th>
                      <th className="text-head-table">Versión</th>
                      <th className="text-head-table">Dealer</th>
                      <th className="text-head-table">Precio</th>
                      <th className="text-head-table">Kilometros</th>
                      <th className="text-head-table">Ubicación</th>
                    </tr>
                  </thead>
                  <tbody>
                    {info2?.published_versions?.map((info4, i4) => (
                      <tr key={i4}>
                        <td className="text-content-table">{info4.date_1}</td>
                        <td className="text-content-table">
                          <a
                            href={`https://auto.mercadolibre.com.ar/MLA-${info4.car_id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {info4.car_id}
                          </a>
                        </td>
                        <td className="text-content-table">{info4.year}</td>
                        <td className="text-content-table">{info4.brand}</td>
                        <td className="text-content-table">{info4.model}</td>
                        <td className="text-content-table">{info4.version}</td>
                        <td className="text-content-table">
                          {info4.dealer === true ? "Si" : "No"}
                        </td>
                        <td className="text-content-table">
                          {info4.last_price_pesos !== ""
                            ? `$ ${Math.trunc(
                              Number(info4.last_price_pesos)
                            ).toLocaleString("de")}`
                            : "-"}
                        </td>
                        <td className="text-content-table">
                          {Number(info4.car_kms).toLocaleString("de")}
                        </td>
                        <td className="text-content-table">
                          {info4.car_location_0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab>
          ))
        )}
      </Tabs>
    </>
  );
};

export default MercadoLibreComponent;
