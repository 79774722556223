import { doc, onSnapshot, collection } from "firebase/firestore";
import { db } from "../firebase/config";

const shortSnapshotFirebase = (company, collectionName, docName, callback) => {
  const docRef = doc(
    collection(db, "companies", company, collectionName),
    docName
  );

  const unsubscribe = onSnapshot(
    docRef,
    (snapshot) => {
      if (snapshot.exists()) {
        callback(snapshot.data());
      } else {
        throw new Error("El documento no existe.");
      }
    },
    (error) => {
      throw new Error(`Error al escuchar el snapshot: ${error.message}`);
    }
  );

  return unsubscribe;
};

export default shortSnapshotFirebase;
