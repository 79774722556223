import React from 'react';
import { Button } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';

// Componente que se muestra cuando ocurre un error
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert" className="containerAll">
      <h2>Algo salió mal:</h2>
      <pre>{error.message}</pre>
      <Button variant='primary' onClick={resetErrorBoundary}>Intentar de nuevo</Button>
    </div>
  );
}

function MyErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export default MyErrorBoundary;