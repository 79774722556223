import React, { useEffect } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { FaClipboardList, FaHistory } from "react-icons/fa";
import { MdOutlineInsertComment } from "react-icons/md";
import { NavLink, useLocation, useParams } from "react-router-dom";
import useFirebaseOnSnapshot from "../../../../hooks/useFirebaseOnSnapshot";
import { useUser } from "../../../../context/UserContext";
import { FaCheckCircle } from "react-icons/fa";
import { colors } from "../../../../styles/colors";

export default function DealSectionsButtonsComponent({ isPageWide }) {
  const { deal_id } = useParams();
  const { user } = useUser();
  const location = useLocation();
  const { data } = useFirebaseOnSnapshot(user?.company, deal_id, "sections_status");

  useEffect(() => {
    // setdataHolder(data?.titulares);
  }, [data]);

  const isActiveOrClose = (path, status) => {
    if (location.pathname === path) {
      return "primary";
    } else if (status === "close") {
      return "dark";
    } else {
      return "outline-primary";
    }
  };

  const isActive = (path) => {
    return location.pathname === path ? "primary" : "outline-primary";
  };

  const buttonStyle = (path, status) => {
    if (location.pathname === path) {
      return { backgroundColor: colors.primary.primary, color: colors.white };
    } else if (status === "close") {
      return { backgroundColor: colors.primary.dark, color: colors.white }; // Darker color for closed status
    } else {
      return {};
    }
  };

  return (
    <>
      {isPageWide ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
          className="mt-3 mb-3"
        >
          <ButtonGroup
            style={{ minWidth: "60%", backgroundColor: colors.neutral.default }}
          >
            <Button
              as={NavLink}
              to={`/deal/${deal_id}`}
              variant={isActiveOrClose(`/deal/${deal_id}`, data?.basics)}
              style={{
                ...buttonStyle(`/deal/${deal_id}`, data?.basics),
                paddingLeft: data?.basics === "close" ? "2px" : "6px",
                paddingRight: data?.basics === "close" ? "2px" : "6px",
              }}
            >
              <p style={{ margin: "0px", fontSize: "15px" }}>
                Información básica
                {data?.basics === "close" ? (
                  <FaCheckCircle className="ms-1 mb-1" />
                ) : null}
              </p>
            </Button>
            <Button
              as={NavLink}
              to={`/inspection/${deal_id}`}
              variant={isActiveOrClose(`/inspection/${deal_id}`, data?.check)}
              style={{
                ...buttonStyle(`/inspection/${deal_id}`, data?.check),
                paddingLeft: data?.check === "close" ? "2px" : "6px",
                paddingRight: data?.check === "close" ? "2px" : "6px",
              }}
            >
              <p style={{ margin: "0px", fontSize: "15px" }}>
                Inspección
                {data?.check === "close" ? (
                  <FaCheckCircle className="ms-1 mb-1" />
                ) : null}
              </p>
            </Button>
            <Button
              as={NavLink}
              to={`/admin-info/${deal_id}`}
              variant={isActiveOrClose(`/admin-info/${deal_id}`, data?.administrative)}
              style={{
                ...buttonStyle(`/admin-info/${deal_id}`, data?.administrative),
                paddingLeft: data?.administrative === "close" ? "2px" : "6px",
                paddingRight: data?.administrative === "close" ? "2px" : "6px",
              }}
            >
              <p style={{ margin: "0px", fontSize: "15px" }}>
                Información administrativa
                {data?.administrative === "close" ? (
                  <FaCheckCircle className="ms-1 mb-1" />
                ) : null}
              </p>
            </Button>
            <Button
              as={NavLink}
              to={`/pre-take-insp/${deal_id}`}
              variant={isActiveOrClose(`/pre-take-insp/${deal_id}`, data?.final_check)}
              style={{
                ...buttonStyle(`/pre-take-insp/${deal_id}`, data?.final_check),
                paddingLeft: data?.final_check === "close" ? "2px" : "6px",
                paddingRight: data?.final_check === "close" ? "2px" : "6px",
              }}
            >
              <p style={{ margin: "0px", fontSize: "15px" }}>
                Inspección Pre Toma
                {data?.final_check === "close" ? (
                  <FaCheckCircle className="ms-1 mb-1" />
                ) : null}
              </p>
            </Button>
            <Button
              as={NavLink}
              to={`/take/${deal_id}`}
              variant={isActiveOrClose(`/take/${deal_id}`, data?.take)}
              style={{
                ...buttonStyle(`/take/${deal_id}`, data?.take),
                paddingLeft: data?.take === "close" ? "2px" : "6px",
                paddingRight: data?.take === "close" ? "2px" : "6px",
              }}
            >
              <p style={{ margin: "0px", fontSize: "15px" }}>
                Toma
                {data?.take === "close" ? (
                  <FaCheckCircle className="ms-1 mb-1" />
                ) : null}
              </p>
            </Button>
          </ButtonGroup>
          <div className="d-flex align-items-center justify-content-end">
            <Button
              as={NavLink}
              to={`/tecnicalsheet/${deal_id}`}
              variant={isActive(`/tecnicalsheet/${deal_id}`)}
              style={{
                borderRadius: "8px !important",
                width: "150px",
                fontSize: "15px",
              }}
            >
              <FaClipboardList
                style={{ marginRight: "4px", fontSize: "16px" }}
                className="mb-1"
              />
              Ficha Técnica
            </Button>
            <Button
              as={NavLink}
              to={`/states/${deal_id}`}
              variant={isActive(`/states/${deal_id}`)}
              style={{
                borderRadius: "8px !important",
                width: "150px",
                fontSize: "15px",
              }}
              className="ms-3 me-3"
            >
              <FaHistory
                style={{ marginRight: "4px", fontSize: "18px" }}
                className="mb-1"
              />
              Historial
            </Button>
            <Button
              as={NavLink}
              to={`/comments/${deal_id}`}
              variant={isActive(`/comments/${deal_id}`)}
              className="navegation-buttons d-flex align-items-center justify-content-center"
              style={{
                borderRadius: "8px !important",
                width: "150px",
                fontSize: "15px",
              }}
            >
              <MdOutlineInsertComment
                style={{ marginRight: "4px", fontSize: "20px" }}
              />
              Mensajes
            </Button>
          </div>
        </div>
      ) : (
        // Vista cuando está en un dispositivo móvil
        <>
          <ButtonGroup className="button-group-container">
            <Button
              as={NavLink}
              to={`/deal/${deal_id}`}
              className="navegation-button-group"
              variant={isActive(`/deal/${deal_id}`)}
            >
              Información básica
            </Button>
            <Button
              as={NavLink}
              to={`/inspection/${deal_id}`}
              className="navegation-button-group"
              variant={isActive(`/inspection/${deal_id}`)}
            >
              Inspección
            </Button>
            <Button
              as={NavLink}
              to={`/admin-info/${deal_id}`}
              className="navegation-button-group"
              variant={isActive(`/admin-info/${deal_id}`)}
            >
              Información administrativa
            </Button>
            <Button
              as={NavLink}
              to={`/pre-take-insp/${deal_id}`}
              className="navegation-button-group"
              variant={isActive(`/pre-take-insp/${deal_id}`)}
            >
              Inspección Pre Toma
            </Button>
            <Button
              as={NavLink}
              to={`/take/${deal_id}`}
              className="navegation-button-group"
              variant={isActive(`/take/${deal_id}`)}
            >
              Toma
            </Button>
          </ButtonGroup>
          <div className="d-flex justify-content-between">
            <Button
              as={NavLink}
              to={`/tecnicalsheet/${deal_id}`}
              className="navegation-buttons-mobile"
              variant={isActive(`/tecnicalsheet/${deal_id}`)}
            >
              <FaClipboardList
                style={{ fontSize: "14px", marginRight: "6px" }}
              />
              Ficha Técnica
            </Button>
            <Button
              as={NavLink}
              to={`/states/${deal_id}`}
              className="navegation-buttons-mobile"
              variant={isActive(`/states/${deal_id}`)}
            >
              <FaHistory style={{ fontSize: "16px", marginRight: "6px" }} />
              Historial
            </Button>
            <Button
              as={NavLink}
              to={`/comments/${deal_id}`}
              className="navegation-buttons-mobile"
              variant={isActive(`/comments/${deal_id}`)}
            >
              <MdOutlineInsertComment
                style={{ fontSize: "18px", marginRight: "6px" }}
              />
              Mensajes
            </Button>
          </div>
        </>
      )}
    </>
  );
}
