import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Container,
  Form,
  Row,
  Col,
  Badge,
  Button,
} from "react-bootstrap";
import { MdClose, MdFilterList } from "react-icons/md";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Select from "react-select";
import customSelectStyles from "../../styles/customSelectStyles";
import {
  getBrands,
  getMinAndMaxPrices,
  getModels,
  getVersions,
} from "../../services/sevicesPricingView";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";

export default function PricingFilterButtonComponent({
  filterValues,
  setFilterValues,
  setCurrentPage,
}) {
  const [openFilters, setOpenFilters] = useState(false);
  const [years, setYears] = useState([]);
  const [rangeValues, setRangeValues] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [orderByPrices, setOrderByPrices] = useState("");
  const [orderByName, setOrderByName] = useState("");
  const [orderByYear, setOrderByYear] = useState("");
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [versions, setVersions] = useState([]);
  const [showRange, setShowRange] = useState();

  let isPageWide = useMediaQuery("(min-width: 460px)");

  useEffect(() => {
    async function fetchData() {
      try {
        const brandsData = await getBrands();
        const transformedBrands = brandsData.brands.map((brand) => ({
          value: brand.name,
          label: brand.name,
        }));
        setBrands(transformedBrands);
        if (selectedBrands.length === 1) {
          try {
            const data = await getModels(selectedBrands[0].value);
            setModels(data.models);
          } catch (error) {
            console.error("Error al obtener los modelos:", error);
          }
        } else {
          setModels([]);
        }
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    }
    fetchData();
  }, [selectedBrands]);

  useEffect(() => {
    async function fetchData() {
      try {
        const minAndMaxData = await getMinAndMaxPrices();
        const { min_price, max_price } = minAndMaxData;
        setRangeValues([parseFloat(min_price), parseFloat(max_price)]);
        const formattedMinPrice = parseFloat(min_price).toLocaleString("es-AR");
        const formattedMaxPrice = parseFloat(max_price).toLocaleString("es-AR");
        setShowRange([formattedMinPrice, formattedMaxPrice]);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    }
    fetchData();

    const generateYears = () => {
      const currentYear = new Date().getFullYear();
      const yearsArray = [];
      for (let year = 2000; year <= currentYear; year++) {
        yearsArray.push({ value: year.toString(), label: year.toString() });
      }
      return yearsArray;
    };
    setYears(generateYears());
  }, []);

  const handleBrandChange = async (selectedOptions) => {
    setSelectedBrands(selectedOptions);
    const selectedValues = selectedOptions
      .map((option) => option.value)
      .join(",");
    setFilterValues((prevState) => ({
      ...prevState,
      brand_filter: selectedValues,
    }));
  };

  const handleModelChange = async (selectedModel) => {
    setFilterValues((prevState) => ({
      ...prevState,
      model_filter: selectedModel || "",
    }));
    if (selectedModel) {
      try {
        const data = await getVersions(
          filterValues.brand_filter,
          selectedModel
        );
        setVersions(data.versions);
      } catch (error) {
        console.error("Error al obtener las versiones:", error);
      }
    } else {
      setVersions([]);
    }
  };

  const handleVersionChange = (selectedVersion) => {
    setFilterValues((prevState) => ({
      ...prevState,
      version_filter: selectedVersion ? selectedVersion : "",
    }));
  };

  const handleNameOrderChange = (selectedOrder) => {
    setOrderByName(selectedOrder);
    setFilterValues((prevState) => ({
      ...prevState,
      order_by_name: selectedOrder,
    }));
  };
  const handlePriceOrderChange = (selectedOrder) => {
    setOrderByPrices(selectedOrder);
    setFilterValues((prevState) => ({
      ...prevState,
      order_by_prices: selectedOrder,
    }));
  };
  const handleYearOrderChange = (selectedOrder) => {
    setOrderByYear(selectedOrder);
    setFilterValues((prevState) => ({
      ...prevState,
      order_by_year: selectedOrder,
    }));
  };
  const handleSliderChange = (values) => {
    const formattedMinPrice = parseFloat(values[0]).toLocaleString("es-AR");
    const formattedMaxPrice = parseFloat(values[1]).toLocaleString("es-AR");

    // Actualizar el estado showRange con el rango formateado como un array
    setShowRange([formattedMinPrice, formattedMaxPrice]);
    setFilterValues((prevState) => ({
      ...prevState,
      price_filter_min: values[0],
      price_filter_max: values[1],
    }));
  };

  const handleYearsToggle = (selectedOptions) => {
    setSelectedYears(selectedOptions);
    const selectedValues = selectedOptions
      .map((option) => option.value)
      .join(",");
    setFilterValues((prevState) => ({
      ...prevState,
      year_filter: selectedValues,
    }));
  };

  const stopperLabels = ["SI", "NO", "Hasta 80.000KMS", "Hasta 100.000KMS"];
  const categoryLabels = ["A", "B", "C", "D", "S", "X"];
  const sortingOptions = [
    { value: "asc", label: "Ascendente" },
    { value: "desc", label: "Descendente" },
  ];

  const handleStopperToggle = (stopper) => {
    console.log(stopper)
    setFilterValues((prevState) => ({
      ...prevState,
      stopper_filter: prevState.stopper_filter.includes(stopper)
        ? prevState.stopper_filter
          .split(",")
          .filter((item) => item !== stopper)
          .join(",")
        : (prevState.stopper_filter ? prevState.stopper_filter + "," : "") +
        stopper,
    }));
  };
  const handleCategoryToggle = (category) => {
    setFilterValues((prevState) => ({
      ...prevState,
      category_filter: prevState.category_filter.includes(category)
        ? prevState.category_filter
          .split(",")
          .filter((item) => item !== category)
          .join(",")
        : (prevState.category_filter ? prevState.category_filter + "," : "") +
        category,
    }));
  };

  const handleFilterBadgeRemove = (filterType, itemToRemove) => {
    if (filterType === "brand_filter") {
      setSelectedBrands((prevSelectedBrands) =>
        prevSelectedBrands.filter((brand) => brand.value !== itemToRemove)
      );
    }
    if (filterType.startsWith("order_by_")) {
      const orderType = filterType.replace("order_by_", "");
      switch (orderType) {
        case "prices":
          setOrderByPrices("");
          break;
        case "name":
          setOrderByName("");
          break;
        case "year":
          setOrderByYear("");
          break;
        default:
          break;
      }
      setFilterValues((prevState) => ({
        ...prevState,
        [filterType]: "",
      }));
    } else if (filterType === "price_filter") {
      setFilterValues((prevState) => ({
        ...prevState,
        price_filter_min: 0,
        price_filter_max: 400000,
      }));
    } else {
      setFilterValues((prevState) => ({
        ...prevState,
        [filterType]: prevState[filterType]
          .split(",")
          .filter((item) => item !== itemToRemove)
          .join(","),
      }));
    }
  };

  function clearFilters() {
    setFilterValues({
      stopper_filter: "",
      category_filter: "",
      brand_filter: "",
      year_filter: "",
      model_filter: "",
      version_filter: "",
      price_filter_min: 0,
      price_filter_max: 600000,
      order_by_prices: "",
      order_by_name: "",
      order_by_year: "",
    });
    setSelectedBrands([]);
    setSelectedYears([]);
    setOrderByName("");
    setOrderByPrices("");
    setOrderByYear("");
    setCurrentPage(1);
    setOpenFilters(false);
  }

  return (
    <Dropdown
      show={openFilters}
      onToggle={(isOpen) => setOpenFilters(isOpen)}
      autoClose="outside"
    >
      <Dropdown.Toggle id="dropdown-basic">
        <MdFilterList className="filter-icon-button" fontSize={18} />
        Filtro
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          width: isPageWide ? "40vw" : "100vw",
          padding: "1rem",
          zIndex: "1",
          boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.20)",
        }}
        className="mt-2"
      >
        <Container className="mb-2 mt-2">
          <p className="tc-ref-text">Filtros aplicados:</p>
          <div className="d-inline-flex flex-wrap">
            {Object.entries(filterValues).map(([filterType, filterValue]) => {
              if (Array.isArray(filterValue) && filterValue.length > 0) {
                // Renderizar badges para arrays no vacíos
                return filterValue.map((item, index) => (
                  <Badge
                    key={`${filterType}-${index}`}
                    className="custom-badge me-2 mb-3 d-flex align-items-center fw-normal"
                    onClick={() => handleFilterBadgeRemove(filterType, item)}
                  >
                    {filterType === "year_filter" ? `Año ${item}` : item}
                    <MdClose
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        cursor: "pointer",
                        marginLeft: "3px",
                      }}
                    />
                  </Badge>
                ));
              } else if (filterType === "price_filter_min") {
                // Renderizar badge para el rango de precio
                return (
                  <Badge
                    key={`price-range`}
                    className="custom-badge me-2 mb-3 d-flex align-items-center fw-normal"
                  >
                    {`Precio: $${filterValues.price_filter_min} - $${filterValues.price_filter_max}`}
                    <MdClose
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        cursor: "pointer",
                        marginLeft: "3px",
                      }}
                    />
                  </Badge>
                );
              } else if (
                filterType.startsWith("order_by_") &&
                filterValue !== ""
              ) {
                // Renderizar badges para ordenar
                let orderType = filterType.replace("order_by_", "");
                if (orderType === "prices") {
                  orderType = "precio";
                } else if (orderType === "name") {
                  orderType = "nombre";
                } else if (orderType === "year") {
                  orderType = "año";
                }
                return (
                  <Badge
                    key={filterType}
                    className="custom-badge me-2 mb-3 d-flex align-items-center fw-normal"
                    onClick={() => handleFilterBadgeRemove(filterType)}
                  >
                    {`Ordenar ${orderType} ${filterValue}`}
                    <MdClose
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        cursor: "pointer",
                        marginLeft: "3px",
                      }}
                    />
                  </Badge>
                );
              } else if (
                typeof filterValue === "string" &&
                filterValue.trim() !== ""
              ) {
                // Renderizar badges para valores de cadena no vacíos
                return filterValue.split(",").map((item, index) => (
                  <Badge
                    key={`${filterType}-${index}`}
                    className="custom-badge me-2 mb-3 d-flex align-items-center fw-normal"
                    onClick={() => handleFilterBadgeRemove(filterType, item)}
                  >
                    {item.trim()}
                    <MdClose
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        cursor: "pointer",
                        marginLeft: "3px",
                      }}
                    />
                  </Badge>
                ));
              } else {
                return null;
              }
            })}
          </div>
          <div className="divider" />
        </Container>
        <Container className="mb-2 mt-2">
          <p className="tc-ref-text me-3">Filtrar por stopper</p>
          <div className="d-flex ">
            {stopperLabels.map((stopper, index) => (
              <div className="w-100" key={stopper}>
                <Form.Check
                  type="checkbox"
                  id={`stopper-checkbox-${stopper}-${index}`}
                  label={stopper}
                  checked={filterValues.stopper_filter.includes(stopper)}
                  onChange={() => handleStopperToggle(stopper)}
                />
              </div>
            ))}
          </div>
        </Container>
        <Container className="mb-2 mt-2 ">
          <p className="tc-ref-text">Filtrar por categoria</p>
          <div className=" d-flex flex-row mb-2">
            {categoryLabels.map((category, index) => (
              <div className="w-100" key={category}>
                <Form.Check
                  type="checkbox"
                  id={`stopper-checkbox-${category}-${index}`}
                  label={category}
                  checked={filterValues.category_filter.includes(category)}
                  onChange={() => handleCategoryToggle(category)}
                />
              </div>
            ))}
          </div>
          <div className="divider" />
        </Container>
        <Row>
          <Col xs={12} lg={6} className="mb-2 mt-1">
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text">Rango de precio USD:</p>
              <div style={{ padding: "4px 16px" }}>
                {showRange ? `$ ${showRange[0]} - $ ${showRange[1]}` : ""}
                <Slider
                  className="slider-range"
                  min={rangeValues[0]}
                  max={rangeValues[1]}
                  step={1000}
                  range
                  value={[
                    filterValues.price_filter_min,
                    filterValues.price_filter_max,
                  ]}
                  onChange={handleSliderChange}
                />
              </div>
            </Container>
          </Col>
          <Col xs={12} lg={6} className="mb-2 mt-1">
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text mb-4">Ordenar por precio</p>
              {sortingOptions.map((option) => (
                <Form.Check
                  key={option.value}
                  inline
                  label={option.label}
                  type="radio"
                  id={`sort${option.value}`}
                  name={`sortingPrices_${option.value}`}
                  value={option.value}
                  checked={orderByPrices === option.value}
                  onChange={() => handlePriceOrderChange(option.value)}
                  disabled={orderByName || orderByYear}
                />
              ))}
            </Container>
          </Col>
        </Row>
        <div className="divider" />
        <Row className="mt-2 ">
          <Col xs={12} lg={6}>
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text">Filtro de Marca</p>
              <Select
                styles={customSelectStyles}
                isMulti
                value={selectedBrands}
                onChange={handleBrandChange}
                options={brands}
                placeholder="Seleccione"
              />
            </Container>
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text">Filtro de modelo</p>
              <Form.Select
                className="filter-select"
                value={filterValues.model_filter || ""}
                onChange={(e) =>
                  handleModelChange(e.target.selectedOptions[0].text)
                }
                disabled={
                  !filterValues.brand_filter || selectedBrands.length > 1
                }
              >
                <option value="">
                  {!filterValues.model_filter
                    ? "Seleccione"
                    : filterValues.model_filter}
                </option>
                {models?.map((model, i) => (
                  <option key={i} value={model.id}>
                    {model.name}
                  </option>
                ))}
              </Form.Select>
            </Container>

            <Container className="mb-2 mt-2">
              <p className="tc-ref-text">Filtro de versión</p>
              <Form.Select
                className="filter-select"
                value={filterValues.version_filter || ""}
                onChange={(e) =>
                  handleVersionChange(e.target.selectedOptions[0].text)
                }
                disabled={
                  !filterValues.model_filter || selectedBrands.length > 1
                }
              >
                <option value="">
                  {!filterValues.version_filter
                    ? "Seleccione"
                    : filterValues.version_filter}
                </option>
                {versions?.map((version, i) => (
                  <option key={i} value={version.id}>
                    {version.name}
                  </option>
                ))}
              </Form.Select>
            </Container>
            <Container className="mb-2 mt-3">
              <p className="tc-ref-text mb-2">
                Ordenar por marca, modelo y versión
              </p>
              {sortingOptions.map((option) => (
                <Form.Check
                  key={option.value}
                  inline
                  label={option.label}
                  type="radio"
                  id={`sort${option.value}`}
                  name={`sortingNames_${option.value}`}
                  value={option.value}
                  checked={orderByName === option.value}
                  onChange={() => handleNameOrderChange(option.value)}
                  disabled={orderByPrices || orderByYear}
                />
              ))}
            </Container>
          </Col>
          <Col xs={12} lg={6}>
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text ">Filtro de Año</p>
              <Select
                styles={customSelectStyles}
                isMulti
                value={selectedYears}
                onChange={handleYearsToggle}
                options={years}
                placeholder="Seleccione"
              />
            </Container>
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text mb-2">Ordenar por años</p>
              {sortingOptions.map((option) => (
                <Form.Check
                  key={option.value}
                  inline
                  label={option.label}
                  type="radio"
                  id={`sort${option.value}`}
                  name={`sortingYears_${option.value}`}
                  value={option.value}
                  checked={orderByYear === option.value}
                  onChange={() => handleYearOrderChange(option.value)}
                  disabled={orderByPrices || orderByName}
                />
              ))}
            </Container>
            <Button
              variant="outline-primary"
              onClick={() => clearFilters(setFilterValues)}
              className="float-end w-100 mt-2"
            >
              Limpiar filtros
            </Button>
          </Col>
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
}
