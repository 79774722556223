import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { teams } from "../../../../constants/teams";
import shortSnapshotFirebase from "../../../../utils/ShortSnapshotFirebase";
import { formatNumber } from "../../../Pricing/services/servicesPricing";
import { colors } from "../../../../styles/colors";

export default function ReportsComponent({
  disabled,
  register,
  dataIformes,
  user,
  deal_id,
  setValue,
  textStyle,
  valueMunicipalizado,
  valueRadicacion,
}) {
  const [containReports, setcontainReports] = useState(null);
  const [valueInfraccionesCABA, setvalueInfraccionesCABA] = useState("");
  const [valueInfraccionesGBA, setvalueInfraccionesGBA] = useState("");
  const [valuePatentes, setvaluePatentes] = useState("");
  const [valueProporcionalPatentes, setvalueProporcionalPatentes] =
    useState("");
  const [totalValueInfracciones, setTotalValueInfracciones] = useState("");

  const disableStyleAs = {
    color: colors.neutral.darkTwo,
    backgroundColor: colors.gray[50],
  };

  const enableStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "500",
  };

  useEffect(() => {
    const callbackActions = (data) => {
      setcontainReports(data.actions.actions);
    };
    const unsubscribeActions = shortSnapshotFirebase(
      user?.company,
      "deals",
      deal_id,
      callbackActions
    );
    return () => {
      unsubscribeActions();
    };
  }, [deal_id, user?.company]);

  useEffect(() => {
    setValue("patentes", dataIformes?.patentes);
    setvaluePatentes(dataIformes?.patente);
    setValue("proporcional_patentes", dataIformes?.proporcional_patentes);
    setvalueProporcionalPatentes(dataIformes?.proporcional_patentes);
    setValue("check_vtv", dataIformes?.check_vtv);
    setValue("check_vpa", dataIformes?.check_vpa);
    setValue("grabado_cristales", dataIformes?.grabado_cristales);
    setValue("grabado_autopartes", dataIformes?.grabado_autopartes);
    setValue("otras_infracciones", dataIformes?.otras_infracciones);
    setValue(
      "total_otras_infracciones",
      dataIformes?.total_otras_infracciones
        ? dataIformes?.total_otras_infracciones
        : "0"
    );
    setTotalValueInfracciones(
      dataIformes?.total_otras_infracciones
        ? dataIformes?.total_otras_infracciones
        : "0"
    );
    setValue(
      "infracciones_caba",
      dataIformes?.infracciones_caba ? dataIformes?.infracciones_caba : "0"
    );
    setvalueInfraccionesCABA(
      dataIformes?.infracciones_caba ? dataIformes?.infracciones_caba : "0"
    );
    setValue(
      "infracciones_gba",
      dataIformes?.infracciones_gba ? dataIformes?.infracciones_gba : "0"
    );
    setvalueInfraccionesGBA(
      dataIformes?.infracciones_gba ? dataIformes?.infracciones_gba : "0"
    );
  }, [dataIformes, setValue]);

  const handleInputChangeInfraccionesCABA = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setvalueInfraccionesCABA(formattedValue);
  };

  const handleInputChangeInfraccionesGBA = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setvalueInfraccionesGBA(formattedValue);
  };

  const handleInputChangePatentes = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setvaluePatentes(formattedValue);
  };

  const handleInputChangeProporcionalPatentes = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setvalueProporcionalPatentes(formattedValue);
  };

  const handleInputChangeTotalInfracciones = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setTotalValueInfracciones(formattedValue);
  };

  return (
    <div>
      <p style={textStyle}>Informes</p>
      <Row>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Infracciones CABA</label>
            <input
              disabled={disabled}
              className="input-deal"
              style={{ width: "100%" }}
              {...register("infracciones_caba")}
              value={valueInfraccionesCABA}
              onChange={handleInputChangeInfraccionesCABA}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Infracciones GBA</label>
            <input
              disabled={disabled}
              className="input-deal"
              style={{ width: "100%" }}
              {...register("infracciones_gba")}
              value={valueInfraccionesGBA}
              onChange={handleInputChangeInfraccionesGBA}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Otras infracciones</label>
            <input
              disabled={disabled}
              className="input-deal"
              type="text"
              style={{ width: "100%" }}
              {...register("otras_infracciones")}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Total otras infracciones</label>
            <input
              disabled={disabled}
              className="input-deal"
              style={{ width: "100%" }}
              {...register("total_otras_infracciones")}
              value={totalValueInfracciones}
              onChange={handleInputChangeTotalInfracciones}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Patentes</label>
            <input
              disabled={disabled || valueMunicipalizado === "Si" ? true : false}
              className="input-deal"
              style={{ width: "100%" }}
              {...register("patentes")}
              value={valuePatentes}
              onChange={handleInputChangePatentes}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div style={{ marginBottom: "12px", marginTop: "8px" }}>
            <label className="label-input">Proporcional patentes</label>
            {user?.team === teams.ADMIN ? (
              <input
                disabled={!disabled ? false : true}
                className="input-deal"
                {...register("proporcional_patentes")}
                value={valueProporcionalPatentes}
                onChange={handleInputChangeProporcionalPatentes}
                style={user?.team === teams.AS ? disableStyleAs : enableStyle}
              />
            ) : !disabled &&
              user?.team === teams.BACKOFFICE &&
              valueMunicipalizado !== "Si" &&
              (valueRadicacion === "CABA" ||
                valueRadicacion === "GBA" ||
                valueRadicacion === "") ? (
              <>
                <input
                  disabled={false}
                  className="input-deal"
                  {...register("proporcional_patentes")}
                  value={valueProporcionalPatentes}
                  onChange={handleInputChangeProporcionalPatentes}
                  style={user?.team === teams.AS ? disableStyleAs : enableStyle}
                />
              </>
            ) : !disabled &&
              user?.team === teams.BACKOFFICE &&
              (valueMunicipalizado === "No" || valueMunicipalizado === "") &&
              (valueRadicacion !== "CABA" || valueRadicacion !== "GBA") ? (
              <>
                <input
                  disabled={true}
                  className="input-deal"
                  {...register("proporcional_patentes")}
                  value={valueProporcionalPatentes}
                  onChange={handleInputChangeProporcionalPatentes}
                  style={user?.team === teams.AS ? disableStyleAs : enableStyle}
                />
              </>
            ) : (
              <>
                <input
                  disabled={true}
                  className="input-deal"
                  {...register("proporcional_patentes")}
                  value={valueProporcionalPatentes}
                  onChange={handleInputChangeProporcionalPatentes}
                  style={user?.team === teams.AS ? disableStyleAs : enableStyle}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">Check VTV</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("check_vtv")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">Check VPA</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("check_vpa")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">Grabado de cristales</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("grabado_cristales")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">Grabado de autopartes</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("grabado_autopartes")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}></Col>
      </Row>
    </div>
  );
}
