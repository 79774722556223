import React, { useState } from "react";
import { MdCheck, MdClose } from "react-icons/md";
import { colors } from "./../../../styles/colors";

const TechnicalSheetCardComponent = ({ title, data }) => {
  const [activeSeeMore, setactiveSeeMore] = useState();

  const seeMore = () => {
    setactiveSeeMore(!activeSeeMore);
  };

  return (
    <div className="technical-sheet-card">
      <p className="technical-card-titlel">{title}</p>
      {activeSeeMore
        ? data?.map((info) => (
            <div
              key={info.id}
              className="animate__fadeInDown"
              style={{ animationDuration: "1s" }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <p className="technical-card-description">{info.description}</p>
                {title === "Datos técnicos" ||
                title === "Motor y transmisión" ? (
                  <p className="technical-card-value">
                    {info.value_description !== undefined
                      ? info.value_description
                      : info.value === false
                      ? "NO"
                      : info.value === true
                      ? "SI"
                      : info.value}
                  </p>
                ) : (
                  <p className="technical-card-value">
                    {info.value === false || info.value === "NO" ? (
                      <MdClose
                        fontSize={18}
                        color={colors.primary.secondaryTwo}
                      />
                    ) : info.value === true || info.value === "SI" ? (
                      <MdCheck fontSize={18} color={colors.primary.primary} />
                    ) : (
                      info.value_description
                    )}
                  </p>
                )}
              </div>
              <div className="technical-card-divider"></div>
            </div>
          ))
        : data?.slice(0, 5).map((info) => (
            <div
              key={info.id}
              className="animate__fadeInUp"
              style={{ animationDuration: "1s" }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <p className="technical-card-description">{info.description}</p>
                {title === "Datos técnicos" ||
                title === "Motor y transmisión" ? (
                  <p className="technical-card-value">
                    {info.value_description !== undefined
                      ? info.value_description
                      : info.value === false
                      ? "NO"
                      : info.value === true
                      ? "SI"
                      : info.value}
                  </p>
                ) : (
                  <p className="technical-card-value">
                    {info.value === false || info.value === "NO" ? (
                      <MdClose
                        fontSize={13}
                        color={colors.primary.secondaryTwo}
                      />
                    ) : info.value === true || info.value === "SI" ? (
                      <MdCheck fontSize={13} color={colors.primary.primary} />
                    ) : (
                      info.value_description
                    )}
                  </p>
                )}
              </div>
              <div className="technical-card-divider"></div>
            </div>
          ))}

      <button className="technical-card-button" onClick={seeMore}>
        {activeSeeMore ? "Ver menos" : "Ver más"}
      </button>
    </div>
  );
};

export default TechnicalSheetCardComponent;
