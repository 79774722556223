import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { MdCancel, MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useUser } from "../../../../context/UserContext";
import PaymentTabComponent from "./PaymentTabComponent";
import AttachmentTabComponent from "./AttachmentTabComponent";
import AmountToPayTabComponent from "./AmountToPayTabComponent";
import DocumentationTabComponent from "./DocumentationTabComponent";
import HolderTabComponent from "./HolderTabComponent";
import { colors } from "../../../../styles/colors";
import {
  ServerErrorComponent,
  SpinnerFullScreenComponent,
} from "../../../../components";
import useFirebaseOnSnapshot from "../../../../hooks/useFirebaseOnSnapshot";
import { BenefitsTabComponent } from "./BenefitsTabComponent";

export default function TakeTabsComponent() {
  const [isEditing, setisEditing] = React.useState(true);
  const [takeData, setTakeData] = React.useState([]);
  const { deal_id } = useParams();
  const { user } = useUser();
  const { data, loading, error } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "take"
  );

  const toggleEditing = React.useCallback(() => {
    setisEditing((isEditing) => !isEditing);
  }, []);

  useEffect(() => {
    setTakeData(data);
  }, [data]);

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "12px",
  };

  return (
    <>
      {loading ? (
        <SpinnerFullScreenComponent />
      ) : (
        <>
          {error ? (
            <ServerErrorComponent error={error} />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p className="deal-title">Resultados</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "30px", height: "30px" }}>
                    {isEditing ? (
                      <MdEdit
                        onClick={toggleEditing}
                        style={{
                          fontSize: "26px",
                          color: colors.primary.primary,
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <MdCancel
                        onClick={toggleEditing}
                        style={{
                          fontSize: "26px",
                          color: colors.status.red,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Tabs
                defaultActiveKey="1"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="1" title="Monto a pagar">
                  <AmountToPayTabComponent
                    disabled={isEditing}
                    takeData={takeData}
                    deal_id={deal_id}
                    toggleEditing={toggleEditing}
                  />
                </Tab>
                <Tab eventKey="2" title="Documentacion entregada">
                  <DocumentationTabComponent
                    disabled={isEditing}
                    takeData={takeData?.documentation}
                    user={user}
                    deal_id={deal_id}
                    toggleEditing={toggleEditing}
                    textStyle={textStyle}
                  />
                </Tab>
                <Tab eventKey="3" title="Titulares">
                  <HolderTabComponent
                    disabled={isEditing}
                    takeData={takeData}
                    setTakeData={setTakeData}
                    deal_id={deal_id}
                    toggleEditing={toggleEditing}
                    textStyle={textStyle}
                  />
                </Tab>
                <Tab eventKey="4" title="Pago">
                  <PaymentTabComponent
                    disabled={isEditing}
                    takeData={takeData}
                    setTakeData={setTakeData}
                    deal_id={deal_id}
                    toggleEditing={toggleEditing}
                    textStyle={textStyle}
                  />
                </Tab>
                <Tab eventKey="5" title="Adjuntos">
                  <AttachmentTabComponent
                    disabled={isEditing}
                    takeData={takeData}
                    setTakeData={setTakeData}
                    toggleEditing={toggleEditing}
                    textStyle={textStyle}
                  />
                </Tab>
                {/* <Tab eventKey="6" title="Beneficios">
                  <BenefitsTabComponent
                    disabled={isEditing}
                    takeData={takeData}
                    deal_id={deal_id}
                    toggleEditing={toggleEditing}
                  />
                </Tab> */}
              </Tabs>
            </>
          )}
        </>
      )}
    </>
  );
}
