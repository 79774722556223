import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { deleteUser } from '../../services/usersConfigServices';
import toast from 'react-hot-toast';
import { colors } from '../../../../styles/colors';

export default function DeleteUserModalComponent({ show, onHide, user, fetchDataUsers }) {
  const handleDeleteUser = async () => {
    try {
      await deleteUser({ user_id: user.user_id, company: 'san_jorge' });
      toast.success("Usuario eliminado con exito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
      onHide();
      fetchDataUsers()
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
      toast.error('Error al guardar los datos. ' + error, {
        style: {
          color: colors.text.primary,
          background: colors.error.dark,
        },
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered size=''>
      <div className='p-4 pb-2'>
        <p className='add-card-title-modal text-center'>
          ¿Estás seguro de que quieres eliminar este usuario?</p>
        <p className='add-card-text-body-modal text-center user-delete'>
          {user?.name}
        </p>
      </div>
      <Modal.Footer style={{ borderTop: 'none' }} className="d-flex justify-content-between">
        <Button variant="outline-primary" onClick={onHide} style={{ flex: '1' }}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleDeleteUser} style={{ flex: '1' }}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}