import React, { useRef, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import '../styles/customAudioPlayer.css';
import { Button } from 'react-bootstrap';

const AudioPlayerComponent = ({ url }) => {
  const player = useRef(null);
  const [selectedRate, setSelectedRate] = useState(1); // Estado para la tasa de reproducción seleccionada

  const rates = [1, 1.25, 1.5, 1.75, 2]; // Las velocidades de reproducción disponibles

  const changePlaybackRate = () => {
    const currentIndex = rates.indexOf(selectedRate);
    const nextIndex = (currentIndex + 1) % rates.length;
    const nextRate = rates[nextIndex];
    if (player.current) {
      player.current.audio.current.playbackRate = nextRate;
      setSelectedRate(nextRate); // Actualiza el estado con la tasa seleccionada
    }
  };

  return (
    <div className='w-100 d-flex align-items-center'>
      <AudioPlayer
        ref={player}
        src={url}
        onPlay={() => console.log("Playing")}
        customAdditionalControls={[]} // Remueve los controles adicionales
        customVolumeControls={[]} // Remueve los controles de volumen
        showJumpControls={false} // Remueve los controles de salto
        layout="horizontal-reverse" // Cambia la disposición para imitar WhatsApp
      />

      <Button
        style={{ borderRadius: '30px', height: '40px', width: '45px', padding: '0', marginLeft: '10px', fontSize: '14px' }}
        variant='primary'
        onClick={changePlaybackRate}
      >
        {selectedRate}x
      </Button>

    </div>
  );
};

export default AudioPlayerComponent;