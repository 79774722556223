import { doc, updateDoc, collection } from 'firebase/firestore';
import { db } from "../../../firebase/config";

export const requestSheet = async (company, deal_id, data) => {
  try {
    const docRef = doc(collection(db, 'companies', company, 'deals', deal_id, 'data'), 'actions');
    await updateDoc(docRef, data);
    return 'Actualización exitosa';
  } catch (error) {
    return 'Error al actualizar: ' + error.message;
  }
};