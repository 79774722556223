import React, { useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import PricingViewTableComponent from '../components/pricingViews/PricingViewTableComponent';
import PricingDiscountsComponent from '../components/pricingViews/PricingDiscountsComponent';
import PricingModifyBaseComponent from '../components/pricingViews/PricingModifyBaseComponent';

export default function PricingViewPage() {

  let isPageWide = useMediaQuery("(min-width: 460px)");
  const [activeView, setActiveView] = useState('table');

  const handleButtonClick = (view) => {
    setActiveView(view);
  };

  return (
    <div className="p-3 h-100">
      {isPageWide ? (

        <div className='d-flex mb-2'>
          <h1 className='view-title-page pe-4'>Pricing</h1>
          <ButtonGroup className="button-group-container" >
            <Button
              className="navegation-button-group"
              variant={activeView === 'table' ? 'primary' : 'outline-primary'}
              onClick={() => handleButtonClick('table')}
            >
              Tabla de autos
            </Button>
            <Button

              className="navegation-button-group"
              variant={activeView === 'discounts' ? 'primary' : 'outline-primary'}
              onClick={() => handleButtonClick('discounts')}
            >
              Descuentos
            </Button>
            <Button
              className="navegation-button-group"
              variant={activeView === 'modifyBase' ? 'primary' : 'outline-primary'}
              onClick={() => handleButtonClick('modifyBase')}
            >
              Modificar base
            </Button>
          </ButtonGroup>
        </div>
      ) : (
        <div className='d-flex justify-content-between align-items-center  '>
          <h1 className='view-title-page pe-2 mb-2'>Pricing</h1>
          <ButtonGroup className="button-group-container" >
            <Button
              className="navegation-button-group"
              variant={activeView === 'table' ? 'primary' : 'outline-primary'}
              onClick={() => handleButtonClick('table')}
            >
              Tabla de autos
            </Button>
            <Button

              className="navegation-button-group"
              variant={activeView === 'discounts' ? 'primary' : 'outline-primary'}
              onClick={() => handleButtonClick('discounts')}
            >
              Descuentos
            </Button>
            <Button
              className="navegation-button-group"
              variant={activeView === 'modifyBase' ? 'primary' : 'outline-primary'}
              onClick={() => handleButtonClick('modifyBase')}
            >
              Modificar base
            </Button>
          </ButtonGroup>
        </div>
      )}
      {activeView === 'table' && <PricingViewTableComponent />}
      {activeView === 'discounts' && <PricingDiscountsComponent />}
      {activeView === 'modifyBase' && <PricingModifyBaseComponent />}
    </div>
  )
}
