import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { updateSalePrice } from "../../../../States/services/servicesStates";
import { colors } from "../../../../../styles/colors";
import { useState } from "react";
import { formatNumber } from "../../../services/servicesPricing";

const SalePricePricerModal = ({
  deal_id,
  setShowSalePrice,
  showSalePrice,
  user,
  setActiveModalPricing,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [salePriceValue, setsalePriceValue] = useState("");

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);

    setsalePriceValue(formattedValue);
  };

  const sendPricingRangeInitial = (dataInput) => {
    const hoy = new Date();
    const devolucion = new Date();
    devolucion.setDate(hoy.getDate() + parseInt(dataInput.value_days));

    const data = {
      "sale_price.value_price": dataInput.value_price,
      "sale_price.value_days": dataInput.value_days,
      "sale_price.user": user,
    };
    updateSalePrice(user?.company, deal_id, data);
    setShowSalePrice(false);
    setActiveModalPricing(false);
  };

  const handleCloseRangeInitial = () => {
    setShowSalePrice(false);
    setActiveModalPricing(false);
  };

  return (
    <div>
      <Modal
        show={showSalePrice}
        onHide={handleCloseRangeInitial}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <form
              onSubmit={handleSubmit(sendPricingRangeInitial)}
              style={{ width: "100%" }}
            >
              <div className="d-flex align-items-center mt-2 mb-1">
                <div>
                  <p
                    style={{
                      margin: "0",
                      color: colors.neutral.lightTwo,
                      fontSize: "14px",
                    }}
                    className="mb-2"
                  >
                    Precio de venta
                  </p>
                  <input
                    className="inputModal"
                    placeholder="$000.000.000"
                    type="text"
                    {...register("value_price", {
                      required: "El precio de venta es requerido",
                    })}
                    style={{ width: "135px" }}
                    value={salePriceValue}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="ms-2">
                  <p
                    style={{
                      margin: "0",
                      color: colors.neutral.lightTwo,
                      fontSize: "14px",
                    }}
                    className="mb-2"
                  >
                    Días para la venta
                  </p>
                  <Form.Select
                    className="select-order"
                    {...register("value_days")}
                    style={{
                      width: "135px",
                      height: "40px",
                      padding: "10px",
                      border: "2px solid #E1E7EF",
                    }}
                  >
                    <option>7</option>
                    <option>14</option>
                    <option>21</option>
                    <option>30</option>
                    <option>45</option>
                    <option>60</option>
                  </Form.Select>
                </div>
              </div>
              {errors.value_price && (
                <p
                  style={{
                    margin: "0px",
                    color: colors.status.red,
                    fontSize: "12px",
                  }}
                  className="mt-2"
                >
                  {errors.value_price?.message}
                </p>
              )}
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center flex-column justify-content-between"
              >
                <button
                  variant="primary"
                  className="btnSendPricing mb-2 mt-2"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </form>
            <button
              variant="secondary"
              onClick={handleCloseRangeInitial}
              className="btnCancelarModal"
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SalePricePricerModal;
