import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";

export const updateActionDone = async (company, deal_id, data) => {
  const actionsDocRef = doc(db, "companies", company, "deals", deal_id, "data", "actions");
  try {
    await updateDoc(actionsDocRef, data);
  } catch (error) {
    console.error("Error updating action done: ", error);
  }
};

export const updateSalePrice = async (company, deal_id, data) => {
  const pricingDocRef = doc(db, "companies", company, "deals", deal_id, "data", "pricing");
  try {
    await updateDoc(pricingDocRef, data);
  } catch (error) {
    console.error("Error updating sale price: ", error);
  }
};

export const actionReminder = async (company, deal_id, data) => {
  const actionsDocRef = doc(db, "companies", company, "deals", deal_id, "data", "actions");
  try {
    await updateDoc(actionsDocRef, data);
  } catch (error) {
    console.error("Error updating action reminder: ", error);
  }
};

export const addActionRequested = async (company, deal_id, data) => {
  const actionsDocRef = doc(db, "companies", company, "deals", deal_id, "data", "actions");
  try {
    await updateDoc(actionsDocRef, data);
  } catch (error) {
    console.error("Error adding action requested: ", error);
  }
};
