import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { MdSms } from "react-icons/md";
import { ImCross } from "react-icons/im";
import EmptyStateComponent from "../../../components/EmptyStateComponent";
import { updateActionDone } from "../services/servicesStates";
import CommentModalComponent from "./CommentModalComponent";
import { BiBellPlus } from "react-icons/bi";
import "../../../styles/style/tableStyle.css";
import ReminderModalComponent from "./ReminderModalComponent";
import { colors } from "../../../styles/colors";
import { useUser } from "../../../context/UserContext";
import ExpiredIcon from "../images/expired-action-icon.svg";
import useFirebaseOnSnapshot from "../../../hooks/useFirebaseOnSnapshot";

export default function ActionsComponent({ dataActions, deal_id }) {
  const [modal, setModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const { user } = useUser();
  const [showModalRevision, setshowModalRevision] = useState();
  const [dataArrayActions, setdataArrayActions] = useState();
  const [nameActions, setnameActions] = useState();
  const [modalReminder, setmodalReminder] = useState(false);
  const [idActions, setidActions] = useState();
  const [closeComment, setCloseComment] = useState('')
  const { register, handleSubmit, reset } = useForm();
  const { data, loading, error } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "basics"
  );

  const handleRevisionModal = () => {
    setshowModalRevision(false);
  };

  const actionDone = (action_id, actionName, id) => {
    setshowModalRevision(true);
    setdataArrayActions(action_id);
    setnameActions(actionName);
    setidActions(id);
  };

  const endAction = (dataComment, status) => {
    const data = {
      "action_status.action_id": dataArrayActions,
      "action_status.id": idActions,
      "action_status.user": user,
      "action_status.comments": dataComment.comments,
      "action_status.date": new Date(),
      "action_status.status": status,
    };
    updateActionDone(user?.company, deal_id, data);
    reset();
    setshowModalRevision(false);
  };

  useEffect(() => {
    setCloseComment(data?.car?.closed_desc)
  }, [data])
    ;
  return (
    <div
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      {dataActions?.length > 0 ? (
        <Table bordered responsive>
          <thead>
            <tr>
              <th className="table-header">Fecha</th>
              <th className="table-header">Team</th>
              <th className="table-header">Acción</th>
              <th className="table-header">Comentario</th>
              <th className="table-header">Estado</th>
              <th className="table-header">Comentario de cierre</th>
              <th className="table-header">Solicitante</th>
              <th className="table-header">Completado por</th>
            </tr>
          </thead>
          <tbody>
            {dataActions?.map((info) => (
              <tr key={info.id}>
                <td className="table-body">
                  {new Date(info.date.seconds * 1000).toLocaleDateString()}
                </td>
                <td className="table-body">{info.team}</td>
                <td className="table-body">{info.action}</td>
                <td>
                  <div
                    className="icon-container-table"
                    style={{
                      backgroundColor:
                        info.comment !== ""
                          ? colors.neutral.lightTwo
                          : colors.neutral.greenLight,
                    }}
                  >
                    <MdSms
                      onClick={() => {
                        if (info.comment !== "") {
                          setModal(true);
                          setDataModal(info.comment);
                        }
                      }}
                      style={{
                        color: "white",
                        cursor: info.comment !== "" ? "pointer" : "",
                      }}
                      fontSize="14px"
                    />
                  </div>
                </td>
                <td>
                  <form
                    onClick={
                      info?.status === "done"
                        ? null
                        : info?.status === "open"
                          ? () => actionDone(info.action_id, info.action, info.id)
                          : null
                    }
                  >
                    {info.status === "cancelled" ? (
                      <ImCross color={colors.status.red} fontSize="14px" />
                    ) : info.status === "expired" ? (
                      <img
                        src={ExpiredIcon}
                        alt="expired-icon"
                        style={{ marginLeft: "-3px" }}
                      />
                    ) : (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="check"
                        disabled={info.status === "done" ? true : false}
                        checked={info.status === "done" ? true : false}
                        onChange={() => { }}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginTop: "0px",
                          color: colors.status.blue,
                        }}
                      />
                    )}
                  </form>
                </td>
                <td>
                  <div
                    className="icon-container-table"
                    style={{
                      backgroundColor:
                        info.completed_comment !== "" || info.action_id === "close"
                          ? colors.neutral.lightTwo
                          : colors.neutral.greenLight,
                    }}
                  >
                    <MdSms
                      onClick={() => {
                        if (info.completed_comment !== "" || info.action_id === "close") {
                          setModal(true);
                          const modalContent = info.action_id === "close" ? closeComment : info.completed_comment;
                          setDataModal(modalContent);
                        }
                      }}
                      style={{
                        color: "white",
                        cursor: info.completed_comment !== "" || info.action_id === "close" ? "pointer" : "",
                      }}
                      fontSize="14px"
                    />
                    <div />
                  </div>
                </td>
                <td className="table-body">{info.request_by}</td>
                <td className="table-body">{info.user}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyStateComponent />
      )}
      <CommentModalComponent modal={modal} setModal={setModal} data={dataModal} />
      <Modal
        show={showModalRevision}
        onHide={handleRevisionModal}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div
              className="d-flex  align-items-center justify-content-around"
              style={{ width: "100%" }}
            >
              <p className="titleModal">Terminar acción</p>
              <BiBellPlus
                fontSize="24px"
                color={colors.status.blue}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setmodalReminder(true);
                }}
              />
              <ReminderModalComponent
                modal={modalReminder}
                setmodal={setmodalReminder}
                user={user}
                deal_id={deal_id}
                actionId={dataArrayActions}
                nameActions={nameActions}
                setshowModalRevision={setshowModalRevision}
              />
            </div>
            <form style={{ width: "100%" }}>
              <textarea
                className="inputModal mt-3"
                style={{ width: "100%", height: "110px" }}
                placeholder="Dejar comentario opcional"
                {...register("comments")}
              />
              <div
                style={{ width: "100%" }}
                className="d-flex flex-column align-items-center justify-content-between"
              >
                <button
                  variant="primary"
                  className="btnSendPricing mb-2 mt-3"
                  type="submit"
                  onClick={handleSubmit((data) => endAction(data, "done"))}
                >
                  Completar acción
                </button>
                <button
                  variant="primary"
                  className="btnCancelRevision mb-2"
                  onClick={handleSubmit((data) => endAction(data, "cancelled"))}
                >
                  Cancelar acción
                </button>
              </div>
            </form>
            <button
              variant="secondary"
              className="btnCancelarModal mt-3"
              onClick={() => setshowModalRevision(false)}
            >
              Cerrar
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
