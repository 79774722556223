import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { MdAccountCircle } from 'react-icons/md';
import { colors } from '../../../styles/colors';

export default function UserAttendingTooltipComponent({ showName }) {

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Popover id="popover-basic">
          <Popover.Body>
            <h4 style={{ fontSize: "16px" }}>
              Usuarios atendiendo:
            </h4>
            {showName?.map((data, i) => (
              <p key={i}>
                {data?.team}: {data?.name}
              </p>
            ))}
          </Popover.Body>
        </Popover>
      }
    >
      <span style={{ marginLeft: "20px" }}>
        <MdAccountCircle
          color={colors.primary.primary}
          fontSize="24"
        />
      </span>
    </OverlayTrigger>
  )
}
