import React, { useState } from "react";
import { Form, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  aceptarPropuesta,
  formatNumber,
  solicitarRevision,
} from "../../../services/servicesPricing";
import toast from "react-hot-toast";
import { colors } from "../../../../../styles/colors";
import { solicitarCotizacion } from "../../../services/servicesPricing";
import { MdHelpOutline } from "react-icons/md";
import { updateSalePrice } from "../../../../States/services/servicesStates";

const SalePricingAdminModal = ({
  deal_id,
  setShowSalePrice,
  showSalePrice,
  user,
  dataHistory,
  actionId,
  sendActionId,
  revisionActionId,
  valueCotizacion,
  status,
  setActiveModalPricing,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [showModalRevision, setshowModalRevision] = useState();
  const [salePriceValue, setsalePriceValue] = useState("");

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Como administrador puedes enviar y solicitar cotizaciones
      </Popover.Body>
    </Popover>
  );
  const handleCloseOfferVirtual = () => {
    setShowSalePrice(false);
    setActiveModalPricing(false);
  };
  const activeModalRevision = () => {
    setShowSalePrice(false);
    setshowModalRevision(true);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);

    setsalePriceValue(formattedValue);
  };

  const sendSalePrice = (dataInput) => {
    const hoy = new Date();
    const devolucion = new Date();
    devolucion.setDate(hoy.getDate() + parseInt(dataInput.value_days));
    const data = {
      "sale_price.value_price": dataInput.value_price,
      "sale_price.value_days": dataInput.value_days,
      // "sale_price.date": hoy.toLocaleDateString("en-US"),
      // "sale_price.until_date": devolucion.toLocaleDateString("en-US"),
      "sale_price.user": user,
    };
    updateSalePrice(user?.company, deal_id, data);
    toast.success("Pricing enviado.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setShowSalePrice(false);
    setActiveModalPricing(false);
  };

  const requestPrice = () => {
    const data = {
      "action_requested.action_id": actionId,
      "action_requested.comments": "",
      "action_requested.user": user,
      "action_requested.data_reminder": {},
    };
    solicitarCotizacion(user?.company, deal_id, data);
    toast.success("Solicitud enviada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setShowSalePrice(false);
    setActiveModalPricing(false);
  };
  const acceptedPrice = () => {
    const data = {
      "action_requested.action_id": sendActionId,
      "action_requested.comments": "",
      "action_requested.user": user,
    };
    aceptarPropuesta(user?.company, deal_id, data);
    toast.success("Oferta aceptada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setShowSalePrice(false);
    setActiveModalPricing(false);
  };
  const sendReviewRequest = (dataComment) => {
    const data = {
      "action_requested.action_id": revisionActionId,
      "action_requested.user": user,
      "action_requested.comments": dataComment.comments,
      "action_requested.data_reminder": {},
    };
    solicitarRevision(user?.company, deal_id, data);
    toast.success("Solicitud de revision enviada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowModalRevision(false);
    setActiveModalPricing(false);
    reset();
  };

  return (
    <div>
      <Modal
        show={showSalePrice}
        onHide={handleCloseOfferVirtual}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <span
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <p className="titleModal">Precio de venta</p>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={popover}
              >
                <span>
                  <MdHelpOutline
                    onClick={() => {}}
                    className="help-icon-modal"
                  />
                </span>
              </OverlayTrigger>
            </span>
            <form onSubmit={handleSubmit(sendSalePrice)} className="w-100">
              <div className="d-flex align-items-center mt-2 mb-1">
                <div>
                  <p
                    style={{
                      margin: "0",
                      color: colors.neutral.lightTwo,
                      fontSize: "14px",
                    }}
                    className="mb-2"
                  >
                    Precio de venta
                  </p>
                  <input
                    className="inputModal"
                    placeholder={
                      valueCotizacion ? valueCotizacion : "$0.000.000"
                    }
                    type="text"
                    {...register("value_price", {
                      required: "El precio de venta es requerido",
                    })}
                    value={salePriceValue}
                    onChange={handleInputChange}
                    style={{ width: "135px" }}
                  />
                </div>
                <div className="ms-2">
                  <p
                    style={{
                      margin: "0",
                      color: colors.neutral.lightTwo,
                      fontSize: "14px",
                    }}
                    className="mb-2"
                  >
                    Días para la venta
                  </p>
                  <Form.Select
                    className="select-order"
                    {...register("value_days")}
                    style={{
                      width: "135px",
                      height: "40px",
                      padding: "10px",
                      border: "2px solid #E1E7EF",
                    }}
                  >
                    <option>7</option>
                    <option>14</option>
                    <option>21</option>
                    <option>30</option>
                    <option>45</option>
                    <option>60</option>
                  </Form.Select>
                </div>
              </div>
              {errors.value_price && (
                <p
                  style={{
                    margin: "0px",
                    color: colors.status.red,
                    fontSize: "12px",
                  }}
                  className="mt-2"
                >
                  {errors.value_price?.message}
                </p>
              )}
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center flex-column justify-content-between mt-3"
              >
                <button
                  variant="primary"
                  className="btnSendPricing mt-2"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </form>
            <div className="mt-2" style={{ width: "100%" }}>
              {dataHistory?.length > 0
                ? dataHistory.map((info, i) => (
                    <div
                      className="d-flex align-items-center justify-content-between dividerHistory mb-3"
                      style={{ width: "100%" }}
                      key={i}
                    >
                      <p className="textHistory mb-2">${info.value}</p>
                      <p className="textHistory mb-2">
                        {" "}
                        {new Date(
                          info.date.seconds * 1000
                        ).toLocaleDateString()}{" "}
                        -{" "}
                        {new Date(info.date.seconds * 1000).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </p>
                    </div>
                  ))
                : null}
            </div>
            <button
              variant="secondary"
              onClick={handleCloseOfferVirtual}
              className="btnCancelarModal"
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalRevision}
        onHide={handleCloseOfferVirtual}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="titleModal">Oferta inicial</p>
            <form
              onSubmit={handleSubmit(sendReviewRequest)}
              style={{ width: "100%" }}
            >
              <textarea
                className="inputModal mt-3"
                style={{ width: "100%", height: "110px" }}
                placeholder="Dejar comentario opcional"
                {...register("comments")}
              />
              <div
                style={{ width: "100%" }}
                className="d-flex flex-column align-items-center justify-content-between"
              >
                <button
                  variant="primary"
                  className="btnSendPricing mb-2 mt-3"
                  type="submit"
                >
                  Solicitar
                </button>
                <button
                  variant="secondary"
                  className="btnCancelarModal"
                  onClick={() => setshowModalRevision(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SalePricingAdminModal;
