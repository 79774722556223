import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { overallStatusOptions } from '../../../../../constants/sectionsConst';

export default function Section7TabComponent({
  formData,
  formDataTableView,
  saveFormDataTable,
  editAllowed,
  saveFormDataObjet
}) {

  const { section7 } = formDataTableView;

  const handleOverallStatusChange = (value) => {
    saveFormDataObjet('section7', 'overallStatus',
      { title: 'Estado general', values: [value] });
  };

  const handleInputScannerResults = (value) => {
    saveFormDataTable('section7', 'scannerResults',
      { title: 'Resultados scanner', values: value });
  }

  return (
    <Container fluid>
      <h1 className='section-title'>Final Inspección</h1>
      <Row>
        <Col md={6} >
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Estado general</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section7-overallStatus'>
              {overallStatusOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="overallStatus"
                  checked={formData?.section7?.overallStatus?.values?.includes(option)}
                  onChange={() => handleOverallStatusChange(option)}
                  className={`section-checks ${index === overallStatusOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>

        </Col>
        <Col md={6} >
          <div className='mb-2' style={{ height: '80%' }} >
            <label className='section-input-label mb-2'>Resultados scanner </label>
            <textarea
              disabled={!editAllowed}
              className='section-input'
              style={{ height: '90%' }}
              value={section7?.scannerResults?.values}
              onChange={(e) => handleInputScannerResults(e.target.value)}
            />
          </div>
        </Col>
      </Row>
    </Container >
  )
}
