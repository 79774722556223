import React from "react";
import DealTableRow from "./DealTableRow";
import { MdKeyboardArrowDown } from "react-icons/md";

export const TableDefaultCheckComponent = ({
  deals,
  rowStyleDeal,
  rowStyleDealInProsess,
  user,
  allUsers,
  handleClick,
  verDeal,
  convertDateFormat,
  action_status,
  actions,
  loadMore,
  increaseDeals,
  colors,
  isPageWide,
  showTooltip,
}) => {
  return (
    <tbody>
      {deals.map((item) => (
        <DealTableRow
          key={item.id}
          item={item}
          handleClick={handleClick}
          verDeal={verDeal}
          convertDateFormat={convertDateFormat}
          rowStyleDeal={rowStyleDeal}
          rowStyleDealInProsess={rowStyleDealInProsess}
          user={user}
          allUsers={allUsers}
          actions={actions}
          action_status={action_status}
          showName={showTooltip(item)}
        />
      ))}
      {!loadMore ? null : (
        <tr>
          <td colSpan="7" style={{ textAlign: "center", cursor: "pointer" }} onClick={increaseDeals}>
            <div className={`d-flex align-items-center justify-content-${isPageWide ? "center" : "start"}`}>
              <p style={{ margin: "0px", color: colors.primary.primary, fontSize: "14px", marginLeft: isPageWide ? "" : "100px" }}>Cargar más</p>
              <MdKeyboardArrowDown color={colors.primary.primary} fontSize="14" />
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
};
