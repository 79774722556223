import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";

export const getFichaTecnica = async (version_id) => {
  const fichaDocRef = doc(db, "features_infoauto", version_id);
  try {
    const dataFichaTecnica = await getDoc(fichaDocRef);
    return dataFichaTecnica;
  } catch (error) {
    console.error("Error al traer el documento", error);
  }
};
