import React, { useState } from "react";
import { useUser } from "../../../context/UserContext";
import { Col, Dropdown, Form, Row, Button } from "react-bootstrap";
import { MdFilterList } from "react-icons/md";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useDeals } from "./../../../context/DealsContext";
import { BiCalendarEvent } from "react-icons/bi";
import { teams } from "../../../constants/teams";
import { useCompany } from "../../../context/CompanyContext";
import ModalFilterDateComponent from "./ModalFilterDateComponent";

const FiltersComponent = () => {
  const { filters, setFilters, clearFilter } = useDeals();
  const { actions, users, phases } = useCompany();
  const sortedActions = actions
    ? Object.entries(actions)
      .sort(([, actionA], [, actionB]) =>
        actionA?.title?.localeCompare(actionB.title)
      )
      .map(([id, action]) => ({ id, ...action }))
    : [];
  let isMobile = useMediaQuery("(max-width: 500px)");
  const { user } = useUser();
  const [openModal, setOpenModal] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const openCalendarModal = () => {
    setOpenModal(true);
  };
  const cleanFilter = () => {
    clearFilter();
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };
  const widthMobileFilter = {
    width: "90vw",
    zIndex: "1",
  };
  const widthFilter = {
    width: "50vw",
    zIndex: "1",
  };
  const handleUserFilter = (e) => {
    const fullUser = users.filter((user) => user.id === e.target.value);
    setFilters({ ...filters, selectedUser: fullUser[0] });
  };

  const handleCategoryVehicle = (e) => {
    setFilters({ ...filters, category: e.target.value });
  };

  return (
    <Dropdown show={openFilters} onToggle={(isOpen) => setOpenFilters(isOpen)}>
      <Dropdown.Toggle className="filter-button" id="dropdown-basic">
        <MdFilterList className="filter-icon-button" fontSize={18} />
        Filtro
      </Dropdown.Toggle>
      <Dropdown.Menu
        show={filters.selectedDates.from}
        className="filter-menu"
        style={isMobile ? widthMobileFilter : widthFilter}
      >
        <Row style={{ width: " 100%", margin: "0px" }}>
          <Col xs={12} md={6} style={{ marginBottom: "8px", marginTop: "8px" }}>
            <p className="filter-title">Filtro de usuario</p>
            <Form.Select
              className="filter-select"
              value={filters?.selectedUser?.id}
              onChange={handleUserFilter}
            >
              <option value="">Seleccione</option>
              {users?.map((user, i) => {
                return (
                  <option key={i} value={user.id}>
                    {user.name}
                  </option>
                );
              })}
            </Form.Select>
          </Col>

          <Col xs={12} md={6} style={{ marginBottom: "8px", marginTop: "8px" }}>
            <p className="filter-title">Filtro de acción</p>
            <Form.Select
              className="filter-select"
              value={filters.selectedAction}
              onChange={(e) =>
                setFilters({ ...filters, selectedAction: e.target.value })
              }
            >
              <option value="">Seleccione</option>
              {sortedActions !== undefined
                ? sortedActions.map((action, i) => {
                  return user?.team !== teams.ADMIN &&
                    action.team === user?.team ? (
                    <option key={action.id} value={action.id}>
                      {action.title}
                    </option>
                  ) : user?.team === teams.ADMIN ? (
                    <option key={action.id} value={action.id}>
                      {action.title}
                    </option>
                  ) : null;
                })
                : null}
            </Form.Select>
          </Col>
        </Row>
        <Row style={{ width: " 100%", margin: "0px" }}>
          <Col xs={12} md={6} style={{ marginBottom: "8px", marginTop: "8px" }}>
            <p
              className="filter-title"
              style={{ marginBottom: "8px", marginTop: "8px" }}
            >
              Filtro de estado
            </p>
            <Form.Select
              className="filter-select"
              value={filters?.selectedPhase}
              onChange={(e) =>
                setFilters({ ...filters, selectedPhase: e.target.value })
              }
            >
              <option value="">Seleccione</option>
              {phases?.map((phase, i) => {
                return (
                  <option key={i} value={phase}>
                    {phase}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
          {/*  */}
          <Col xs={12} md={6} style={{ marginBottom: "8px", marginTop: "8px" }}>
            <p
              className="filter-title"
              style={{ marginBottom: "8px", marginTop: "8px" }}
            >
              Filtro de orden
            </p>
            <Form>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-2 mt-2">
                  <Form.Check
                    onChange={(e) =>
                      setFilters({ ...filters, orderBy: e.target.value })
                    }
                    checked={filters.orderBy === "desc"}
                    inline
                    label="Descendente"
                    value="desc"
                    name="group2"
                    type={type}
                  />
                  <Form.Check
                    onChange={(e) =>
                      setFilters({ ...filters, orderBy: e.target.value })
                    }
                    checked={filters.orderBy === "asc"}
                    inline
                    value="asc"
                    label="Ascendente"
                    name="group2"
                    type={type}
                  />
                </div>
              ))}
            </Form>
          </Col>
        </Row>
        <Row style={{ width: " 100%", margin: "0px" }}>
          <p
            className="filter-title"
            style={{ marginBottom: "8px", marginTop: "8px" }}
          >
            Filtros de fecha
          </p>
          <Col xs={12} md={6} style={{ marginBottom: "8px", marginTop: "8px" }}>
            <div
              className="reminder-input d-flex align-items-center justify-content-between mb-2"
              onClick={openCalendarModal}
              style={{
                cursor: "pointer",
                width: "100%",
                height: "38px",
              }}
            >
              <p
                className="reminder-text-placeholder"
                style={{
                  color: "#1B2130",
                }}
              >
                {filters.selectedDates.from
                  ? filters.selectedDates?.from.toLocaleDateString() +
                  " - " +
                  filters.selectedDates?.to.toLocaleDateString()
                  : "Seleccionar tiempo"}
              </p>
              <div className="me-2">
                <BiCalendarEvent fontSize="22px" color="#1B2130" />
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} style={{ marginBottom: "8px", marginTop: "8px" }}>
            <Form>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-2 mt-2">
                  <Form.Check
                    inline
                    onChange={(e) =>
                      setFilters({ ...filters, dealDateStatus: e.target.value })
                    }
                    checked={filters.dealDateStatus === "pending"}
                    value="pending"
                    label="Pendientes"
                    name="group1"
                    type={type}
                  />
                  <Form.Check
                    onChange={(e) =>
                      setFilters({ ...filters, dealDateStatus: e.target.value })
                    }
                    checked={filters.dealDateStatus === "next"}
                    inline
                    label="Proximos"
                    value="next"
                    name="group1"
                    type={type}
                  />
                  <Form.Check
                    onChange={(e) =>
                      setFilters({ ...filters, dealDateStatus: e.target.value })
                    }
                    checked={filters.dealDateStatus === ""}
                    inline
                    label="Todos"
                    value=""
                    name="group1"
                    type={type}
                  />
                </div>
              ))}
            </Form>
          </Col>
          <Col xs={12} md={6} style={{ marginBottom: "8px", marginTop: "8px" }}>
            <p className="filter-title">Filtro por categoria de vehiculo</p>
            <Form.Select
              className="filter-select"
              value={filters?.category}
              onChange={handleCategoryVehicle}
            >
              <option value="">Seleccione</option>
              <option value={"A"}>A</option>
              <option value={"B"}>B</option>
              <option value={"C"}>C</option>
              <option value={"D"}>D</option>
            </Form.Select>
          </Col>
        </Row>

        <Row style={{ width: " 100%", margin: "0px" }}>
          <Col></Col>
          <Col
            xs={12}
            md={6}
            style={{
              // marginBottom: "8px",
              // marginTop: "8px",
              display: "flex",
              justifyContent: " flex-end ",
            }}
          >
            <Button className="mb-2" onClick={cleanFilter}>
              Limpiar filtros
            </Button>
          </Col>
        </Row>
      </Dropdown.Menu>
      <ModalFilterDateComponent
        openModal={openModal}
        setOpenModal={setOpenModal}
        setState={setState}
        state={state}
      />
    </Dropdown>
  );
};
export default FiltersComponent;
