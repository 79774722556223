export const getStatusColor = (status) => {
  let color = "";
  switch (status) {
    case "valid":
      color = "#864BFA";
      break;
    case "new":
      color = "#238EE8";
      break;
    case "requested":
      color = "#FFC90A";
      break;
    case "expired":
      color = "#F0802F";
      break;
    case "rejected":
      color = "#F40000";
      break;
    case "accepted":
      color = "#0e983d";
      break;
    default:
      color = "#d9c4ff";
  }

  return color;
};
