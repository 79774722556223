import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../../../styles/style/carouselStyle.css";
import { HiOutlineDownload } from "react-icons/hi";

const MediaItem = ({ media, download }) => {
  const { urlFirestore, type } = media;
  const [zoomLevel, setZoomLevel] = useState(1);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [zoomingIn, setZoomingIn] = useState(true);

  const handleMouseMove = (e) => {
    if (zoomLevel > 1) {
      const { left, top, width, height } = e.target.getBoundingClientRect();
      const x = ((e.clientX - left) / width) * 100;
      const y = ((e.clientY - top) / height) * 100;
      setZoomPosition({ x, y });
    }
  };

  const handleWheel = (e) => {
    if (e.deltaY < 0) {
      setZoomLevel((prev) => Math.min(prev + 0.5, 5));
    } else {
      setZoomLevel((prev) => Math.max(prev - 0.5, 1));
    }
  };

  const handleZoom = (clientX, clientY, boundingRect) => {
    const { left, top, width, height } = boundingRect;
    const x = ((clientX - left) / width) * 100;
    const y = ((clientY - top) / height) * 100;
    setZoomPosition({ x, y });

    if (zoomingIn) {
      if (zoomLevel < 5) {
        setZoomLevel((prev) => prev + 1);
      } else {
        setZoomingIn(false);
        setZoomLevel(4);
      }
    } else {
      if (zoomLevel > 1) {
        setZoomLevel((prev) => prev - 1);
      } else {
        setZoomingIn(true);
        setZoomLevel(2);
      }
    }
  };

  const handleClick = (e) => {
    handleZoom(e.clientX, e.clientY, e.target.getBoundingClientRect());
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    handleZoom(touch.clientX, touch.clientY, e.target.getBoundingClientRect());
  };

  if (type === "video/mp4") {
    return (
      <div className="grid-video">
        <video controls className="video-fc">
          <source src={urlFirestore} type="video/mp4" />
        </video>
      </div>
    );
  } else {
    return (
      <div
        className="grid-image-zoom-container"
        onMouseMove={handleMouseMove}
        onWheel={handleWheel}
        onClick={handleClick}
        onTouchStart={handleTouchStart}
        style={{
          overflow: zoomLevel > 1 ? "visible" : "hidden",
        }}
      >
        <div
          className="grid-image-zoom"
          style={{
            transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`,
            transform: `scale(${zoomLevel})`,
            cursor: zoomingIn ? "zoom-in" : "zoom-out", // Cambia el cursor dependiendo de zoomingIn
          }}
        >
          <img className="img-carousel" src={urlFirestore} alt="Imagen" />
        </div>
      </div>
    );
  }
};

export default function ModalCarouselComponent({
  filesInspect,
  modal,
  setmodal,
  selectedMedia,
  setSelectedMedia,
  download,
}) {
  const handleSelect = (selectedIndex, e) => {
    setSelectedMedia(selectedIndex);
  };

  const handleClose = () => {
    setmodal(false);
    setSelectedMedia(selectedMedia);
  };
  return (
    <Modal show={modal} size="xl" centered onHide={handleClose}>
      <Carousel
        activeIndex={selectedMedia}
        onSelect={handleSelect}
        interval={null}
        style={{ width: "100%", height: "90vh" }}
      >
        {filesInspect?.map((media, index) => (
          <Carousel.Item key={media.id}>
            {download ? (
              <div
                style={{ backgroundColor: "#5F35BC" }}
                className="d-flex  align-items-center justify-content-center"
              >
                <a href={`${media.url}`} download={media.name} rel="noreferrer">
                  <HiOutlineDownload fontSize="30px" color="white" />
                </a>
              </div>
            ) : null}
            <MediaItem media={media} download={download} />
          </Carousel.Item>
        ))}
      </Carousel>
    </Modal>
  );
}
