import { db } from "../../../../firebase/config";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";

export const sendFile = async (user, deal_id, files) => {
  const takeDocRef = doc(db, "companies", user?.company, "deals", deal_id, "data", "take");

  try {
    await updateDoc(takeDocRef, {
      attachments: files,
      user_update_data: user,
    });
    return { success: true };
  } catch (error) {
    return { error };
  }
};

export const deleteFile = async (user, company, deal_id, url, name, size, id) => {
  const takeDocRef = doc(db, "companies", company, "deals", deal_id, "data", "take");

  try {
    await updateDoc(takeDocRef, {
      attachments: arrayRemove({
        id: id,
        url: url,
        name: name,
        size: size,
      }),
      user_update_data: user,
    });
    return { success: true };
  } catch (error) {
    return { error };
  }
};