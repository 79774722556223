import React, { useEffect, useState } from "react";
import "../../../../styles/style/dragAndDropFileStyle.css";

import { useParams } from "react-router-dom";
import { useUser } from "../../../../context/UserContext";
import LongSnapshotFirebase from "../../../../utils/LongSnapshotFirebase";
import DragAndDropComponent from "../../../../components/DragAndDropComponent";

export default function AttachedFilesComponents({
  disabled,
  setfilesAdmin,
  filesAdmin,
}) {
  const { deal_id } = useParams();
  const { user } = useUser();

  useEffect(() => {
    const callbackAttachments = (data) => {
      setfilesAdmin(data.attachments);
    };
    const unsubscribeAttachments = LongSnapshotFirebase(
      user?.company,
      deal_id,
      "data",
      "administrative",
      callbackAttachments
    );
    return () => {
      unsubscribeAttachments();
    };
  }, [deal_id, setfilesAdmin, user?.company]);

  return (
    <>
      <DragAndDropComponent
        dataFiles={filesAdmin}
        setDataFiles={setfilesAdmin}
        disabled={disabled}
      />
    </>
  );
}
