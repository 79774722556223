import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import {
  backWindowOptions,
  exhaustSmokeOptions,
  hydraulicJackOptions,
  internalTrunkLidOptions,
  leftExteriorHandlesOptions,
  leftExteriorMoldingsOptions,
  leftSideBodyOptions,
  leftSideVisibilityOptions,
  rearBumperOptions,
  rearLightsOptions,
  rearTabletOptions,
  rightExteriorHandlesOptions,
  rightSideBodyOptions,
  rightSideMoldingsOptions,
  rightSideVisibilityOptions,
  severalOptions,
  spareTireOptions,
  trunkInteriorFloorOptions,
  trunkOptions,
  trunkRugOptions
} from '../../../../../constants/sectionsConst';
import {
  updateDataForCheckboxChange,
  updateFormDataTableViewMultiple
} from '../../../../../utils/sectionFuncions';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';

export default function Section6TabComponent({
  formDataTableView,
  saveFormDataTable,
  setFormDataTableView,
  editAllowed
}) {

  let isMobile = useMediaQuery("(max-width: 500px)");

  const { section6 } = formDataTableView;

  const handleCheckboxChangeRightSideVisibility = (sectionName, nestedObjectName, rowTitle, option) => {
    console.log(option)
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  }

  const handleCheckboxChangeRightSideBody = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };
  const handleCheckboxChangeRightSideMoldings = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeRightExteriorHandles = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeRearLights = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeSeveral = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeLeftSideVisibility = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeLeftSideBody = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeLeftExteriorHandles = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeLeftExteriorMoldings = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleBackWindowChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section6.backWindow.values, value)
    saveFormDataTable('section6', 'backWindow', { title: 'Luneta', values: updatedValues });
  }

  const handleTrunkChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section6.trunk.values, value)
    saveFormDataTable('section6', 'trunk', { title: 'Baúl', values: updatedValues });
  }

  const handleInternalTrunkLidChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section6.internalTrunkLid.values, value)
    saveFormDataTable('section6', 'internalTrunkLid', { title: 'Tapa interna de baúl', values: updatedValues });
  }

  const handleRearTabletChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section6.rearTablet.values, value)
    saveFormDataTable('section6', 'rearTablet', { title: 'Tableta trasera', values: updatedValues });
  }

  const handleTrunkRugChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section6.trunkRug.values, value)
    saveFormDataTable('section6', 'trunkRug', { title: 'Alfombra de baúl', values: updatedValues });
  }

  const handleSpareTireChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section6.spareTire.values, value)
    saveFormDataTable('section6', 'spareTire', { title: 'Rueda de auxilio', values: updatedValues });
  }

  const handleHydraulicJackChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section6.hydraulicJack.values, value)
    saveFormDataTable('section6', 'hydraulicJack', { title: 'Gato/Manija', values: updatedValues });
  }

  const handleExhaustSmokeChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section6.exhaustSmoke.values, value)
    saveFormDataTable('section6', 'exhaustSmoke', { title: 'Humo de escape', values: updatedValues });
  }

  const handleTrunkInteriorFloorChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section6.trunkInteriorFloor.values, value)
    saveFormDataTable('section6', 'trunkInteriorFloor', { title: 'Piso interior de baúl', values: updatedValues });
  }

  const handleRearBumperChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section6.rearBumper.values, value)
    saveFormDataTable('section6', 'rearBumper', { title: 'Paragolpes Trasero', values: updatedValues });
  }

  const handleInputCommentsRightSide = (value) => {
    saveFormDataTable('section6', 'commentsRightSide',
      { title: 'Comentarios Lateral Derecho', values: value });
  }

  const handleInputCommentsRear = (value) => {
    saveFormDataTable('section6', 'commentsRear',
      { title: 'Comentarios Parte trasera', values: value });
  }
  const handleInputCommentsLeftSide = (value) => {
    saveFormDataTable('section6', 'commentsLeftSide',
      { title: 'Comentarios Lateral Izq.', values: value });
  }
  return (
    <>
      {!isMobile ? (
        <Container fluid>
          <h1 className='section-title'>Exterior Derecho, Trasero, Izquierdo</h1>
          <Row>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Visibilidad Lateral Derecha</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section6-rightSideVisibility'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {rightSideVisibilityOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section6?.rightSideVisibility?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {rightSideVisibilityOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeRightSideVisibility('section6', 'rightSideVisibility', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Molduras Lateral Derecho</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section6-rightSideMoldings'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {rightSideMoldingsOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section6?.rightSideMoldings?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {rightSideMoldingsOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeRightSideMoldings('section6', 'rightSideMoldings', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='mb-2' style={{ height: '84%' }} id='section6-commentsRightSide' >
                <label className='section-input-label'>Comentarios Lateral Derecho</label>
                <textarea
                  className='section-input'
                  style={{ height: '84%' }}
                  value={section6?.commentsRightSide?.values}
                  disabled={!editAllowed}
                  onChange={(e) => handleInputCommentsRightSide(e.target.value)}
                />
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Paragolpes Trasero</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section6-rearBumper'>
                  {rearBumperOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="rearBumperOptions"
                      checked={section6?.rearBumper?.values.includes(option)}
                      onChange={() => handleRearBumperChange(option)}
                      className={`section-checks ${index === rearBumperOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
            </Col>
            <Col md={6} >
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Carroceria Lateral Derecho</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section6-rightSideBody'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {rightSideBodyOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section6?.rightSideBody?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {rightSideBodyOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeRightSideBody('section6', 'rightSideBody', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Manijas Exteriores Derecha</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section6-rightExteriorHandles'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {rightExteriorHandlesOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section6?.rightExteriorHandles?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {rightExteriorHandlesOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                type="checkbox"
                                disabled={!editAllowed}
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeRightExteriorHandles('section6', 'rightExteriorHandles', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Faros Trasero</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section6-rearLights'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {rearLightsOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section6?.rearLights?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {rearLightsOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeRearLights('section6', 'rearLights', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} >
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Varios</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section6-several'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {severalOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section6?.several?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {severalOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeSeveral('section6', 'several', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Baúl</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section6-trunk'>
                  {trunkOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="trunk"
                      checked={section6?.trunk?.values.includes(option)}
                      onChange={() => handleTrunkChange(option)}
                      className={`section-checks ${index === trunkOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Luneta</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section6-backWindow'>
                  {backWindowOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="backWindow"
                      checked={section6?.backWindow?.values.includes(option)}
                      onChange={() => handleBackWindowChange(option)}
                      className={`section-checks ${index === backWindowOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Tapa interna de baúl</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section6-internalTrunkLid'>
                  {internalTrunkLidOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="internalTrunkLid"
                      checked={section6?.internalTrunkLid?.values.includes(option)}
                      onChange={() => handleInternalTrunkLidChange(option)}
                      className={`section-checks ${index === internalTrunkLidOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Tableta trasera</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section6-rearTablet'>
                  {rearTabletOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="rearTablet"
                      checked={section6?.rearTablet?.values.includes(option)}
                      onChange={() => handleRearTabletChange(option)}
                      className={`section-checks ${index === rearTabletOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} >
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Alfombra de baúl</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section6-trunkRug'>
                  {trunkRugOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="trunkRug"
                      checked={section6?.trunkRug?.values.includes(option)}
                      onChange={() => handleTrunkRugChange(option)}
                      className={`section-checks ${index === trunkRugOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Gato/Manija</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section6-hydraulicJack'>
                  {hydraulicJackOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="hydraulicJack"
                      checked={section6?.hydraulicJack?.values.includes(option)}
                      onChange={() => handleHydraulicJackChange(option)}
                      className={`section-checks ${index === hydraulicJackOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Humo de escape</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section6-exhaustSmoke'>
                  {exhaustSmokeOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="exhaustSmoke"
                      checked={section6?.exhaustSmoke?.values.includes(option)}
                      onChange={() => handleExhaustSmokeChange(option)}
                      className={`section-checks ${index === exhaustSmokeOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Rueda de auxilio</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section6-spareTire'>
                  {spareTireOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="spareTire"
                      checked={section6?.spareTire?.values.includes(option)}
                      onChange={() => handleSpareTireChange(option)}
                      className={`section-checks ${index === spareTireOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Piso interior de baúl</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section6-trunkInteriorFloor'>
                  {trunkInteriorFloorOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="trunkRug"
                      checked={section6?.trunkInteriorFloor?.values.includes(option)}
                      onChange={() => handleTrunkInteriorFloorChange(option)}
                      className={`section-checks ${index === trunkInteriorFloorOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2' id='section6-commentsRear'>
                <label className='section-input-label'>Comentarios Parte trasera</label>
                <textarea
                  disabled={!editAllowed}
                  className='section-input'
                  style={{ height: '70%' }}
                  value={section6?.commentsRear?.values}
                  onChange={(e) => handleInputCommentsRear(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} >
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Visibilidad Lateral Izquierda</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section6-leftSideVisibility'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {leftSideVisibilityOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section6?.leftSideVisibility?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {leftSideVisibilityOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeLeftSideVisibility('section6', 'leftSideVisibility', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Carrocería Lateral Izquierda</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section6-leftSideBody'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {leftSideBodyOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section6?.leftSideBody?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {leftSideBodyOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeLeftSideBody('section6', 'leftSideBody', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Manijas Exteriores Izq.</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section6-leftExteriorHandles'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {leftExteriorHandlesOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section6?.leftExteriorHandles?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {leftExteriorHandlesOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeLeftExteriorHandles('section6', 'leftExteriorHandles', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Molduras Exteriores Izq</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section6-leftExteriorMoldings'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {leftExteriorMoldingsOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section6?.leftExteriorMoldings?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {leftExteriorMoldingsOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeLeftExteriorMoldings('section6', 'leftExteriorMoldings', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='mb-2' style={{ height: '30%' }} id='section6-commentsLeftSide'>
                <label className='section-input-label'>Comentarios Lateral Izq.</label>
                <textarea
                  disabled={!editAllowed}
                  className='section-input'
                  style={{ height: '80%' }}
                  value={section6?.commentsLeftSide?.values}
                  onChange={(e) => handleInputCommentsLeftSide(e.target.value)}
                />
              </div>
            </Col>
          </Row>
        </Container >
      ) : (
        <Container fluid>
          <h1 className='section-title'>Exterior Derecho, Trasero, Izquierdo</h1>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Visibilidad Lateral Derecha</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section6-rightSideVisibility'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {rightSideVisibilityOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section6?.rightSideVisibility?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {rightSideVisibilityOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeRightSideVisibility('section6', 'rightSideVisibility', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Carroceria Lateral Derecho</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section6-rightSideBody'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {rightSideBodyOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section6?.rightSideBody?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {rightSideBodyOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeRightSideBody('section6', 'rightSideBody', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Molduras Lateral Derecho</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section6-rightSideMoldings'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {rightSideMoldingsOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section6?.rightSideMoldings?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {rightSideMoldingsOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeRightSideMoldings('section6', 'rightSideMoldings', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Manijas Exteriores Derecha</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section6-rightExteriorHandles'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {rightExteriorHandlesOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section6?.rightExteriorHandles?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {rightExteriorHandlesOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            type="checkbox"
                            disabled={!editAllowed}
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeRightExteriorHandles('section6', 'rightExteriorHandles', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2' style={{ height: '84%' }} id='section6-commentsRightSide' >
            <label className='section-input-label'>Comentarios Lateral Derecho</label>
            <textarea
              className='section-input'
              style={{ height: '84%' }}
              value={section6?.commentsRightSide?.values}
              disabled={!editAllowed}
              onChange={(e) => handleInputCommentsRightSide(e.target.value)}
            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Faros Trasero</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section6-rearLights'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {rearLightsOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section6?.rearLights?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {rearLightsOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeRearLights('section6', 'rearLights', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Paragolpes Trasero</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section6-rearBumper'>
              {rearBumperOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="rearBumperOptions"
                  checked={section6?.rearBumper?.values.includes(option)}
                  onChange={() => handleRearBumperChange(option)}
                  className={`section-checks ${index === rearBumperOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Varios</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section6-several'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {severalOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section6?.several?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {severalOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeSeveral('section6', 'several', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Luneta</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section6-backWindow'>
              {backWindowOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="backWindow"
                  checked={section6?.backWindow?.values.includes(option)}
                  onChange={() => handleBackWindowChange(option)}
                  className={`section-checks ${index === backWindowOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Baúl</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section6-trunk'>
              {trunkOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="trunk"
                  checked={section6?.trunk?.values.includes(option)}
                  onChange={() => handleTrunkChange(option)}
                  className={`section-checks ${index === trunkOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Tapa interna de baúl</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section6-internalTrunkLid'>
              {internalTrunkLidOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="internalTrunkLid"
                  checked={section6?.internalTrunkLid?.values.includes(option)}
                  onChange={() => handleInternalTrunkLidChange(option)}
                  className={`section-checks ${index === internalTrunkLidOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Tableta trasera</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section6-rearTablet'>
              {rearTabletOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="rearTablet"
                  checked={section6?.rearTablet?.values.includes(option)}
                  onChange={() => handleRearTabletChange(option)}
                  className={`section-checks ${index === rearTabletOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Alfombra de baúl</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section6-trunkRug'>
              {trunkRugOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="trunkRug"
                  checked={section6?.trunkRug?.values.includes(option)}
                  onChange={() => handleTrunkRugChange(option)}
                  className={`section-checks ${index === trunkRugOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Rueda de auxilio</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section6-spareTire'>
              {spareTireOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="spareTire"
                  checked={section6?.spareTire?.values.includes(option)}
                  onChange={() => handleSpareTireChange(option)}
                  className={`section-checks ${index === spareTireOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Gato/Manija</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section6-hydraulicJack'>
              {hydraulicJackOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="hydraulicJack"
                  checked={section6?.hydraulicJack?.values.includes(option)}
                  onChange={() => handleHydraulicJackChange(option)}
                  className={`section-checks ${index === hydraulicJackOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Piso interior de baúl</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section6-trunkInteriorFloor'>
              {trunkInteriorFloorOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="trunkRug"
                  checked={section6?.trunkInteriorFloor?.values.includes(option)}
                  onChange={() => handleTrunkInteriorFloorChange(option)}
                  className={`section-checks ${index === trunkInteriorFloorOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Humo de escape</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section6-exhaustSmoke'>
              {exhaustSmokeOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="exhaustSmoke"
                  checked={section6?.exhaustSmoke?.values.includes(option)}
                  onChange={() => handleExhaustSmokeChange(option)}
                  className={`section-checks ${index === exhaustSmokeOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2' id='section6-commentsRear'>
            <label className='section-input-label'>Comentarios Parte trasera</label>
            <textarea
              disabled={!editAllowed}
              className='section-input'
              style={{ height: '70%' }}
              value={section6?.commentsRear?.values}
              onChange={(e) => handleInputCommentsRear(e.target.value)}
            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Visibilidad Lateral Izquierda</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section6-leftSideVisibility'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {leftSideVisibilityOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section6?.leftSideVisibility?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {leftSideVisibilityOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeLeftSideVisibility('section6', 'leftSideVisibility', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Manijas Exteriores Izq.</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section6-leftExteriorHandles'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {leftExteriorHandlesOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section6?.leftExteriorHandles?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {leftExteriorHandlesOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeLeftExteriorHandles('section6', 'leftExteriorHandles', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Carrocería Lateral Izquierda</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section6-leftSideBody'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {leftSideBodyOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section6?.leftSideBody?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {leftSideBodyOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeLeftSideBody('section6', 'leftSideBody', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Molduras Exteriores Izq</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section6-leftExteriorMoldings'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {leftExteriorMoldingsOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section6?.leftExteriorMoldings?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {leftExteriorMoldingsOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeLeftExteriorMoldings('section6', 'leftExteriorMoldings', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2' style={{ height: '30%' }} id='section6-commentsLeftSide'>
            <label className='section-input-label'>Comentarios Lateral Izq.</label>
            <textarea
              disabled={!editAllowed}
              className='section-input'
              style={{ height: '80%' }}
              value={section6?.commentsLeftSide?.values}
              onChange={(e) => handleInputCommentsLeftSide(e.target.value)}
            />
          </div>
        </Container >
      )}
    </>
  )
}
