import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";

Object.filter = function (mainObject, filterFunction) {
  return Object.keys(mainObject)
    .filter(function (ObjectKey) {
      return filterFunction(mainObject[ObjectKey]);
    })
    .reduce(function (result, ObjectKey) {
      result[ObjectKey] = mainObject[ObjectKey];
      return result;
    }, {});
};

export const saveUserData = async (data, company, deal_id, user) => {
  const user_array = {
    email: data.email,
    name: data.name,
    phone: data.phone,
    user_update_data: user,
  };

  const basics_array = {
    "source.source": data.source,
    "source.medium": data.medium,
    patente: data.patente,
    user_update_data: user,
    "car.kms": data.kms,
    paracaidas: data.paracaidas === "false" ? false : true,
  };

  const user_data = Object.filter(user_array, function (info) {
    return info !== undefined;
  });

  const basics = Object.filter(basics_array, function (info) {
    return info !== undefined;
  });

  try {
    const basicsRef = doc(db, "companies", company, "deals", deal_id, "data", "basics");
    await updateDoc(basicsRef, basics);

    const userDataRef = doc(db, "companies", company, "deals", deal_id, "data", "user_data");
    await updateDoc(userDataRef, user_data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveDataCarBasics = async (data, company, deal_id, user) => {
  const basics_array = {
    "car.brand": data.brand,
    "car.model": data.model,
    "car.version": data.version,
    "car.year": data.year,
    "car.brand_id": data.brand_id,
    "car.model_id": data.model_id,
    "car.version_id": data.version_id,
    "car.type": data.type,
    "car.gnc": data.gnc,
    "car.kms": data.kms,
    user_update_data: user,
  };

  try {
    const basicsRef = doc(db, "companies", company, "deals", deal_id, "data", "basics");
    await updateDoc(basicsRef, basics_array);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveDataKmsGnc = async (data, company, deal_id, user) => {
  const basics_array = {
    "car.gnc": data.gnc,
    "car.kms": data.kms,
    user_update_data: user,
  };

  try {
    const basicsRef = doc(db, "companies", company, "deals", deal_id, "data", "basics");
    await updateDoc(basicsRef, basics_array);
  } catch (error) {
    console.log(error);
    return error;
  }
};
