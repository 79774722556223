import { apiUrl, axiosInstance, handleError } from "../../../../services/apiConfig";

// POST
export const postSectionData = async ({ deal_id, company, body }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/check/`, body, {
      params: {
        deal_id,
        company,
      }
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al realizar la comprobación' };
  }
};

// GET
export const getSectionData = async ({ deal_id, company }) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/check`, {
      params: { deal_id, company }
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener la comprobación' };
  }
};

// POST /check/load_photos
export const postLoadPhotos = async ({ deal_id, company, files }) => {
  try {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    const response = await axiosInstance.post(`${apiUrl}/check/load_photos`, formData, {
      params: { deal_id, company },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al cargar las fotos' };
  }
};

// GET /check/get_photos
export const getPhotos = async ({ deal_id, company }) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/check/get_photos`, {
      params: { deal_id, company }
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener las fotos' };
  }
};

// POST /check/delete_car_photo
export const deleteCarPhoto = async ({ deal_id, company, photo_url }) => {
  console.log(photo_url)
  try {
    const response = await axiosInstance.post(
      `${apiUrl}/check/delete_car_photo`,
      { deal_id, company, photo_url },
    );
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al borrar la foto' };
  }
};