import React, { useCallback, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { BiCalendarEvent } from "react-icons/bi";
import ModalCalendarComponent from "./ModalCalendarComponent";
import { colors } from "../../../styles/colors";
import { useForm } from "react-hook-form";
import { addActionRequested } from "../services/servicesStates";
import moment from "moment";
import { toast } from "react-hot-toast";

export default function ModalAddActions({
  modal,
  setModal,
  actions,
  user,
  deal_id,
}) {
  const [modalCalendar, setmodalCalendar] = useState(false);
  const [dateReminder, setdateReminder] = useState("");
  const { register, handleSubmit, setValue, reset } = useForm();
  const handleClose = () => setModal(false);

  const getOrderedActions = useCallback(() => {
    const orderedActions = Object.entries(actions).sort((a, b) => {
      if (a[1].team === b[1].team) {
        return a[1].title.localeCompare(b[1].title);
      }
      return a[1].team.localeCompare(b[1].team);
    });
    return orderedActions;
  }, [actions]);

  let fecha = new Date();
  let horasEstablecidas = fecha.getHours();
  let minutosEstablecidos = fecha.getMinutes();

  useEffect(() => {
    getOrderedActions();
    setValue(
      "hours_reminder",
      minutosEstablecidos >= 55 ? horasEstablecidas + 1 : horasEstablecidas
    );
    setValue(
      "minutes_reminder",
      minutosEstablecidos >= 55
        ? "0 min"
        : `${Math.floor(minutosEstablecidos / 5) * 5 + 5} min`
    );
  }, [getOrderedActions, modal, setValue]);

  const addActions = (data) => {
    const dateObject = {
      date: moment(dateReminder).format("YYYY-MM-DD"),
      hours: data.hours_reminder.toString(),
      minutes: data.minutes_reminder,
    };
    const objectAction = {
      "action_requested.action_id": data.action,
      "action_requested.action_date": dateObject,
      "action_requested.user": user,
      "action_requested.comments": data.comments,
    };
    addActionRequested(user?.company, deal_id, objectAction);
    toast.success("Accion creada con exito.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setModal(false);
    setdateReminder("");
    reset();
  };

  return (
    <Modal show={modal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="deal-title">Agregar accion</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(addActions)}>
        <Modal.Body>
          <div
            className="d-flex align-items-start justify-content flex-column"
            style={{ width: "100%" }}
          >
            <div
              className="d-flex align-items-center justify-content-center mb-3"
              style={{ width: "100%" }}
            >
              <div style={{ width: "100%" }} className="ms-1">
                <label className="label-input">Accion - Team</label>
                <Form.Select
                  className="select-order"
                  style={{ width: "100%", border: "2px solid #e1e7ef" }}
                  {...register("action")}
                >
                  {actions
                    ? getOrderedActions().map((action, i) =>
                        action[1].show ? (
                          <option key={i} value={action[0]}>
                            {action[1].team} - {action[1].title}
                          </option>
                        ) : null
                      )
                    : null}
                </Form.Select>
              </div>
            </div>
            <label className="label-input">Fecha</label>
            <div
              className="reminder-input d-flex align-items-center justify-content-between mb-2"
              onClick={() => setmodalCalendar(true)}
              style={{ cursor: "pointer", width: "100%" }}
            >
              <p
                className="reminder-text-placeholder"
                style={{ color: dateReminder !== "" ? "black" : "" }}
              >
                {dateReminder !== ""
                  ? dateReminder.toLocaleDateString()
                  : "Seleccione una fecha"}
              </p>
              <div className="me-2">
                <BiCalendarEvent
                  fontSize="24px"
                  color={colors.neutral.lightTwo}
                />
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-center mt-2"
              style={{ width: "100%" }}
            >
              <div style={{ width: "100%" }} className="me-1">
                <label className="label-input">Horas</label>
                <Form.Select
                  className="select-order"
                  style={{ width: "100%", border: "2px solid #e1e7ef" }}
                  {...register("hours_reminder")}
                >
                  <option>07</option>
                  <option>08</option>
                  <option>09</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                  <option>16</option>
                  <option>17</option>
                  <option>18</option>
                  <option>19</option>
                  <option>20</option>
                  <option>21</option>
                </Form.Select>
              </div>
              <div style={{ width: "100%" }} className=" ms-1">
                <label className="label-input">Minutos</label>
                <Form.Select
                  className="select-order"
                  style={{ width: "100%", border: "2px solid #e1e7ef" }}
                  {...register("minutes_reminder")}
                >
                  <option>0 min</option>
                  <option>5 min</option>
                  <option>10 min</option>
                  <option>15 min</option>
                  <option>20 min</option>
                  <option>25 min</option>
                  <option>30 min</option>
                  <option>35 min</option>
                  <option>40 min</option>
                  <option>45 min</option>
                  <option>50 min</option>
                  <option>55 min</option>
                </Form.Select>
              </div>
            </div>
            <label className="label-input mt-3">Comentario adicional</label>
            <textarea
              placeholder="Agregar comentario adicional"
              className="reminder-input"
              style={{ height: "90px", width: "100%" }}
              {...register("comments")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            style={{ width: "100%" }}
            type="submit"
          >
            Agregar
          </Button>
        </Modal.Footer>
      </form>
      <ModalCalendarComponent
        modalCalendar={modalCalendar}
        setmodalCalendar={setmodalCalendar}
        setdateReminder={setdateReminder}
        modal={modal}
      />
    </Modal>
  );
}
