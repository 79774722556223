export const paneles = [
  { value: "ninguno", label: "Ninguno" },
  { value: "capot", label: "Capot" },
  { value: "techo", label: "Techo" },
  { value: "baul", label: "Baul" },
  { value: "paragolpe_delantero", label: "Paragolpe delantero" },
  { value: "paragolpe_trasero", label: "Paragolpe trasero" },
  {
    value: "guardabarro_delantero_derecho",
    label: "Guardabarro delantero derecho",
  },
  {
    value: "guardabarro_delantero_izquierdo",
    label: "Guardabarro delantero izquierdo",
  },
  {
    value: "guardabarro_trasero_derecho",
    label: "Guardabarro trasero derecho",
  },
  {
    value: "guardabarro_trasero_izquierdo",
    label: "Guardabarro trasero izquierdo",
  },
  { value: "puerta_delantera_derecha", label: "Puerta delantera derecha" },
  { value: "puerta_delantera_izquierda", label: "Puerta delantera izquierda" },
  { value: "puerta_trasera_derecha", label: "Puerta trasera derecha" },
  { value: "puerta_trasera_izquierda", label: "Puerta trasera izquierda" },
  { value: "espejos", label: "Espejos" },
];
export const panelesLeves = [
  { value: "leves_ninguno", label: "Ninguno" },
  { value: "leves_capot", label: "Capot" },
  { value: "leves_techo", label: "Techo" },
  { value: "leves_baul", label: "Baul" },
  { value: "leves_paragolpe_delantero", label: "Paragolpe delantero" },
  { value: "leves_paragolpe_trasero", label: "Paragolpe trasero" },
  {
    value: "leves_guardabarro_delantero_derecho",
    label: "Guardabarro delantero derecho",
  },
  {
    value: "guardabarro_delantero_izquierdo",
    label: "Guardabarro delantero izquierdo",
  },
  {
    value: "leves_guardabarro_trasero_derecho",
    label: "Guardabarro trasero derecho",
  },
  {
    value: "leves_guardabarro_trasero_Izquierdo",
    label: "Guardabarro trasero izquierdo",
  },
  {
    value: "leves_puerta_delantera_derecha",
    label: "Puerta delantera derecha",
  },
  {
    value: "leves_puerta_delantera_izquierda",
    label: "Puerta delantera izquierda",
  },
  { value: "leves_puerta_trasera_derecha", label: "Puerta trasera derecha" },
  {
    value: "leves_puerta_trasera_izquierda",
    label: "Puerta trasera izquierda",
  },
  { value: "leves_espejo", label: "Espejos" },
];
export const accesorios = [
  {
    value: "llave_comando",
    label: "Llave comando (funcionando comando y arranque)",
  },
  { value: "segunda_llave", label: "Segunda llave (funcionando arranque)" },
  { value: "manual", label: "Manual" },
  { value: "rueda_de_auxilio", label: "Rueda de auxilio" },
  { value: "cricket", label: "Cricket" },
  { value: "tuerca_de_seguridad", label: "Tuerca de seguridad" },
  { value: "ninguno", label: "Ninguno" },
];
export const documentos = [
  { value: "documentos", label: "Poseo todos los documentos" },
  { value: "verificacion_policial", label: "Verificación Policial" },
  { value: "vtv", label: "VTV" },
  { value: "grabado_autopartes", label: "Grabado de autopartes" },
  { value: "grabado_cristales", label: "Grabado de cristales" },
  { value: "titulo_auto", label: "Título del auto" },
  { value: "cedulas_auto", label: "Cédulas del auto" },
];

/// INSPECCION PRE TOMA - ACCESORIOS

///GUANTERA

export const guantera = [
  { value: "Si", register: "segunda_llave" },
  { value: "No", register: "segunda_llave" },
  { value: "N/C", register: "segunda_llave" },
];

export const listadoServices = [
  { value: "Si", register: "service" },
  { value: "No", register: "service" },
  { value: "N/C", register: "service" },
];

///GENERALES

export const generalesCrique = [
  { value: "Si", register: "crique" },
  { value: "No", register: "crique" },
];

export const generalesLlave = [
  { value: "Si", register: "llave" },
  { value: "No", register: "llave" },
];

export const generalesRuedaAuxilio = [
  { value: "Si", register: "rueda_auxilio" },
  { value: "No", register: "rueda_auxilio" },
];

///SEGURIDAD

export const seguridadMatafuegos = [
  { value: "Si", register: "matafuegos" },
  { value: "No", register: "matafuegos" },
];

export const seguridadValiza = [
  { value: "Si", register: "valiza" },
  { value: "No", register: "valiza" },
];

export const seguridadChaleco = [
  { value: "Si", register: "chaleco" },
  { value: "No", register: "chaleco" },
];

export const seguridadBotiquin = [
  { value: "Si", register: "botiquin" },
  { value: "No", register: "botiquin" },
];

export const seguridadLingaRemolque = [
  { value: "Si", register: "linga_remolque" },
  { value: "No", register: "linga_remolque" },
];

export const seguridadOtros = [
  { value: "Si", register: "otros" },
  { value: "No", register: "otros" },
];

///VARIOS

export const seguridadBulonesSeguridad = [
  { value: "Si", register: "bulones_seguridad" },
  { value: "No", register: "bulones_seguridad" },
];

export const seguridadPortaEquipaje = [
  { value: "Si", register: "portaequipaje" },
  { value: "No", register: "portaequipaje" },
];
