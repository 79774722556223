import React, { useEffect, useState } from "react";
import { Modal, Row, Button, Col } from "react-bootstrap";
import InputComponent from "../../../../components/InputComponent";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { colors } from "../../../../styles/colors";
import { useUser } from "../../../../context/UserContext";
import {
  createDiscount,
  editDiscount,
} from "../../services/TakeServices/AmountToPayServices";
import { formatNumber } from "../../../Pricing/services/servicesPricing";

export default function ModalAddOrEditDiscountsComponent({
  setModal,
  modal,
  isEditing,
  dataEdit,
  discounts,
  isBenefits,
}) {
  const params = useParams();

  const { register, handleSubmit, reset, setValue } = useForm();
  const [valueInput, setvalueInput] = useState("");
  const [valueInputsBenefits, setValueInputsBenefits] = useState("");

  const { user } = useUser();

  useEffect(() => {
    if (isEditing && !isBenefits) {
      setValue("name", dataEdit?.name);
      setValue("value", dataEdit?.value);
    } else if (isEditing && isBenefits) {
      setValue("discounts_benefits", dataEdit?.discounts_benefits);
      setValue("benefits", dataEdit?.benefits);
    }
  }, [dataEdit, isEditing, setValue]);

  const editDiscountData = (data) => {
    const newObj = Object.assign({}, dataEdit, data);
    const indexDiscount = discounts.findIndex(
      (item) => item.id === dataEdit.id
    );
    discounts[indexDiscount] = newObj;
    editDiscount(params.deal_id, discounts, user, isBenefits);
    reset();
    setModal(false);
  };

  const addAccount = (data) => {
    if (isEditing) {
      //editar
      editDiscountData(data);
      toast.success("Informacion editada con exito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
    } else {
      //crear
      createDiscount(
        params.deal_id,
        !isBenefits ? data.value : data.discounts_benefits,
        !isBenefits ? data.name : data.benefits,
        user,
        isBenefits
      );
      toast.success("Informacion Guardada con exito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
    }
    setvalueInput("");
    reset();
    setModal(false);
  };

  const handleClose = () => {
    reset();
    setModal(false);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setvalueInput(formattedValue);
  };

  const handleInputChangeBenefits = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setValueInputsBenefits(formattedValue);
  };

  return (
    <>
      <Modal size="lg" show={modal} onHide={handleClose}>
        <form>
          <Modal.Header closeButton>
            <Modal.Title className="deal-title">
              {isEditing ? "Editar" : "Crear"}{" "}
              {isBenefits ? "adicional" : "descuento"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!isBenefits ? (
              <Row>
                <Col lg={6}>
                  <InputComponent
                    label={"Descuento"}
                    placeholder=""
                    type="text"
                    register={register}
                    valueRegister={"name"}
                  />
                </Col>
                <Col lg={6}>
                  <div style={{ marginBottom: "12px", marginTop: "8px" }}>
                    <label className="label-input">Monto</label>
                    <input
                      className="input-deal"
                      style={{ width: "100%" }}
                      {...register("value")}
                      value={valueInput}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col lg={4}>
                  <InputComponent
                    label={"Adicional"}
                    placeholder=""
                    type="text"
                    register={register}
                    valueRegister={"discounts_benefits"}
                  />
                </Col>
                <Col lg={6}>
                  <div style={{ marginBottom: "12px", marginTop: "8px" }}>
                    <label className="label-input">Monto</label>
                    <input
                      className="input-deal"
                      style={{ width: "100%" }}
                      {...register("benefits")}
                      value={valueInputsBenefits}
                      onChange={handleInputChangeBenefits}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleSubmit(addAccount)}>
              {isEditing ? "Editar" : "Crear"}{" "}
              {isBenefits ? "adicional" : "descuento"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
