import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { StatusIconComponent } from "../../../../components";
import { colors } from "../../../../styles/colors";
import { getStatusColor } from "../../../../utils/StatusColor";
import {
  formatNumber,
  updateOfferExpected,
} from "../../services/servicesPricing";

const ExpectedOffer = ({
  user,
  deal_id,
  expectedOffer,
  setActiveModalPricing,
}) => {
  const [showModal, setshowModal] = useState(false);
  const [expectedOfferValue, setexpectedOfferValue] = useState("");

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);

    setexpectedOfferValue(formattedValue);
  };

  const { register, handleSubmit } = useForm();

  const closeModal = () => {
    setshowModal(false);
    setActiveModalPricing(false);
  };

  const sendOfferExpected = (dataInput) => {
    const data = {
      "expected_offer.value": dataInput.value,
      "expected_offer.user": user,
    };
    updateOfferExpected(user?.company, deal_id, data);
    toast.success("Oferta esperada enviada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowModal(false);
    setActiveModalPricing(false);
  };

  return (
    <>
      <div
        className="containerPricing"
        onClick={() => {
          setshowModal(true);
          setActiveModalPricing(true);
        }}
      >
        <p className="textTitle">Oferta esperada</p>
        <div className="divider-pricing" />
        <div className="text-and-icon">
          <p
            className="textPricing"
            style={{
              // color: getStatusColor(expectedOffer?.status),
              color:
                expectedOffer?.value !== ""
                  ? colors.primary.primary
                  : colors.primary.secondaryLight,
            }}
          >
            {expectedOffer?.value === ""
              ? "$00.000.000"
              : "$" + expectedOffer?.value}
          </p>

          {expectedOffer?.status === "" ? (
            <span className="status-icon"></span>
          ) : (
            <StatusIconComponent status={expectedOffer?.status} />
          )}
        </div>
      </div>
      <div>
        <Modal
          show={showModal}
          onHide={closeModal}
          centered
          dialogClassName="myModal"
        >
          <Modal.Body>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <p className="titleModal">Oferta esperada</p>
              <form
                style={{ width: "100%" }}
                onSubmit={handleSubmit(sendOfferExpected)}
              >
                <input
                  className="inputModal mt-3"
                  type="text"
                  placeholder={
                    expectedOffer?.value !== ""
                      ? expectedOffer?.value
                      : "$0.000.000"
                  }
                  style={{ width: "100%" }}
                  {...register("value")}
                  value={expectedOfferValue}
                  onChange={handleInputChange}
                />
                <div
                  style={{ width: "100%" }}
                  className="d-flex flex-column align-items-center justify-content-between"
                >
                  <button
                    variant="primary"
                    className="btnSendPricing mb-2 mt-3"
                    type="submit"
                  >
                    Guardar
                  </button>
                </div>
              </form>
              <button
                variant="secondary"
                onClick={closeModal}
                className="btnCancelarModal"
              >
                Cancelar
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ExpectedOffer;
