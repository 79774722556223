import { apiUrl, axiosInstance, handleError } from "../../../../services/apiConfig";

// POST
export const postDiscountsData = async ({ deal_id, company, body }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/pre_take/`, body, {
      params: {
        deal_id,
        company,
      }
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al realizar la comprobación' };
  }
};

// GET
export const getDiscountsData = async ({ deal_id, company }) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/pre_take`, {
      params: { deal_id, company }
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener la comprobación' };
  }
};
