import { apiUrl, axiosInstance, handleError } from "./apiConfig";


export const getUser = async ({ params }) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/users/get_user`, {
      params,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener datos de la API' };
  }
};


export const createUser = async ({
  user_id,
  company,
  name,
  email,
  team }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/users/create_user`, {
      user_id,
      company,
      name,
      email,
      team,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener datos de la API' };
  }
};