import React from "react";
import "../../../../../styles/style/dragAndDropFileStyle.css";
import DragAndDropComponent from "../../../../../components/DragAndDropComponent";

export default function AttachmentPreTakeComponent({
  isEditing,
  dataResultFiles,
  setDataResultFiles,
}) {
  return (
    <>
      <DragAndDropComponent
        dataFiles={dataResultFiles}
        setDataFiles={setDataResultFiles}
        disabled={isEditing}
      />
    </>
  );
}
