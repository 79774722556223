import TechnicalSheetCardComponent from "./TechnicalSheetCardComponent";

const SecurityComponent = ({ data }) => {
  return (
    <>
      <TechnicalSheetCardComponent title="Seguridad" data={data} />
    </>
  );
};

export default SecurityComponent;
