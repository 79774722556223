import { Modal } from "react-bootstrap";
import { solicitarCotizacion } from "../../../services/servicesPricing";

const ApplyModal = ({
  deal_id,
  setshowApplyModal,
  showApplyModal,
  titleModal,
  user,
  actionId,
  setActiveModalPricing,
}) => {
  const handleApplyModal = () => {
    setshowApplyModal(false);
    setActiveModalPricing(false);
  };

  const sendApply = () => {
    const data = {
      "action_requested.action_id": actionId,
      "action_requested.comments": "",
      "action_requested.user": user,
      "action_requested.data_reminder": {},
    };
    solicitarCotizacion(user?.company, deal_id, data);
    setshowApplyModal(false);
  };

  return (
    <div>
      <Modal
        show={showApplyModal}
        onHide={handleApplyModal}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="titleModal">{titleModal}</p>
            <div
              style={{ width: "100%" }}
              className="d-flex flex-column align-items-center justify-content-between"
            >
              <button
                variant="primary"
                className="btnSendPricing mb-2 mt-3"
                onClick={sendApply}
              >
                Solicitar
              </button>
              <button
                variant="secondary"
                onClick={handleApplyModal}
                className="btnCancelarModal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ApplyModal;
