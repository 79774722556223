import { useState, useCallback } from "react";
import { useIdleTimer } from "react-idle-timer";

const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);

  // Maneja el evento de inactividad
  const handleIdle = useCallback(() => {
    setIdle(true);
    onIdle();
  }, [onIdle]);

  // Maneja el evento de reactivación
  const handleActive = useCallback(() => {
    setIdle(false);
  }, []);

  // Configura el idle timer
  const { reset, ...idleTimer } = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: idleTimeout / 2,
    onIdle: handleIdle,
    onActive: handleActive,
    debounce: 500,
  });

  // Devuelve el estado y las funciones del idle timer
  return {
    isIdle,
    setIdle,
    idleTimer,
    reset,
  };
};

export default useIdleTimeout;