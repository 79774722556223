import { Form, Modal } from "react-bootstrap";
import { colors } from "../../styles/colors";
import { useState } from "react";
import { sendCloseDeal } from "./servicesMenu";
import { useForm } from "react-hook-form";
import { MdClose } from "react-icons/md";

const ButtonCloseDeal = ({ deal_id, user }) => {
  const [modal, setmodal] = useState(false);

  const { register, handleSubmit, reset } = useForm();
  const [valueRazon, setvalueRazon] = useState(true);
  const [valueTicketTrengo, setvalueTicketTrengo] = useState(false);

  const titleModal = {
    color: colors.primary.secondaryTwo,
    fontWeight: "500",
    fontSize: "16px",
    margin: "0px",
  };

  const btnYes = {
    backgroundColor: colors.primary.primary,
    border: "none",
    borderRadius: "8px",
    width: "100%",
    height: "50px",
    color: colors.neutral.light,
  };

  const closeDeal = (dataForm) => {
    const dateObject = {
      motivo_cierre: dataForm.motivo_cierre,
      comments: dataForm.comments,
      comunicar_cliente_sucursal: valueRazon,
      close_ticket_trengo: valueTicketTrengo,
    };
    const data = {
      "action_requested.action_id": "close",
      "action_requested.comments": "",
      "action_requested.user": user,
      "action_requested.close_data": dateObject,
    };
    sendCloseDeal(user?.company, deal_id, data);
    setmodal(false);
    reset();
  };

  const handleClose = () => setmodal(false);

  const handleChange = (e) => {
    setvalueRazon(
      e.target.value === "Rechazo en inspección sin OC" ||
        e.target.value === "Rechazo en inspección con OC"
    );
  };

  const handleCheckboxChange = () => {
    setvalueRazon(!valueRazon);
  };

  const handleCheckboxChangeTicketTrengo = () => {
    setvalueTicketTrengo(!valueTicketTrengo);
  };

  return (
    <>
      <div>
        <span onClick={() => setmodal(true)} style={{ cursor: "pointer" }}>
          <MdClose
            fontSize="20"
            color="white"
            style={{ backgroundColor: colors.status.red, borderRadius: "50%" }}
          />
        </span>
      </div>
      <Modal show={modal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <p style={titleModal}>Confirmar cierre del deal</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(closeDeal)}>
            <div className="d-flex align-items-start flex-column">
              <label className="label-input">Motivo de cierre</label>
              <Form.Select
                {...register("motivo_cierre")}
                onChange={(e) => handleChange(e)}
              >
                <option>Rechazo comercial con OC</option>
                <option>Rechazo comercial sin OC</option>
                <option>Rechazo en inspección sin OC</option>
                <option>Rechazo en inspección con OC</option>
                <option>Rango inicial bajo</option>
                <option>Oferta inicial baja</option>
                <option>Oferta Virtual baja</option>
                <option>Oferta final baja</option>
                <option>No pasa preguntas de seguridad</option>
                <option>Cliente del Interior</option>
                <option>Solo queria cotizar</option>
                <option>Cierre con Recordatorio</option>
                <option>Vendido</option>
                <option>Sin Respuesta</option>
                <option>No interesado</option>
                <option>Lead de Prueba</option>
                <option>Lead Duplicado</option>
                <option>Error de dato</option>
              </Form.Select>
              <label className="label-input mt-3">Comentario</label>
              <textarea
                className="inputModal"
                style={{ width: "100%", height: "110px" }}
                placeholder="Dejar observaciones adicionales"
                {...register("comments")}
              />
              <div className="d-flex align-items-center mt-3">
                <input
                  className="form-check-input"
                  style={{ margin: "0" }}
                  type="checkbox"
                  checked={valueRazon}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="label-input-check ms-1"
                  style={{ margin: "0" }}
                >
                  Comunicar al cliente en sucursal
                </label>
              </div>
              <div className="d-flex align-items-center mt-1">
                <input
                  className="form-check-input"
                  style={{ margin: "0" }}
                  type="checkbox"
                  checked={valueTicketTrengo}
                  onChange={handleCheckboxChangeTicketTrengo}
                />
                <label
                  className="label-input-check ms-1"
                  style={{ margin: "0" }}
                >
                  Cerrar ticket en Trengo
                </label>
              </div>
              <button style={btnYes} className="mt-3" type="submit">
                Confirmar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ButtonCloseDeal;
