import { apiUrl, axiosInstance, handleError } from "../../../services/apiConfig";

export const updateGoogleSheets = async ({ data }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/get_deals_sheet`, data);
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al modificar sheet' };
  }
};