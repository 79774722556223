import { db } from "../../../../firebase/config";
import { generateId } from "../../../../utils/IdGenerator";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore"; // Importamos las funciones necesarias

export const deleteDiscount = async (deal_id, data, user, isBenefits) => {
  const takeDocRef = doc(
    db,
    "companies",
    user?.company,
    "deals",
    deal_id,
    "data",
    "take"
  );

  if (!isBenefits) {
    try {
      await updateDoc(takeDocRef, {
        "amount_to_pay.discounts": arrayRemove(data),
        user_update_data: user,
      });
    } catch (error) {
      return error;
    }
  } else {
    try {
      await updateDoc(takeDocRef, {
        "amount_to_pay.benefits": arrayRemove(data),
        user_update_data: user,
      });
    } catch (error) {
      return error;
    }
  }
};

export const createDiscount = async (deal_id, value, name, user, isBenefits) => {
  const takeDocRef = doc(
    db,
    "companies",
    user?.company,
    "deals",
    deal_id,
    "data",
    "take"
  );

  const newDiscount = {
    id: generateId(),
    value: value,
    name: name,
  };

  const newBenefit = {
    id: generateId(),
    discounts_benefits: value,
    benefits: name,
  };

  try {
    if (!isBenefits) {
      await updateDoc(takeDocRef, {
        "amount_to_pay.discounts": arrayUnion(newDiscount),
        user_update_data: user,
      });
    } else {
      await updateDoc(takeDocRef, {
        "amount_to_pay.benefits": arrayUnion(newBenefit),
        user_update_data: user,
      });
    }
  } catch (error) {
    return error;
  }
};

export const editDiscount = async (deal_id, newArray, user, isBenefits) => {
  const takeDocRef = doc(
    db,
    "companies",
    user?.company,
    "deals",
    deal_id,
    "data",
    "take"
  );

  try {
    if (!isBenefits) {
      await updateDoc(takeDocRef, {
        "amount_to_pay.discounts": newArray,
        user_update_data: user,
      });
    } else {
      await updateDoc(takeDocRef, {
        "amount_to_pay.benefits": newArray,
        user_update_data: user,
      });
    }
  } catch (error) {
    console.error("Error updating discount:", error);
  }
};

export const typeMoney = async (deal_id, data, user) => {
  const takeDocRef = doc(
    db,
    "companies",
    user?.company,
    "deals",
    deal_id,
    "data",
    "take"
  );

  try {
    await updateDoc(takeDocRef, {
      "amount_to_pay.money": data.money,
      "amount_to_pay.type_change_money": data.type_change_money,
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error updating money:", error);
  }
};
