import React from "react";
import TakeTabsComponent from "../components/take/TakeTabsComponent";

export default function TakePage() {
  return (
    <div className="containerAll">
      <TakeTabsComponent />
    </div>
  );
}
