export default function removeDuplicateWords(text) {
  const words = text.split(' ');
  const wordMap = {};

  const uniqueWords = words.filter(word => {
    const lowercaseWord = word.toLowerCase();

    if (wordMap[lowercaseWord]) {
      return false;
    }

    wordMap[lowercaseWord] = true;
    return true;
  });

  const result = uniqueWords.join(' ');

  return result;
}