import React, { useEffect, useState } from 'react';
import { Col, Container, Form, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { MdInfo } from 'react-icons/md';
import { handleSingleCheckAndInputBoxChange, updateFormDataTableView } from '../../../../../utils/sectionFuncions';
import { checkboxValuesSeg, colorOptions, fuelOptions, options } from '../../../../../constants/sectionsConst';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';

export default function Section1TabComponent({
  formData,
  setFormData,
  saveFromDataString,
  saveFormDataObjet,
  editAllowed,
  setIsSection1Valid
}) {
  let isMobile = useMediaQuery("(max-width: 500px)");
  const { section1 } = formData;

  const [selectedValue, setSelectedValue] = useState('Warnes');
  const [selectedColor, setSelectedColor] = useState('');
  const [patentValue, setPatentValue] = useState('');
  const [brandValue, setBrandValue] = useState('');
  const [versionValue, setVersionValue] = useState('');
  const [modelValue, setModelValue] = useState('');
  const [yearValue, setYearValue] = useState('');
  const [mileageValue, setMileageValue] = useState('');
  const [doorsValue, setDoorsValue] = useState('');

  useEffect(() => {
    if (formData) {
      setPatentValue(section1?.carPlate);
      setBrandValue(section1?.brand);
      setVersionValue(section1?.version);
      setModelValue(section1?.model);
      setYearValue(section1?.year);
      setMileageValue(section1?.mileage);
      setDoorsValue(section1?.doors);
    }
    validateSection1();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, section1?.brand, section1?.carPlate, section1?.doors, section1?.mileage, section1?.model, section1?.version, section1?.year]);

  const validateSection1 = () => {
    const isValid = patentValue && brandValue && versionValue && modelValue && yearValue && mileageValue && doorsValue &&
      (inspectionPlace && (knownPlaces.includes(inspectionPlace) || isOtherPlace)) &&
      (colorData && (colorOptions.includes(colorData) || isOtherColor));
    setIsSection1Valid(isValid);
  };

  const handleCheckboxAndInputChangeInsp = (value) => {
    saveFormDataObjet('section1', 'inspectionPlace',
      { title: 'Inspección para:', value: value });
  };

  const handleCheckboxChangeSeg = (value) => {
    saveFormDataObjet('section1', 'segment', { title: 'Segmento', value: value });
  };

  const handleCheckboxAndInputChangeColor = (color) => {
    saveFormDataObjet('section1', 'color', { title: 'Color', value: color });
  };

  const handleFuelChange = (fuel) => {
    saveFormDataObjet('section1', 'fuel', { title: 'Combustible', value: fuel });
  };

  const handleTextChange = (setValue, key) => (event) => {
    const { value } = event.target;
    setValue(value);
    saveFromDataString('section1', key, value);
  };

  //este es el unico q va en la tabla
  const handleCheckboxChange = (sectionName, nestedObjectName, rowTitle, optionIndex) => {
    const selectedOption = options[optionIndex];
    updateFormDataTableView(setFormData, sectionName, nestedObjectName, rowTitle, selectedOption, formData[sectionName]);
  };
  const knownPlaces = ['Independencia', 'Warnes', 'Lavallol'];
  const inspectionPlace = formData?.section1?.inspectionPlace?.value;
  const isOtherPlace = inspectionPlace && !knownPlaces.includes(inspectionPlace);
  const colorData = formData?.section1?.color?.value
  const isOtherColor = colorData && !colorOptions.includes(colorData);

  const formatNumberWithDotSeparator = (numberString) => {
    if (!numberString) return ''; // Manejar el caso en que el valor sea null o undefined
    const number = parseInt(numberString.replace(/\./g, ''), 10); // Convertir a entero y eliminar los puntos
    if (isNaN(number)) return numberString; // Si no es un número válido, devolver el valor original
    return number.toLocaleString('es-ES'); // Formatear el número con el separador de miles
  };
  return (
    <>
      {!isMobile ? (
        <Container fluid>
          <h1 className='section-title'>Inicio</h1>
          <Row>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center mb-1'>
                  <label className='section-input-label me-1'>Patente</label>
                  <span className="text-danger">*</span>
                </div>
                <input
                  type="text"
                  className="section-input"
                  value={patentValue}
                  onChange={handleTextChange(setPatentValue, 'carPlate')}
                  disabled={!editAllowed}
                />
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center mb-1'>
                  <label className='section-input-label me-1'>Inspección para:</label>
                  <span className="text-danger">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Body>
                          Información
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <span>
                      <MdInfo className='section-info-icon' />
                    </span>
                  </OverlayTrigger>
                </div>
                <div className='section-container'>
                  {['Warnes', 'Independencia', 'Lavallol'].map((option, index) => (
                    <Form.Check
                      key={option}
                      type="checkbox"
                      label={option}
                      name={option}
                      value={option}
                      checked={inspectionPlace === option}
                      className={`section-checks ${index === 2 ? 'mb-1' : 'mb-2'}`}
                      onChange={(e) => {
                        handleSingleCheckAndInputBoxChange(option, setSelectedValue);
                        handleCheckboxAndInputChangeInsp(e.target.value);
                      }}
                      disabled={!editAllowed}
                    />
                  ))}
                  <div className="d-flex align-items-center">
                    <Form.Check
                      type="checkbox"
                      label="Otro:"
                      name="Other"
                      value="Other"
                      checked={isOtherPlace || selectedValue === 'Other'}
                      className='section-checks'
                      onChange={(e) => {
                        handleSingleCheckAndInputBoxChange(e.target.value, setSelectedValue);
                      }}
                      disabled={!editAllowed}
                    />
                    <input
                      type="text"
                      className='section-input ms-2'
                      value={isOtherPlace ? inspectionPlace : ''}
                      onChange={(e) => handleCheckboxAndInputChangeInsp(e.target.value)}
                      disabled={selectedValue !== 'Other' || !editAllowed}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-2'>
                <div className='d-flex align-items-center mb-1'>
                  <label className='section-input-label me-1'>Documentación</label>
                  <span className="text-danger">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Body>
                          Información
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <span>
                      <MdInfo className='section-info-icon' />
                    </span>
                  </OverlayTrigger>
                </div>
                <table className="section-table" id="section1-documentation">
                  <thead>
                    <tr>
                      <th className="section-table-cell"></th>
                      {options.map((option, index) => (
                        <th key={index} className="section-table-cell">{option}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {section1?.documentation?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="section-table-cell">{row.title}</td>
                        {options.map((option, optionIndex) => (
                          <td key={optionIndex} className="section-table-cell">
                            <Form.Check
                              type="checkbox"
                              className='section-checks'
                              checked={row.values.includes(option)}
                              onChange={() => handleCheckboxChange('section1', 'documentation', row.title, optionIndex)}
                              disabled={!editAllowed}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row className='mt-2 mb-2'>
            <Col md={6}>
              <div className='mb-2'>
                <div className='d-flex align-items-center mb-1'>
                  <label className='section-input-label me-1'>Marca</label>
                  <span className="text-danger">*</span>
                </div>
                <input
                  type="text"
                  className="section-input"
                  value={brandValue}
                  onChange={handleTextChange(setBrandValue, 'brand')}
                  disabled={!editAllowed}
                />
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center mb-1'>
                  <label className='section-input-label me-1'>Versión</label>
                  <span className="text-danger">*</span>
                </div>
                <input
                  type="text"
                  className="section-input"
                  value={versionValue}
                  onChange={handleTextChange(setVersionValue, 'version')}
                  disabled={!editAllowed}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-2'>
                <div className='d-flex align-items-center mb-1'>
                  <label className='section-input-label me-1'>Modelo</label>
                  <span className="text-danger">*</span>
                </div>
                <input
                  type="text"
                  className="section-input"
                  value={modelValue}
                  onChange={handleTextChange(setModelValue, 'model')}
                  disabled={!editAllowed}
                />
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center mb-1'>
                  <label className='section-input-label me-1'>Año</label>
                  <span className="text-danger">*</span>
                </div>
                <input
                  type="text"
                  className="section-input"
                  value={yearValue}
                  onChange={handleTextChange(setYearValue, 'year')}
                  disabled={!editAllowed}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center mb-1'>
                  <label className='section-input-label me-1'>Segmento</label>
                  <span className="text-danger">*</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Body>
                          Información
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <span>
                      <MdInfo className='section-info-icon' />
                    </span>
                  </OverlayTrigger>
                </div>
                <div className='section-container'>
                  {checkboxValuesSeg.map((value, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={value}
                      id={`checkbox-${index}`}
                      checked={formData?.section1?.segment?.value === value}
                      onChange={() => handleCheckboxChangeSeg(value)}
                      className={`section-checks ${index === 11 ? 'mb-1' : 'mb-2'}`}
                      disabled={!editAllowed}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center mb-1'>
                  <label className='section-input-label me-1'>Combustible</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container'>
                  {fuelOptions.map((fuel, index) => (
                    <Form.Check
                      key={index}
                      type="radio"
                      label={fuel}
                      name="fuel"
                      checked={formData?.section1?.fuel.value === fuel}
                      onChange={() => handleFuelChange(fuel)}
                      className={`section-radio ${index === 1 ? 'mb-1' : 'mb-2'}`}
                      disabled={!editAllowed}
                    />
                  ))}
                </div>
              </div>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center mb-1'>
                  <label className='section-input-label me-1'>Color</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container'>
                  {colorOptions.map((color, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={color}
                      name={color}
                      value={color}
                      checked={colorData === color}
                      className={`section-checks ${index === colorOptions.length - 1 ? 'mb-1' : 'mb-2'}`}
                      onChange={(e) => {
                        handleSingleCheckAndInputBoxChange(color, setSelectedColor);
                        handleCheckboxAndInputChangeColor(e.target.value);
                      }}
                      disabled={!editAllowed}
                    />
                  ))}
                  <div className="d-flex align-items-center">
                    <Form.Check
                      type="checkbox"
                      label="Otro:"
                      name="Other"
                      value='Other'
                      checked={isOtherColor || selectedColor === 'Other'}
                      className='section-checks'
                      onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedColor)}
                      disabled={!editAllowed}
                    />
                    <input
                      type="text"
                      className='section-input ms-2'
                      value={isOtherColor ? colorData : ''}
                      onChange={(e) => handleCheckboxAndInputChangeColor(e.target.value)}
                      disabled={selectedColor !== 'Other' || !editAllowed}
                    />
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='mb-2'>
                  <div className='d-flex align-items-center mb-1'>
                    <label className='section-input-label me-1'>Kilometraje</label>
                    <span className="text-danger">*</span>
                  </div>
                  <input
                    type="text" max={6}
                    className="section-input"
                    value={formatNumberWithDotSeparator(mileageValue)}
                    onChange={handleTextChange(setMileageValue, 'mileage')}
                    disabled={!editAllowed}
                  />
                </div>
                <div className='mb-2'>
                  <div className='d-flex align-items-center mb-1'>
                    <label className='section-input-label me-1'>Puertas</label>
                    <span className="text-danger">*</span>
                  </div>
                  <input
                    type="number"
                    className="section-input"
                    value={doorsValue}
                    onChange={handleTextChange(setDoorsValue, 'doors')}
                    disabled={!editAllowed}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid>
          <h1 className='section-title'>Inicio</h1>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Patente</label>
              <span className="text-danger">*</span>
            </div>
            <input
              type="text"
              className="section-input"
              value={patentValue}
              onChange={handleTextChange(setPatentValue, 'carPlate')}
              disabled={!editAllowed}
            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Documentación</label>
              <span className="text-danger">*</span>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Body>
                      Información
                    </Popover.Body>
                  </Popover>
                }
              >
                <span>
                  <MdInfo className='section-info-icon' />
                </span>
              </OverlayTrigger>
            </div>
            <table className="section-table" id="section1-documentation">
              <thead>
                <tr>
                  <th className="section-table-cell"></th>
                  {options.map((option, index) => (
                    <th key={index} className="section-table-cell">{option}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {section1?.documentation?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="section-table-cell">{row.title}</td>
                    {options.map((option, optionIndex) => (
                      <td key={optionIndex} className="section-table-cell">
                        <Form.Check
                          type="checkbox"
                          className='section-checks'
                          checked={row.values.includes(option)}
                          onChange={() => handleCheckboxChange('section1', 'documentation', row.title, optionIndex)}
                          disabled={!editAllowed}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Inspección para:</label>
              <span className="text-danger">*</span>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Body>
                      Información
                    </Popover.Body>
                  </Popover>
                }
              >
                <span>
                  <MdInfo className='section-info-icon' />
                </span>
              </OverlayTrigger>
            </div>
            <div className='section-container'>
              {['Warnes', 'Independencia', 'Lavallol'].map((option, index) => (
                <Form.Check
                  key={option}
                  type="checkbox"
                  label={option}
                  name={option}
                  value={option}
                  checked={inspectionPlace === option}
                  className={`section-checks ${index === 2 ? 'mb-1' : 'mb-2'}`}
                  onChange={(e) => {
                    handleSingleCheckAndInputBoxChange(option, setSelectedValue);
                    handleCheckboxAndInputChangeInsp(e.target.value);
                  }}
                  disabled={!editAllowed}
                />
              ))}
              <div className="d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  label="Otro:"
                  name="Other"
                  value="Other"
                  checked={isOtherPlace || selectedValue === 'Other'}
                  className='section-checks'
                  onChange={(e) => {
                    handleSingleCheckAndInputBoxChange(e.target.value, setSelectedValue);
                  }}
                  disabled={!editAllowed}
                />
                <input
                  type="text"
                  className='section-input ms-2'
                  value={isOtherPlace ? inspectionPlace : ''}
                  onChange={(e) => handleCheckboxAndInputChangeInsp(e.target.value)}
                  disabled={selectedValue !== 'Other' || !editAllowed}
                />
              </div>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Marca</label>
              <span className="text-danger">*</span>
            </div>
            <input
              type="text"
              className="section-input"
              value={brandValue}
              onChange={handleTextChange(setBrandValue, 'brand')}
              disabled={!editAllowed}
            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Modelo</label>
              <span className="text-danger">*</span>
            </div>
            <input
              type="text"
              className="section-input"
              value={modelValue}
              onChange={handleTextChange(setModelValue, 'model')}
              disabled={!editAllowed}
            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Versión</label>
              <span className="text-danger">*</span>
            </div>
            <input
              type="text"
              className="section-input"
              value={versionValue}
              onChange={handleTextChange(setVersionValue, 'version')}
              disabled={!editAllowed}
            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Año</label>
              <span className="text-danger">*</span>
            </div>
            <input
              type="text"
              className="section-input"
              value={yearValue}
              onChange={handleTextChange(setYearValue, 'year')}
              disabled={!editAllowed}
            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Segmento</label>
              <span className="text-danger">*</span>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Body>
                      Información
                    </Popover.Body>
                  </Popover>
                }
              >
                <span>
                  <MdInfo className='section-info-icon' />
                </span>
              </OverlayTrigger>
            </div>
            <div className='section-container'>
              {checkboxValuesSeg.map((value, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={value}
                  id={`checkbox-${index}`}
                  checked={formData?.section1?.segment?.value === value}
                  onChange={() => handleCheckboxChangeSeg(value)}
                  className={`section-checks ${index === 11 ? 'mb-1' : 'mb-2'}`}
                  disabled={!editAllowed}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Color</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container'>
              {colorOptions.map((color, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={color}
                  name={color}
                  value={color}
                  checked={colorData === color}
                  className={`section-checks ${index === colorOptions.length - 1 ? 'mb-1' : 'mb-2'}`}
                  onChange={(e) => {
                    handleSingleCheckAndInputBoxChange(color, setSelectedColor);
                    handleCheckboxAndInputChangeColor(e.target.value);
                  }}
                  disabled={!editAllowed}
                />
              ))}
              <div className="d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  label="Otro:"
                  name="Other"
                  value='Other'
                  checked={isOtherColor || selectedColor === 'Other'}
                  className='section-checks'
                  onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedColor)}
                  disabled={!editAllowed}
                />
                <input
                  type="text"
                  className='section-input ms-2'
                  value={isOtherColor ? colorData : ''}
                  onChange={(e) => handleCheckboxAndInputChangeColor(e.target.value)}
                  disabled={selectedColor !== 'Other' || !editAllowed}
                />
              </div>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Combustible</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container'>
              {fuelOptions.map((fuel, index) => (
                <Form.Check
                  key={index}
                  type="radio"
                  label={fuel}
                  name="fuel"
                  checked={formData?.section1?.fuel.value === fuel}
                  onChange={() => handleFuelChange(fuel)}
                  className={`section-radio ${index === 1 ? 'mb-1' : 'mb-2'}`}
                  disabled={!editAllowed}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Kilometraje</label>
              <span className="text-danger">*</span>
            </div>
            <input
              type="text" max={6}
              className="section-input"
              value={formatNumberWithDotSeparator(mileageValue)}
              onChange={handleTextChange(setMileageValue, 'mileage')}
              disabled={!editAllowed}
            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center mb-1'>
              <label className='section-input-label me-1'>Puertas</label>
              <span className="text-danger">*</span>
            </div>
            <input
              type="number"
              className="section-input"
              value={doorsValue}
              onChange={handleTextChange(setDoorsValue, 'doors')}
              disabled={!editAllowed}
            />
          </div>
        </Container>
      )}
    </>
  );
}