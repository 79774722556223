import { doc, getDoc } from 'firebase/firestore';
import { db } from "../../../firebase/config";

export const getHistoryDeals = async (client_id) => {
  try {
    if (!client_id) {
      console.error("client_id is undefined or null");
      return null; // O arroja un error si lo prefieres
    }
    const docRef = doc(db, 'clients_admin', client_id);
    const dataHistoryDeals = await getDoc(docRef);

    if (dataHistoryDeals.exists()) {
      return dataHistoryDeals.data(); // Retorna los datos si existen
    } else {
      console.error("No such document!");
      return null; // Retorna null si no existe el documento
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    throw new Error('Error al traer el documento');
  }
}