import { Button, Modal } from "react-bootstrap";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as locales from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import { useState } from "react";
import { useDeals } from "./../../../context/DealsContext";

export default function ModalFilterDateComponent({
  openModal,
  setOpenModal,
  setState,
  state,
}) {
  const [locale] = useState("es");
  const { filters, setFilters } = useDeals();

  const handleClose = () => {
    setOpenModal(false);
  };

  const addHours = (date) => {
    console.log(date);
    date.setHours(date.getHours() + 23, 59);
    return date;
  };

  const handleChange = (item) => {
    console.log(item);
    let endDate = [item.selection][0]?.endDate;
    addHours(endDate);
    setState([item.selection]);
  };

  const sendApply = () => {
    setFilters({
      ...filters,
      selectedDates: { from: state[0].startDate, to: state[0].endDate },
      dealDateStatus: "",
    });
    setOpenModal(false);
  };

  return (
    <div>
      <Modal show={openModal} onHide={handleClose}>
        <DateRange
          editableDateInputs={true}
          onChange={handleChange}
          moveRangeOnFirstSelection={false}
          ranges={state}
          locale={locales[locale]}
          maxDate={new Date("2024-12-31")}
          minDate={new Date("2022-01-02")}
        />
        <div className="d-flex align-items-center justify-content-end">
          <Button
            size="sm"
            style={{ width: "65px", height: "30px" }}
            className="me-4 mb-4"
            onClick={sendApply}
          >
            Aplicar
          </Button>
        </div>
      </Modal>
    </div>
  );
}
