import { db } from "../../../firebase/config";
import { doc, updateDoc } from "firebase/firestore";

// Función personalizada para filtrar objetos
Object.filter = function (mainObject, filterFunction) {
  return Object.keys(mainObject)
    .filter(function (ObjectKey) {
      return filterFunction(mainObject[ObjectKey]);
    })
    .reduce(function (result, ObjectKey) {
      result[ObjectKey] = mainObject[ObjectKey];
      return result;
    }, {});
};

// Función para actualizar el resumen de un trato
export const updateResumen = async (company, deal_id, data, user) => {
  const results_object = {
    "results.status": data.status,
    "results.ready_to_sell": data.ready_to_sell,
    "results.discounts_value": data.discounts_value,
    "results.days_to_sale": data.days_to_sale,
    "results.mechanical_repair": data.mechanical_repair,
    "results.visual_repair": data.visual_repair,
    "results.kms": data.kms,
    "results.car_type": data.car_type,
    "results.comments": data.comments,
    "results.chasis": data.chasis,
    "results.engine": data.engine,
    "results.color": data.color,
    "results.transmission": data.transmission,
    "results.fuel": data.fuel,
    "results.check_car": data.check_car,
    "results.check_vtv": data.check_vtv,
    "results.gnc": data.gnc,
  };

  const results = Object.filter(results_object, function (info) {
    return info !== undefined && info !== "";
  });

  try {
    const checkDocRef = doc(db, "companies", company, "deals", deal_id, "data", "check");
    await updateDoc(checkDocRef, results);
    await updateDoc(checkDocRef, { user_update_data: user });
  } catch (error) {
    console.error("Error actualizando el resumen: ", error);
    return error;
  }
};

// Actualiza el tipo de campo en los datos del chequeo
export const updateCampoType = async (company, deal_id, type, user) => {
  const checkDocRef = doc(db, "companies", company, "deals", deal_id, "data", "check");

  try {
    await updateDoc(checkDocRef, {
      type: type,
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error actualizando el tipo de campo: ", error);
    return error;
  }
};

// Actualiza los resultados del chequeo
export const updateResultados = async (company, deal_id, data, user, dataPhotos) => {
  const check_object = {
    "check.informacion_inicial.ex_vehiculo_pasajeros": data.ex_vehiculo_pasajeros,
    "check.informacion_inicial.ploteo": data.ploteo,
    "check.informacion_inicial.modificacion_deportiva": data.modificacion_deportiva,
    "check.informacion_inicial.situacion_gnc": data.situacion_gnc,
    "check.informacion_inicial.dano_estructural": data.dano_estructural,
    "check.check_interior.tapizados_estado": data.tapizados_estado,
    "check.check_interior.cierre_centralizado_estado": data.cierre_centralizado_estado,
    "check.check_interior.vidrios_estado": data.vidrios_estado,
    "check.check_tecnico.kilometraje": data.kilometraje,
    "check.check_tecnico.combustion": data.combustion,
    "check.check_tecnico.transmision": data.transmision,
    "check.check_tecnico.motor": data.motor,
    "check.check_tecnico.tablero": data.tablero,
    "check.check_tecnico.embrague": data.embrague,
    "check.check_tecnico.caja_cambios": data.caja_cambios,
    "check.check_tecnico.frenos": data.frenos,
    "check.check_tecnico.aire_acondicionado": data.aire_acondicionado,
    "check.check_tecnico.calefaccion": data.calefaccion,
    "check.check_tecnico.motor_ruidos": data.motor_ruidos,
    "check.check_tecnico.neumaticos_delanteros": data.neumaticos_delanteros,
    "check.check_tecnico.neumaticos_traseros": data.neumaticos_traseros,
    "check.check_exterior.chapa_pintura_estado": data.chapa_pintura_estado,
    "check.check_exterior.llantas_estado": data.llantas_estado,
    "check.check_adicionales.services_oficiales": data.services_oficiales,
    "check.check_adicionales.comentarios_adicionales": data.comentarios_adicionales,
    "check.photos.photos": dataPhotos,
  };

  const check_options = {
    "check.check_exterior.paneles_rayas_profundas.ninguno": data.ningunos,
    "check.check_exterior.paneles_rayas_profundas.capot": data.capot,
    "check.check_exterior.paneles_rayas_profundas.techo": data.techo,
    "check.check_exterior.paneles_rayas_profundas.baul": data.baul,
    "check.check_exterior.paneles_rayas_profundas.paragolpe_delantero": data.paragolpe_delantero,
    // ... Continúa con el resto de los datos como los tenías
  };

  Object.keys(check_options).forEach((key) => {
    if (check_options[key] === undefined) delete check_options[key];
  });

  const check = Object.filter(check_object, (info) => info !== undefined && info !== "");

  const check_final_object = Object.assign({}, check_options, check, {
    user_update_data: user,
  });

  try {
    const checkDocRef = doc(db, "companies", company, "deals", deal_id, "data", "check");
    await updateDoc(checkDocRef, check_final_object);
  } catch (error) {
    console.error("Error actualizando los resultados: ", error);
    return error;
  }
};

// Actualiza los archivos adjuntos
export const updateAttachedFiles = async (user, company, deal_id, data) => {
  const checkDocRef = doc(db, "companies", company, "deals", deal_id, "data", "check");

  try {
    await updateDoc(checkDocRef, {
      "results.files": data,
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error actualizando los archivos adjuntos: ", error);
    return error;
  }
};

// Actualiza las observaciones
export const updateObservaciones = async (user, company, deal_id, data, dataObservaciones) => {
  const descuentosTotales = dataObservaciones?.reduce((total, objeto) => {
    return total + parseFloat(objeto.descuento.toString().replace(/\./g, "").replace(/\$/g, ""));
  }, 0);

  const checkDocRef = doc(db, "companies", company, "deals", deal_id, "data", "check");

  try {
    await updateDoc(checkDocRef, {
      "results.discounts": data,
      "results.discounts_table": descuentosTotales,
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error actualizando las observaciones: ", error);
    return error;
  }
};

// Función para calcular descuentos obligatorios
export const descuentosObligatorios = (dataObservaciones) => {
  if (dataObservaciones?.length > 0) {
    const descuentosObligatorioSi = dataObservaciones
      .filter((objeto) => objeto.obligatorio.toUpperCase() === "SI")
      .reduce((total, objeto) => {
        return total + parseFloat(objeto.descuento.toString().replace(/\./g, "").replace(/\$/g, ""));
      }, 0);

    return descuentosObligatorioSi.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return "0";
  }
};

// Función para calcular los descuentos totales
export const descuentosTotales = (dataObservaciones) => {
  if (dataObservaciones?.length > 0) {
    const descuentoTotal = dataObservaciones.reduce((total, objeto) => {
      return total + parseFloat(objeto.descuento.toString().replace(/\./g, "").replace(/\$/g, ""));
    }, 0);

    return descuentoTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return "0";
  }
};
