import TechnicalSheetCardComponent from "./TechnicalSheetCardComponent";

const TechnicalDataComponent = ({ data }) => {
  return (
    <>
      <TechnicalSheetCardComponent title="Datos técnicos" data={data} />
    </>
  );
};

export default TechnicalDataComponent;
