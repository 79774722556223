import TechnicalSheetCardComponent from "./TechnicalSheetCardComponent";

const ConsumptionBenefitsComponent = ({ data }) => {
  return (
    <>
      <TechnicalSheetCardComponent title="Motor y transmisión" data={data} />
    </>
  );
};

export default ConsumptionBenefitsComponent;
