import React from "react";
import { CountActionsWithIconComponent } from "./CountActionsWithIconComponent";
import PendingActionsIcon from "../images/icon-pending-actions.svg";
import VencerActionsIcon from "../images/icon-vencer-actions.svg";
import NextActionsIcon from "../images/icon-next-actions.svg";

const MainActionDataComponent = ({ pendingDealsCount, nextDealsCount }) => {
  return (
    <div className="d-flex">
      {/* <CountActionsWithIconComponent icon={PendingActionsIcon} count={"123"} /> */}
      <CountActionsWithIconComponent
        icon={NextActionsIcon}
        count={nextDealsCount}
        type={"proximas"}
      />
      <CountActionsWithIconComponent
        icon={VencerActionsIcon}
        count={pendingDealsCount}
        type={"pendientes"}
      />
    </div>
  );
};

export default MainActionDataComponent;
