import React, { useState } from "react";
import "../../../styles/style/MainSearchStyle.css";
import {
  Button,
  FormControl,
  InputGroup,
  Modal,
  Spinner,
  Table
} from "react-bootstrap";
import { searchPatent, validarPatente } from "../services/servicesTableDeals";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import ModalHistoryDealsComponent from "../../../components/ModalHistoryDealsComponent";
import NavigateDealIcon from "../../Deal/images/navigate-arrow-icon.svg";

export default function MainSearchComponent({ user }) {
  const [modal, setModal] = useState(false);
  const [resultQueryDb, setresultQueryDb] = useState([]);
  const [loadingDataDb, setloadingDataDb] = useState(false);
  const [valueInput, setvalueInput] = useState("");
  const [patenteValida, setPatenteValida] = useState(false);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [dataHistory, setDataHistory] = useState([]);
  const [modalSearchPhone, setModalSearchPhone] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleCloseModal = () => {
    setModal(false);
    setresultQueryDb([]);
  };

  const handleInputChange = (e) => {
    setvalueInput(e.target.value.toUpperCase());
    setPatenteValida(validarPatente(e.target.value.toUpperCase()));
  };

  const submitValuePatent = (e) => {
    if (valueInput === "" && expanded) {
      setExpanded(!expanded);
    } else {
      e.preventDefault();
      setModal(true);
      setloadingDataDb(true);
      searchPatent(
        patenteValida,
        valueInput,
        setresultQueryDb,
        setloadingDataDb,
        user,
        setDataHistory,
        setModalSearchPhone,
        setModal
      );
    }
  };

  const navigateDeal = (dealId) => {
    navigate(`/comments/${dealId}`);
    setModal(false);
  };

  const handleClose = () => {
    setDataHistory([]);
    setModalSearchPhone(false);
  };

  return (
    <>
      <div>
        <form onSubmit={submitValuePatent}>
          <InputGroup className={`search-input ${expanded ? "expanded" : ""}`}>
            {!expanded && (
              <div style={{ cursor: "pointer" }} onClick={handleToggleExpand}>
                <FaSearch className="search-icon" />
              </div>
            )}
            {expanded && (
              <FormControl
                placeholder="Patente/trengo-ticket-id/télefono"
                value={valueInput}
                onChange={handleInputChange}
                className="search-input-text"
              />
            )}
            {expanded && (
              <Button className="search-button" type="submit">
                <FaSearch />
              </Button>
            )}
          </InputGroup>
        </form>
      </div>
      <Modal show={modal} centered onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="add-card-title-modal">Buscando: {valueInput}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingDataDb ? (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <Spinner
                animation="border"
                role="status"
                className="me-2"
                size="sm"
                variant="primary"
              />
              <p>Buscando el cliente, espere un momento...</p>
            </div>
          ) : Array.isArray(resultQueryDb) && resultQueryDb.length > 0 ? (
            <Table className="deals-table-style" responsive>
              <thead>
                <tr>
                  <th className="textHead">Auto</th>
                  <th className="textHead">Ir al deal</th>
                </tr>
              </thead>
              <tbody>
                {resultQueryDb.map((info, index) =>
                  info?.car ? (
                    <tr key={index}>
                      <td>
                        <p
                          className="text-content-table-deals"
                          style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                        >
                          {info?.car}
                        </p>
                      </td>
                      <td>
                        <img
                          src={NavigateDealIcon}
                          alt="navigate-arrow"
                          style={{ cursor: "pointer" }}
                          className="mt-1"
                          onClick={() => navigateDeal(info.id)}
                        />
                      </td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </Table>
          ) : (
            <p className="add-card-title-modal">
              No se encontraron resultados.
            </p>
          )}
        </Modal.Body>
      </Modal>
      <ModalHistoryDealsComponent
        modal={modalSearchPhone}
        handleEvent={handleClose}
        dataHistory={dataHistory}
      />
    </>
  );
}
