import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ActionsComponent from "./ActionsComponent";
import HistorialComponent from "./HistorialComponent";
import { useUser } from "../../../context/UserContext";
import useFirebaseOnSnapshot from "../../../hooks/useFirebaseOnSnapshot";
import { ServerErrorComponent, SpinnerFullScreenComponent } from "../../../components";
import LongSnapshotFirebase from "../../../utils/LongSnapshotFirebase";

export default function TabsStatesComponent() {
  const { deal_id } = useParams();
  const { user } = useUser();
  const [dataActions, setdataActions] = useState();
  const [dataHistory, setdataHistory] = useState();
  const { data, loading, error } = useFirebaseOnSnapshot(user?.company, deal_id, 'actions');

  useEffect(() => {
    setdataActions(data?.actions);
    const callbackHistory = (dataHistory) => {
      setdataHistory(dataHistory?.logs)
    }
    const unsubscribeHistory = LongSnapshotFirebase(user?.company, deal_id, "data", "history", callbackHistory)
    return () => {
      unsubscribeHistory();
    };

  }, [deal_id, user?.company, data]);

  return (
    <>{loading
      ? (
        <SpinnerFullScreenComponent />
      ) : (
        <>{error
          ? (
            <ServerErrorComponent error={error} />
          ) : (
            <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="1" title="Acciones">
                <ActionsComponent dataActions={dataActions} deal_id={deal_id} />
              </Tab>
              <Tab eventKey="2" title="Historial">
                <HistorialComponent dataHistory={dataHistory} deal_id={deal_id} />
              </Tab>
            </Tabs>
          )}
        </>
      )}
    </>
  );
}
