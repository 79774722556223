import React, { useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import { inviteUser } from '../../services/usersConfigServices';
import toast from 'react-hot-toast';
import { colors } from '../../../../styles/colors';

export default function InviteUserModalComponent({ show, onHide }) {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);


  const sendUserInvitation = async () => {
    setIsSubmitting(true);
    try {
      await inviteUser({ email: email, company: 'san_jorge' });
      toast.success("Invitación enviada con éxito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
      setEmail('')
      onHide()
      setIsSubmitting(false);
    } catch (error) {
      console.error('Error al enviar la invitación:', error);
      toast.error('Error al enviar la invitación. ' + error, {
        style: {
          color: colors.text.primary,
          background: colors.error.dark,
        },
      });
      setIsSubmitting(false);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <div className='p-4'>
        <p className='add-card-title-modal mb-2 '>Invitar nuevo usuario</p>
        <div className="d-flex flex-wrap mb-4">
          <input
            type='text'
            className="input-invite"
            placeholder='Ingresar mail'
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <Button variant="primary" onClick={sendUserInvitation} size='lg' style={{ width: '100%', fontSize: '16px' }}>
          {isSubmitting ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Enviar invitación'
          )}
        </Button>

      </div>
    </Modal>
  )
}
