import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CreateCarModalComponent = ({
  modal,
  setModal,
  newDocId,
  setnewDocId,
}) => {
  const navigate = useNavigate();

  const handleClose = () => {
    setModal(false);
    setnewDocId("");
  };

  const navigateNewDeal = () => {
    navigate(`/comments/${newDocId}`);
  };

  return (
    <Modal show={modal} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="add-card-title-modal">Agregar auto: {newDocId}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <Button
            disabled={newDocId !== "" ? false : true}
            onClick={navigateNewDeal}
            className="d-flex align-items-center justify-content-center"
            style={{ width: "100%" }}
          >
            <Spinner
              animation="border"
              role="status"
              className="me-2"
              size="sm"
              variant="light"
              style={{ display: newDocId !== "" ? "none" : "" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            {newDocId !== ""
              ? "Navegar al deal"
              : "Creando deal, espere un momento"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateCarModalComponent;
