export const options = ["Válido", "Inválido", "N/O o No leído"];

export const checkboxValuesSeg = [
  "A",
  "B",
  "B-SUV",
  "B-Pick-up",
  "C",
  "C-SUV",
  "C-Pick-up",
  "D",
  "D-SUV",
  "E.F",
  "Minivan",
  "Utilitario",
];
export const colorOptions = [
  "Blanco",
  "Crema",
  "Gris Plata",
  "Gris Acero",
  "Negro",
  "Celeste",
  "Azul",
  "Rojo",
  "Marrón",
  "Verde",
  "Dorado/Beige",
];
export const fuelOptions = ["Nafta", "Diesel", "GNC"];

export const roofOptions = [
  "Ok",
  "Reparado",
  "Rayado",
  "Abollado",
  "Granizo",
  "Cambio",
];
export const hoodOptions = [
  "Ok",
  "Reparado",
  "Rayado",
  "Abollado",
  "Granizo",
  "Cambio",
];
export const antenaOptions = ["Ok", "Roto/Faltante"];
export const gridsOptions = ["Ok", "Rota/Faltante", "Quebrada/Rota"];

export const headlightsOptions = ["Ok", "Opaca", "Rajada", "Rota"];

export const bumperMoldingsOptions = ["Ok", "Rayadas", "Rotas/Faltantes"];

export const windshieldOptions = ["Ok", "Rayado", "Piedra", "Rajado"];

export const frontBumperOptions = ["Ok", "Rayado", "Quebrado", "Cambio"];
export const wiperBladesOptions = ["Ok", "No OK"];

export const heatingRadiatorOptions = ["Ok", "By Pass/Anulado"];

export const frontBadgeOptions = ["Ok", "No OK"];

export const fluidsOptions = ["Ok", "Mezcla", "Muy Bajo"];

export const oilVentOptions = ["Ok", "Poco", "Mucho"];

export const engineVanNoiseOptions = [
  "Ok",
  "Válvulas",
  "Poleas/Distribución",
  "Motor",
  "Embrague",
];

export const motorizedBodyOptions = ["Ok", "Inestable", "No lo hace"];

export const bateryOptions = ["Ok", "1/2 carga", "Remplazar"];

export const electroFanOptions = ["Ok", "Suelto/Roto", "Ruidoso"];

export const rightSideVisibilityOptions = ["Ok", "Rajada", "Falta/Rota"];

export const rightSideBodyOptions = [
  "Ok",
  "Reparado",
  "Rayado",
  "Abollado",
  "Cambiar",
];

export const rightSideMoldingsOptions = ["Ok", "Rayada", "Rota/Faltante"];

export const rightExteriorHandlesOptions = ["Ok", "N/F", "Rota/Faltante"];

export const rearBumperOptions = ["Ok", "Rayado", "Quebrado", "Cambio"];

export const rearLightsOptions = ["Ok", "Rajada", "Roto"];

export const severalOptions = ["Ok", "Roto/Faltante"];

export const backWindowOptions = ["Ok", "Rayada", "Rajada"];

export const trunkOptions = [
  "Ok",
  "Reparado",
  "Rayado",
  "Abollado",
  "Granizo",
  "Cambio",
];

export const internalTrunkLidOptions = ["Ok", "Rota", "Faltante", "No aplica"];

export const rearTabletOptions = ["Ok", "Rota/Faltante"];

export const trunkRugOptions = ["Ok", "Rota", "Faltante"];

export const spareTireOptions = ["Ok", "Gastada/Pinchada", "Faltante"];

export const hydraulicJackOptions = ["Ok", "No"];

export const exhaustSmokeOptions = [
  "Ok",
  "Negro",
  "Blanco",
  "Azul",
  "Chupada de aire",
];

export const trunkInteriorFloorOptions = ["Ok", "Daño Leve", "Daño Grave"];

export const leftSideVisibilityOptions = ["Ok", "Rajada", "Roto/Faltante"];

export const leftSideBodyOptions = [
  "Ok",
  "Reparado",
  "Rayado",
  "Abollado",
  "Cambiar",
];

export const leftExteriorHandlesOptions = ["Ok", "N/F", "Roto/Faltante"];

export const leftExteriorMoldingsOptions = ["Ok", "Rayyada", "Roto/Faltante"];

export const oilLossOptions = [
  "Ok",
  "Tapa válvulas",
  "Distribución/Cigüeñal",
  "Junta Carter",
  "Bancada",
  "Caja Velocidades",
];

export const directionOptions = ["Ok", "No Ok"];

export const condenserOptions = ["Ok", "Doblado", "Roto/Faltante"];

export const tiresOptions = ["Ok", "Bueno", "Regular", "Gastados", "Vencidos"];

export const rimsOptions = ["Ok", "Rayada", "Golpeada"];

export const distributionOptions = ["Ok", "Remplazar"];

export const clutchPedalOptions = [
  "Ok",
  "Corto/Largo",
  "Trepida",
  "AT - No aplica",
  "Duro",
];

export const fourXfourOptions = ["Si", "No"];

export const witness4x4Options = ["Ok", "No Enciende", "Parpadea"];

export const witnessHiLoOptions = ["Ok", "No Enciende", "Parpadea"];

export const keysOptions = ["Ok", "No", "Carcaza Rota"];

export const airConditioningOptions = ["Ok", "Enfría poco", "No Funciona"];

export const solarRoofOptions = ["Ok", "No Funciona"];

export const witnessesOptions = ["Ok", "No Enciende", "Encendido"];

export const upholsteredOptions = ["Ok", "Daño Leve", "Roto"];

export const cleanUpholsteryOptions = ["Ok", "Limpiar"];

export const commandDoorsOptions = ["Ok", "N/F", "Falta"];

export const audioOptions = ["Ok", "N/F", "Falta", "N/C"];

export const parkingOptions = ["Ok", "N/F", "N/C"];

export const overallStatusOptions = [
  "Excelente",
  "Muy Bueno",
  "Bueno",
  "Regular",
  "Malo",
  "Rechazado",
];
