import React, { useEffect, useState } from "react";
import { Button, Container, Spinner, Tab, Tabs } from "react-bootstrap";
import Section1TabComponent from "./InspectionSectionsTabs/Section1TabComponent";
import Section2TabComponent from "./InspectionSectionsTabs/Section2TabComponent";
import Section3TabComponent from "./InspectionSectionsTabs/Section3TabComponent";
import Section4TabComponent from "./InspectionSectionsTabs/Section4TabComponent";
import Section5TabComponent from "./InspectionSectionsTabs/Section5TabComponent";
import Section6TabComponent from "./InspectionSectionsTabs/Section6TabComponent";
import Section7TabComponent from "./InspectionSectionsTabs/Section7TabComponent";

import "../styles/SectionsTabs.css";
import ResumenVirtualInspComponent from "./InspectionSectionsTabs/ResumenVirtualInspComponent";
import {
  getSectionData,
  postSectionData,
} from "../../services/InspectionServices/secctionsInspectionServices";
import toast from "react-hot-toast";
import { colors } from "../../../../styles/colors";
import { useUser } from "../../../../context/UserContext";

export default function InspectionSectionsTabsComponent({ deal_id }) {
  const [activeTab, setActiveTab] = useState("1");
  const [is4x4, setIs4x4] = useState(false);
  const [editAllowed, setEditAllowed] = useState(false);
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [isSection1Valid, setIsSection1Valid] = useState(false);
  const [formDataTableView, setFormDataTableView] = useState({
    section2: {
      VHComments: {
        title: "",
        values: "",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-VHComments",
      },
      otherFluidLoss: {
        title: "",
        values: "",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-otherFluidLoss",
      },
      frontAxleAndFuel: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-frontAxleAndFuel",
      },
      rearAxle: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-rearAxle",
      },
      floorsChassis: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-floorsChassis",
      },
      exhaustCircuit: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-exhaustCircuit",
      },
      changeOfSpeeds: {
        title: "",
        values: [""],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-changeOfSpeeds",
      },
      oilLoss: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-oilLoss",
      },
      direction: {
        title: "",
        values: "Ok",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-direction",
      },
      tires: [
        "Neumaticos",
        {
          title: "Neumático DI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section2-tires",
        },
        {
          title: "Neumático DD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section2-tires",
        },
        {
          title: "Neumático TD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section2-tires",
        },
        {
          title: "Neumático TI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section2-tires",
        },
      ],
      condenser: {
        title: "",
        values: "Ok",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-condenser",
      },
      rims: [
        "Llantas",
        {
          title: "Llanta DI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section2-rims",
        },
        {
          title: "Llanta DD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section2-rims",
        },
        {
          title: "Llanta TD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section2-rims",
        },
        {
          title: "Llanta TI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section2-rims",
        },
      ],
      distribution: {
        title: "",
        values: "Ok",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-distribution",
      },
      clutchPedal: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section2-clutchPedal",
      },
    },
    section3: {
      witness4x4: {
        title: "",
        values: "Ok",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section3-witness4x4",
      },
      witnessHiLo: {
        title: "",
        values: "Ok",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section3-witnessHiLo",
      },
      traction: {
        title: "",
        values: "Ok",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section3-traction",
      },
    },
    section4: {
      keys: [
        "Llaves",
        {
          title: "Primera llave",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-keys",
        },
        {
          title: "Segunda llave",
          values: ["No"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-keys",
        },
      ],
      witnesses: [
        "Testigos",
        {
          title: "Testigo Aceite",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-witnesses",
        },
        {
          title: "Testigo Temp.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-witnesses",
        },
        {
          title: "Testigo Airbag",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-witnesses",
        },
        {
          title: "Testigo ABS",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-witnesses",
        },
        {
          title: "Testigo Inyección",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-witnesses",
        },
        {
          title: "Testigo Anti arranque",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-witnesses",
        },
        {
          title: "Testigo ESP",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-witnesses",
        },
      ],
      commandDoors: [
        "Comando puertas",
        {
          title: "LV DI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-commandDoors",
        },
        {
          title: "LV DD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-commandDoors",
        },
        {
          title: "LV TD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-commandDoors",
        },
        {
          title: "LV TI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-commandDoors",
        },
        {
          title: "Cierre Centr.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-commandDoors",
        },
        {
          title: "Esp. Ext. Izq.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-commandDoors",
        },
        {
          title: "Esp. Ext. Der.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-commandDoors",
        },
        {
          title: "Freno de mano",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-commandDoors",
        },
      ],
      audio: [
        "Audio",
        {
          title: "Radio/CD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-audio",
        },
        {
          title: "Pantalla Multimedia",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-audio",
        },
      ],
      parking: [
        "Parking",
        {
          title: "Cámara M/A",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-parking",
        },
        {
          title: "Sensor Trasero",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-parking",
        },
        {
          title: "Sensor Delantero",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-parking",
        },
      ],
      upholstered: [
        "Tapizados",
        {
          title: "Volante",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-upholstered",
        },
        {
          title: "Pomo cambios",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-upholstered",
        },
        {
          title: "Resp. Conductor",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-upholstered",
        },
        {
          title: "Asto. Conductor",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-upholstered",
        },
        {
          title: "Resp. Acomp.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-upholstered",
        },
        {
          title: "Asto. Acomp.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-upholstered",
        },
        {
          title: "Resp. Trasero",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-upholstered",
        },
        {
          title: "Asto. Trasero",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section4-upholstered",
        },
      ],
      commentUpholstered: {
        title: "",
        values: "",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section4-commentUpholstered",
      },
      commands: {
        title: "",
        values: "Ok",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section4-commands",
      },
      airConditioning: {
        title: "",
        values: "Ok",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section4-airConditioning",
      },
      solarRoof: {
        title: "",
        values: "Ok",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section4-solarRoof",
      },
      cleanUpholstery: {
        title: "",
        values: "Ok",
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section4-cleanUpholstery",
      },
    },
    section5: {
      roof: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-roof",
      },
      hood: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-hood",
      },
      antena: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-antena",
      },
      bumperMoldings: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-bumperMoldings",
      },
      windshield: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-windshield",
      },
      wiperBlade: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-wiperBlade",
      },
      frontBumper: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-frontBumper",
      },
      heatingRadiator: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-heatingRadiator",
      },
      oilVent: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-oilVent",
      },
      frontBadge: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-frontBadge",
      },
      engineVanNoise: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-engineVanNoise",
      },
      batery: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-batery",
      },
      electroFan: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-electroFan",
      },
      grids: [
        "Grillas",
        {
          title: "Grilla Superior",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-grids",
        },
        {
          title: "Grilla Inferior",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-grids",
        },
      ],
      headlights: [
        "Faros Delanteros",
        {
          title: "Óptica DI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-headlights",
        },
        {
          title: "Óptica DD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-headlights",
        },
        {
          title: "Faro Aux DI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-headlights",
        },
        {
          title: "Faro Aux DD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-headlights",
        },
        {
          title: "Luz Diurna DI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-headlights",
        },
        {
          title: "Luz Diurna DD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-headlights",
        },
      ],
      fluids: [
        "Fluidos",
        {
          title: "Aceite",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-fluids",
        },
        {
          title: "Refrigerante",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-fluids",
        },
        {
          title: "D/H",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-fluids",
        },
        {
          title: "Frenos",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-fluids",
        },
      ],
      motorizedBody: [
        "Cuerpo Motorizado",
        {
          title: "Ralenti",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-motorizedBody",
        },
        {
          title: "Aceleración",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section5-motorizedBody",
        },
      ],
      turbo: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-turbo",
      },
      commentsRoofAndFront: {
        title: "",
        values: [],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-commentsRoofAndFront",
      },
      commentsFluidLoss: {
        title: "",
        values: [],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-commentsFluidLoss",
      },
      commentEngineSpan: {
        title: "",
        values: [],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section5-commentEngineSpan",
      },
    },
    section6: {
      rightSideVisibility: [
        "Visibilidad Lateral Derecha",
        {
          title: "Luz Lat. Der.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideVisibility",
        },
        {
          title: "Luz Esp. Der.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideVisibility",
        },
        {
          title: "Vidrio Esp. Der.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideVisibility",
        },
        {
          title: "Espejo Ext. Der.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideVisibility",
        },
        {
          title: "Cacha Esp. Der.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideVisibility",
        },
      ],
      rightSideBody: [
        "Carroceria Lateral Derecho",
        {
          title: "GDD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideBody",
        },
        {
          title: "Pilar A",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideBody",
        },
        {
          title: "PDD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideBody",
        },
        {
          title: "Ent. PDD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideBody",
        },
        {
          title: "Pilar B",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideBody",
        },
        {
          title: "PTD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideBody",
        },
        {
          title: "Ent. PTD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideBody",
        },
        {
          title: "Pilar C",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideBody",
        },
        {
          title: "GTD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideBody",
        },
      ],
      rightSideMoldings: [
        "Molduras Lateral Derecho",
        {
          title: "Moldura GDD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideMoldings",
        },
        {
          title: "Moldura PDD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideMoldings",
        },
        {
          title: "Moldura PTD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideMoldings",
        },
        {
          title: "Moldura GTD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightSideMoldings",
        },
      ],
      rightExteriorHandles: [
        "Manijas Exteriores Derecha",
        {
          title: "Manija Ext. Del. Der",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightExteriorHandles",
        },
        {
          title: "Manija Ext. Tras. Der",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rightExteriorHandles",
        },
      ],
      rearLights: [
        "Faros Trasero",
        {
          title: "Faro TD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rearLights",
        },
        {
          title: "Faro int. TD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rearLights",
        },
        {
          title: "Faro Tras. Aux.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rearLights",
        },
        {
          title: "Faro M/A",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rearLights",
        },
        {
          title: "Faro TI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rearLights",
        },
        {
          title: "Faro Int. TI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rearLights",
        },
        {
          title: "Ojo Gato TD",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rearLights",
        },
        {
          title: "Ojo Gato TI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-rearLights",
        },
      ],
      several: [
        "Varios",
        {
          title: "Moldura Parag. Tras.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-several",
        },
        {
          title: "Insignia Tras.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-several",
        },
        {
          title: "Escobilla Tras.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-several",
        },
        {
          title: "Brazo Tras.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-several",
        },
      ],
      leftSideVisibility: [
        "Visibilidad Lateral Izquierda",
        {
          title: "Luz Lat. I",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideVisibility",
        },
        {
          title: "Luz Esp. I",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideVisibility",
        },
        {
          title: "Vidrio Esp. I",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideVisibility",
        },
        {
          title: "Espejo Izq.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideVisibility",
        },
        {
          title: "Cacha Esp. I",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideVisibility",
        },
      ],
      leftSideBody: [
        "Carrocería Lateral Izquierda",
        {
          title: "GDI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideBody",
        },
        {
          title: "Pilar A",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideBody",
        },
        {
          title: "PDI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideBody",
        },
        {
          title: "Ent. PDI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideBody",
        },
        {
          title: "Pilar B",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideBody",
        },
        {
          title: "PTI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideBody",
        },
        {
          title: "Ent. PTI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideBody",
        },
        {
          title: "Pilar C",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideBody",
        },
        {
          title: "GTI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftSideBody",
        },
      ],
      leftExteriorHandles: [
        "Manijas Exteriores Izq.",
        {
          title: "Manija Ext. Del. Izq.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftExteriorHandles",
        },
        {
          title: "Manija Ext. Tras. Izq.",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftExteriorHandles",
        },
      ],
      leftExteriorMoldings: [
        "Molduras Exteriores Izq",
        {
          title: "Moldura GDI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftExteriorMoldings",
        },
        {
          title: "Moldura PDI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftExteriorMoldings",
        },
        {
          title: "Moldura PTI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftExteriorMoldings",
        },
        {
          title: "Moldura GTI",
          values: ["Ok"],
          comments: "",
          discount: "",
          mandatory: "",
          anchor: "section6-leftExteriorMoldings",
        },
      ],
      backWindow: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-backWindow",
      },
      trunk: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-trunk",
      },
      internalTrunkLid: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-internalTrunkLid",
      },
      rearTablet: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-rearTablet",
      },
      trunkRug: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-trunkRug",
      },
      spareTire: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-spareTire",
      },
      hydraulicJack: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-hydraulicJack",
      },
      exhaustSmoke: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-exhaustSmoke",
      },
      trunkInteriorFloor: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-trunkInteriorFloor",
      },
      rearBumper: {
        title: "",
        values: ["Ok"],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-rearBumper",
      },
      commentsRightSide: {
        title: "",
        values: [],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-commentsRightSide",
      },
      commentsRear: {
        title: "",
        values: [],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-commentsRear",
      },
      commentsLeftSide: {
        title: "",
        values: [],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section6-commentsLeftSide",
      },
    },
    section7: {
      scannerResults: {
        title: "",
        values: [],
        comments: "",
        discount: "",
        mandatory: "",
        anchor: "section7-scannerResults",
      },
    },
    observations: {},
  });
  const [formData, setFormData] = useState({
    section1: {
      carPlate: "",
      brand: "",
      model: "",
      version: "",
      year: "",
      mileage: "",
      doors: "",
      inspectionPlace: { title: "", value: "" },
      segment: { title: "", value: "" },
      color: { title: "", value: "" },
      fuel: { title: "", value: "" },
      documentation: [
        "Documentacion",
        { title: "VTV", values: ["Válido"] },
        { title: "Oblea GNC", values: ["N/O o No leído"] },
        { title: "Grab. cristales", values: ["Válido"] },
        { title: "RVA", values: ["Válido"] },
        { title: "Nro. Chasis", values: ["N/O o No leído"] },
        { title: "Nro. Motor", values: ["N/O o No leído"] },
        { title: "Patente legible", values: ["Válido"] },
      ],
    },
    section2: {
      tireMeasurements: "",
      fourXfour: { title: "", values: "No" },
    },
    section7: {
      overallStatus: { title: "", values: ["Excelente"] },
    },
    results: {
      requiresMechanicalRepair: "",
      readyForSale: "",
      daysOfSetUp: "",
      requiresSheetMetalAndPaint: "",
      comments: "",
    },
  });

  useEffect(() => {
    if (
      user?.team === "Admin" ||
      user?.team === "Inspeccion" ||
      user?.team === "Pricing"
    ) {
      setEditAllowed(true);
    }
  }, [user, formData, formDataTableView]);

  const saveFormDataTable = (section, key, data) => {
    setFormDataTableView((prevFormData) => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [key]: {
          ...prevFormData[section][key],
          ...data,
        },
      },
    }));
  };

  const saveFormDataObjet = (section, key, data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [key]: {
          ...prevFormData[section][key],
          ...data,
        },
      },
    }));
  };

  //guarda solo un string en el objeto.
  const saveFromDataString = (section, key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        [key]: value,
      },
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getSectionData({ deal_id, company: "san_jorge" });
        if (!result.error) {
          if (Object.keys(result).length !== 0) {
            const {
              formData: fetchedFormData,
              formDataTableView: fetchedFormDataTableView,
            } = result;
            setFormData(fetchedFormData);
            setFormDataTableView(fetchedFormDataTableView);
          }
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [deal_id]);
  const handlePrevTab = () => {
    let prevTab = (parseInt(activeTab) - 1).toString();
    if (prevTab === "3" && !is4x4) {
      prevTab = "2"; // Saltar Tab 3 si is4x4 es false
    }
    setActiveTab(prevTab);
  };

  const handleNextTab = () => {
    let nextTab = (parseInt(activeTab) + 1).toString();
    if (nextTab === "3" && !is4x4) {
      nextTab = "4"; // Saltar Tab 3 si is4x4 es false
    }
    setActiveTab(nextTab);
  };
  const handleLinkClick = (tabKey, anchorId) => {
    setActiveTab(tabKey);
    setTimeout(() => {
      const element = document.getElementById(anchorId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
          offsetTop: 100,
        });
      } else {
        console.error(`Element with ID ${anchorId} not found.`);
      }
    }, 100);
  };
  const saveChanges = async () => {
    try {
      const result = await postSectionData({
        deal_id,
        company: "san_jorge",
        body: {
          formData,
          formDataTableView,
        },
      });

      if (!result.error) {
        toast.success("Informacion Guardada con exito.", {
          style: {
            color: colors.text.primary,
            background: colors.neutral.green,
          },
        });
      } else {
        console.error(result.message);
        toast.error("Error al guardar los datos.", {
          style: {
            color: colors.text.primary,
            background: colors.error.dark,
          },
        });
      }
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner
            as="span"
            variant="primary"
            animation="border"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <p className="deal-title">Inspección fisica</p>
            <Button onClick={saveChanges} disabled={!editAllowed}>
              Guardar cambios
            </Button>
          </div>
          <Tabs
            activeKey={activeTab}
            onSelect={(key) => setActiveTab(key)}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="1" title="Seccion 1">
              <Section1TabComponent
                formData={formData}
                setFormData={setFormData}
                saveFromDataString={saveFromDataString}
                saveFormDataObjet={saveFormDataObjet}
                editAllowed={editAllowed}
                setIsSection1Valid={setIsSection1Valid}
              />
            </Tab>
            <Tab eventKey="2" title="Seccion 2" disabled={!isSection1Valid}>
              <Section2TabComponent
                setIs4x4={setIs4x4}
                formData={formData}
                formDataTableView={formDataTableView}
                saveFromDataString={saveFromDataString}
                saveFormDataTable={saveFormDataTable}
                setFormDataTableView={setFormDataTableView}
                editAllowed={editAllowed}
                saveFormDataObjet={saveFormDataObjet}
              />
            </Tab>
            <Tab
              eventKey="3"
              title="Seccion 3"
              disabled={!is4x4 || !isSection1Valid}
            >
              <Section3TabComponent
                formDataTableView={formDataTableView}
                saveFormDataTable={saveFormDataTable}
                editAllowed={editAllowed}
              />
            </Tab>
            <Tab eventKey="4" title="Seccion 4" disabled={!isSection1Valid}>
              <Section4TabComponent
                formDataTableView={formDataTableView}
                saveFormDataTable={saveFormDataTable}
                setFormDataTableView={setFormDataTableView}
                editAllowed={editAllowed}
              />
            </Tab>
            <Tab eventKey="5" title="Seccion 5" disabled={!isSection1Valid}>
              <Section5TabComponent
                formDataTableView={formDataTableView}
                saveFormDataTable={saveFormDataTable}
                setFormDataTableView={setFormDataTableView}
                editAllowed={editAllowed}
              />
            </Tab>
            <Tab eventKey="6" title="Seccion 6" disabled={!isSection1Valid}>
              <Section6TabComponent
                formData={formData}
                formDataTableView={formDataTableView}
                saveFromDataString={saveFromDataString}
                saveFormDataTable={saveFormDataTable}
                setFormDataTableView={setFormDataTableView}
                editAllowed={editAllowed}
              />
            </Tab>
            <Tab eventKey="7" title="Seccion 7" disabled={!isSection1Valid}>
              <Section7TabComponent
                formData={formData}
                formDataTableView={formDataTableView}
                saveFormDataTable={saveFormDataTable}
                editAllowed={editAllowed}
                saveFormDataObjet={saveFormDataObjet}
              />
            </Tab>
            <Tab eventKey="8" title="Resultados" disabled={!isSection1Valid}>
              <ResumenVirtualInspComponent
                deal_id={deal_id}
                formData={formData}
                formDataTableView={formDataTableView}
                setFormDataTableView={setFormDataTableView}
                handleLinkClick={handleLinkClick}
                saveFromDataString={saveFromDataString}
                editAllowed={editAllowed}
              />
            </Tab>
          </Tabs>
          <Container fluid className="d-flex justify-content-end">
            {activeTab !== "1" && (
              <Button variant="outline-primary" onClick={handlePrevTab}>
                Seccion Anterior
              </Button>
            )}
            {activeTab !== "8" && (
              <Button
                onClick={handleNextTab}
                className="ms-2"
                disabled={!isSection1Valid}
              >
                Seccion Siguiente
              </Button>
            )}
          </Container>
        </>
      )}
    </>
  );
}
