import React, { useRef, useState, useEffect } from "react";
import { Form, Table, Button, Spinner } from "react-bootstrap";
import { useDeals } from "../../../context/DealsContext";
import { action_status } from "../../../constants/actionStatus";
import { useCompany } from "../../../context/CompanyContext";
import { colors } from "../../../styles/colors";
import { teams } from "../../../constants/teams";
import { useUser } from "../../../context/UserContext";
import Swal from "sweetalert2";
import { updateDealInProcess } from "../services/servicesTableDeals";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import {
  EmptyStateComponent,
  SpinnerFullScreenComponent,
} from "../../../components";
import { TablePendingDoneCheckComponent } from "./TablePendingDoneCheckComponent";
import { TableDefaultCheckComponent } from "./TableDefaultCheckComponent";
import { updateGoogleSheets } from "../services/checkTableServices";

export default function QuickCheckViewTableComponent() {
  const { user } = useUser();
  let isPageWide = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();
  const { loadMore, increaseDeals, deals, loadingDeals } = useDeals();
  const [showDone, setShowDone] = useState(false);
  const [showOpen, setShowOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const { actions, allUsers } = useCompany();

  const headTable = [
    "Fecha",
    "Turno",
    "Agente",
    "Sucursal",
    "Confirmado",
    "Estado",
    "Acciones",
    "Ultimo agente",
    "Cliente",
    "Vehiculo",
    "Patente",
    "Última Oferta",
    "Oferta esperada",
    "",
  ];

  const rowStyleDealInProsess = {
    backgroundColor: colors.primary.secundaryTransparent,
  };
  const rowStyleDeal = {
    backgroundColor: colors.neutral.light,
  };

  const verDeal = (id) => {
    navigate(`/comments/${id}`);
  };

  const canUserViewDeal = (data) => {
    if (data?.current_user_team) {
      const userTeamInProcess = Object.keys(data?.current_user_team);
      const userTeamExist = userTeamInProcess.includes(user?.team);
      if (!userTeamExist || !data.current_user_team[user?.team] || data.current_user_team[user?.team] === user.uid) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const showTooltip = (data) => {
    let usersNames = [];
    for (let key in data?.current_user_team) {
      if (data?.current_user_team[key] !== null) {
        const userId = data?.current_user_team[key];
        const foundUser = allUsers.find((user) => user.id === userId);
        if (foundUser) {
          usersNames.push(foundUser);
        } else {
          usersNames.push({ team: key, name: userId });
        }
      }
    }
    return usersNames;
  };

  const handleClick = (event, data) => {
    if (user?.team === teams.ADMIN || !data?.current_user_team || canUserViewDeal(data)) {
      updateDealInProcess(user?.company, user?.team, user?.uid, data.id);
    } else {
      event.preventDefault();
      const userIdInProcess = Object.values(data?.current_user_team);
      const userNameInProcess = allUsers.find((user) => userIdInProcess.includes(user.id));
      Swal.fire({
        title: "No puede trabajar en este deal",
        text: `Se encuentra en proceso con ${userNameInProcess?.name}  `,
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const convertDateFormat = (originalDate) => {
    const [year, month, day] = originalDate.split("-");
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const filterDeals = deals.map((item) => ({
      ...item,
      deal_data: {
        ...item.deal_data,
        actions: {
          ...item?.deal_data?.actions,
          data_actions: showOpen
            ? showDone
              ? item.deal_data?.actions?.data_actions
              : item?.deal_data.actions?.data_actions.filter(
                (action) => action.status === "open"
              )
            : showDone
              ? item?.deal_data?.actions?.data_actions.filter(
                (action) => action.status === "done"
              )
              : [],
        },
      },
    }));
    setFilteredData(filterDeals);
  }, [deals, showOpen, showDone]);

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Crear la función createSheetData
  const createSheetData = async (data) => {
    setIsSubmitting(true);
    const formattedData = data.map(deal => [
      deal.deal_data.status.phases_dates["INSPECCION AGENDADA"]?.seconds
        ? new Date(deal.deal_data.status.phases_dates["INSPECCION AGENDADA"]?.seconds * 1000).toLocaleDateString()
        : "-",
      `${convertDateFormat(deal.date)} - ${deal.hour}`,
      allUsers.find((dataAllUser) => dataAllUser.id === deal.user_team?.CS)?.name || '',
      deal.deal_data.appointment_data?.check?.punto_de_inspeccion || '-',
      "Si/No", // Esto parece ser un dato estático en el ejemplo original
      deal.status || '',
      deal.actions.data_actions
        .filter((action) => action.status === action_status.OPEN)
        .map((action) => action.action)
        .join(', '),
      deal.last_agent ? `${deal.last_agent.name} (${deal.last_agent.team})` : '',
      deal.name,
      deal.car,
      deal.car_id,
      deal.deal_data.last_offer?.value || '',
      "Oferta esperada" // Esto parece ser un dato estático en el ejemplo original
    ]);
    try {
      const response = await updateGoogleSheets({ data: formattedData });
      if (response && response.link) {
        window.open(response.link, '_blank');
        setIsSubmitting(false);
      } else {
        console.error('No se encontró una URL en la respuesta:', response);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error al modificar el equipo del usuario:', error);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex mt-4">
          <Form.Check
            type="checkbox"
            id="custom-switch-open"
            label="Pendientes"
            checked={showOpen}
            onChange={() => setShowOpen(!showOpen)}
            style={{ fontSize: "14px" }}
          />
          <Form.Check
            type="checkbox"
            id="custom-switch-done"
            label="Realizadas"
            checked={showDone}
            onChange={() => setShowDone(!showDone)}
            className="ms-2"
            style={{ fontSize: "14px" }}
          />
        </div>
        <Button
          className="ms-2"
          onClick={() => createSheetData(filteredData)}
          disabled={isSubmitting} // Deshabilitar el botón mientras se está enviando
        >
          {isSubmitting ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Exportar a Google Sheets'
          )}
        </Button>
      </div>
      {loadingDeals ? (
        <SpinnerFullScreenComponent />
      ) : (
        <>
          {deals?.length === 0 ? (
            <div className="mt-4 h-100 deals-table-style">
              <EmptyStateComponent />
            </div>
          ) : (
            <div
              className="table-container-grab scrollChat"
              ref={containerRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              style={{ overflow: "scroll", overflowY: "hidden" }}
            >
              <Table
                hover
                className="mt-1 deals-table-style"
                style={{ width: "125vw" }}
              >
                <thead>
                  <tr>
                    <th
                      className="textHead"
                      colSpan={6}
                      style={{
                        backgroundColor: colors.primary.secondaryTwo,
                        padding: "0px !important",
                        color: "white",
                        fontWeight: "400",
                        borderTopLeftRadius: "8px",
                      }}
                    >
                      Agendamiento
                    </th>
                    <th
                      className="textHead"
                      colSpan={5}
                      style={{
                        backgroundColor: colors.primary.secondary,
                        padding: "0px !important",
                        color: "white",
                        fontWeight: "400",
                      }}
                    >
                      Deal
                    </th>
                    <th
                      className="textHead"
                      colSpan={5}
                      style={{
                        backgroundColor: colors.neutral.dark,
                        padding: "0px !important",
                        color: "white",
                        fontWeight: "400",
                        borderTopRightRadius: "8px",
                      }}
                    >
                      Oferta
                    </th>
                  </tr>
                  <tr>
                    {headTable.map((data, i) => (
                      <th
                        key={i}
                        className="textHead pt-3 pb-3"
                        style={{
                          backgroundColor: colors.neutral.default,
                          borderRight: data === "Estado" || data === "Patente" ? "2px solid #E8E1EF" : "none",
                        }}
                      >
                        {data}
                      </th>
                    ))}
                  </tr>
                </thead>
                {(showDone && !showOpen) || (showOpen && !showDone) ? (
                  <TablePendingDoneCheckComponent
                    filteredData={filteredData}
                    rowStyleDeal={rowStyleDeal}
                    rowStyleDealInProsess={rowStyleDealInProsess}
                    user={user}
                    allUsers={allUsers}
                    handleClick={handleClick}
                    verDeal={verDeal}
                    convertDateFormat={convertDateFormat}
                    action_status={action_status}
                    actions={actions}
                    loadMore={loadMore}
                    increaseDeals={increaseDeals}
                    colors={colors}
                    isPageWide={isPageWide}
                    showTooltip={showTooltip}
                  />
                ) : (showDone && showOpen) || (!showDone && !showOpen) ? (
                  <TableDefaultCheckComponent
                    deals={deals}
                    rowStyleDeal={rowStyleDeal}
                    rowStyleDealInProsess={rowStyleDealInProsess}
                    user={user}
                    allUsers={allUsers}
                    handleClick={handleClick}
                    verDeal={verDeal}
                    convertDateFormat={convertDateFormat}
                    action_status={action_status}
                    actions={actions}
                    loadMore={loadMore}
                    increaseDeals={increaseDeals}
                    colors={colors}
                    isPageWide={isPageWide}
                    showTooltip={showTooltip}
                  />
                ) : null}
              </Table>
            </div>
          )}
        </>
      )}
    </>
  );
}