import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { MdAdd, MdEdit } from 'react-icons/md';
import { getAssignment } from '../../services/usersConfigServices';
import { colors } from '../../../../styles/colors';
import AssignmentDealModalComponent from './AssignmentDealModalComponent';

export default function AssignmentsTableComponent() {
  const [assignments, setAssignments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [modalMode, setModalMode] = useState('create');

  useEffect(() => {
    async function fetchAssignments() {
      try {
        const assignmentsData = await getAssignment();
        setAssignments(assignmentsData);
      } catch (error) {
        console.error('Error al obtener las asignaciones:', error);
      }
    }
    fetchAssignments();
  }, []);

  const headTable = [
    "Nombre",
    "Email",
    "Asignación",
    "Trengo ids",
    "%",
    "",
  ];

  const handleEditAssignment = (assignment) => {
    setSelectedAssignment(assignment);
    setModalMode('edit');
    setShowModal(true);
  };

  const handleCreateAssignment = () => {
    setSelectedAssignment(null);
    setModalMode('create');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedAssignment(null);
    setModalMode('create');
    setShowModal(false);
  };

  const totalValidAssignments = assignments.reduce((total, assignment) => {
    return assignment.percentage !== 0 ? total + assignment.percentage : total;
  }, 0);

  const calculatePercentage = (currentTask) => {
    if (currentTask.percentage === 0) {
      return 0;
    } else {
      const percentage = (currentTask.percentage / totalValidAssignments) * 100;
      return percentage.toFixed(2); // Round the percentage to two decimal places
    }
  };
  return (
    <>
      <Table hover responsive className="mt-3 deals-table-style">
        <thead>
          <tr>
            {headTable.map((data, i) => (
              <th key={i} className="textHead">
                {i === 5 ? (
                  <div className="right-align">
                    <Button variant='primary' onClick={handleCreateAssignment}>Cargar <MdAdd /></Button>
                  </div>
                ) : (
                  data
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {assignments?.map((assignment, index) => (
            <tr key={index}>
              <td className="text-content-table-deals">{assignment.name}</td>
              <td className="text-content-table-deals not-capitalize">{assignment.email}</td>
              <td className="text-content-table-deals">{assignment.percentage}</td>
              <td className="text-content-table-deals">{assignment.trengo_id}</td>
              <td className="text-content-table-deals">
                {calculatePercentage(assignment)}%
              </td>
              <td className="right-align text-content-table-deals mt-0">
                <MdEdit
                  style={{
                    fontSize: "24px",
                    color: colors.primary.primary,
                    cursor: "pointer",
                  }}
                  onClick={() => handleEditAssignment(assignment)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <AssignmentDealModalComponent
        show={showModal}
        onHide={handleCloseModal}
        mode={modalMode}
        assignment={selectedAssignment}
        assignments={assignments}
        setAssignments={setAssignments}
      />
    </>
  );
}
