import { Col, Container, Row } from "react-bootstrap";
import InputComponent from "../../../../../components/InputComponent";

const VehicleView = ({ dataVehiculo, register, textStyle }) => {
  return (

    <div>
      <Row>
        <p style={textStyle} className="mb-2">
          Vehículo
        </p>
        <Col lg={6}>
          <div className="select-container">
            <InputComponent
              label={"Año"}
              placeholder={dataVehiculo?.year}
              register={register}
              disabled={true}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <InputComponent
              label={"Marca"}
              placeholder={dataVehiculo?.brand}
              register={register}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <InputComponent
              label={"Modelo"}
              placeholder={dataVehiculo?.model}
              register={register}
              disabled={true}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <InputComponent
              label={"Versión"}
              placeholder={dataVehiculo?.version}
              register={register}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <InputComponent
              label={"Gnc"}
              placeholder={dataVehiculo?.gnc === false ? "NO" : "SI"}
              register={register}
              disabled={true}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <InputComponent
              label={"Stopper"}
              placeholder={dataVehiculo?.stopper === false ? "NO" : "SI"}
              register={register}
              disabled={true}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VehicleView;
