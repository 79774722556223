import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { editCarPriceByCoefficient } from '../../services/sevicesPricingView';

export default function PricingEditPriceModalComponent({
  modal,
  setModal,
  isSingleRowSelected,
  selectedIds,
  fetchData,
  setSelectedRows,
  userId,
  fetchLastEditor
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [percentage, setPercentage] = useState('');
  const [dollars, setDollars] = useState('');

  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
  };

  const handleDollarsChange = (e) => {
    const inputValue = e.target.value;
    setDollars('$' + formatCurrency(inputValue));
  };

  const formatCurrency = (value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    const formattedValue = new Intl.NumberFormat('es-ES').format(numericValue);
    return formattedValue;
  };

  const handleSaveChanges = async () => {
    console.log(parseFloat(dollars.replace(/\D/g, '')))
    setIsSubmitting(true);
    try {
      const requestData = {
        user_id: userId,
        idsAuta: selectedIds,
        percentage: isSingleRowSelected ? (percentage && parseFloat(percentage)) : percentage,
        price: isSingleRowSelected ? (dollars && parseFloat(dollars.replace(/\D/g, ''))) : null,
      };
      const response = await editCarPriceByCoefficient(requestData);
      fetchData()
      fetchLastEditor()
      console.log('Success:', response);
      setPercentage('');
      setDollars('');
      setSelectedRows([])
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setIsSubmitting(false);
      setModal(false);
    }
  };

  return (
    <Modal show={modal} centered onHide={() => setModal(false)}>
      <Modal.Body className='pricing-body-modal d-flex align-items-center'>
        <p className="add-card-title-modal mt-2" style={{ textAlign: 'center' }}>
          {isSingleRowSelected ? 'Editar Precio' : 'Editar Precio por Coeficiente'}
        </p>
        {isSingleRowSelected ?
          <div className="d-flex w-100 justify-content-evenly">
            <div className="" >
              <p className="card-subtitle mt-4 mb-1 text-center">  Var +/- (%) </p>
              <input
                type='text'
                className="input-pricing"
                placeholder='%0'
                value={percentage}
                onChange={handlePercentageChange}
                disabled={dollars !== ''} />
            </div>
            <div className="">
              <p className="card-subtitle mt-4 mb-1 text-center"> Precio en Dolares (USD)</p>
              <input
                type='text'
                className="input-pricing"
                placeholder='$000.000'
                value={dollars}
                onChange={handleDollarsChange}
                disabled={percentage !== ''} />
            </div>
          </div>
          :
          <input
            type='text'
            className="input-pricing align-self-center w-25"
            placeholder='%0'
            value={percentage}
            onChange={handlePercentageChange}
          />
        }
        <p className="card-subtitle-ejem mt-4 mb-1 w-80 " style={{ textAlign: 'center', fontSize: '12px', width: '80%' }}>
          Ej: Si queres disminuir el precio un 10%, el coeficiente será 0.9. Si queres aumentar el pricing el coeficiente sera 1.1.</p>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: 'none', justifyContent: 'space-evenly' }}>
        <Button
          variant="outline-primary"
          style={{ width: "40%" }}
          onClick={() => setModal(false)}
        >
          Cancelar
        </Button>

        <Button
          variant="primary"
          style={{ width: "40%" }}
          onClick={handleSaveChanges}
        >
          {isSubmitting ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Guardar cambios'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
