import React, { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { getQuoterPrice } from '../../services/sevicesPricingView';


export default function PricingQuoteSimulateModalComponent({
  modal,
  setModal,
  data,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isInitialOffer, setIsInitialOffer] = useState(false);
  const [response, setResponse] = useState(null);
  const [gnc, setGnc] = useState(false);
  const [kms, setKms] = useState('');
  const idAutaValue = data?.idAuta ?? '';
  const handleSimulation = async () => {
    setIsSubmitting(true);
    try {
      const kmsWithoutDots = kms.replace(/\./g, '');
      const result = await getQuoterPrice({ idAuta: Number(idAutaValue), kms: Number(kmsWithoutDots), gnc });
      setResponse(result);
      setIsInitialOffer(result?.initial_offer !== null);
    } catch (error) {
      console.error('Error al simular cotización:', error);
    } finally {
      setIsSubmitting(false);
    }
  }
  const handleCancel = () => {
    setModal(false);
    setIsInitialOffer(false);
    setResponse(null);
    setGnc(false);
    setKms('');
  };

  const handleInputKms = (e) => {
    const inputNumber = e.target.value.replace(/[^\d]/g, ''); // Elimina caracteres no numéricos
    const formattedNumber = new Intl.NumberFormat('es-AR').format(inputNumber);
    setKms(formattedNumber);
  };
  return (
    <Modal show={modal} centered onHide={() => setModal(false)}>
      {modal && !response ?
        <>
          <Modal.Body className='pricing-body-modal'>
            <p className="add-card-title-modal mt-2" style={{ textAlign: 'center' }}>Simular cotización</p>
            <p className="card-subtitle mt-4 mb-1" style={{ textAlign: 'center' }}> Kilometraje</p>
            <input
              type='text'
              className="input-pricing align-self-center"
              placeholder='000.000'
              value={kms}
              onChange={(e) => handleInputKms(e)}
            />
            <Form.Group controlId="formGnc" className='mt-4 d-flex flex-column justify-content-center align-items-center '>
              <Form.Label className="card-subtitle mb-1">¿Tiene GNC?</Form.Label>
              <div className='d-flex'>
                <Form.Check
                  className='me-2'
                  type="radio"
                  label="Sí"
                  id="gncSi"
                  checked={gnc}
                  onChange={() => setGnc(true)}
                />
                <Form.Check
                  className='ms-2'
                  type="radio"
                  label="No"
                  id="gncNo"
                  checked={!gnc}
                  onChange={() => setGnc(false)}
                />
              </div>
            </Form.Group>
            <p className="card-subtitle mt-4 mb-1"
              style={{ textAlign: 'center', fontSize: '12px' }}>
              {data?.brand} - {data?.model} - {data?.version} - {data?.year}
            </p>
          </Modal.Body><Modal.Footer style={{ borderTop: 'none', justifyContent: 'space-evenly' }}>
            <Button
              variant="outline-primary"
              style={{ width: "40%" }}
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              style={{ width: "40%" }}
              onClick={handleSimulation}
            >
              {isSubmitting ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                'Simular'
              )}
            </Button>
          </Modal.Footer>
        </>
        :
        <>
          <Modal.Body className='pricing-body-modal d-flex align-items-center'>
            <p className="add-card-title-modal mt-2" style={{ textAlign: 'center' }}>Cotización estimada</p>
            <p className="card-subtitle mt-4 mb-1" style={{ textAlign: 'center' }}> {isInitialOffer ? 'Oferta inicial' : 'Rango inicial'}</p>
            {isInitialOffer ?
              <input
                type='text'
                className="input-pricing align-self-center"
                value={response?.initial_offer}
                readOnly />
              : <div className="d-flex justify-content-evenly align-items-center w-75">
                <input type='text'
                  className="input-pricing align-self-center"
                  style={{ width: "40%" }}
                  value={response?.range?.min}
                  readOnly />
                <span style={{ width: "8px", height: '2px', backgroundColor: '#864BFA' }} />
                <input type='text'
                  className="input-pricing align-self-center"
                  style={{ width: "40%" }}
                  value={response?.range?.max}
                  readOnly />
              </div>
            }
            <p className="card-subtitle mt-4 mb-1"
              style={{ textAlign: 'center', fontSize: '12px' }}>
              {data?.brand} - {data?.model} - {data?.version} - {data?.year}
            </p>
            {response?.stopper
              ? (
                <p className="text-danger mt-2 mb-0"
                  style={{ textAlign: 'center', fontSize: '14px' }}>
                  {response?.stopper_desc}.
                </p>
              ) : null}
          </Modal.Body>
          <Modal.Footer style={{ borderTop: 'none', justifyContent: 'space-evenly' }}>
            <Button
              variant="outline-primary"
              style={{ width: "40%" }}
              onClick={handleCancel}
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </>}
    </Modal >
  )
}
