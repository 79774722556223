import React, { useState, useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import { useAuth } from '../context/AuthContext';
import useIdleTimeout from '../hooks/useIdleTimeout';
import { useDeals } from '../context/DealsContext';
import { useUser } from '../context/UserContext';
import { teams } from '../constants/teams';

export default function TimeOutSessionModalComponent() {
  const [openModal, setOpenModal] = useState(false);
  const [countdown, setCountdown] = useState(20);
  const { logout, isAuthenticated } = useAuth();
  const { user } = useUser();
  const { clearFilter } = useDeals();

  const handleIdle = () => {
    if (isAuthenticated && user?.team === teams.CS) {
      setOpenModal(true);
    }
  };

  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 3600 });

  const handleLogout = async () => {
    clearFilter();
    try {
      await logout();
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  useEffect(() => {
    let intervalId;

    if (openModal && isAuthenticated) {
      intervalId = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [openModal, isAuthenticated]);

  useEffect(() => {
    if (countdown === 0) {
      handleLogout();
    }
  }, [countdown]);

  const handleClose = () => {
    setOpenModal(false);
    setCountdown(20);
    idleTimer.reset();
  };

  return (
    <Modal show={openModal} centered data-keyboard="false" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="add-card-title-modal">¿Sigues ahí?</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ textAlign: 'center', fontSize: '18px' }}>
          {countdown !== 0
            ? `Se cerrará la sesión por inactividad en ${countdown} segundos.`
            : 'Has estado mucho tiempo inactivo. Vuelve a iniciar sesión.'}
        </p>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <Button
            className="d-flex align-items-center justify-content-center"
            style={{ width: "100%" }}
            onClick={handleClose}
          >
            {countdown !== 0 ? 'Continuar sesión' : 'Cerrar'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}