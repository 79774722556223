import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import AssignmentsTableComponent from '../components/usersComponents/AssignmentsTableComponent';
import TrengoComponent from '../components/usersComponents/TrengoComponent';
import FilterUsersComponent from '../components/usersComponents/FilterUsersComponent';
import { getUsers } from '../services/usersConfigServices';
import TeamsTableComponent from '../components/usersComponents/TeamsTableComponent';


export default function UsersViewPage() {
  const [activeView, setActiveView] = useState('teams');
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [usersData, setUsersData] = useState([]);


  const teams = [
    { value: 'Admin', label: 'Admin' },
    { value: 'AS', label: 'Advisor AS' },
    { value: 'CS', label: 'Advisor CS' },
    { value: 'Pricing', label: 'Pricer' },
    { value: 'BackOffice', label: 'Back office' },
    { value: 'Inspeccion', label: 'Inspector' },
  ]


  const fetchDataUsers = async () => {
    try {
      const usersResponse = await getUsers({ params: { company: 'san_jorge' } });
      setUsersData(usersResponse.users);
    } catch (error) {
      console.error('Error al obtener datos de usuarios:', error);
    }
  };

  const handleButtonClick = (view) => {
    setActiveView(view);
  };

  useEffect(() => {
    fetchDataUsers();
  }, []);
  return (
    <div className="p-3 h-100 ">
      <div className=" w-100 d-flex justify-content-between">
        <ButtonGroup className="button-group-container" style={{ height: '48px' }}>
          <Button
            className="navegation-button-group"
            variant={activeView === 'teams' ? 'primary' : 'outline-primary'}
            onClick={() => handleButtonClick('teams')}
          >
            Usuarios
          </Button>
          <Button

            className="navegation-button-group"
            variant={activeView === 'assignments' ? 'primary' : 'outline-primary'}
            onClick={() => handleButtonClick('assignments')}
          >
            Asignaciones
          </Button>
          <Button
            className="navegation-button-group"
            variant={activeView === 'trengo' ? 'primary' : 'outline-primary'}
            onClick={() => handleButtonClick('trengo')}
          >
            Tickets Trengo
          </Button>
        </ButtonGroup>
        {activeView === 'teams'
          ? (
            <FilterUsersComponent
              activeView={activeView}
              selectedTeam={selectedTeam}
              setSelectedTeam={setSelectedTeam}
              teams={teams} />
          ) : (
            null
          )}
      </div>
      {activeView === 'teams' &&
        <TeamsTableComponent selectedTeam={selectedTeam} usersData={usersData} teams={teams} fetchDataUsers={fetchDataUsers} />}
      {activeView === 'assignments' && <AssignmentsTableComponent />}
      {activeView === 'trengo' && <TrengoComponent />}
    </div>
  );
}

