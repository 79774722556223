import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputComponent from "../../../../../components/InputComponent";

export default function TechnicalCheckComponent({
  disabled,
  register,
  dataCheckTecnico,
  setValue,
}) {
  React.useEffect(() => {
    setValue("kilometraje", dataCheckTecnico?.kilometraje);
    setValue("combustion", dataCheckTecnico?.combustion);
    setValue("transmision", dataCheckTecnico?.transmision);
    setValue("motor", dataCheckTecnico?.motor);
    setValue("tablero", dataCheckTecnico?.tablero);
    setValue("embrague", dataCheckTecnico?.embrague);
    setValue("caja_cambios", dataCheckTecnico?.caja_cambios);
    setValue("frenos", dataCheckTecnico?.frenos);
    setValue("aire_acondicionado", dataCheckTecnico?.aire_acondicionado);
    setValue("calefaccion", dataCheckTecnico?.calefaccion);
    setValue("motor_ruidos", dataCheckTecnico?.motor_ruidos);
    setValue("neumaticos_delanteros", dataCheckTecnico?.neumaticos_delanteros);
    setValue("neumaticos_traseros", dataCheckTecnico?.neumaticos_traseros);
  }, [dataCheckTecnico, setValue]);

  return (
    <div>
      <Row>
        <Col lg={6}>
          <InputComponent
            disabled={disabled}
            type="number"
            label={"Indica el kilometraje actual de tu vehículo"}
            placeholder={dataCheckTecnico?.kilometraje}
            register={register}
            valueRegister={"kilometraje"}
          />
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              Selecciona el tipo de combustión de tu auto
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("combustion")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Nafta</option>
              <option>Nafta/GNC</option>
              <option>Diesel</option>
              <option>Híbrido</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">¿El Que Transmisión tiene?</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("transmision")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Automatica</option>
              <option>Manual</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              ¿El motor funciona correctamente?
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("motor")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              ¿Prende testigos/luz de falla en el tablero?
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("tablero")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">Funcionamiento del embrague</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("embrague")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Excelente</option>
              <option>Muy Bueno</option>
              <option>Regular/Malo</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              Funcionamiento de caja de cambios
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("caja_cambios")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Excelente</option>
              <option>Muy Bueno</option>
              <option>Regular/Malo</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">Funcionamiento de frenos</label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("frenos")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Excelente</option>
              <option>Muy Bueno</option>
              <option>Regular/Malo</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              Funcionamiento del aire acondicionado
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("aire_acondicionado")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Excelente</option>
              <option>Muy Bueno</option>
              <option>Regular/Malo</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              Funcionamiento de la calefacción
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("calefaccion")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Excelente</option>
              <option>Muy Bueno</option>
              <option>Regular/Malo</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              ¿El auto hace ruidos anormales al estar en funcionamiento?
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("motor_ruidos")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>Si, hace ruidos anormales</option>
              <option>No</option>
            </Form.Select>
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              ¿Cuántos kms tienen los neumáticos delanteros?
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("neumaticos_delanteros")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>0km - 25.000kms</option>
              <option>25.000kms - 50.000kms</option>
              <option>50.000kms - 75.000kms</option>
              <option>mas de 75.000kms</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <label className="label-input">
              ¿Cuántos kms tienen los neumáticos traseros?
            </label>
            <Form.Select
              className="deal-select"
              disabled={disabled}
              {...register("neumaticos_traseros")}
            >
              <option value="" disabled hidden>
                Seleccione una opción
              </option>
              <option>0km - 25.000kms</option>
              <option>25.000kms - 50.000kms</option>
              <option>50.000kms - 75.000kms</option>
              <option>mas de 75.000kms</option>
            </Form.Select>
          </div>
        </Col>
      </Row>
    </div>
  );
}
