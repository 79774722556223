import { db } from "../../../../firebase/config";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { generateId } from "../../../../utils/IdGenerator";

export const createPayments = async (
  deal_id,
  titular,
  tipoOperacion,
  monto,
  porc,
  moneda,
  fechaAPagar,
  fechaPago,
  estado,
  file,
  user
) => {
  const takeDocRef = doc(db, "companies", user?.company, "deals", deal_id, "data", "take");

  try {
    await updateDoc(takeDocRef, {
      "payment.payments": arrayUnion({
        id: generateId(),
        titular,
        tipoOperacion,
        monto,
        porc,
        moneda,
        fechaAPagar,
        fechaPago,
        estado,
        file,
      }),
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error creating payments:", error);
  }
};

export const editPayments = async (deal_id, newArray, user) => {
  const takeDocRef = doc(db, "companies", user?.company, "deals", deal_id, "data", "take");

  try {
    await updateDoc(takeDocRef, {
      "payment.payments": newArray,
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error editing payments:", error);
  }
};

export const deletePayments = async (deal_id, data, user) => {
  const takeDocRef = doc(db, "companies", user?.company, "deals", deal_id, "data", "take");

  try {
    await updateDoc(takeDocRef, {
      "payment.payments": arrayRemove(data),
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error deleting payments:", error);
  }
};

export const deleteAccount = async (
  deal_id,
  id,
  cbu,
  bank,
  account_holder,
  CUIT,
  user
) => {
  const takeDocRef = doc(db, "companies", user?.company, "deals", deal_id, "data", "take");

  try {
    await updateDoc(takeDocRef, {
      "payment.bank_accounts": arrayRemove({
        id,
        cbu,
        bank,
        account_holder,
        CUIT,
      }),
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error deleting account:", error);
  }
};

export const createAccount = async (
  deal_id,
  cbu,
  bank,
  account_holder,
  CUIT,
  user
) => {
  const takeDocRef = doc(db, "companies", user?.company, "deals", deal_id, "data", "take");

  try {
    await updateDoc(takeDocRef, {
      "payment.bank_accounts": arrayUnion({
        id: generateId(),
        cbu,
        bank,
        account_holder,
        CUIT,
      }),
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error creating account:", error);
  }
};

export const editAccount = async (deal_id, newArray, user) => {
  const takeDocRef = doc(db, "companies", user?.company, "deals", deal_id, "data", "take");

  try {
    await updateDoc(takeDocRef, {
      "payment.bank_accounts": newArray,
    });

    await updateDoc(takeDocRef, { user_update_data: user });
  } catch (error) {
    console.error("Error editing account:", error);
  }
};
