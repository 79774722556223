import TechnicalSheetCardComponent from "./TechnicalSheetCardComponent";

const ComfortComponent = ({ data }) => {
  return (
    <>
      <TechnicalSheetCardComponent title="Confort" data={data} />
    </>
  );
};

export default ComfortComponent;
