export const teams = {
  ADMIN: "Admin",
  CS: "CS",
  AS: "AS",
  PRICING: "Pricing",
  INSPECCION: "Inspeccion",
  BACKOFFICE: "BackOffice",
};

export const teams_comments = [
  {
    id: "Pricing",
    display: "Pricing",
    type: "team",
  },
  {
    id: "BackOffice",
    display: "BackOffice",
    type: "team",
  },
  {
    id: "AS",
    display: "AS",
    type: "team",
  },
  {
    id: "CS",
    display: "CS",
    type: "team",
  },
  {
    id: "Inspeccion",
    display: "Inspeccion",
    type: "team",
  },
  {
    id: "Admin",
    display: "Admin",
    type: "team",
  },
];
