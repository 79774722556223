import React, { useEffect, useState } from "react";
import IconoValid from "../images/ic_Valid.png";
import IconoResquested from "../images/ic_requested.png";
import IconoNew from "../images/ic_new.png";
import IconoAccepted from "../images/ic_accepted.png";
import IconoExpired from "../images/ic_expired.png";
import IconoRejected from "../images/ic_rejected.png";
import "../styles/style/PricingStyle.css";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function StatusIconComponent({ status }) {
  const [statusIcon, setStatusIcon] = useState(null);
  const [statusIconAltText, setStatusIconAltText] = useState("");
  const [tooltipText, setTooltipText] = useState("");

  useEffect(() => {
    getStatusIcon(status);
    getStatusIconAltText(status);
    getPopoverContent(status)
  }, [status]);

  const getPopoverContent = (status) => {
    switch (status) {
      case "valid":
        setTooltipText("Válido");
        break;
      case "requested":
        setTooltipText("Solicitado");
        break;
      case "new":
        setTooltipText("Nuevo");
        break;
      case "expired":
        setTooltipText("Vencido");
        break;
      case "accepted":
        setTooltipText("Aceptado");
        break;
      case "rejected":
        setTooltipText("Rechazado");
        break;
      default:
        setTooltipText("");
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "valid":
        setStatusIcon(IconoValid);
        break;
      case "requested":
        setStatusIcon(IconoResquested);
        break;
      case "new":
        setStatusIcon(IconoNew);
        break;
      case "expired":
        setStatusIcon(IconoExpired);
        break;
      case "rejected":
        setStatusIcon(IconoRejected);
        break;
      case "accepted":
        setStatusIcon(IconoAccepted);
        break;
      default:
        setStatusIcon();
    }
    return statusIcon;
  };
  const getStatusIconAltText = (status) => {
    switch (status) {
      case "valid":
        setStatusIconAltText("icono_valid");
        break;
      case "requested":
        setStatusIconAltText("icono_requested");
        break;
      case "new":
        setStatusIconAltText("icono_new");
        break;
      case "expired":
        setStatusIconAltText("icono_expired");
        break;
      case "accepted":
        setStatusIconAltText("icono_accepted");
        break;
      case "rejected":
        setStatusIconAltText("icono_rejected");
        break;
      default:
        setStatusIconAltText();
    }

    return statusIconAltText;
  };

  return (
    <OverlayTrigger
      placement="right"
      overlay={
        <Popover id="popover-basic">
          <Popover.Body>{tooltipText}</Popover.Body>
        </Popover>
      }
    >
      <img src={statusIcon} alt={statusIconAltText} className="status-icon" />
    </OverlayTrigger>
  );
}
