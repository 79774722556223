import { Button, Form, Modal } from "react-bootstrap";
import "react-day-picker/dist/style.css";
import { useEffect, useState } from "react";
import "../../../styles/style/ReminderStyle.css";
import { BiCalendarEvent } from "react-icons/bi";
import ModalCalendarComponent from "./ModalCalendarComponent";
import { useForm } from "react-hook-form";
import moment from "moment";
import { actionReminder } from "../services/servicesStates";
import { colors } from "../../../styles/colors";

const ReminderModalComponent = ({
  modal,
  setmodal,
  user,
  deal_id,
  actionId = "",
  nameActions,
  setshowModalRevision,
}) => {
  const [modalCalendar, setmodalCalendar] = useState(false);
  const [dateReminder, setdateReminder] = useState("");
  const { register, handleSubmit, reset, setValue } = useForm();

  const handleClose = () => {
    setmodal(false);
  };

  let fecha = new Date();
  let horasEstablecidas = fecha.getHours();
  let minutosEstablecidos = fecha.getMinutes();

  useEffect(() => {
    setValue(
      "hours_reminder",
      minutosEstablecidos >= 55 ? horasEstablecidas + 1 : horasEstablecidas
    );
    setValue(
      "minutes_reminder",
      minutosEstablecidos >= 55
        ? "0 min"
        : `${Math.floor(minutosEstablecidos / 5) * 5 + 5} min`
    );
  }, [modal]);

  const saveReminder = (data) => {
    const dateObject = {
      date: moment(dateReminder).format("YYYY-MM-DD"),
      hours: data.hours_reminder.toString(),
      minutes: data.minutes_reminder,
      comments: data.comments_reminder,
      action_id: actionId,
    };
    const objectReminder = {
      "action_requested.action_id": "reminder",
      "action_requested.data_reminder": dateObject,
      "action_requested.user": user,
      "action_requested.comments": "",
    };
    actionReminder(user?.company, deal_id, objectReminder);
    setdateReminder("");
    if (setshowModalRevision !== undefined) {
      setshowModalRevision(false);
    }
    setmodal(false);
    reset();
  };

  return (
    <>
      <Modal
        show={modal}
        onHide={handleClose}
        centered
        // dialogClassName="width-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="add-card-title-modal">Recordatorio {nameActions}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(saveReminder)}>
            <div className="d-flex align-items-start justify-content-between">
              <div
                className="d-flex align-items-center justify-content flex-column"
                style={{ width: "100%" }}
              >
                <div
                  className="d-flex align-items-center justify-content-between flex-column"
                  style={{ width: "100%" }}
                >
                  <div
                    className="reminder-input d-flex align-items-center justify-content-between mb-2"
                    onClick={() => setmodalCalendar(true)}
                    style={{ cursor: "pointer", width: "100%" }}
                  >
                    <p
                      className="reminder-text-placeholder"
                      style={{ color: dateReminder !== "" ? "black" : "" }}
                    >
                      {dateReminder !== ""
                        ? dateReminder.toLocaleDateString()
                        : "Seleccione una fecha"}
                    </p>
                    <div className="me-2">
                      <BiCalendarEvent
                        fontSize="24px"
                        color={colors.neutral.lightTwo}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center mt-2"
                  style={{ width: "100%" }}
                >
                  <Form.Select
                    className="select-order me-1"
                    style={{ width: "100%", border: "2px solid #e1e7ef" }}
                    {...register("hours_reminder")}
                  >
                    <option>07</option>
                    <option>08</option>
                    <option>09</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                  </Form.Select>
                  <Form.Select
                    className="select-order ms-1"
                    style={{ width: "100%", border: "2px solid #e1e7ef" }}
                    {...register("minutes_reminder")}
                  >
                    <option>0 min</option>
                    <option>5 min</option>
                    <option>10 min</option>
                    <option>15 min</option>
                    <option>20 min</option>
                    <option>25 min</option>
                    <option>30 min</option>
                    <option>35 min</option>
                    <option>40 min</option>
                    <option>45 min</option>
                    <option>50 min</option>
                    <option>55 min</option>
                  </Form.Select>
                </div>
              </div>
              <div className="ms-3">
                <textarea
                  placeholder="Comentario opcional"
                  className="reminder-input "
                  style={{ height: "90px", width: "100%" }}
                  {...register("comments_reminder")}
                />
                <Button
                  variant="primary"
                  className="btnPedirRevision mt-2"
                  type="submit"
                  style={{ width: "100%" }}
                >
                  Guardar recordatorio
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ModalCalendarComponent
        modalCalendar={modalCalendar}
        setmodalCalendar={setmodalCalendar}
        setdateReminder={setdateReminder}
        modal={modal}
      />
    </>
  );
};

export default ReminderModalComponent;
