import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  formatNumber,
  updateRangeInitial,
} from "../../../services/servicesPricing";
import toast from "react-hot-toast";
import { colors } from "../../../../../styles/colors";
import { useEffect, useState } from "react";

const InitialRangePricerModal = ({
  deal_id,
  setshowRangeInitial,
  showRangeInitial,
  user,
  dataHistory,
  valueCotizacion,
  setActiveModalPricing,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [arrayValues, setArrayValues] = useState([]);
  const [rangeOne, setrangeOne] = useState("");
  const [rangeTwo, setrangeTwo] = useState("");

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);

    setrangeOne(formattedValue);
  };

  const handleInputChangeTwo = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);

    setrangeTwo(formattedValue);
  };

  const sendPricingRangeInitial = (dataInput) => {
    const data = {
      "range.value": `$${dataInput.rangeOne} - $${dataInput.rangeTwo}`,
      "range.user": user,
    };
    updateRangeInitial(user?.company, deal_id, data);
    toast.success("Pricing enviado.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowRangeInitial(false);
    setActiveModalPricing(false);
  };

  const handleCloseRangeInitial = () => {
    setshowRangeInitial(false);
    setActiveModalPricing(false);
  };
  useEffect(() => {
    setArrayValues(valueCotizacion?.split("-"));
  }, [valueCotizacion]);
  return (
    <div>
      <Modal
        show={showRangeInitial}
        onHide={handleCloseRangeInitial}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="titleModal">Rango inicial</p>
            <form onSubmit={handleSubmit(sendPricingRangeInitial)}>
              <div className="d-flex align-items-center mt-2">
                <input
                  className="inputModal"
                  placeholder={valueCotizacion ? arrayValues[0] : "$0.000.000"}
                  type="text"
                  {...register("rangeOne", {
                    required: "El rango es requerido",
                  })}
                  value={rangeOne}
                  onChange={handleInputChange}
                />
                <div className="dividerInput"></div>
                <input
                  className="inputModal"
                  placeholder={valueCotizacion ? arrayValues[1] : "$0.000.000"}
                  type="text"
                  {...register("rangeTwo")}
                  value={rangeTwo}
                  onChange={handleInputChangeTwo}
                />
              </div>
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center flex-column justify-content-between mt-3"
              >
                {errors.rangeOne && (
                  <p
                    style={{
                      margin: "0px",
                      color: colors.status.red,
                      fontSize: "12px",
                      width: "100%",
                    }}
                    className="mt-2"
                  >
                    {errors.rangeOne?.message}
                  </p>
                )}
                <button
                  variant="primary"
                  className="btnSendPricing mb-2 mt-2"
                  type="submit"
                >
                  Enviar pricing
                </button>
              </div>
            </form>
            <div className="mt-2" style={{ width: "100%" }}>
              {dataHistory?.length > 0
                ? dataHistory.map((info, i) => (
                    <div
                      className="d-flex align-items-center justify-content-between dividerHistory mb-3"
                      style={{ width: "100%" }}
                      key={i}
                    >
                      <p className="textHistory mb-2">${info.value}</p>
                      <p className="textHistory mb-2">
                        {" "}
                        {new Date(
                          info.date.seconds * 1000
                        ).toLocaleDateString()}{" "}
                        -{" "}
                        {new Date(info.date.seconds * 1000).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </p>
                    </div>
                  ))
                : null}
            </div>
            <button
              variant="secondary"
              onClick={handleCloseRangeInitial}
              className="btnCancelarModal"
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default InitialRangePricerModal;
