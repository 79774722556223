import { apiUrl, axiosInstance, handleError } from "../../../services/apiConfig";

export const getUsers = async ({ params }) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/users/all_user`, {
      params,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener datos de la API' };
  }
};

export const deleteUser = async ({ user_id, company }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/users/delete_user`, {
      user_id,
      company,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al eliminar usuario' };
  }
}
export const inviteUser = async ({ email, company }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/users/send_invitation`, {
      email,
      company,
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al eliminar usuario' };
  }
}
export const updateUser = async ({ user_id, name, team, company }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/users/update_user`, {
      user_id,
      name,
      team,
      company
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al modificar usuario' };
  }
};
export const getTicket = async ({ deal_id }) => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/get_ticket/${deal_id}`);
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener ticket' };
  }
};

export const updateTicket = async ({ ticket_id, deal_id }) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/update_ticket`, {
      ticket_id,
      deal_id
    });
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al actualizar ticket' };
  }
};
export const getAssignment = async () => {
  try {
    const response = await axiosInstance.get(`${apiUrl}/get_assignment`);
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al obtener asignación' };
  }
};

export const updateAssignment = async (assignments) => {
  try {
    const response = await axiosInstance.post(`${apiUrl}/update_assignment`, assignments);
    return response.data;
  } catch (error) {
    handleError(error);
    return { error: true, message: 'Error al actualizar asignación' };
  }
};