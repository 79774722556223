import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import { HiOutlineDownload, HiOutlineUpload } from "react-icons/hi";
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { exportTable } from '../../services/sevicesPricingView';

export default function PricingImportAndExportModalComponent({ modal, data, setData, setModal }) {
  let isPageWide = useMediaQuery("(min-width: 460px)");


  const handleExportTable = async () => {
    console.log('ecportanfdo')
    try {
      const exportData = await exportTable({ endpoint: 'pricing/export_table' });
      // Process the exported data as needed, for example, download a file
      console.log(exportData);
    } catch (error) {
      console.error('Error exporting table:', error);
    }
  };
  const fileInputRef = useRef(null);

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      complete: (result) => {
        const transformedData = result.data.map(row => {
          const transformedRow = {
            "source": row["Fuente"],
            "year": row["Año"],
            "brand": row["Marca"],
            "model": row["Modelo"],
            "version": row["Versión"],
            "idInfo": row["ID Infoauto"],
            "stopper": row["Stopper"],
            "category": row["Categoría"],
            "usd": row["Precio USD"],
            "ars": row["Precio ARS"]
          };
          return transformedRow;
        });
        console.log('Datos CSV:', result.data);
        console.log('Datos transformados:', transformedData);
        setData(transformedData)
        // Hacer algo con result.data si es necesario
        setModal(false);
      },
      header: true,
      dynamicTyping: true,
    });
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const headers = [
    { label: 'Fuente', key: 'source' },
    { label: 'Año', key: 'year' },
    { label: 'Marca', key: 'brand' },
    { label: 'Modelo', key: 'model' },
    { label: 'Versión', key: 'version' },
    { label: 'ID Infoauto', key: 'idInfo' },
    { label: 'Stopper', key: 'stopper' },
    { label: 'Categoría', key: 'category' },
    { label: 'Precio USD', key: 'usd' },
    { label: 'Precio ARS', key: 'ars' },
  ];
  return (
    <Modal show={modal} centered onHide={() => setModal(false)} className='export-file-modal'>
      <Modal.Body >
        <span onClick={handleButtonClick} className={`export-file-button ${!isPageWide ? 'mobile' : ''}`}  >
          <HiOutlineDownload className={`export-file-button-icon ${!isPageWide ? 'mobile-icon' : ''}`} />
          Importar archivo
          <input type="file" accept=".csv" ref={fileInputRef} onChange={handleCSVUpload} style={{ display: 'none' }} />
        </span>

        <span onClick={handleExportTable} className={`export-file-button ${!isPageWide ? 'mobile' : ''}`} >
          <HiOutlineUpload className={`export-file-button-icon ${!isPageWide ? 'mobile-icon' : ''}`} />
          Exportar archivo
        </span>

      </Modal.Body>
    </Modal >
  )
}
