import React from "react";
import TabsStatesComponent from "./components/TabsStatesComponent";
import { colors } from "../../styles/colors";

export default function StatesPage() {
  return (
    <>
      <div className="containerAll">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <p
              style={{
                color: colors.primary.secondaryTwo,
                fontWeight: "600",
                margin: "0px",
              }}
              className="mb-2 mt-2 ms-2"
            >
              Estados
            </p>
          </div>
          <TabsStatesComponent />
        </div>
      </div>
    </>
  );
}
