import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../styles/style/InspectionPageStyle.css";
import { useUser } from "../../../context/UserContext";
import { Button } from "react-bootstrap";
import { updateCampoType } from "../services/servicesInspect";
import useFirebaseOnSnapshot from "../../../hooks/useFirebaseOnSnapshot";
import {
  ServerErrorComponent,
  SpinnerFullScreenComponent,
} from "../../../components";
import InspectionComponent from "../components/inspection/InspectionComponent";

export default function InspectionPage() {
  const { deal_id } = useParams();
  const { user } = useUser();
  const [dataAppointment, setDataAppointment] = useState(null);
  const { data, loading, error } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "check"
  );

  useEffect(() => {
    if (data && data.appointment) {
      setDataAppointment(data.appointment);
    }
  }, [data]);

  function handledInpsType(type) {
    updateCampoType(user?.company, deal_id, type, user);
  }

  return (
    <>
      {loading ? (
        <SpinnerFullScreenComponent />
      ) : (
        <>
          {error ? (
            <ServerErrorComponent error={error} />
          ) : (
            <>
              {data && data.type === "" ? (
                <div className="container-type-inspect">
                  <p className="inspection-text">Inspección</p>
                  <p className="inspection-question">
                    ¿Qué inspección se va a realizar?
                  </p>
                  <div className="inspection-buttons">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        handledInpsType("virtual");
                      }}
                      style={{ width: "110px", height: "40px" }}
                    >
                      Virtual
                    </Button>
                    <Button
                      style={{ width: "110px", height: "40px" }}
                      variant="outline-primary"
                      onClick={() => {
                        handledInpsType("physical");
                      }}
                    >
                      Física
                    </Button>
                  </div>
                </div>
              ) : (
                data && (
                  <InspectionComponent dataAppointment={dataAppointment} />
                )
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
