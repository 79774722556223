import React, { useState } from 'react';
import '../../styles/trengoView.css';
import { Button, Spinner } from 'react-bootstrap';
import { getTicket, updateTicket } from '../../services/usersConfigServices'; // Ajusta la ruta del archivo según la ubicación real

export default function TrengoComponent() {
  const [dealId, setDealId] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [isLoadingConsult, setIsLoadingConsult] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const handleConsult = async () => {
    setIsLoadingConsult(true);
    try {
      const { ticket_id } = await getTicket({ deal_id: dealId });
      setTicketId(ticket_id);
    } catch (error) {
      console.error('Error al obtener ticket:', error);
    } finally {
      setIsLoadingConsult(false);
    }
  };

  const handleUpdate = async () => {
    setIsLoadingUpdate(true);
    try {
      await updateTicket({ ticket_id: ticketId, deal_id: dealId });
    } catch (error) {
      console.error('Error al actualizar ticket:', error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  return (
    <div className='containerAll mt-3 d-inline-block'>
      <div className='d-flex'>
        <div className='m-1 '>
          <label className='trengo-input-label mb-1'>Deal id</label>
          <input
            type="text"
            className="trengo-input mb-4"
            name=""
            placeholder='Ingrese deal id'
            value={dealId}
            onChange={(e) => setDealId(e.target.value)}
          />
          <div className='d-flex justify-content-end'>
            <Button
              style={{ width: '100px' }}
              disabled={isLoadingConsult}
              onClick={handleConsult}>
              {isLoadingConsult ? (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status" />
              ) : 'Consultar'}
            </Button>
          </div>
        </div>
        <div className='m-1'>
          <label className='trengo-input-label mb-1 disabled'>Ticket id</label>
          <input
            type="text"
            className="trengo-input mb-4"
            name=""
            disabled={!ticketId}
            placeholder='Ingrese ticket id'
            value={ticketId}
            onChange={(e) => setTicketId(e.target.value)}
          />
          <div className='d-flex justify-content-end'>
            <Button
              style={{ width: '100px' }}
              disabled={!ticketId || isLoadingUpdate}
              onClick={handleUpdate}
            >
              {isLoadingUpdate ? (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status" />
              ) : 'Actualizar'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}