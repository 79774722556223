import React from 'react'

export default function GraphicsPricingPage() {
  return (
    <div className="p-3 h-100">
      <iframe
        width="100%"
        height="100%"
        src="https://lookerstudio.google.com/embed/reporting/665c22ad-6002-43ca-a39c-091056a69fa3/page/p_idjdd0vnjd"
        frameborder="0"
        allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox">
      </iframe>
    </div>
  )
}