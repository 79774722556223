import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import useFirebaseGet from "../../../hooks/useFirebaseGet";

const InfoAutoComponent = ({ deal_id, user, infoAuto }) => {
  const [brand, setbrand] = useState();
  const [model, setmodel] = useState();
  const [year, setyear] = useState();
  const [versions, setversions] = useState();
  const [versionCurrent, setversionCurrent] = useState();
  const { data } = useFirebaseGet(user?.company, deal_id, 'data', 'basics')

  useEffect(() => {
    setversions(infoAuto?.infoauto.versions);
    setbrand(infoAuto?.infoauto.brand);
    setmodel(infoAuto?.infoauto.model);
    setyear(infoAuto?.base_year);
    setversionCurrent(data?.car?.version);
  }, [deal_id, user.company, infoAuto, data]);

  return (
    <Table id="uncontrolled-tab-example" bordered hover responsive>
      <thead>
        <tr>
          <th className="text-head-table">Marca</th>
          <th className="text-head-table">Modelo</th>
          <th className="text-head-table">Version</th>
          <th className="text-head-table">Año</th>
          <th className="text-head-table">Precio {Number(year) - 1}</th>
          <th className="text-head-table">Precio {year}</th>
          <th className="text-head-table">Precio {Number(year) + 1}</th>
        </tr>
      </thead>
      <tbody>
        {versions?.map((info, i) => (
          <tr key={i}>
            <td
              className={
                info.version === versionCurrent
                  ? "text-version-current"
                  : "text-content-table"
              }
            >
              {brand}
            </td>
            <td
              className={
                info.version === versionCurrent
                  ? "text-version-current"
                  : "text-content-table"
              }
            >
              {model}
            </td>
            <td
              className={
                info.version === versionCurrent
                  ? "text-version-current"
                  : "text-content-table"
              }
            >
              {info.version}
            </td>
            <td
              className={
                info.version === versionCurrent
                  ? "text-version-current"
                  : "text-content-table"
              }
            >
              {year}
            </td>
            <td
              className={
                info.version === versionCurrent
                  ? "text-version-current"
                  : "text-content-table"
              }
            >
              {info.price_prev_year !== ""
                ? `$ ${Number(info.price_prev_year).toLocaleString("de")}`
                : "-"}
            </td>
            <td
              className={
                info.version === versionCurrent
                  ? "text-version-current"
                  : "text-content-table"
              }
            >
              {info.price !== ""
                ? `$ ${Number(info.price).toLocaleString("de")}`
                : "-"}
            </td>
            <td
              className={
                info.version === versionCurrent
                  ? "text-version-current"
                  : "text-content-table"
              }
            >
              {info.price_next_year !== ""
                ? `$ ${Number(info.price_next_year).toLocaleString("de")}`
                : "-"}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default InfoAutoComponent;
