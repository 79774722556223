import { useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { MdAdd, MdEdit } from "react-icons/md";
import ModalAddOrEditDiscountsComponent from "./ModalAddOrEditDiscountsComponent";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useUser } from "../../../../context/UserContext";
import { useParams } from "react-router-dom";

import Swal from "sweetalert2";
import {
  deleteDiscount,
  typeMoney,
} from "../../services/TakeServices/AmountToPayServices";
import { colors } from "../../../../styles/colors";
import { InputComponent } from "../../../../components";
import { useForm } from "react-hook-form";
import { formatNumber } from "../../../Pricing/services/servicesPricing";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

export default function AmountToPayTabComponent({
  disabled,
  takeData,
  deal_id,
  toggleEditing,
}) {
  const [addOrEditDiscounts, setAddOrEditDiscounts] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);
  const { user } = useUser();
  const params = useParams();
  const [valueMoneda, setvalueMoneda] = useState("");
  const [valueInput, setvalueInput] = useState("");
  const [isBenefits, setIsBenefits] = useState(false);

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    setValue("money", takeData?.amount_to_pay?.money);
    setvalueMoneda(takeData?.amount_to_pay?.money);
    setValue("type_change_money", takeData?.amount_to_pay?.type_change_money);
    setvalueInput(takeData?.amount_to_pay?.type_change_money);
  }, [
    takeData?.amount_to_pay?.money,
    takeData?.amount_to_pay?.type_change_money,
    isEditing,
    setValue,
  ]);

  const styledisabled = {
    backgroundColor: colors.gris[100],
  };
  const cellStyle = {
    color: colors.neutral.lightTwo,
  };
  function deleteDiscountsModal(data, dataIsBenefits) {
    Swal.fire({
      title: "Borrar descuento",
      text: "¿Estas seguro de que quieres eliminar el descuento?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((resultado) => {
      if (resultado.value) {
        deleteDiscount(params.deal_id, data, user, dataIsBenefits);
      } else {
        console.log("*NO se elimina el descuento*");
      }
    });
  }

  const editDiscounts = (data) => {
    setDataEdit(data);
    setAddOrEditDiscounts(true);
    setIsEditing(true);
  };

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "12px",
  };

  const handleInputChange = (e) => {
    setvalueMoneda(e.target.value);
  };

  const handleInputChangeTypeChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setvalueInput(`$${formattedValue}`);
  };

  const sendValueTypeMoney = (data) => {
    typeMoney(deal_id, data, user);
    toast.success("Informacion Guardada con exito.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    toggleEditing();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(sendValueTypeMoney)}>
        <Row>
          <Col lg={3}>
            <p style={textStyle} className="mb-2">
              Moneda
            </p>
            <Form.Select
              disabled={disabled}
              className="select-order"
              style={{ width: "100%" }}
              {...register("money")}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="ARS">Pesos (ARS)</option>
              <option value="USD">Dólares (USD)</option>
            </Form.Select>
          </Col>
          {valueMoneda === "USD" ? (
            <Col lg={3}>
              <p style={textStyle} className="mb-2">
                Tipo de cambio
              </p>
              <input
                disabled={disabled}
                className="input-deal"
                style={{ width: "100%" }}
                {...register("type_change_money")}
                value={valueInput}
                onChange={handleInputChangeTypeChange}
              />
            </Col>
          ) : null}
          <Col
            lg={valueMoneda === "USD" ? 6 : 9}
            className="d-flex align-items-center justify-content-end"
          >
            {!disabled ? (
              <Button type="submit" disabled={disabled} className="mb-5">
                Guardar cambios
              </Button>
            ) : null}
          </Col>
        </Row>
      </form>
      <div className="divider mt-3 mb-3" />
      <p style={textStyle} className="mb-2">
        Revision de oferta
      </p>
      <div style={{ width: "200px" }}>
        <Table bordered style={styledisabled}>
          <thead>
            <tr className="table-header">
              <th className="table-title">Oferta final</th>
              <th style={cellStyle} className="table-body">
                ${takeData?.amount_to_pay?.offer}
              </th>
            </tr>
          </thead>
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p style={textStyle}>Descuentos</p>

        {
          <Button
            size="sm"
            hidden={disabled}
            onClick={() => {
              setIsBenefits(false);
              setAddOrEditDiscounts(true);
              setIsEditing(false);
            }}
          >
            <MdAdd
              style={{
                fontSize: "18px",
              }}
            />
          </Button>
        }
      </div>
      <Table bordered style={styledisabled}>
        <thead>
          <tr
            className="table-header"
            style={{
              borderBottom: disabled
                ? "1px solid #1162A6"
                : "2px solid #864BFA",
            }}
          >
            <th className="table-title">Descuentos</th>
            <th className="table-title">Monto</th>
          </tr>
        </thead>
        <tbody>
          {takeData?.amount_to_pay?.discounts?.map((data, i) => (
            <tr key={i}>
              <td className="content-table">{data.name}</td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="content-table"
              >
                ${data.value}
                {!disabled ? (
                  <div>
                    <MdEdit
                      onClick={() => {
                        editDiscounts(data);
                      }}
                      style={{
                        fontSize: "24px",
                        color: colors.primary.primary,
                        cursor: "pointer",
                      }}
                    />
                    <RiDeleteBin6Fill
                      style={{
                        fontSize: "24px",
                        color: colors.status.red,
                        cursor: "pointer",
                      }}
                      onClick={() => deleteDiscountsModal(data, false)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p style={textStyle}>Adicionales</p>
        {
          <Button
            size="sm"
            hidden={disabled}
            onClick={() => {
              setIsBenefits(true);
              setAddOrEditDiscounts(true);
              setIsEditing(false);
            }}
          >
            <MdAdd
              style={{
                fontSize: "18px",
              }}
            />
          </Button>
        }
      </div>
      <Table bordered style={styledisabled}>
        <thead>
          <tr
            className="table-header"
            style={{
              borderBottom: disabled
                ? "1px solid #1162A6"
                : "2px solid #864BFA",
            }}
          >
            {/* <th className="table-title">Oferta final</th> */}
            <th className="table-title">Adicionales</th>
            <th className="table-title">Monto</th>
          </tr>
        </thead>
        <tbody>
          {takeData?.amount_to_pay?.benefits?.map((data, i) => (
            <tr key={i}>
              {/* <td className="content-table"></td> */}
              <td className="content-table">{data?.discounts_benefits}</td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="content-table"
              >
                ${data.benefits}
                {!disabled ? (
                  <div>
                    <MdEdit
                      onClick={() => {
                        editDiscounts(data);
                      }}
                      style={{
                        fontSize: "24px",
                        color: colors.primary.primary,
                        cursor: "pointer",
                      }}
                    />
                    <RiDeleteBin6Fill
                      style={{
                        fontSize: "24px",
                        color: colors.status.red,
                        cursor: "pointer",
                      }}
                      onClick={() => deleteDiscountsModal(data, true)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ width: "200px" }}>
        <Table bordered style={styledisabled}>
          <thead>
            <tr className="table-header">
              <th className="table-title">Monto a pagar</th>
              <th style={cellStyle} className="table-body">
                ${takeData?.amount_to_pay?.total}
              </th>
            </tr>
          </thead>
        </Table>
        {takeData?.amount_to_pay?.total_usd !== "" ? (
          <Table bordered style={styledisabled}>
            <thead>
              <tr className="table-header">
                <th className="table-title">Monto a pagar en USD</th>
                <th style={cellStyle} className="table-body">
                  ${takeData?.amount_to_pay?.total_usd}
                </th>
              </tr>
            </thead>
          </Table>
        ) : null}
        {takeData?.amount_to_pay?.resto_ars !== "" ? (
          <Table bordered style={styledisabled}>
            <thead>
              <tr className="table-header">
                <th className="table-title">Redondeo Pesos</th>
                <th style={cellStyle} className="table-body">
                  ${takeData?.amount_to_pay?.resto_ars}
                </th>
              </tr>
            </thead>
          </Table>
        ) : null}
      </div>
      <ModalAddOrEditDiscountsComponent
        modal={addOrEditDiscounts}
        setModal={setAddOrEditDiscounts}
        isEditing={isEditing}
        dataEdit={dataEdit}
        discounts={
          !isBenefits
            ? takeData?.amount_to_pay?.discounts
            : takeData?.amount_to_pay?.benefits
        }
        isBenefits={isBenefits}
      />
    </div>
  );
}
