import React from "react";
import { BiCar } from "react-icons/bi";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { SmallSpinnerComponent } from "../../../../components";
import kilometres from "../../../../images/ic_Kilometres.svg";
import removeDuplicateWords from "../../../../utils/removeDuplicateWords";
import { colors } from "../../../../styles/colors";

export default function DealInfoCardSectionComponent({
  basics,
  isPageWide,
  loading,
}) {
  function formatNumberWithCommas(numberOrString) {
    let number = parseFloat(numberOrString);

    if (isNaN(number)) {
      return "Invalid input";
    }
    // Verifica si el número tiene decimales
    const isFloat = number % 1 !== 0;

    // Formatea el número con puntos como separadores de miles y, si es necesario, muestra los decimales
    const formattedNumber = isFloat
      ? number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return formattedNumber;
  }
  const carString = [
    basics?.car?.brand,
    basics?.car?.model,
    basics?.car?.version,
  ]
    .filter((detail) => detail !== undefined && detail !== null)
    .join(" - ");
  return (
    <div className="container-card-deal-layout container-car-col">
      {loading ? (
        <SmallSpinnerComponent />
      ) : (
        <>
          <div className="container-row-car-info">
            <BiCar className="car-info-icon" />
            <p
              className={`car-info-text ${
                !isPageWide ? "car-info-text-mobile-size" : ""
              }`}
            >
              {removeDuplicateWords(carString)}
            </p>
          </div>
          <div className="container-row-car-info">
            <MdOutlineCalendarMonth className="car-info-icon" />
            <p className="car-info-text">
              {basics?.car?.year ? `${basics?.car?.year}` : ""}
            </p>
          </div>
          <div className="container-row-car-info">
            {/* <BiCar className='car-info-icon' /> */}
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ width: "100%" }}
            >
              <div className="d-flex">
                <img
                  src={kilometres}
                  alt="logo-auta"
                  className="car-info-icon"
                />
                <p className="car-info-text">
                  {basics?.car?.kms
                    ? `${formatNumberWithCommas(basics?.car?.kms)} km`
                    : ""}
                </p>
              </div>
              <div className="d-flex mb-1">
                <p className="car-info-text">Categoría:</p>
                <p
                  className="car-info-text ms-1"
                  style={{ color: colors.primary.primary }}
                >
                  {basics?.car?.category}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
