import React, { useRef, useState } from "react";
import { Table, OverlayTrigger, Popover, Button, Spinner } from "react-bootstrap";
import { colors } from "../../../styles/colors";
import { MdKeyboardArrowDown } from "react-icons/md";
import { ImWhatsapp } from "react-icons/im";
import UserAttendingTooltipComponent from "./UserAttendingTooltipComponent";
import TableActionsComponent from "./TableActionsComponent";
import {
  EmptyStateComponent,
  SpinnerFullScreenComponent,
} from "../../../components";
import { useUser } from "../../../context/UserContext";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../../context/CompanyContext";
import { useDeals } from "../../../context/DealsContext";
import { teams } from "../../../constants/teams";
import { updateDealInProcess } from "../services/servicesTableDeals";
import Swal from "sweetalert2";
import { action_status } from "../../../constants/actionStatus";
import { updateGoogleSheets } from "../services/takeTableServices";


export default function QuickTakeViewTableComponent() {
  const { user } = useUser();
  let isPageWide = useMediaQuery("(min-width: 600px)");
  const navigate = useNavigate();
  const { loadMore, increaseDeals, deals, loadingDeals } = useDeals();
  const { actions, allUsers } = useCompany();
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para el spinner

  const headTable = [
    "Fecha",
    "Turno",
    "Agente",
    "Sucursal",
    "Turno confirmado",
    "Estado",
    "Acciones",
    "Ultimo agente",
    "Cliente",
    "Vehiculo",
    "Patente",
    "Back Office",
    "Comentarios",
    "Ultima oferta",
    "",
  ];

  const rowStyleDealInProsess = {
    backgroundColor: colors.primary.secundaryTransparent,
  };
  const rowStyleDeal = {
    backgroundColor: colors.neutral.light,
  };
  const verDeal = (id) => {
    navigate(`/comments/${id}`);
  };
  let userTeamInProcess = [];

  const canUserViewDeal = (data) => {
    if (data?.current_user_team) {
      userTeamInProcess = Object.keys(data?.current_user_team);
      const userTeamExist = userTeamInProcess.includes(user?.team);
      if (!userTeamExist) {
        return true;
      } else {
        if (!data.current_user_team[user?.team]) {
          return true;
        } else if (data.current_user_team[user?.team] === user.uid) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };
  let showName = [];

  const showTooltip = (data) => {
    let usersNames = [];
    for (let key in data?.current_user_team) {
      if (data?.current_user_team[key] !== null) {
        Object.values(data?.current_user_team).forEach((value) => {
          if (value) {
            const foundUser = allUsers?.find((user) =>
              value?.includes(user.id)
            );
            if (foundUser) {
              usersNames.push(foundUser);
            } else {
              usersNames.push({
                team: Object.keys(data?.current_user_team),
                name: value,
              });
            }
          }
        });
        showName = usersNames;
        return true;
      }
    }
  };

  let userNameInProcess;

  function handleClick(event, data) {
    if (user?.team === teams.ADMIN || !data?.current_user_team) {
      updateDealInProcess(user?.company, user?.team, user?.uid, data.id);
    } else if (canUserViewDeal(data)) {
      if (data.current_user_team[user?.team] !== user.uid) {
        updateDealInProcess(user?.company, user?.team, user?.uid, data.id);
      }
    } else {
      event.preventDefault();
      const userIdInProcess = Object.values(data?.current_user_team);
      userNameInProcess = allUsers.find((user) =>
        userIdInProcess.includes(user.id)
      );
      Swal.fire({
        title: "No puede trabajar en este deal",
        text: `Se encuentra en proceso con ${userNameInProcess?.name}  `,
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
    }
  }

  const convertDateFormat = (originalDate) => {
    const [year, month, day] = originalDate.split("-");
    return `${day}/${month}/${year}`;
  };

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const MAX_ACTIONS_TO_DISPLAY = 4;

  // Crear la función createSheetData
  const createSheetData = async (data) => {
    setIsSubmitting(true);
    const formattedData = data.map(deal => [
      deal.deal_data.status.phases_dates["INSPECCION AGENDADA"]?.seconds
        ? new Date(deal.deal_data.status.phases_dates["INSPECCION AGENDADA"]?.seconds * 1000).toLocaleDateString()
        : "-",
      `${convertDateFormat(deal.date)} - ${deal.hour}`,
      allUsers.find((dataAllUser) => dataAllUser.id === deal.user_team?.CS)?.name || '',
      deal.deal_data.appointment_data?.check?.punto_de_inspeccion || '-',
      "Si/No",
      deal.status || '',
      deal.actions.data_actions
        .filter((action) => action.status === action_status.OPEN)
        .map((action) => action.action)
        .join(', '),
      deal.last_agent ? `${deal.last_agent.name} (${deal.last_agent.team})` : '',
      deal.name,
      deal.car,
      deal.car_id,
      deal.status_bo || '',
      deal.comments_bo || '',
      deal.deal_data.last_offer?.value || '',
    ]);
    try {
      const response = await updateGoogleSheets({ data: formattedData });
      if (response && response.link) {
        window.open(response.link, '_blank');
        setIsSubmitting(false);
      } else {
        console.error('No se encontró una URL en la respuesta:', response);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error al modificar el equipo del usuario:', error);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          className="mt-2"
          onClick={() => createSheetData(deals)}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Exportar a Google Sheets'
          )}
        </Button>
      </div>
      <div
        className="table-container-grab scrollChat"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        style={{ overflow: "scroll", overflowY: "hidden" }}
      >
        {loadingDeals ? (
          <SpinnerFullScreenComponent />
        ) : (
          <>
            {deals?.length === 0 ? (
              <div className="mt-3 h-100 deals-table-style">
                <EmptyStateComponent />
              </div>
            ) : (
              <Table
                hover
                className="mt-2 deals-table-style"
                style={{
                  width: "120vw",
                }}
              >
                <thead>
                  <tr>
                    <th
                      className="textHead"
                      colSpan={6}
                      style={{
                        backgroundColor: colors.primary.secondaryTwo,
                        padding: "0px !important",
                        color: "white",
                        fontWeight: "400",
                        borderTopLeftRadius: "8px",
                      }}
                    >
                      Agendamiento
                    </th>
                    <th
                      className="textHead"
                      colSpan={5}
                      style={{
                        backgroundColor: colors.primary.secondary,
                        padding: "0px !important",
                        color: "white",
                        fontWeight: "400",
                      }}
                    >
                      Deal
                    </th>
                    <th
                      className="textHead"
                      colSpan={5}
                      style={{
                        backgroundColor: colors.neutral.dark,
                        padding: "0px !important",
                        color: "white",
                        fontWeight: "400",
                        borderTopRightRadius: "8px",
                      }}
                    >
                      Back Office
                    </th>
                  </tr>
                  <tr>
                    {headTable.map((data, i) => (
                      <th
                        key={i}
                        className="textHead pt-3 pb-3"
                        style={{
                          backgroundColor: colors.neutral.default,
                          borderRight:
                            data === "Estado" || data === "Patente"
                              ? "2px solid #E8E1EF"
                              : "none",
                        }}
                      >
                        {data}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {deals?.map((data, i) => (
                    <tr
                      key={i}
                      onClick={(e) => handleClick(e, data)}
                      style={
                        !data?.current_user_team
                          ? rowStyleDeal
                          : !data?.current_user_team[user?.team]
                            ? rowStyleDeal
                            : data?.current_user_team[user?.team] !== user.uid
                              ? rowStyleDealInProsess
                              : rowStyleDeal
                      }
                    >
                      <td onClick={() => verDeal(data.id)}>
                        <p
                          className="text-content-table-deals"
                          style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                        >
                          {data?.deal_data?.status?.phases_dates[
                            "INSPECCION AGENDADA"
                          ]?.seconds
                            ? new Date(
                              data?.deal_data?.status?.phases_dates[
                                "INSPECCION AGENDADA"
                              ]?.seconds * 1000
                            )?.toLocaleDateString()
                            : "-"}
                        </p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p className="text-content-table-deals">
                          {convertDateFormat(data.date)} - {data.hour}
                        </p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p className="text-content-table-deals">
                          {allUsers.find(dataAllUser => dataAllUser.id === data.user_team?.CS)?.name || ""}
                        </p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p className="text-content-table-deals">
                          {data.deal_data.appointment_data.check.punto_de_inspeccion
                            ? data.deal_data.appointment_data.check.punto_de_inspeccion === "Av. Warnes 2707, CABA"
                              ? "Warnes"
                              : "Independencia"
                            : "-"}
                        </p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p className="text-content-table-deals">
                          {data?.deal_data?.status?.phase}
                        </p>
                      </td>
                      <td
                        onClick={() => verDeal(data.id)}
                        style={{
                          borderRight: "2px solid #E8E1EF",
                        }}
                      >
                        <p className="text-content-table-deals">{data?.status}</p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <div className="d-flex align-items-center justify-content-start">
                          {data?.actions?.data_actions
                            .filter(
                              (action) => action.status === action_status.OPEN
                            )
                            .slice(0, MAX_ACTIONS_TO_DISPLAY)
                            .map((action, i) => (
                              <TableActionsComponent
                                actions={actions}
                                action={action}
                                i={i}
                                key={i}
                              />
                            ))}

                          {data?.actions?.data_actions.filter(
                            (action) => action.status === action_status.OPEN
                          ).length > MAX_ACTIONS_TO_DISPLAY && (
                              <p className="text-length-action">
                                +
                                {data?.actions?.data_actions.filter(
                                  (action) => action.status === action_status.OPEN
                                ).length - MAX_ACTIONS_TO_DISPLAY}
                              </p>
                            )}
                        </div>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p className="text-content-table-deals">
                          {data?.last_agent
                            ? data?.last_agent?.name +
                            " " +
                            "(" +
                            data?.last_agent?.team +
                            ")"
                            : null}
                        </p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p className="text-content-table-deals">{data?.name}</p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p className="text-content-table-deals">
                          {data.car.split("-")[0]} {data.car.split("-")[1]}{" "}
                          {data.car.split("-")[3]}
                        </p>
                      </td>
                      <td
                        onClick={() => verDeal(data.id)}
                        style={{
                          borderRight: "2px solid #E8E1EF",
                        }}
                      >
                        <p className="text-content-table-deals">{data?.car_id}</p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p className="text-content-table-deals">
                          {data?.status_bo}
                        </p>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={
                            <Popover id="popover-basic">
                              <Popover.Body>
                                <p>{data.comments_bo}</p>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <p
                            className="text-content-table-deals"
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              maxWidth: "90px",
                            }}
                          >
                            {data.comments_bo}
                          </p>
                        </OverlayTrigger>
                      </td>
                      <td onClick={() => verDeal(data.id)}>
                        <p className="text-content-table-deals">
                          {data?.deal_data?.last_offer?.value}
                        </p>
                      </td>
                      <td
                        onClick={() => {
                          if (data.trengo_ticket_id) {
                            window.open(
                              `https://app.trengo.com/tickets/${data.trengo_ticket_id}`,
                              "_blank"
                            );
                          }
                        }}
                      >
                        <span style={{ display: "flex", alignItems: "flex-end" }}>
                          {data.trengo_ticket_id ? (
                            <p className="text-content-table-deals">
                              <ImWhatsapp fontSize="20" color="#00BB2D" />
                            </p>
                          ) : null}
                          {showTooltip(data) ? (
                            <UserAttendingTooltipComponent showName={showName} />
                          ) : null}
                        </span>
                      </td>
                    </tr>
                  ))}
                  {!loadMore ? null : (
                    <tr>
                      <td
                        colSpan="7"
                        style={{ textAlign: "center", cursor: "pointer" }}
                        onClick={increaseDeals}
                      >
                        <div
                          className={`d-flex align-items-center justify-content-${isPageWide ? "center" : "start"
                            }`}
                        >
                          <p
                            style={{
                              margin: "0px",
                              color: colors.primary.primary,
                              fontSize: "14px",
                              marginLeft: isPageWide ? "" : "100px",
                            }}
                          >
                            Cargar más
                          </p>
                          <MdKeyboardArrowDown
                            color={colors.primary.primary}
                            fontSize="14"
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </>
        )}
      </div>
    </>
  );
}
