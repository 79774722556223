import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const CountActionsWithIconComponent = ({ icon, count, type }) => {
  return (
    <>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Popover id="popover-basic">
            <Popover.Body>
              Tareas {type} {count === 0 ? null : `(${count})`}
            </Popover.Body>
          </Popover>
        }
      >
        <div className="d-flex align-items-center justify-content-between container-icon-data-actions me-3">
          <img src={icon} alt="icon-count" />
          <div className="divider-actions-data ms-1 me-1"></div>
          <div className="count-actions-data">{count}</div>
        </div>
      </OverlayTrigger>
    </>
  );
};
