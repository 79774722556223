import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import DealQuotesModalComponent from "./DealQuotesModalComponent";
import { teams } from "../../../../constants/teams";
import useFirebaseOnSnapshot from "../../../../hooks/useFirebaseOnSnapshot";
import {
  SmallSpinnerComponent,
  StatusIconComponent,
} from "../../../../components";
import { getStatusColor } from "../../../../utils/StatusColor";

export default function DealQuotesCardSectionComponent({ user, isPageWide }) {
  const { deal_id } = useParams();
  const { data, loading } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "pricing"
  );
  const [showPricingModal, setShowPricingModal] = useState(false);

  const handleClose = () => setShowPricingModal(false);

  const handleShow = () => {
    setShowPricingModal(true);
  };
  const [latestDate, setLatestDate] = useState(null);
  const [latestValue, setLatestValue] = useState(null);
  const [latestKey, setLatestKey] = useState(null);
  const [latestStatus, setLatestStatus] = useState(null);

  const findLatestDate = () => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const dateValue = data[key].date;
        let currentDate;
        if (typeof dateValue === "string") {
          currentDate = new Date(dateValue);
        } else if (dateValue && dateValue.seconds) {
          currentDate = new Date(dateValue.seconds * 1000);
        } else {
          console.error(`Fecha no válida para la clave ${key}`);
          continue;
        }

        if (!isNaN(currentDate)) {
          // Verifica si la conversión a objeto de fecha fue exitosa
          if (!latestDate || currentDate > latestDate) {
            setLatestDate(currentDate);
            setLatestValue(data[key].value || "00.000.000");
            setLatestKey(key);
            setLatestStatus(data[key].status); // Actualiza el estado del estatus
          }
        }
      }
    }
  };

  findLatestDate();
  function getSpecificWord(text) {
    const keywordMapping = {
      expected_offer: "Oferta esperada",
      final_offer: "Oferta final",
      offer: "Oferta inicial",
      range: "Rango inicial",
      sale_price: "Precio de venta",
      virtual_offer: "Oferta Virtual",
    };
    if (keywordMapping.hasOwnProperty(text)) {
      return keywordMapping[text];
    } else {
      return false;
    }
  }
  const isRange = latestKey === "range";

  return (
    <div className="container-card-deal-layout vertical-divider">
      {loading ? (
        <SmallSpinnerComponent />
      ) : (
        <>
          <div className="column-data">
            {latestKey ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    className="offer-type"
                    style={{
                      color: getStatusColor(latestStatus),
                    }}
                  >
                    {getSpecificWord(latestKey)}
                  </p>
                  <StatusIconComponent status={latestStatus} />
                </div>
                <p
                  className={`offer-value ${isRange ? "range-font-size" : ""} ${isPageWide ? "" : "range-font-size-mobile"
                    }`}
                  style={{
                    color: getStatusColor(latestStatus),
                  }}
                >
                  {latestKey !== "range"
                    ? latestValue
                      ? latestValue.startsWith("$") // Verifica si latestValue ya empieza con "$"
                        ? latestValue // Si ya tiene "$", no lo agregues nuevamente
                        : `$${latestValue}` // Si no tiene "$", agrégalo
                      : ""
                    : latestValue}
                </p>
                <p className="offer-date">
                  Última cotización{" "}
                  {latestDate
                    ? latestDate.toLocaleDateString("es-AR", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                    : "N/A"}
                </p>
              </>
            ) : (
              <p
                className="pending-offer"
                style={{
                  color: getStatusColor("requested"),
                  alignSelf: "center",
                }}
              >
                Cotizacion Pendiente
              </p>
            )}
          </div>
          <div className="columm-buttons">
            <Button
              variant={`${showPricingModal ? "primary" : "outline-primary"}`}
              className={`${isPageWide ? "" : "font-size-mobile"}`}
              onClick={handleShow}
            >
              Cotizaciones
            </Button>
            {user?.team === teams.PRICING || user?.team === teams.ADMIN ? (
              <Button
                style={{ marginTop: "8px" }}
                className={`${isPageWide ? "" : "font-size-mobile"}`}
                as={NavLink}
                to={`analitics/${deal_id}`}
                variant="outline-primary"
              >
                Analíticas
              </Button>
            ) : null}
          </div>
          <DealQuotesModalComponent
            showPricingModal={showPricingModal}
            handleClose={handleClose}
            data={data}
            loading={loading}
            latestKey={latestKey}
          />
        </>
      )}
    </div>
  );
}
