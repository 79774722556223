import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase/config";

export const updateDocumentation = async (data, deal_id, user) => {
  const documentation_object = {
    titulo_vehiculo: data.titulo_vehiculo,
    ddjj_extravio: data.ddjj_extravio,
    cedulas_verdes: data.cedulas_verdes,
    value_cedulas_verdes: data.value_cedulas_verdes,
    cedulas_azules: data.cedulas_azules,
    value_cedulas_azules: data.value_cedulas_azules,
    ddjj_extravio_cedula_azul: data.ddjj_extravio_cedula_azul,
    grabado_autopartes: data.grabado_autopartes,
    grabado_cristales: data.grabado_cristales,
    vpa: data.vpa,
    vtv: data.vtv,
    vtv_expiration_date: data.vtv_expiration_date,
    manual_auto: data.manual_auto,
    llave_original: data.llave_original,
    segunda_llave: data.segunda_llave,
    gns: data.gns,
    combrobantes_service: data.combrobantes_service,
    codigo_radio_llaves: data.codigo_radio_llaves,
    value_codigo_radio_llaves: data.value_codigo_radio_llaves,
    tuerca_seguridad: data.tuerca_seguridad,
    baja_municipal_provincial: data.baja_municipal_provincial,
    libra_deuda_municipal_provincial: data.libra_deuda_municipal_provincial,
  };

  const documentation = Object.filter(documentation_object, function (info) {
    return info !== undefined && info !== "";
  });

  try {
    const takeDocRef = doc(db, "companies", user?.company, "deals", deal_id, "data", "take");
    await updateDoc(takeDocRef, { documentation, user_update_data: user });
  } catch (error) {
    console.error("Error actualizando la documentación: ", error);
    return error;
  }
};
