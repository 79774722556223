import React, { useEffect, useState } from "react";
import HolderComponent from "../adminInfo/HolderComponent";
import { useUser } from "../../../../context/UserContext";
import LongSnapshotFirebase from "../../../../utils/LongSnapshotFirebase";

export default function HolderTabComponent({
  disabled,
  deal_id,
  toggleEditing,
  textStyle,
}) {
  const { user } = useUser();

  const [arrayConsumidorFinal, setarrayConsumidorFinal] = useState([]);
  const [arrayConsumidorFinalDelete, setarrayConsumidorFinalDelete] = useState(
    []
  );
  const [arrayPersonaJuridica, setarrayPersonaJuridica] = useState([]);
  const [arrayPersonaJuridicaDelete, setarrayPersonaJuridicaDelete] = useState(
    []
  );
  const [dataEmpresa, setdataEmpresa] = useState();
  const [dataTipoInscripcion, setdataTipoInscripcion] = useState();
  useEffect(() => {
    const callbackHolder = (dataHolder) => {
      setarrayConsumidorFinal(
        dataHolder?.payment?.titulares?.personas_fisicas
      );
      setarrayPersonaJuridica(
        dataHolder?.payment?.titulares?.persona_juridica?.apoderados
      );
      setdataTipoInscripcion(dataHolder?.payment?.titulares?.tipo_inscripcion);
      setdataEmpresa(dataHolder?.payment?.titulares?.persona_juridica);
    }
    const unsubscribeHolder = LongSnapshotFirebase(user?.company, deal_id, "data", "take", callbackHolder)
    return () => {
      unsubscribeHolder();
    };
  }, [deal_id, user?.company]);
  return (
    <HolderComponent
      disabled={disabled}
      deal_id={deal_id}
      arrayConsumidorFinal={arrayConsumidorFinal}
      setarrayConsumidorFinal={setarrayConsumidorFinal}
      setarrayConsumidorFinalDelete={setarrayConsumidorFinalDelete}
      arrayConsumidorFinalDelete={arrayConsumidorFinalDelete}
      setarrayPersonaJuridica={setarrayPersonaJuridica}
      arrayPersonaJuridica={arrayPersonaJuridica}
      arrayPersonaJuridicaDelete={arrayPersonaJuridicaDelete}
      setarrayPersonaJuridicaDelete={setarrayPersonaJuridicaDelete}
      dataEmpresa={dataEmpresa}
      dataTipoInscripcion={dataTipoInscripcion}
      user={user}
      toggleEditing={toggleEditing}
      textStyle={textStyle}
    />
  );
}
