import { useEffect } from "react";
import { Row } from "react-bootstrap";
import { teams } from "../../../../constants/teams";

const CommentAdditional = ({
  disabled,
  register,
  setValue,
  dataObservaciones,
  dataObservacionesAddittional,
  user,
}) => {
  useEffect(() => {
    setValue("observaciones", dataObservaciones);
    setValue("observaciones_adicionales", dataObservacionesAddittional);
  }, [dataObservaciones, dataObservacionesAddittional, setValue]);

  return (
    <div>
      <Row>
        <div className="select-container">
          <label className="label-input">Observaciones adicionales</label>
          <textarea
            disabled={
              !disabled &&
                (user?.team === teams.ADMIN || user?.team === teams.BACKOFFICE)
                ? false
                : true
            }
            className="inputModal mt-1"
            style={{ width: "100%", height: "110px" }}
            placeholder="Dejar observaciones adicionales"
            {...register("observaciones_adicionales")}
          />
        </div>
      </Row>
    </div>
  );
};

export default CommentAdditional;
