import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { witness4x4Options, witnessHiLoOptions } from '../../../../../constants/sectionsConst';
import {
  handleSingleCheckAndInputBoxChange
} from '../../../../../utils/sectionFuncions';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';

export default function Section3TabComponent({
  formDataTableView,
  saveFormDataTable,
  editAllowed
}) {
  let isMobile = useMediaQuery("(max-width: 500px)");
  const [selectedTractionValue, setSelectedTractionValue] = useState('');
  const { section3 } = formDataTableView;

  const handleOptionChangeWitnessHiLo = (value) => {
    saveFormDataTable('section3', 'witnessHiLo', { title: 'Testigo Hi/Lo', values: value });
  };

  const handleOptionChangeWitness4x4 = (value) => {
    saveFormDataTable('section3', 'witness4x4', { title: 'Testigo 4x4', values: value });
  };

  const handleCheckboxAndInputTractionChange = (value) => {
    saveFormDataTable('section3', 'traction', { title: 'Tracción 4x4', values: value });
  }
  return (
    <>
      {!isMobile ? (
        <Container fluid>
          <h1 className='section-title'>Doble Tracción</h1>
          <Row>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Testigo 4x4</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section3-witness4x4'>
                  {witness4x4Options.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      checked={section3?.witness4x4?.values === option}
                      onChange={() => handleOptionChangeWitness4x4(option)}
                      className={`section-checks ${index === witness4x4Options.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Tracción 4x4</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section3-traction'>
                  {['Ok', 'No Acopla'].map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name={option}
                      value={option}
                      checked={section3?.traction?.values === option}
                      className={`section-checks ${index === 1 ? 'mb-1' : 'mb-2'}`}
                      onChange={(e) => {
                        handleSingleCheckAndInputBoxChange(e.target.value, setSelectedTractionValue);
                        handleCheckboxAndInputTractionChange(e.target.value)
                      }}
                    />
                  ))}
                  <div className="d-flex align-items-center">
                    <Form.Check
                      disabled={!editAllowed}
                      type="checkbox"
                      label="Otro:"
                      name="Other"
                      value='Other'
                      className='section-checks'
                      checked={section3?.traction?.values !== 'Ok' && section3?.traction?.values !== 'No Acopla' || selectedTractionValue === 'Other'}
                      onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedTractionValue)}
                    />

                    <input
                      type="text"
                      className='section-input ms-2'
                      value={section3?.traction?.values !== 'Ok' && section3?.traction?.values !== 'No Acopla' ? section3?.traction?.values : ''}
                      onChange={(e) => handleCheckboxAndInputTractionChange(e.target.value)}
                      disabled={selectedTractionValue !== 'Other' || !editAllowed}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Testigo Hi/Lo</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section3-witnessHiLo'>
                  {witnessHiLoOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      checked={section3?.witnessHiLo?.values === option}
                      onChange={() => handleOptionChangeWitnessHiLo(option)}
                      className={`section-checks ${index === witnessHiLoOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container fluid>
          <h1 className='section-title'>Doble Tracción</h1>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Testigo 4x4</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section3-witness4x4'>
              {witness4x4Options.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  checked={section3?.witness4x4?.values === option}
                  onChange={() => handleOptionChangeWitness4x4(option)}
                  className={`section-checks ${index === witness4x4Options.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Testigo Hi/Lo</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section3-witnessHiLo'>
              {witnessHiLoOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  checked={section3?.witnessHiLo?.values === option}
                  onChange={() => handleOptionChangeWitnessHiLo(option)}
                  className={`section-checks ${index === witnessHiLoOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Tracción 4x4</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section3-traction'>
              {['Ok', 'No Acopla'].map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name={option}
                  value={option}
                  checked={section3?.traction?.values === option}
                  className={`section-checks ${index === 1 ? 'mb-1' : 'mb-2'}`}
                  onChange={(e) => {
                    handleSingleCheckAndInputBoxChange(e.target.value, setSelectedTractionValue);
                    handleCheckboxAndInputTractionChange(e.target.value)
                  }}
                />
              ))}
              <div className="d-flex align-items-center">
                <Form.Check
                  disabled={!editAllowed}
                  type="checkbox"
                  label="Otro:"
                  name="Other"
                  value='Other'
                  className='section-checks'
                  checked={section3?.traction?.values !== 'Ok' && section3?.traction?.values !== 'No Acopla' || selectedTractionValue === 'Other'}
                  onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedTractionValue)}
                />
                <input
                  type="text"
                  className='section-input ms-2'
                  value={section3?.traction?.values !== 'Ok' && section3?.traction?.values !== 'No Acopla' ? section3?.traction?.values : ''}
                  onChange={(e) => handleCheckboxAndInputTractionChange(e.target.value)}
                  disabled={selectedTractionValue !== 'Other' || !editAllowed}
                />
              </div>
            </div>
          </div>

        </Container>
      )}
    </>
  )
}
