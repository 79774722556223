import React from "react";
import "../styles/style/inputStyle.css";
import { colors } from "../styles/colors";

const disableStyle = {
  color: colors.gris[1000],
  backgroundColor: colors.gris[100],
};

const enableStyle = {
  color: colors.primary.secondaryTwo,
  fontWeight: "500",
};
const InputComponent = ({
  label,
  placeholder,
  register,
  valueRegister,
  disabled,
  type,
  maxLength,
  max,
  min,
}) => {
  return (
    <div style={{ marginBottom: "12px", marginTop: "8px" }}>
      <label className="label-input">{label}</label>
      <input
        max={max}
        min={min}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        {...register(`${valueRegister}`)}
        className="input-deal"
        type={type}
        style={disabled ? disableStyle : enableStyle}
      />
    </div>
  );
};

export default InputComponent;
