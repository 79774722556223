//para los componentes que manejan checkbox mas input otros(manejo de seleccion)
export const handleSingleCheckAndInputBoxChange = (selected, setSelectedCheckbox) => {
  setSelectedCheckbox(selected);
};
/// esta funcion es para guardar en el objeto para la tabla de observaciones lo estados de los checkbox de multiseleccion
export function updateDataForCheckboxChange(currentValues, newValue) {
  if (currentValues.includes(newValue)) {
    return currentValues.filter(item => item !== newValue);
  } else {
    return [...currentValues, newValue];
  }
}

///cambioss
//seleccion simple por tabla 
export const updateFormDataTableView = (setFormDataTableView, sectionName, nestedObjectName, rowTitle, selectedOption, section) => {
  setFormDataTableView(prevState => {
    return {
      ...prevState,
      [sectionName]: {
        ...section,
        [nestedObjectName]: section[nestedObjectName].map(row => {
          if (row.title === rowTitle) {
            let updatedValues;
            // Si el selectedOption ya está presente, mantener solo ese valor
            if (row.values.includes(selectedOption)) {
              updatedValues = [selectedOption];
            } else {
              // Si no, reemplazar todas las values con el selectedOption
              updatedValues = [selectedOption];
            }
            return { ...row, values: updatedValues };
          }
          return row;
        })
      }
    };
  });
};
//seleccion multiple por tabla
export const updateFormDataTableViewMultiple = (setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, section) => {
  setFormDataTableView(prevState => {
    return {
      ...prevState,
      [sectionName]: {
        ...section,
        [nestedObjectName]: section[nestedObjectName].map(row => {
          if (row.title === rowTitle) {
            const updatedValues = row.values.includes(option)
              ? row.values.filter(value => value !== option)
              : [...row.values, option];
            return { ...row, values: updatedValues };
          }
          return row;
        })
      }
    };
  });
};