import React, { useState } from "react";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  aceptarPropuesta,
  rechazarPropuesta,
  solicitarRevision,
  updateOfferPostFinal,
  solicitarCotizacion,
  formatNumber,
} from "../../../services/servicesPricing";
import toast from "react-hot-toast";
import { colors } from "../../../../../styles/colors";
import { MdHelpOutline } from "react-icons/md";
import { teams } from "../../../../../constants/teams";

const FinalOfferAdminModal = ({
  deal_id,
  setshowOfferFinal,
  showOfferFinal,
  user,
  dataHistory,
  actionId,
  sendActionId,
  valueCotizacion,
  revisionActionId,
  status,
  setActiveModalPricing,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [showModalRevision, setshowModalRevision] = useState();
  const [valueOfferFinal, setvalueOfferFinal] = useState("");

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Como administrador puedes enviar y solicitar cotizaciones.
      </Popover.Body>
    </Popover>
  );
  const handleCloseOfferFinal = () => {
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };
  const activeModalRevision = () => {
    setshowOfferFinal(false);
    setshowModalRevision(true);
    reset();
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);

    setvalueOfferFinal(formattedValue);
  };

  const sendPricingOfferFinal = (dataInput) => {
    const data = {
      "final_offer.value": dataInput.value,
      "final_offer.user": user,
    };
    updateOfferPostFinal(user?.company, deal_id, data);
    toast.success("Pricing enviado.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  const requestPrice = () => {
    const data = {
      "action_requested.action_id": actionId,
      "action_requested.comments": "",
      "action_requested.user": user,
      "action_requested.data_reminder": {},
    };
    solicitarCotizacion(user?.company, deal_id, data);
    toast.success("Solicitud enviada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };
  const acceptedPrice = () => {
    const data = {
      "action_requested.action_id": sendActionId,
      "action_requested.comments": "",
      "action_requested.user": user,
    };
    aceptarPropuesta(user?.company, deal_id, data);
    toast.success("Oferta aceptada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  const rejectedPrice = () => {
    const data = {
      "action_requested.action_id": "offer-f-rejected",
      "action_requested.comments": "",
      "action_requested.user": user,
    };
    rechazarPropuesta(user?.company, deal_id, data);
    toast.success("Oferta rechazada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  const sendReviewRequest = (dataComment) => {
    const data = {
      "action_requested.action_id": revisionActionId,
      "action_requested.user": user,
      "action_requested.comments": dataComment.comments,
      "action_requested.data_reminder": {},
    };
    solicitarRevision(user?.company, deal_id, data);
    toast.success("Solicitud de revision enviada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowModalRevision(false);
    setActiveModalPricing(false);
    reset();
  };

  return (
    <div>
      <Modal
        show={showOfferFinal}
        onHide={handleCloseOfferFinal}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <span
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <p className="titleModal">Oferta final</p>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={popover}
              >
                <span>
                  <MdHelpOutline
                    onClick={() => {}}
                    className="help-icon-modal"
                  />
                </span>
              </OverlayTrigger>
            </span>
            <form
              onSubmit={handleSubmit(sendPricingOfferFinal)}
              style={{ width: "100%" }}
            >
              <div className="d-flex align-items-center mt-2">
                <input
                  className="inputModal"
                  style={{ width: "100%" }}
                  type="text"
                  placeholder={valueCotizacion ? valueCotizacion : "$0.000.000"}
                  {...register("value", {
                    required: "La oferta final es requerida",
                  })}
                  value={valueOfferFinal}
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center flex-column justify-content-between mt-3"
              >
                {errors.value && (
                  <p
                    style={{
                      margin: "0px",
                      color: colors.status.red,
                      fontSize: "12px",
                      width: "100%",
                    }}
                    className="mt-2"
                  >
                    {errors.value?.message}
                  </p>
                )}
                <button
                  type="submit"
                  variant="primary"
                  className="btnSendPricing mb-2 mt-2"
                >
                  Enviar pricing
                </button>
              </div>
            </form>
            {!valueCotizacion ? (
              <button
                variant="primary"
                className="btnSendPricing mb-2"
                onClick={requestPrice}
              >
                Solicitar
              </button>
            ) : (
              <>
                {status !== "expired" &&
                (user.team === teams.CS ||
                  user.team === teams.AS ||
                  user.team === teams.ADMIN) ? (
                  <>
                    <button
                      variant="primary"
                      className="btnSendPricing mb-2"
                      onClick={acceptedPrice}
                    >
                      Aceptó la oferta
                    </button>
                    <button
                      variant="primary"
                      className="btnSendPricing mb-2"
                      onClick={rejectedPrice}
                    >
                      Rechazó la oferta
                    </button>
                  </>
                ) : null}
                <button
                  variant="primary"
                  className="btnPedirRevision mb-3"
                  onClick={activeModalRevision}
                >
                  Pedir revisión
                </button>
              </>
            )}
            <div className="mt-2" style={{ width: "100%" }}>
              {dataHistory?.length > 0
                ? dataHistory.map((info, i) => (
                    <div
                      className="d-flex align-items-center justify-content-between dividerHistory mb-3"
                      style={{ width: "100%" }}
                      key={i}
                    >
                      <p className="textHistory mb-2">${info.value}</p>
                      <p className="textHistory mb-2">
                        {" "}
                        {new Date(
                          info.date.seconds * 1000
                        ).toLocaleDateString()}{" "}
                        -{" "}
                        {new Date(info.date.seconds * 1000).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </p>
                    </div>
                  ))
                : null}
            </div>
            <button
              variant="secondary"
              onClick={handleCloseOfferFinal}
              className="btnCancelarModal"
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalRevision}
        onHide={handleCloseOfferFinal}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="titleModal">Oferta final</p>
            <form
              onSubmit={handleSubmit(sendReviewRequest)}
              style={{ width: "100%" }}
            >
              <textarea
                className="inputModal mt-3"
                style={{ width: "100%", height: "110px" }}
                placeholder="Dejar comentario opcional"
                {...register("comments")}
              />
              <div
                style={{ width: "100%" }}
                className="d-flex flex-column align-items-center justify-content-between"
              >
                <button
                  variant="primary"
                  className="btnSendPricing mb-2 mt-3"
                  type="submit"
                  // onClick={sendApply}
                >
                  Solicitar
                </button>
                <button
                  variant="secondary"
                  className="btnCancelarModal"
                  onClick={() => setshowModalRevision(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FinalOfferAdminModal;
