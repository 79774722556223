import React, { useState } from 'react'
import { Form, Pagination, Table } from 'react-bootstrap';
import '../../styles/pricingView.css'

export default function PricingTableComponent({
  data,
  selectedRows,
  setSelectedRows,
  currentPage,
  setCurrentPage,
  totalPages,
  setIsStopper

}) {
  const [selectAll, setSelectAll] = useState(false);

  const headTable = [
    "",
    "ID Auta",
    "Marca",
    "Modelo",
    "Versión",
    "Año",
    "Categoría",
    "Stopper",
    "Precio USD",
    "Precio ARS",
  ];

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    const selectedRowsData = selectAll ? [] : data;
    setSelectedRows(selectedRowsData);
  };

  const toggleRowSelect = (rowData) => {
    const isSelected = selectedRows.some((row) => row === rowData);

    if (isSelected) {
      // Si la fila está seleccionada, la deseleccionamos
      const newSelectedRows = selectedRows.filter((row) => row !== rowData);
      setSelectedRows(newSelectedRows);

      // Verificar si hay alguna otra fila seleccionada con stopper: "SI"
      const anyStopperRowSelected = newSelectedRows.some((row) => row.stopper === "SI");
      setIsStopper(anyStopperRowSelected);
    } else {
      // Si la fila no está seleccionada, la seleccionamos
      const newSelectedRows = [...selectedRows, rowData];
      setSelectedRows(newSelectedRows);

      // Verificar si la fila seleccionada tiene stopper: "SI"
      if (rowData.stopper === "SI") {
        setIsStopper(true);
      }
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationItems = () => {
    const pageItems = [];
    const maxPagesToShow = 10;

    const addPaginationItem = (content) => {
      pageItems.push(
        <Pagination.Item
          key={content}
          active={content === currentPage}
          onClick={() => handlePageChange(content)}
        >
          {content}
        </Pagination.Item>
      );
    };

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        addPaginationItem(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

      if (startPage > 1) {
        addPaginationItem(1);
        if (startPage > 2) {
          pageItems.push(<Pagination.Ellipsis key="ellipsis-start" disabled />);
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        addPaginationItem(i);
      }

      if (endPage < totalPages - 1) {
        pageItems.push(<Pagination.Ellipsis key="ellipsis-end" disabled />);
      }
    }

    return pageItems;
  };

  return (
    <>
      <Table hover responsive className="mt-3 deals-table-style">
        <thead>
          <tr>
            {headTable.map((data, i) => (
              <th key={i} className="textHead">
                {i === 0 ? (
                  <Form.Check
                    type="checkbox"
                    id="selectAllCheckbox"
                    className='pricing-table-checkbox'
                    checked={selectAll}
                    onChange={toggleSelectAll} />
                ) : (
                  data
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((rowData, i) => (
            <tr key={i} onClick={() => toggleRowSelect(rowData)}>
              <td>
                <Form.Check
                  type="checkbox"
                  id={`rowCheckbox${i}`}
                  className='pricing-table-checkbox'
                  checked={selectedRows.includes(rowData)}
                  onChange={() => toggleRowSelect(rowData)} />
              </td>
              <td>
                <p className="text-content-table-deals">{rowData?.idAuta}</p>
              </td>
              <td>
                <p className="text-content-table-deals">{rowData?.brand}</p>
              </td>
              <td>
                <p className="text-content-table-deals">{rowData?.model}</p>
              </td>
              <td>
                <p className="text-content-table-deals">{rowData?.version}</p>
              </td>
              <td>
                <p className="text-content-table-deals">{rowData?.year}</p>
              </td>
              <td>
                <p className="text-content-table-deals">{rowData?.category}</p>
              </td>
              <td>
                <p className="text-content-table-deals">{rowData?.stopper}</p>
              </td>

              <td>
                <p className="text-content-table-deals">
                  $ {Number(rowData?.usd).toLocaleString('es-AR', { minimumIntegerDigits: 1, useGrouping: true })}
                </p>
              </td>
              <td>
                <p className="text-content-table-deals">
                  $ {rowData?.ars.toLocaleString()}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {data?.length ?
        <Pagination className='d-flex justify-content-center'>
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {renderPaginationItems()}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
        : null}
    </>
  );
}