import React from "react";
import CamaraValComponent from "./components/CamaraValComponent";
import InfoAutoComponent from "./components/InfoAutoComponent";
import MercadoLibreComponent from "./components/MercadoLibreComponent";
import BtnsSheetsComponent from "./components/BtnsSheetComponent";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useParams } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import "../../styles/style/AnaliticsStyle.css";
import { colors } from "../../styles/colors";
import useFirebaseOnSnapshot from "../../hooks/useFirebaseOnSnapshot";
import { EmptyStateComponent, SpinnerFullScreenComponent } from "../../components";

export default function AnaliticsPage() {
  const { deal_id } = useParams();
  const { user } = useUser();
  const { data, loading, error } = useFirebaseOnSnapshot(user?.company, deal_id, 'pricing_analytics');
  let isPageWide = useMediaQuery("(min-width: 450px)");

  const container = {
    width: isPageWide ? "65vw" : "100%",
  };
  console.log(error)
  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "20px",
    marginTop: "12px",
  };

  return (
    <>{loading
      ? (
        <SpinnerFullScreenComponent />
      ) : (
        <>{error
          ? (
            <div className="containerAll" style={{ height: '600px' }}>
              <EmptyStateComponent />
            </div>
          ) : (
            <div style={container}>
              <div className="container-tables">
                <BtnsSheetsComponent deal_id={deal_id} user={user} data={data} />
              </div>
              <div className="container-tables">
                <p style={textStyle}>Infoauto</p>
                <InfoAutoComponent deal_id={deal_id} user={user} infoAuto={data} />
              </div>
              <div className="container-tables">
                <p style={textStyle}>Camara Val</p>
                <CamaraValComponent data={data} />
              </div>
              <div className="container-tables">
                <p style={textStyle}>Mercado Libre</p>
                <MercadoLibreComponent data={data} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
