import { Modal } from "react-bootstrap";
import { DayPicker } from "react-day-picker";
import es from "date-fns/locale/es";
import { useEffect, useState } from "react";

const ModalCalendarComponent = ({
  modalCalendar,
  setmodalCalendar,
  setdateReminder,
  modal,
}) => {
  const [selected, setSelected] = useState(new Date());

  useEffect(() => {
    setdateReminder(selected);
  }, [modal, selected, setdateReminder]);

  const handleClose = () => {
    setmodalCalendar(false);
  };

  const selectDay = (day) => {
    setdateReminder(day);
    setmodalCalendar(false);
  };

  return (
    <>
      <Modal
        show={modalCalendar}
        onHide={handleClose}
        centered
        dialogClassName="width-modal-calendar"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="add-card-title-modal">Seleccionar una fecha</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DayPicker
            locale={es}
            numberOfMonths={1}
            mode="single"
            selected={selected}
            onSelect={setSelected}
            onDayClick={(day) => selectDay(day)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCalendarComponent;
