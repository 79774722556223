import React from "react";
import { Col, Container } from "react-bootstrap";
import { MdWarning } from "react-icons/md";
import "../styles/style/EmptyStateStyle.css";
export default function EmptyStateComponent({ title, route }) {
  return (
    <Container className="empty-container">
      <Col>
        <MdWarning className="empty-icon" />
        <p className="empty-text">No hay información para mostrar.</p>
      </Col>
    </Container>
  );
}
