import { useUser } from "../../../context/UserContext";
import { MdAttachFile, MdPhoto, MdRemoveRedEye } from "react-icons/md";
import { colors } from "../../../styles/colors";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { HiOutlineDownload } from "react-icons/hi";
import { FileMessageComponent } from "./FileMessageComponent";

const MessageComponent = ({
  id,
  message,
  name,
  team,
  fecha,
  hora,
  file,
  manual,
}) => {
  const { user } = useUser();

  let isPageWide = useMediaQuery("(min-width: 450px)");

  const containerMessage = {
    backgroundColor: !manual
      ? colors.neutral.default
      : colors.primary.primaryDark,
    borderRadius: "8px",
    width: isPageWide ? (message?.length > 40 ? "50vw" : "25vw") : "300px",
  };

  const textName = {
    textAlign: "left",
    color: !manual ? colors.neutral.dark : colors.neutral.light,
    fontWeight: "600",
    fontSize: "14px",
    width: "300px",
  };

  const textMessage = {
    textAlign: "left",
    color: !manual ? colors.neutral.dark : colors.neutral.light,
    fontWeight: "400",
    fontSize: "14px",
    wordWrap: "break-word",
  };

  const textNameFile = {
    fontWeight: "400",
    fontSize: "14px",
    color:
      id !== user.uid ? colors.primary.secondaryDark : colors.neutral.light,
    textAlign: "left",
    width: "100%",
  };

  const textHora = {
    color: colors.primary.secondaryTwo,
    fontWeight: "500",
    fontSize: "12px",
  };

  return (
    <div
      className={`d-flex flex-column justify-content-start align-items-${
        id !== user.uid ? "start" : "end"
      }
      ms=${id !== user.uid ? 4 : 0}
      me=${id !== user.uid ? 0 : 4}
      `}
    >
      <div style={containerMessage} className="p-3">
        <p style={textName} className="m-0">
          {name} ({team})
        </p>
        <p style={textMessage} className="m-0">
          {message}
        </p>
        {file ? (
          Array.isArray(file) ? (
            <FileMessageComponent id={id} user={user} file={file} />
          ) : (
            <div className="d-flex align-items-center justify-content-between">
              {file.type === "application/pdf" ||
              file.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
              file.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                <MdAttachFile
                  fontSize={24}
                  type="file"
                  color={
                    id !== user.uid ? colors.neutral.dark : colors.neutral.light
                  }
                />
              ) : (
                <div>
                  <MdPhoto
                    fontSize={24}
                    color={
                      id !== user.uid
                        ? colors.neutral.dark
                        : colors.neutral.light
                    }
                  />
                </div>
              )}
              <p style={textNameFile} className="m-0 ms-1 me-2">
                {file.name}
              </p>
              <a href={file.urlFirestore} target="_blank" rel="noreferrer">
                <MdRemoveRedEye
                  fontSize={24}
                  color={
                    id !== user.uid ? colors.neutral.dark : colors.neutral.light
                  }
                />
              </a>
              {file.url ? (
                <a href={`${file.url}`} download={file.name} rel="noreferrer">
                  <HiOutlineDownload
                    className="ms-2 me-2"
                    fontSize="22px"
                    color="white"
                  />
                </a>
              ) : null}
            </div>
          )
        ) : null}
      </div>
      <p style={textHora} className="m-0 mb-4 mt-2">
        {fecha}, {hora} hs
      </p>
    </div>
  );
};

export default MessageComponent;
