import React, { useEffect } from 'react'
import { FiltersDateStatusComponent, QuickTakeViewTableComponent } from '../components';
import '../../../styles/style/ViewPages.css'
import { useDeals } from '../../../context/DealsContext';

export default function TakeViewPage() {
  const { setViewName } = useDeals();
  useEffect(() => {
    setViewName('takeView')
  }, [])

  return (
    <div className="p-3 h-100">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className='view-title-page'>Tomas</h1>
        <FiltersDateStatusComponent />
      </div>
      <QuickTakeViewTableComponent />
    </div >

  )
}
