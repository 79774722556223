import React from 'react';
import { NavLink } from 'react-router-dom';

export default function MainMenuItemsComponent({ onClick, isActive, icon, text, to, expanded }) {
  return (
    <NavLink to={to} >
      <div className={`item-view ${isActive ? 'item-active' : ''}`} onClick={onClick}>
        <span>{icon}<span>{text}</span>
        </span>
      </div>
    </NavLink>
  );
}