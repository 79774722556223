import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { updateAssignment } from '../../services/usersConfigServices';

const AssignmentDealModalComponent = ({ show, onHide, mode, assignment, assignments, setAssignments }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [assignmentValue, setAssignmentValue] = useState('');
  const [email, setEmail] = useState('');
  const [trengoId, setTrengoId] = useState('');

  useEffect(() => {
    if (mode === 'edit' && assignment) {
      setName(assignment.name);
      setAssignmentValue(String(assignment.percentage));
      setEmail(assignment.email);
      setTrengoId(assignment.trengo_id);
    } else {
      setName('');
      setAssignmentValue('');
      setEmail('');
      setTrengoId('');
    }
  }, [mode, assignment]);

  const handleUpdateAssignment = async () => {
    try {
      setIsLoading(true);
      const updatedAssignment = {
        name: name,
        percentage: parseInt(assignmentValue),
        trengo_id: trengoId,
        email: email
      };
      let updatedAssignments = [];
      if (mode === 'create') {
        updatedAssignments = [...assignments, updatedAssignment];
      } else if (mode === 'edit' && assignment) {
        updatedAssignments = assignments.map((item) => (item === assignment ? updatedAssignment : item));
      }
      await updateAssignment(updatedAssignments);
      setAssignments(updatedAssignments);
      setIsLoading(false);
      onHide();
    } catch (error) {
      console.error('Error updating assignment:', error);
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <div className='p-4'>
        <p className='add-card-title-modal mb-2 '>{mode === 'create' ? 'Cargar' : 'Editar'} Asignación</p>
        <div className='d-flex  justify-content-between'>
          <div className=' '>
            <label className='trengo-input-label mb-1'>Nombre</label>
            <input
              type="text"
              className="trengo-input mb-4"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder='Ingrese nombre'
            />
            <label className='trengo-input-label mb-1'>Asignación</label>
            <input
              type="text"
              className="trengo-input mb-4"
              value={assignmentValue}
              onChange={(e) => setAssignmentValue(e.target.value)}
              placeholder='Ingrese asignación'
            />
          </div>
          <div className=''>
            <label className='trengo-input-label mb-1 '>Trengo ID</label>
            <input
              type="text"
              className="trengo-input mb-4"
              placeholder='Ingrese trengo id'
              value={trengoId}
              onChange={(e) => setTrengoId(e.target.value)}
            />
            <label className='trengo-input-label mb-1'>Email</label>
            <input
              type="text"
              className="trengo-input mb-4"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Ingrese email'
            />
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <Button
            variant="outline-primary"
            style={{ width: '100px' }}
            onClick={onHide}>
            Cerrar
          </Button>
          <Button
            style={{ width: '100px' }}
            className='ms-3'
            onClick={handleUpdateAssignment}
            disabled={!name || !assignmentValue || !email || isLoading}
          >
            {isLoading ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
              />
            ) : 'Guardar'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AssignmentDealModalComponent;
