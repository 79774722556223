import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import {
  handleSingleCheckAndInputBoxChange,
  updateFormDataTableView,
  updateFormDataTableViewMultiple,
} from '../../../../../utils/sectionFuncions';
import {
  airConditioningOptions,
  audioOptions,
  cleanUpholsteryOptions,
  commandDoorsOptions,
  keysOptions,
  parkingOptions,
  solarRoofOptions,
  upholsteredOptions,
  witnessesOptions,
} from '../../../../../constants/sectionsConst';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';

export default function Section4TabComponent({
  formDataTableView,
  saveFormDataTable,
  setFormDataTableView,
  editAllowed
}) {
  let isMobile = useMediaQuery("(max-width: 500px)");
  const { section4 } = formDataTableView;
  const [selectedCommandsValue, setSelectedCommandsValue] = useState('');

  const handleCheckboxChangeKeys = (sectionName, nestedObjectName, rowTitle, optionIndex) => {
    const selectedOption = keysOptions[optionIndex];
    updateFormDataTableView(setFormDataTableView, sectionName, nestedObjectName, rowTitle, selectedOption, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeWitnesses = (sectionName, nestedObjectName, rowTitle, optionIndex) => {
    const selectedOption = witnessesOptions[optionIndex];
    updateFormDataTableView(setFormDataTableView, sectionName, nestedObjectName, rowTitle, selectedOption, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeCommandDoors = (sectionName, nestedObjectName, rowTitle, optionIndex) => {
    const selectedOption = commandDoorsOptions[optionIndex];
    updateFormDataTableView(setFormDataTableView, sectionName, nestedObjectName, rowTitle, selectedOption, formDataTableView[sectionName]);
  };
  const handleCheckboxChangeAudio = (sectionName, nestedObjectName, rowTitle, optionIndex) => {
    const selectedOption = audioOptions[optionIndex];
    updateFormDataTableView(setFormDataTableView, sectionName, nestedObjectName, rowTitle, selectedOption, formDataTableView[sectionName]);
  };
  const handleCheckboxChangParking = (sectionName, nestedObjectName, rowTitle, optionIndex) => {
    const selectedOption = parkingOptions[optionIndex];
    updateFormDataTableView(setFormDataTableView, sectionName, nestedObjectName, rowTitle, selectedOption, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeUpholstered = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleOptionChangeAirConditioning = (value) => {
    saveFormDataTable('section4', 'airConditioning',
      { title: 'Aire Acondicionado', values: value });
  };

  const handleOptionChangeSolarRoof = (value) => {
    saveFormDataTable('section4', 'solarRoof',
      { title: ' Techo Solar', values: value });
  };

  const handleOptionChangeCleanUpholstery = (value) => {
    saveFormDataTable('section4', 'cleanUpholstery',
      { title: 'Tapizados', values: value });
  };

  const handleCheckboxAndInputChangeCommands = (value) => {
    saveFormDataTable('section4', 'commands',
      { title: 'Comandos', values: value });
  };

  const handleTextChange = (value) => {
    saveFormDataTable('section4', 'commentUpholstered',
      { title: 'Comentario Tapizados', values: value });
  }

  return (
    <>
      {!isMobile ? (
        <Container fluid>
          <h1 className='section-title'>Habitáculo</h1>
          <Row>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Llaves</label>
                  <span className="text-danger">*</span>
                </div>
                <table className="section-table" id='section4-keys'>
                  <thead>
                    <tr>
                      <th className="section-table-cell"></th>
                      {keysOptions.map((option, index) => (
                        <th key={index} className="section-table-cell">{option}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {section4?.keys?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="section-table-cell">{row.title}</td>
                        {keysOptions.map((option, optionIndex) => (
                          <td key={optionIndex} className="section-table-cell">
                            <Form.Check
                              disabled={!editAllowed}
                              type="checkbox"
                              className='section-checks'
                              checked={row?.values?.includes(option)}
                              onChange={() => handleCheckboxChangeKeys('section4', 'keys', row.title, optionIndex)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Aire Acondicionado</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section4-airConditioning'>
                  {airConditioningOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      checked={section4?.airConditioning?.values === option}
                      onChange={() => handleOptionChangeAirConditioning(option)}
                      className={`section-checks ${index === airConditioningOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Techo Solar</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section4-solarRoof'>
                  {solarRoofOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      checked={section4?.solarRoof?.values === option}
                      onChange={() => handleOptionChangeSolarRoof(option)}
                      className={`section-checks ${index === solarRoofOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Comandos</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section4-commands'>
                  <Form.Check
                    disabled={!editAllowed}
                    type="checkbox"
                    label='Ok'
                    value='Ok'
                    checked={section4?.commands?.values === 'Ok'}
                    className='mb-2 section-checks'
                    onChange={(e) => {
                      handleSingleCheckAndInputBoxChange(e.target.value, setSelectedCommandsValue);
                      handleCheckboxAndInputChangeCommands(e.target.value)
                    }}
                  />
                  <div className="d-flex align-items-center">
                    <Form.Check
                      disabled={!editAllowed}
                      type="checkbox"
                      label="Otro:"
                      value='Other'
                      className='section-checks'
                      checked={section4?.commands?.values !== 'Ok' || selectedCommandsValue === 'Other'}
                      onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedCommandsValue)}
                    />
                    <input

                      type="text"
                      className='section-input ms-2'
                      value={section4?.commands?.values !== 'Ok' ? section4?.commands?.values : ''}
                      onChange={(e) => handleCheckboxAndInputChangeCommands(e.target.value)}
                      disabled={selectedCommandsValue !== 'Other' || !editAllowed}
                    />
                  </div>
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Testigos</label>
                  <span className="text-danger">*</span>
                </div>
                <table className="section-table" id='section4-witnesses'>
                  <thead>
                    <tr>
                      <th className="section-table-cell"></th>
                      {witnessesOptions.map((option, index) => (
                        <th key={index} className="section-table-cell">{option}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {section4?.witnesses?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="section-table-cell">{row.title}</td>
                        {witnessesOptions.map((option, optionIndex) => (
                          <td key={optionIndex} className="section-table-cell">
                            <Form.Check
                              disabled={!editAllowed}
                              type="checkbox"
                              className='section-checks'
                              checked={row?.values?.includes(option)}
                              onChange={() => handleCheckboxChangeWitnesses('section4', 'witnesses', row.title, optionIndex)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <Row className='mt-2 mb-2'>
            <Col md={6} >
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Tapizados</label>
                  <span className="text-danger">*</span>
                </div>
                <table className="section-table" id='section4-upholstered'>
                  <thead>
                    <tr>
                      <th className="section-table-cell"></th>
                      {upholsteredOptions.map((option, index) => (
                        <th key={index} className="section-table-cell">{option}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {section4?.upholstered?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="section-table-cell">{row.title}</td>
                        {upholsteredOptions.map((option, optionIndex) => (
                          <td key={optionIndex} className="section-table-cell">
                            <Form.Check
                              disabled={!editAllowed}
                              type="checkbox"
                              className='section-checks'
                              checked={row.values.includes(option)}
                              onChange={() => handleCheckboxChangeUpholstered('section4', 'upholstered', row.title, option)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Tapizados</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section4-cleanUpholstery'>
                  {cleanUpholsteryOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      value={option}
                      checked={section4?.cleanUpholstery?.values === option}
                      onChange={() => handleOptionChangeCleanUpholstery(option)}
                      className={`section-checks ${index === cleanUpholsteryOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2' style={{ height: '92%' }} id='section4-commentUpholstered'>
                <label className='section-input-label'>Comentario Tapizados</label>
                <textarea
                  className='section-input'
                  style={{ height: '92%' }}
                  value={section4?.commentUpholstered?.values}
                  disabled={!editAllowed}
                  onChange={(e) => handleTextChange(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Comando puertas</label>
                  <span className="text-danger">*</span>
                </div>
                <table className="section-table" id='section4-commandDoors'>
                  <thead>
                    <tr>
                      <th className="section-table-cell"></th>
                      {commandDoorsOptions.map((option, index) => (
                        <th key={index} className="section-table-cell">{option}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {section4?.commandDoors?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="section-table-cell">{row.title}</td>
                        {commandDoorsOptions.map((option, optionIndex) => (
                          <td key={optionIndex} className="section-table-cell">
                            <Form.Check
                              disabled={!editAllowed}
                              type="checkbox"
                              className='section-checks'
                              checked={row?.values?.includes(option)}
                              onChange={() => handleCheckboxChangeCommandDoors('section4', 'commandDoors', row.title, optionIndex)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-between" >
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Audio</label>
                  <span className="text-danger">*</span>
                </div>
                <table className="section-table" id='section4-audio'>
                  <thead>
                    <tr>
                      <th className="section-table-cell"></th>
                      {audioOptions.map((option, index) => (
                        <th key={index} className="section-table-cell">{option}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {section4?.audio?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="section-table-cell">{row.title}</td>
                        {audioOptions.map((option, optionIndex) => (
                          <td key={optionIndex} className="section-table-cell">
                            <Form.Check
                              disabled={!editAllowed}
                              type="checkbox"
                              className='section-checks'
                              checked={row?.values?.includes(option)}
                              onChange={() => handleCheckboxChangeAudio('section4', 'audio', row.title, optionIndex)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Parking</label>
                  <span className="text-danger">*</span>
                </div>
                <table className="section-table" id='section4-parking'>
                  <thead>
                    <tr>
                      <th className="section-table-cell"></th>
                      {parkingOptions.map((option, index) => (
                        <th key={index} className="section-table-cell">{option}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {section4?.parking?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="section-table-cell">{row.title}</td>
                        {parkingOptions.map((option, optionIndex) => (
                          <td key={optionIndex} className="section-table-cell">
                            <Form.Check
                              disabled={!editAllowed}
                              type="checkbox"
                              className='section-checks'
                              checked={row?.values?.includes(option)}
                              onChange={() => handleCheckboxChangParking('section4', 'parking', row.title, optionIndex)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Container >
      ) : (
        <Container fluid>
          <h1 className='section-title'>Habitáculo</h1>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Llaves</label>
              <span className="text-danger">*</span>
            </div>
            <table className="section-table" id='section4-keys'>
              <thead>
                <tr>
                  <th className="section-table-cell"></th>
                  {keysOptions.map((option, index) => (
                    <th key={index} className="section-table-cell">{option}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {section4?.keys?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="section-table-cell">{row.title}</td>
                    {keysOptions.map((option, optionIndex) => (
                      <td key={optionIndex} className="section-table-cell">
                        <Form.Check
                          disabled={!editAllowed}
                          type="checkbox"
                          className='section-checks'
                          checked={row?.values?.includes(option)}
                          onChange={() => handleCheckboxChangeKeys('section4', 'keys', row.title, optionIndex)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Comandos</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section4-commands'>
              <Form.Check
                disabled={!editAllowed}
                type="checkbox"
                label='Ok'
                value='Ok'
                checked={section4?.commands?.values === 'Ok'}
                className='mb-2 section-checks'
                onChange={(e) => {
                  handleSingleCheckAndInputBoxChange(e.target.value, setSelectedCommandsValue);
                  handleCheckboxAndInputChangeCommands(e.target.value)
                }}
              />
              <div className="d-flex align-items-center">
                <Form.Check
                  disabled={!editAllowed}
                  type="checkbox"
                  label="Otro:"
                  value='Other'
                  className='section-checks'
                  checked={section4?.commands?.values !== 'Ok' || selectedCommandsValue === 'Other'}
                  onChange={(e) => handleSingleCheckAndInputBoxChange(e.target.value, setSelectedCommandsValue)}
                />
                <input
                  type="text"
                  className='section-input ms-2'
                  value={section4?.commands?.values !== 'Ok' ? section4?.commands?.values : ''}
                  onChange={(e) => handleCheckboxAndInputChangeCommands(e.target.value)}
                  disabled={selectedCommandsValue !== 'Other' || !editAllowed}
                />
              </div>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Aire Acondicionado</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section4-airConditioning'>
              {airConditioningOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  checked={section4?.airConditioning?.values === option}
                  onChange={() => handleOptionChangeAirConditioning(option)}
                  className={`section-checks ${index === airConditioningOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Techo Solar</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section4-solarRoof'>
              {solarRoofOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  checked={section4?.solarRoof?.values === option}
                  onChange={() => handleOptionChangeSolarRoof(option)}
                  className={`section-checks ${index === solarRoofOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Testigos</label>
              <span className="text-danger">*</span>
            </div>
            <table className="section-table" id='section4-witnesses'>
              <thead>
                <tr>
                  <th className="section-table-cell"></th>
                  {witnessesOptions.map((option, index) => (
                    <th key={index} className="section-table-cell">{option}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {section4?.witnesses?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="section-table-cell">{row.title}</td>
                    {witnessesOptions.map((option, optionIndex) => (
                      <td key={optionIndex} className="section-table-cell">
                        <Form.Check
                          disabled={!editAllowed}
                          type="checkbox"
                          className='section-checks'
                          checked={row?.values?.includes(option)}
                          onChange={() => handleCheckboxChangeWitnesses('section4', 'witnesses', row.title, optionIndex)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Tapizados</label>
              <span className="text-danger">*</span>
            </div>
            <table className="section-table" id='section4-upholstered'>
              <thead>
                <tr>
                  <th className="section-table-cell"></th>
                  {upholsteredOptions.map((option, index) => (
                    <th key={index} className="section-table-cell">{option}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {section4?.upholstered?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="section-table-cell">{row.title}</td>
                    {upholsteredOptions.map((option, optionIndex) => (
                      <td key={optionIndex} className="section-table-cell">
                        <Form.Check
                          disabled={!editAllowed}
                          type="checkbox"
                          className='section-checks'
                          checked={row.values.includes(option)}
                          onChange={() => handleCheckboxChangeUpholstered('section4', 'upholstered', row.title, option)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Tapizados</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section4-cleanUpholstery'>
              {cleanUpholsteryOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  value={option}
                  checked={section4?.cleanUpholstery?.values === option}
                  onChange={() => handleOptionChangeCleanUpholstery(option)}
                  className={`section-checks ${index === cleanUpholsteryOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2' style={{ height: '92%' }} id='section4-commentUpholstered'>
            <label className='section-input-label'>Comentario Tapizados</label>
            <textarea
              className='section-input'
              style={{ height: '92%' }}
              value={section4?.commentUpholstered?.values}
              disabled={!editAllowed}
              onChange={(e) => handleTextChange(e.target.value)}
            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Comando puertas</label>
              <span className="text-danger">*</span>
            </div>
            <table className="section-table" id='section4-commandDoors'>
              <thead>
                <tr>
                  <th className="section-table-cell"></th>
                  {commandDoorsOptions.map((option, index) => (
                    <th key={index} className="section-table-cell">{option}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {section4?.commandDoors?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="section-table-cell">{row.title}</td>
                    {commandDoorsOptions.map((option, optionIndex) => (
                      <td key={optionIndex} className="section-table-cell">
                        <Form.Check
                          disabled={!editAllowed}
                          type="checkbox"
                          className='section-checks'
                          checked={row?.values?.includes(option)}
                          onChange={() => handleCheckboxChangeCommandDoors('section4', 'commandDoors', row.title, optionIndex)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Audio</label>
              <span className="text-danger">*</span>
            </div>
            <table className="section-table" id='section4-audio'>
              <thead>
                <tr>
                  <th className="section-table-cell"></th>
                  {audioOptions.map((option, index) => (
                    <th key={index} className="section-table-cell">{option}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {section4?.audio?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="section-table-cell">{row.title}</td>
                    {audioOptions.map((option, optionIndex) => (
                      <td key={optionIndex} className="section-table-cell">
                        <Form.Check
                          disabled={!editAllowed}
                          type="checkbox"
                          className='section-checks'
                          checked={row?.values?.includes(option)}
                          onChange={() => handleCheckboxChangeAudio('section4', 'audio', row.title, optionIndex)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Parking</label>
              <span className="text-danger">*</span>
            </div>
            <table className="section-table" id='section4-parking'>
              <thead>
                <tr>
                  <th className="section-table-cell"></th>
                  {parkingOptions.map((option, index) => (
                    <th key={index} className="section-table-cell">{option}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {section4?.parking?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="section-table-cell">{row.title}</td>
                    {parkingOptions.map((option, optionIndex) => (
                      <td key={optionIndex} className="section-table-cell">
                        <Form.Check
                          disabled={!editAllowed}
                          type="checkbox"
                          className='section-checks'
                          checked={row?.values?.includes(option)}
                          onChange={() => handleCheckboxChangParking('section4', 'parking', row.title, optionIndex)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Container >
      )}
    </>
  )
}
