import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import {
  updateDataForCheckboxChange,
  updateFormDataTableViewMultiple
} from '../../../../../utils/sectionFuncions';
import {
  antenaOptions,
  bateryOptions,
  bumperMoldingsOptions,
  electroFanOptions,
  engineVanNoiseOptions,
  fluidsOptions,
  frontBadgeOptions,
  frontBumperOptions,
  gridsOptions,
  headlightsOptions,
  heatingRadiatorOptions,
  hoodOptions,
  motorizedBodyOptions,
  oilVentOptions,
  roofOptions,
  windshieldOptions,
  wiperBladesOptions
} from '../../../../../constants/sectionsConst';
import { useMediaQuery } from '../../../../../hooks/useMediaQuery';

export default function Section5TabComponent({
  formDataTableView,
  saveFormDataTable,
  setFormDataTableView,
  editAllowed
}) {
  let isMobile = useMediaQuery("(max-width: 500px)");

  const { section5 } = formDataTableView;

  const handleRoofChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.roof.values, value)
    saveFormDataTable('section5', 'roof',
      { title: 'Techo Carrocería', values: updatedValues });
  };

  const handleHoodChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.hood.values, value)
    saveFormDataTable('section5', 'hood', {
      title: 'Capot', values: updatedValues
    });
  };

  const handleOptionChangeAntena = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.antena.values, value)
    saveFormDataTable('section5', 'antena',
      { title: 'Antena', values: updatedValues });
  }

  const handleBumperMoldingsChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.bumperMoldings.values, value)
    saveFormDataTable('section5', 'bumperMoldings',
      { title: 'Molduras Paragolpes', values: updatedValues });
  };

  const handleWindshieldChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.windshield.values, value)
    saveFormDataTable('section5', 'windshield',
      { title: 'Parabrisas', values: updatedValues });
  };

  const handleWiperBladesChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.wiperBlade.values, value)
    saveFormDataTable('section5', 'wiperBlade',
      { title: 'Escobillas', values: updatedValues });
  };

  const handleFrontBumperChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.frontBumper.values, value)
    saveFormDataTable('section5', 'frontBumper',
      { title: 'Paragolpes Delantero', values: updatedValues });
  };

  const handleHeatingRadiatorChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.heatingRadiator.values, value)
    saveFormDataTable('section5', 'heatingRadiator',
      { title: 'Radiador de Calefacción', values: updatedValues });
  };

  const handleOilVentChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.oilVent.values, value)
    saveFormDataTable('section5', 'oilVent',
      { title: 'Venteo de Aceite', values: updatedValues });
  };


  const handleFrontBadgeChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.frontBadge.values, value)
    saveFormDataTable('section5', 'frontBadge',
      { title: 'Insignia Delantera', values: updatedValues });
  };

  const handleEngineVanNoiseChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.engineVanNoise.values, value)
    saveFormDataTable('section5', 'engineVanNoise',
      { title: 'Ruido Vano Motora', values: updatedValues });
  };

  const handleBateryChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.batery.values, value)
    saveFormDataTable('section5', 'batery',
      { title: 'Batería', values: updatedValues });
  };

  const handleElectroFanChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.electroFan.values, value)
    saveFormDataTable('section5', 'electroFan',
      { title: 'Electroventilador', values: updatedValues });
  };

  const handleTurboChange = (value) => {
    const updatedValues = updateDataForCheckboxChange(section5.turbo.values, value)
    saveFormDataTable('section5', 'turbo', {
      title: 'Turbo',
      values: updatedValues
    });
  }
  const handleCheckboxChangeGrids = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeHeadlights = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeFluids = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleCheckboxChangeMotorizedBody = (sectionName, nestedObjectName, rowTitle, option) => {
    updateFormDataTableViewMultiple(setFormDataTableView, sectionName, nestedObjectName, rowTitle, option, formDataTableView[sectionName]);
  };

  const handleInputCommentsRoofAndFront = (value) => {
    saveFormDataTable('section5', 'commentsRoofAndFront',
      { title: 'Comentarios Techo y Frente', values: value });
  }
  const handleInputCommentsFluidLoss = (value) => {
    saveFormDataTable('section5', 'commentsFluidLoss',
      { title: 'Perdida de Fluidos (Que y Donde)', values: value });
  }

  const handleInputCommentEngineSpan = (value) => {
    saveFormDataTable('section5', 'commentEngineSpan',
      { title: 'Comentario Vano Motor', values: value });
  }

  return (
    <>
      {!isMobile ? (
        <Container fluid>
          <h1 className='section-title'>Exterior frente y motor</h1>
          <Row>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Techo Carrocería</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-roof'>
                  {roofOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={index}
                      type="checkbox"
                      label={option}
                      name="roof"
                      value={option}
                      checked={section5?.roof?.values?.includes(option)}
                      onChange={() => handleRoofChange(option)}
                      className={`section-checks ${index === roofOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Capot</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' d='section5-hood'>
                  {hoodOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={index}
                      type="checkbox"
                      label={option}
                      name="hood"
                      checked={section5?.hood?.values?.includes(option)}
                      onChange={() => handleHoodChange(option)}
                      className={`section-checks ${index === hoodOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Molduras Paragolpes</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-bumperMoldings'>
                  {bumperMoldingsOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="bumperMoldings"
                      checked={section5?.bumperMoldings?.values?.includes(option)}
                      onChange={() => handleBumperMoldingsChange(option)}
                      className={`section-checks ${index === bumperMoldingsOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Escobillas</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-wiperBlade'>
                  {wiperBladesOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="wiperBlades"
                      checked={section5?.wiperBlade?.values?.includes(option)}
                      onChange={() => handleWiperBladesChange(option)}
                      className={`section-checks ${index === wiperBladesOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Antena</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-antena'>
                  {antenaOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name='antena'
                      checked={section5?.antena?.values?.includes(option)}
                      onChange={() => handleOptionChangeAntena(option)}
                      className={`section-checks ${index === antenaOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Grillas</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section5-grids'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {gridsOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section5?.grids?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {gridsOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeGrids('section5', 'grids', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Faros Delanteros</label>
                  <span className="text-danger">*</span>
                </div>
                <table className="section-table" id='section5-headlights'>
                  <thead>
                    <tr>
                      <th className="section-table-cell"></th>
                      {headlightsOptions.map((option, index) => (
                        <th key={index} className="section-table-cell">{option}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {section5?.headlights?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="section-table-cell">{row.title}</td>
                        {headlightsOptions.map((option, optionIndex) => (
                          <td key={optionIndex} className="section-table-cell">
                            <Form.Check
                              disabled={!editAllowed}
                              type="checkbox"
                              className='section-checks'
                              checked={row.values.includes(option)}
                              onChange={() => handleCheckboxChangeHeadlights('section5', 'headlights', row.title, option)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Parabrisas</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-windshield'>
                  {windshieldOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name='windshield'
                      checked={section5?.windshield?.values?.includes(option)}
                      onChange={() => handleWindshieldChange(option)}
                      className={`section-checks ${index === windshieldOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-2 mb-2'>
            <Col md={6} >
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Paragolpes Delantero</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-frontBumper'>
                  {frontBumperOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={index}
                      type="checkbox"
                      label={option}
                      name="frontBumper"
                      checked={section5?.frontBumper?.values?.includes(option)}
                      onChange={() => handleFrontBumperChange(option)}
                      className={`section-checks ${index === frontBumperOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Radiador de Calefacción</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-heatingRadiator'>
                  {heatingRadiatorOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="heatingRadiator"
                      checked={section5?.heatingRadiator?.values?.includes(option)}
                      onChange={() => handleHeatingRadiatorChange(option)}
                      className={`section-checks ${index === heatingRadiatorOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Fluidos</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section5-fluids'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {fluidsOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section5?.fluids?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {fluidsOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeFluids('section5', 'fluids', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Venteo de Aceite</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-oilVent'>
                  {oilVentOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="oilVent"
                      checked={section5?.oilVent?.values?.includes(option)}
                      onChange={() => handleOilVentChange(option)}
                      className={`section-checks ${index === oilVentOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Ruido Vano Motora</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-engineVanNoise'>
                  {engineVanNoiseOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={index}
                      type="checkbox"
                      label={option}
                      name="roof"
                      checked={section5?.engineVanNoise?.values?.includes(option)}
                      onChange={() => handleEngineVanNoiseChange(option)}
                      className={`section-checks ${index === engineVanNoiseOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2' id='section5-commentEngineSpan'>
                <label className='section-input-label'>Comentario Vano Motor</label>
                <textarea
                  disabled={!editAllowed}
                  className='section-input'
                  value={section5?.commentEngineSpan?.values}
                  onChange={(e) => handleInputCommentEngineSpan(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6} className="d-flex flex-column justify-content-between">
              <div className='mb-2' style={{ height: '80%' }} id='section5-commentsRoofAndFront'>
                <label className='section-input-label'>Comentarios Techo y Frente</label>
                <textarea
                  className='section-input'
                  style={{ height: '86%' }}
                  value={section5?.commentsRoofAndFront?.values}
                  onChange={(e) => handleInputCommentsRoofAndFront(e.target.value)}
                />
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Insignia Delantera</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-frontBadge'>
                  {frontBadgeOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="frontBadge"
                      checked={section5?.frontBadge?.values?.includes(option)}
                      onChange={() => handleFrontBadgeChange(option)}
                      className={`section-checks ${index === frontBadgeOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2' style={{ height: '80%' }} id='section5-commentsFluidLoss'>
                <label className='section-input-label'>Perdida de Fluidos (Que y Donde)</label>
                <textarea
                  className='section-input'
                  disabled={!editAllowed}
                  style={{ height: '80%' }}
                  value={section5?.commentsFluidLoss?.values}
                  onChange={(e) => handleInputCommentsFluidLoss(e.target.value)}

                />
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Cuerpo Motorizado</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='overflow-x-auto'>
                  <table className="section-table" id='section5-motorizedBody'>
                    <thead>
                      <tr>
                        <th className="section-table-cell"></th>
                        {motorizedBodyOptions.map((option, index) => (
                          <th key={index} className="section-table-cell">{option}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {section5?.motorizedBody?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td className="section-table-cell">{row.title}</td>
                          {motorizedBodyOptions.map((option, optionIndex) => (
                            <td key={optionIndex} className="section-table-cell">
                              <Form.Check
                                disabled={!editAllowed}
                                type="checkbox"
                                className='section-checks'
                                checked={row?.values?.includes(option)}
                                onChange={() => handleCheckboxChangeMotorizedBody('section5', 'motorizedBody', row.title, option)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Batería</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-batery'>
                  {bateryOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="batery"
                      checked={section5?.batery?.values?.includes(option)}
                      onChange={() => handleBateryChange(option)}
                      className={`section-checks ${index === bateryOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <label className='section-input-label'>Electroventilador</label>
                <div className='section-container' id='section5-electroFan'>
                  {electroFanOptions.map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name="electroFan"
                      checked={section5?.electroFan?.values?.includes(option)}
                      onChange={() => handleElectroFanChange(option)}
                      className={`section-checks ${index === electroFanOptions.length - 1 ? '' : 'mb-2'}`}
                    />
                  ))}
                </div>
              </div>
              <div className='mb-2'>
                <div className='d-flex align-items-center'>
                  <label className='section-input-label me-1'>Turbo</label>
                  <span className="text-danger">*</span>
                </div>
                <div className='section-container' id='section5-turbo'>
                  {['Ok', 'No ok'].map((option, index) => (
                    <Form.Check
                      disabled={!editAllowed}
                      key={option}
                      type="checkbox"
                      label={option}
                      name={option}
                      value={option}
                      checked={section5?.turbo?.values?.includes(option)}
                      className={`section-checks ${index !== 0 ? '' : 'mb-2'}`}
                      onChange={() => handleTurboChange(option)}
                    />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container >
      ) : (
        <Container fluid>
          <h1 className='section-title'>Exterior frente y motor</h1>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Techo Carrocería</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-roof'>
              {roofOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={index}
                  type="checkbox"
                  label={option}
                  name="roof"
                  value={option}
                  checked={section5?.roof?.values?.includes(option)}
                  onChange={() => handleRoofChange(option)}
                  className={`section-checks ${index === roofOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Antena</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-antena'>
              {antenaOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name='antena'
                  checked={section5?.antena?.values?.includes(option)}
                  onChange={() => handleOptionChangeAntena(option)}
                  className={`section-checks ${index === antenaOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Grillas</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section5-grids'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {gridsOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section5?.grids?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {gridsOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeGrids('section5', 'grids', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Capot</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' d='section5-hood'>
              {hoodOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={index}
                  type="checkbox"
                  label={option}
                  name="hood"
                  checked={section5?.hood?.values?.includes(option)}
                  onChange={() => handleHoodChange(option)}
                  className={`section-checks ${index === hoodOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Faros Delanteros</label>
              <span className="text-danger">*</span>
            </div>
            <table className="section-table" id='section5-headlights'>
              <thead>
                <tr>
                  <th className="section-table-cell"></th>
                  {headlightsOptions.map((option, index) => (
                    <th key={index} className="section-table-cell">{option}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {section5?.headlights?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="section-table-cell">{row.title}</td>
                    {headlightsOptions.map((option, optionIndex) => (
                      <td key={optionIndex} className="section-table-cell">
                        <Form.Check
                          disabled={!editAllowed}
                          type="checkbox"
                          className='section-checks'
                          checked={row.values.includes(option)}
                          onChange={() => handleCheckboxChangeHeadlights('section5', 'headlights', row.title, option)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Molduras Paragolpes</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-bumperMoldings'>
              {bumperMoldingsOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="bumperMoldings"
                  checked={section5?.bumperMoldings?.values?.includes(option)}
                  onChange={() => handleBumperMoldingsChange(option)}
                  className={`section-checks ${index === bumperMoldingsOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Parabrisas</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-windshield'>
              {windshieldOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name='windshield'
                  checked={section5?.windshield?.values?.includes(option)}
                  onChange={() => handleWindshieldChange(option)}
                  className={`section-checks ${index === windshieldOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Escobillas</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-wiperBlade'>
              {wiperBladesOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="wiperBlades"
                  checked={section5?.wiperBlade?.values?.includes(option)}
                  onChange={() => handleWiperBladesChange(option)}
                  className={`section-checks ${index === wiperBladesOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Paragolpes Delantero</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-frontBumper'>
              {frontBumperOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={index}
                  type="checkbox"
                  label={option}
                  name="frontBumper"
                  checked={section5?.frontBumper?.values?.includes(option)}
                  onChange={() => handleFrontBumperChange(option)}
                  className={`section-checks ${index === frontBumperOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2' style={{ height: '80%' }} id='section5-commentsRoofAndFront'>
            <label className='section-input-label'>Comentarios Techo y Frente</label>
            <textarea
              className='section-input'
              style={{ height: '86%' }}
              value={section5?.commentsRoofAndFront?.values}
              onChange={(e) => handleInputCommentsRoofAndFront(e.target.value)}
            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Radiador de Calefacción</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-heatingRadiator'>
              {heatingRadiatorOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="heatingRadiator"
                  checked={section5?.heatingRadiator?.values?.includes(option)}
                  onChange={() => handleHeatingRadiatorChange(option)}
                  className={`section-checks ${index === heatingRadiatorOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Insignia Delantera</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-frontBadge'>
              {frontBadgeOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="frontBadge"
                  checked={section5?.frontBadge?.values?.includes(option)}
                  onChange={() => handleFrontBadgeChange(option)}
                  className={`section-checks ${index === frontBadgeOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Fluidos</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section5-fluids'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {fluidsOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section5?.fluids?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {fluidsOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeFluids('section5', 'fluids', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2' style={{ height: '80%' }} id='section5-commentsFluidLoss'>
            <label className='section-input-label'>Perdida de Fluidos (Que y Donde)</label>
            <textarea
              className='section-input'
              disabled={!editAllowed}
              style={{ height: '80%' }}
              value={section5?.commentsFluidLoss?.values}
              onChange={(e) => handleInputCommentsFluidLoss(e.target.value)}

            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Cuerpo Motorizado</label>
              <span className="text-danger">*</span>
            </div>
            <div className='overflow-x-auto'>
              <table className="section-table" id='section5-motorizedBody'>
                <thead>
                  <tr>
                    <th className="section-table-cell"></th>
                    {motorizedBodyOptions.map((option, index) => (
                      <th key={index} className="section-table-cell">{option}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {section5?.motorizedBody?.filter(item => typeof item === 'object').map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="section-table-cell">{row.title}</td>
                      {motorizedBodyOptions.map((option, optionIndex) => (
                        <td key={optionIndex} className="section-table-cell">
                          <Form.Check
                            disabled={!editAllowed}
                            type="checkbox"
                            className='section-checks'
                            checked={row?.values?.includes(option)}
                            onChange={() => handleCheckboxChangeMotorizedBody('section5', 'motorizedBody', row.title, option)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Venteo de Aceite</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-oilVent'>
              {oilVentOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="oilVent"
                  checked={section5?.oilVent?.values?.includes(option)}
                  onChange={() => handleOilVentChange(option)}
                  className={`section-checks ${index === oilVentOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Batería</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-batery'>
              {bateryOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="batery"
                  checked={section5?.batery?.values?.includes(option)}
                  onChange={() => handleBateryChange(option)}
                  className={`section-checks ${index === bateryOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Ruido Vano Motora</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-engineVanNoise'>
              {engineVanNoiseOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={index}
                  type="checkbox"
                  label={option}
                  name="roof"
                  checked={section5?.engineVanNoise?.values?.includes(option)}
                  onChange={() => handleEngineVanNoiseChange(option)}
                  className={`section-checks ${index === engineVanNoiseOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2'>
            <label className='section-input-label'>Electroventilador</label>
            <div className='section-container' id='section5-electroFan'>
              {electroFanOptions.map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name="electroFan"
                  checked={section5?.electroFan?.values?.includes(option)}
                  onChange={() => handleElectroFanChange(option)}
                  className={`section-checks ${index === electroFanOptions.length - 1 ? '' : 'mb-2'}`}
                />
              ))}
            </div>
          </div>
          <div className='mb-2' id='section5-commentEngineSpan'>
            <label className='section-input-label'>Comentario Vano Motor</label>
            <textarea
              disabled={!editAllowed}
              className='section-input'
              value={section5?.commentEngineSpan?.values}
              onChange={(e) => handleInputCommentEngineSpan(e.target.value)}
            />
          </div>
          <div className='mb-2'>
            <div className='d-flex align-items-center'>
              <label className='section-input-label me-1'>Turbo</label>
              <span className="text-danger">*</span>
            </div>
            <div className='section-container' id='section5-turbo'>
              {['Ok', 'No ok'].map((option, index) => (
                <Form.Check
                  disabled={!editAllowed}
                  key={option}
                  type="checkbox"
                  label={option}
                  name={option}
                  value={option}
                  checked={section5?.turbo?.values?.includes(option)}
                  className={`section-checks ${index !== 0 ? '' : 'mb-2'}`}
                  onChange={() => handleTurboChange(option)}
                />
              ))}
            </div>
          </div>
        </Container >
      )}
    </>
  )
}
