import React, { useEffect } from "react";
import {
  QuickCheckViewTableComponent,
  QuickViewsFilterComponent,
} from "../components";
import { useDeals } from "../../../context/DealsContext";

export default function CheckViewPage() {
  const { setViewName } = useDeals();

  useEffect(() => {
    setViewName("checkView");
  }, []);

  return (
    <div className="p-3 h-100">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="view-title-page">Inspecciones</h1>
        <QuickViewsFilterComponent />
      </div>
      <QuickCheckViewTableComponent />
    </div>
  );
}
